import {
    isLoadingBrandsAction,
    saveBrandsAction,
    saveBrandsFavoriteListAction,
    storeActiveBrandAction,
    isSwitchingBrandAction
} from "./actions";
import { handleActions } from "redux-actions";

const initialState = {
    brandList: null,
    isLoadingBrands: false,
    activeBrand: null,
    isSwitchingBrand: false,
    favorite: []
};

export const BrandReducer = handleActions(
    {
        [isSwitchingBrandAction]: ( state, { payload } ) => ( {
            ...state,
            isSwitchingBrand: payload
        } ),
        [isLoadingBrandsAction]: ( state, { payload } ) => ( {
            ...state,
            isLoadingBrands: payload
        } ),
        [saveBrandsAction]: ( state, { payload } ) => ( {
            ...state,
            brandList: payload
        } ),
        [saveBrandsFavoriteListAction]: ( state, { payload } ) => ( {
            ...state,
            favorite: payload
        } ),
        [storeActiveBrandAction]: ( state, { payload } ) => ( {
            ...state,
            activeBrand: payload
        } )
    },
    initialState
);

import React from "react";
import "./Tooltip.scss";

export const Tooltip = ( { children, title = "" } ) => {
    return (
        <div className="tooltip-box">
            <div className="tooltip-body">{title}</div>
            {children}
        </div>
    );
};

import React from "react";
import { ProdSwitcher as Component } from "./ProdSwitcher";
import { urlUtil } from "utils";

const { getProdPath } = urlUtil;

export const ProdSwitcher = ( { activeModule, activeModuleState, className } ) => {
    return (
        <Component
            activeModuleState={activeModuleState}
            className={className}
            activeModule={activeModule}
            getProdPath={getProdPath}
        />
    );
};

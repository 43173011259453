import React, { PureComponent } from "react";
import { sendSvg } from "components";
import { Loader, CardActions } from "components";
import "./NoteBlockViewDetails.scss";

export class NoteBlockViewDetails extends PureComponent {
    state = {
        text: "",
        showInput: false,
        loading: false
    };

    static getDerivedStateFromProps( nextProps, prevState ) {
        if ( Object.prototype.hasOwnProperty.call( nextProps, "note" ) && prevState.text === "" ) {
            return {
                text: nextProps.note
            };
        }
        return null;
    }

    addTextComment = e => {
        this.setState( {
            text: e.target.value
        } );
    };

    editAction = () => {
        this.setState( {
            showInput: true
        } );
    };

    addCommentToCard = () => {
        const { text } = this.state;
        const { id, updateCardItemAction } = this.props;
        return new Promise( ( resolve, reject ) => {
            updateCardItemAction( { id: id, text: text, resolve, reject } );
            this.setState( { loading: true } );
        } )
            .then( () => {
                this.setState( {
                    text: "",
                    showInput: false,
                    loading: false
                } );
            } )
            .catch( err => {
                this.setState( {
                    loading: false
                } );
            } );
    };

    render() {
        const { note = null } = this.props;
        const { text, showInput, loading } = this.state;
        return (
            <div className="note-block">
                <div className="note-block__header">
                    <span>Comment</span>
                </div>
                {note ? (
                    <ul className="notes-lists">
                        <li>
                            <div className="text-box">{note}</div>
                            <div className="lists__header">
                                <CardActions
                                    horizontally={true}
                                    deleteBtn={false}
                                    editAction={this.editAction}
                                />
                            </div>
                        </li>
                    </ul>
                ) : null}
                {loading ? (
                    <Loader style={{ margin: "50px auto" }} />
                ) : !note || showInput ? (
                    <div className="send-note">
                        <label>
                            <input
                                type="text"
                                placeholder="Type comment…"
                                value={text || ""}
                                onChange={this.addTextComment}
                            />
                        </label>
                        <button type="submit" onClick={this.addCommentToCard}>
                            {sendSvg()}
                        </button>
                    </div>
                ) : null}
            </div>
        );
    }
}

import React, { PureComponent } from "react";
import { Loader, PaginationFooter } from "components";
import { CardView, FilterCustomize } from "./components/";
// import { pageListViewChangeMPAction } from "../../services/UserView/actions";

export class PageRoute extends PureComponent {
    render() {
        const {
            entries,
            isLoadingCardListState,
            activeModuleState,
            pageViewState,
            pageListViewChangeMPAction,
            activeFilter,
            platformListLoaderState,
            activeBrandState,
            statusListState,
            statusListIsLoadingState,
            platformListState,
            loadMoreAction,
            handleChangeLimitPage,
            handleChangeFilter,
            handlePageChange,
            countAvailableEntries,
            countLoadedEntries,
            isLoadingMore,
            filterEntriesLimit,
            filterPageNumber
        } = this.props;

        return (
            <div>
                {/*FilterCustomize start*/}
                <FilterCustomize
                    statusListState={statusListState}
                    statusListIsLoadingState={statusListIsLoadingState}
                    platformListState={platformListState}
                    activeModuleState={activeModuleState}
                    activeFilter={activeFilter}
                    platformListLoaderState={platformListLoaderState}
                    cardListState={entries}
                    activeView={pageViewState}
                    pageViewChange={pageListViewChangeMPAction}
                    handleChangeLimitPage={handleChangeLimitPage}
                    handleChangeFilter={handleChangeFilter}
                />
                {/*FilterCustomize end*/}

                {/*Mapping Cards start*/}
                {isLoadingCardListState || !activeBrandState ? (
                    <div className="col-lg-12">
                        <Loader style={{ margin: "15% auto" }} />
                    </div>
                ) : entries ? (
                    //@TODO: Get rid of this, should be entries prop in cardview too
                    <CardView {...this.props} cardListState={entries} />
                ) : null}
                {/*Mapping Cards end*/}

                {/*Footer start*/}
                {!isLoadingCardListState && entries && (
                    <PaginationFooter
                        entries={entries}
                        handlePageChange={handlePageChange}
                        handleLoadMore={() => loadMoreAction()}
                        isLoadingMore={isLoadingMore}
                        countAvailable={countAvailableEntries}
                        countLoaded={countLoadedEntries}
                        limit={filterEntriesLimit}
                        page={filterPageNumber}
                    />
                )}
                {/*Footer end*/}
            </div>
        );
    }
}

import { takeLatest, put, delay } from "redux-saga/effects";
import { saveMessageBodyAction, showAndHideGlobalMessageAction } from "./actions";

const defaultTimeout = 3000;

//WORKERS
function* showAndHideGlobalMessageWorker( { payload } ) {
    const timeout = payload.timeout;

    delete payload.timeout;

    yield put( saveMessageBodyAction( payload ) );
    yield delay( timeout || defaultTimeout );
    yield put( saveMessageBodyAction( null ) );
}

export function* showAndHideGlobalMessageWatcher() {
    yield takeLatest( showAndHideGlobalMessageAction,  showAndHideGlobalMessageWorker );
}
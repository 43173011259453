import { connect } from "react-redux";
import { ViewDetails as component } from "./ViewDetails";

import { activeModuleStateSelector } from "services/UserView/selectors";
import { viewDetailItemSelector } from "pages/MarketProtect/services/Entries/selectors";
import {
    showViewDetailsPanelMPAction,
    goToNextViewDetailsMPAction
} from "pages/MarketProtect/services/UserView/actions";
import { infringementTypeListSelector } from "services/InfringementType/selectors";
import {
    statusListSelector
    //statusListIsLoadingSelector
} from "pages/MarketProtect/services/Status/selectors";
import {
    //isLoadingLabelsSelector,
    labelsSelector
} from "pages/MarketProtect/services/Label/selectors";
import {
    startEnforcementAction,
    selectedPostMarkIdAction
} from "pages/MarketProtect/services/Enforcement/actions";
import { selectedNewPostMarkAction } from "pages/MarketProtect/services/Enforcement/actions";
import {
    selectedPostMarkIdSelector,
    startEnforcementLoaderSelector,
    selectedEnforcementStatusIdSelector,
    selectedInfringementTypeIdSelector
} from "pages/MarketProtect/services/Options/selectors";
import {
    selectInfringementTypeInTopBarAction,
    selectEnforcementStatusInTopBarAction
} from "pages/MarketProtect/services/Options/actions";

const mapDispatchToProps = {
    showViewDetailsPanelMPAction,
    goToNextViewDetailsMPAction,
    selectedPostMarkIdAction,
    selectedNewPostMarkAction,
    selectInfringementTypeInTopBarAction,
    selectEnforcementStatusInTopBarAction,
    startEnforcementAction
};

const mapStateToProps = state => {
    const activeModuleState = activeModuleStateSelector( state );
    const viewDetailItemDataState = viewDetailItemSelector( state );
    const startEnforcementLoaderState = startEnforcementLoaderSelector( state );
    const selectedPostMarkIdState = selectedPostMarkIdSelector( state );
    const labelsState = labelsSelector( state );
    const infringementTypeListState = infringementTypeListSelector( state );
    const infringementTypeIdState = selectedInfringementTypeIdSelector( state );
    const statusListState = statusListSelector( state );
    const selectedEnforcementStatusIdState = selectedEnforcementStatusIdSelector( state );
    return {
        activeModuleState,
        viewDetailItemDataState,
        startEnforcementLoaderState,
        selectedPostMarkIdState,
        labelsState,
        infringementTypeIdState,
        infringementTypeListState,
        statusListState,
        selectedEnforcementStatusIdState
    };
};

export const ViewDetails = connect(
    mapStateToProps,
    mapDispatchToProps
)( component );

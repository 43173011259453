import React from "react";
import { BrandSelect, ProdSwitcher, HeaderWrapper } from "components";
import { AddItem } from "pages/Landing/components";

export const Header = props => {
    return (
        <HeaderWrapper
            left={( <div className="title">{props.title}</div> )}
            center={props.children}
            right={(
                <>
                    <ProdSwitcher {...props} />
                    <AddItem />
                    <BrandSelect {...props} />
                </>
            )}
        />
    );
};

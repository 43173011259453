import React, { PureComponent } from "react";
import { BrandSelect, MenuModules, ProdSwitcher, HeaderWrapper } from "components";

export class Header extends PureComponent {
    render() {
        const { title = "", children } = this.props;
        return (
            <HeaderWrapper
                left={( <div className="title">{title}</div> )}
                center={children}
                right={(
                    <>
                        <ProdSwitcher {...this.props} />
                        <MenuModules />
                        <BrandSelect {...this.props} />
                    </>
                )}
            />
        );
    }
}

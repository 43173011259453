//@TODO: Can this be abstracted like the label service? We are duplicating stuff between MP and SP.
import { createSelector } from "reselect";
import { activeModuleStateSelector } from "services/UserView/selectors";


export const searchWordTitleSelector = createSelector(
    state => {
        const filter = activeFilterSelector( state );
        return filter.searchTitle;
    },
    state => state
);

export const currentPageSelector = createSelector(
    state => {
        const filter = activeFilterSelector( state );

        return filter.page;
    },
    state => state
);

export const activeFilterSelector = createSelector(
    state => {
        const activeModuleState = activeModuleStateSelector( state );
        return state.marketProtect.options.filter[activeModuleState];
    },
    state => state
);

export const filterEntriesLimitSelector = createSelector(
    state => activeFilterSelector( state ).limit,
    state => state
);

export const filterPageNumberSelector = createSelector(
    state => activeFilterSelector( state ).page,
    state => state
);

export const allFiltersSelector = createSelector(
    state => {
        return state.marketProtect.options.filter;
    },
    state => state
);

export const selectedPostMarkIdSelector = createSelector(
    state => {
        return state.marketProtect.options.selectedPostMarkId;
    },
    state => state
);

export const startEnforcementLoaderSelector = createSelector(
    state => state.marketProtect.options.startEnforcementLoader,
    state => state
);

export const selectedEnforcementStatusIdSelector = createSelector(
    state => {
        return state.marketProtect.options.selectedEnforcementStatusId;
    },
    state => state
);

export const selectedInfringementTypeIdSelector = createSelector(
    state => {
        return state.marketProtect.options.selectedInfringementTypeId;
    },
    state => state
);
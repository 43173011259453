import React from "react";
import "./EnforcementActionButtons.scss";
import { Button, Loader } from "components";

export const EnforcementActionButtons = props => {
    return (
        props.isEnforcementLoading ? (
            <Loader style={{ margin: "19px auto 19px" }} />
        ) : (
            <div className="select_block btn-row">
                <div>
                    <Button
                        text="Start Enforcement"
                        handleClick={props.startEnforcement}
                    />
                    <Button
                        text="Cancel"
                        color="secondary"
                        handleClick={props.closeEnforcement}
                    />
                </div>
                <div>{props.textMessage}</div>
            </div>
        )
    );
};

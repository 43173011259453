import {
    setAlertModalIsOpenAction,
    toggleExportDataModalIsOpenAction,
    setAlertModalConfigurationAction,
    toggleImportModalStateAction
} from "./actions";
import { handleActions } from "redux-actions";

const initialState = {
    alertModalIsOpen: false,
    alertModalConfiguration: false,
    exportDataModalIsOpen: false,
    importDataModalIsOpen: false
};

export const modalReducer = handleActions(
    {
        [setAlertModalIsOpenAction]: ( state, { payload } ) => ( {
            ...state,
            alertModalIsOpen: payload
        } ),
        [setAlertModalConfigurationAction]: ( state, { payload } ) => ( {
            ...state,
            alertModalConfiguration: payload
        } ),
        [toggleExportDataModalIsOpenAction]: state => ( {
            ...state,
            exportDataModalIsOpen: !state.exportDataModalIsOpen
        } ),
        [toggleImportModalStateAction]: state => ( {
            ...state,
            importDataModalIsOpen: !state.importDataModalIsOpen
        } )
    },
    initialState
);

import { createSelector } from "reselect";
import { activeModuleStateSelector } from "services/UserView/selectors";

export const pageViewStateSelector = createSelector(
    state => {
        const activeModuleState = activeModuleStateSelector( state );
        return state.marketProtect.userView[activeModuleState].page_list_view;
    },
    state => state
);

export const customizeTableViewSelector = createSelector(
    state => {
        const activeModuleState = activeModuleStateSelector( state );
        return state.marketProtect.userView[activeModuleState].customize_table_view;
    },
    state => state
);

export const pageCardDisplayViewSelector = createSelector(
    state => {
        const activeModuleState = activeModuleStateSelector( state );
        return state.marketProtect.userView[activeModuleState].page_card_display_view;
    },
    state => state
);
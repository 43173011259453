//@TODO: no longer "connected" b/c of refacto. Can move.

import { history } from "services/Navigation/history";

export function getObjectFromURLParams() {
    const newFilterObj = {};
    let { search } = history.location;
    if ( search && search !== "" ) search = search.slice( 1 );
    search.split( "&" ).forEach( e => {
        if ( !e || ( e && e.indexOf( "=" ) === -1 ) ) return;
        let newObjItem = e.split( "=" );
        newFilterObj[newObjItem[0]] = decodeURIComponent( newObjItem[1] );
    } );
    return newFilterObj;
}

import React from "react";
import PropTypes from "prop-types";
import IconButton from "@material-ui/core/IconButton";
import { closeSvg } from "components";

export const ButtonClose = props => {
    const { color, onClick, className } = props;

    return (
        <IconButton
            className={className || ""}
            onClick={onClick}
            aria-label="Close"
        >
            {closeSvg( color )}
        </IconButton>
    );
};

ButtonClose.propTypes = {
    onClick: PropTypes.func.isRequired,
    color: PropTypes.string.isRequired
};

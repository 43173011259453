import React, { PureComponent } from "react";
import {
    draggingSvg,
    tableL,
    tableM,
    tableS,
    cardView1,
    cardView2,
    cardView6,
    cardView4,
    cardView5
} from "components/Icons/Svg";
import { connect } from "react-redux";
import { Checkbox, CustomizeBlockWrapper } from "components";
import {
    tableViewChangeAction,
    tableColumnSelectedChangeAction,
    tableSequenceColumnChangeAction,
    pageCardDisplayViewChangeAction
} from "pages/SocialProtect/services/UserView/actions";
import { customizeTableViewSelector } from "pages/SocialProtect/services/UserView/selectors";
import {
    tableColumnSelectedTasksSelector,
    tableColumnSelectedColumnsSelector,
    pageViewStateSelector,
    pageCardDisplayViewSelector
} from "pages/SocialProtect/services/UserView/selectors";
import { activeModuleStateSelector } from "services/UserView/selectors";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { uniqueId } from "utils";

class CustomizeBlockDisplay extends PureComponent {
    constructor( props ) {
        super( props );
        this.myRef = React.createRef();
        this.state = {
            open: false,
            selectAll: false
        };
    }

    static getDerivedStateFromProps( nextProps ) {
        if ( nextProps.tasks && nextProps.columns ) {
            let marker = true;
            nextProps.columns.taskIds.forEach( e => {
                if ( !nextProps.tasks[e].value ) marker = false;
            } );
            if ( marker ) {
                return {
                    selectAll: true
                };
            } else {
                return {
                    selectAll: false
                };
            }
        }
        return null;
    }

    componentDidMount() {
        document.addEventListener( "click", this.documentClick,  { capture: true } );
    }

    componentWillUnmount() {
        document.removeEventListener( "click", this.documentClick,  { capture: true } );
    }

    changeView = view => {
        const { tableViewChangeAction } = this.props;
        tableViewChangeAction( view );
    };

    changeCardDisplayView = value => {
        const { pageCardDisplayViewChangeAction } = this.props;
        this.setState( { open: false }, () => {
            pageCardDisplayViewChangeAction( value );
        } );
    };

    documentClick = e => {
        const { open } = this.state;
        const node = this.myRef.current;
        const target = e.target.closest( ".customize-body" );
        if ( target !== node && open ){
            this.closeDropDown();
        }
    };

    changeStateDropDown = () => {
        const { disabled } = this.props;
        if ( disabled ) return;
        this.setState( prevState => ( { open: !prevState.open } ) );
    };

    closeDropDown = () => {
        this.setState( { open: false } );
    };

    onDragEnd = result => {
        const { columns, tableSequenceColumnChangeAction } = this.props;
        const { destination, source, draggableId } = result;
        if ( !destination ) return;
        if (
            destination.droppableId === source.droppableId &&
            destination.index === source.index
        ) {
            return;
        }
        const newTasksId = Array.from( columns.taskIds );
        newTasksId.splice( source.index, 1 );
        newTasksId.splice( destination.index, 0, draggableId );
        tableSequenceColumnChangeAction( newTasksId );
    };

    handleChangeCheckBox = event => {
        const { tableColumnSelectedChangeAction } = this.props;
        const { tasks } = this.props;
        const newTasks = { ...tasks };
        newTasks[event.name].value = event.checked;
        tableColumnSelectedChangeAction( newTasks );
    };

    changeAll = value => {
        const { tasks, tableColumnSelectedChangeAction, activeModuleState } = this.props;
        const newTasks = { ...tasks };
        for ( let key in tasks ) {
            if( tasks[key].visibility[activeModuleState] && tasks[key].disabled ) continue;
            let newItem = { ...tasks[key] };
            newItem.value = value;
            newTasks[key] = newItem;
        }
        tableColumnSelectedChangeAction( newTasks );
    };

    render() {
        const { open, selectAll } = this.state;
        const {
            tasks,
            columns,
            pageView,
            pageCardDisplayView,
            tableView: { view = "large" } = {},
            activeModuleState,
            label
        } = this.props;
        if ( activeModuleState === "profiles" && pageView === "block" ) return null;

        return (
            <CustomizeBlockWrapper
                myRef={this.myRef}
                open={open}
                pageView={pageView}
                label={label}
                changeStateDropDown={this.changeStateDropDown}
                cardList={(
                    <ul>
                        <li
                            className={
                                pageCardDisplayView === "block-description-x1"
                                    ? "active"
                                    : ""
                            }
                        >
                            <button
                                type="button"
                                onClick={() => {
                                    this.changeCardDisplayView( "block-description-x1" );
                                }}
                            >
                                {cardView1(
                                    pageCardDisplayView === "block-description-x1"
                                        ? "#445bf4"
                                        : "#809096"
                                )}
                            </button>
                        </li>

                        <li
                            className={
                                pageCardDisplayView === "block-description-x2"
                                    ? "active"
                                    : ""
                            }
                        >
                            <button
                                type="button"
                                onClick={() => {
                                    this.changeCardDisplayView( "block-description-x2" );
                                }}
                            >
                                {cardView2(
                                    pageCardDisplayView === "block-description-x2"
                                        ? "#445bf4"
                                        : "#809096"
                                )}
                            </button>
                        </li>

                        <li
                            className={
                                pageCardDisplayView === "block-img-x1" ? "active" : ""
                            }
                        >
                            <button
                                type="button"
                                onClick={() => {
                                    this.changeCardDisplayView( "block-img-x1" );
                                }}
                            >
                                {cardView4(
                                    pageCardDisplayView === "block-img-x1"
                                        ? "#445bf4"
                                        : "#809096"
                                )}
                            </button>
                        </li>

                        <li
                            className={
                                pageCardDisplayView === "block-img-x2" ? "active" : ""
                            }
                        >
                            <button
                                type="button"
                                onClick={() => {
                                    this.changeCardDisplayView( "block-img-x2" );
                                }}
                            >
                                {cardView5(
                                    pageCardDisplayView === "block-img-x2"
                                        ? "#445bf4"
                                        : "#809096"
                                )}
                            </button>
                        </li>

                        <li
                            className={
                                pageCardDisplayView === "block-img-x3" ? "active" : ""
                            }
                        >
                            <button
                                type="button"
                                onClick={() => {
                                    this.changeCardDisplayView( "block-img-x3" );
                                }}
                            >
                                {cardView6(
                                    pageCardDisplayView === "block-img-x3"
                                        ? "#445bf4"
                                        : "#809096"
                                )}
                            </button>
                        </li>
                    </ul>
                )}
                dragDropContext={
                    <DragDropContext onDragEnd={this.onDragEnd}>
                        <Droppable droppableId="droppable">
                            {( provided, snapshot ) => (
                                <ul
                                    {...provided.droppableProps}
                                    ref={provided.innerRef}
                                >
                                    {columns && columns.taskIds
                                    && columns.taskIds.map( ( e, i ) => (
                                        <Draggable
                                            key={uniqueId()}
                                            draggableId={tasks[e].droppableId}
                                            index={i}
                                        >
                                            {( provided, snapshot ) => (
                                                <li
                                                    ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                    {...provided.dragHandleProps}
                                                    className={
                                                        snapshot.isDragging ? "dragging" : ""
                                                    }
                                                >
                                                    <div className="icon-dragging">
                                                        {draggingSvg( i )}
                                                    </div>
                                                    <Checkbox
                                                        handleChange={
                                                            this.handleChangeCheckBox
                                                        }
                                                        name={tasks[e].label}
                                                        label={tasks[e].label}
                                                        disabled={tasks[e].disabled}
                                                        defaultValue={tasks[e].value}
                                                    />
                                                </li>
                                            )}
                                        </Draggable>
                                    ) )}
                                    {provided.placeholder}
                                </ul>
                            )}
                        </Droppable>
                    </DragDropContext>
                }
                rowHeightList={(
                    <ul>
                        <li>
                            <button
                                type="button"
                                onClick={() => this.changeView( "large" )}
                            >
                                {tableL( view === "large" ? "#445bf4" : "#809096" )}
                            </button>
                        </li>
                        <li>
                            <button
                                type="button"
                                onClick={() => this.changeView( "medium" )}
                            >
                                {tableM( view === "medium" ? "#445bf4" : "#809096" )}
                            </button>
                        </li>
                        <li>
                            <button
                                type="button"
                                onClick={() => this.changeView( "small" )}
                            >
                                {tableS( view === "small" ? "#445bf4" : "#809096" )}
                            </button>
                        </li>
                    </ul>
                )}
                selectAll={selectAll}
                changeAll={this.changeAll}
            />
        );
    }
}

const mapDispatchToProps = {
    tableViewChangeAction,
    tableColumnSelectedChangeAction,
    tableSequenceColumnChangeAction,
    pageCardDisplayViewChangeAction
};

const mapStateToProps = state => {
    const activeModuleState = activeModuleStateSelector( state );
    const tableView = customizeTableViewSelector( state );
    const tasks = tableColumnSelectedTasksSelector( state );
    const columns = tableColumnSelectedColumnsSelector( state );
    const pageView = pageViewStateSelector( state );
    const pageCardDisplayView = pageCardDisplayViewSelector( state );
    return {
        tableView,
        tasks,
        columns,
        pageView,
        pageCardDisplayView,
        activeModuleState
    };
};

export const CustomizeBlock = connect(
    mapStateToProps,
    mapDispatchToProps
)( CustomizeBlockDisplay );

import React from "react";
import {
    StatusDropdown,
    ButtonHoverSVG,
    Button,
    ChangeLabel
} from "components";
import "./MarkEditorInViewDetailPanel.scss";

export const MarkEditorInViewDetailPanel = ( {
    data,
    data: { mark } = {},
    labelsState,
    selectedPostMarkIdState,
    setOptions = () => {},
    setCurrentInfringementType = () => {},
    clickApplyInfringement = () => {},
    closeList = () => {},
    selectedPostMark = () => {},
    optionValue,
    valueList,
    changeListValues
} ) => {

    labelsState =  ( labelsState && labelsState.filter( ( e )=> e.name !== "Unhandled" ) ) || [];

    return (
        <div className="mark-editor-view-detail">
            <StatusDropdown
                wrapperClassName="status-dropdown"
                data={labelsState}
                defaultValue={
                    selectedPostMarkIdState
                        ? selectedPostMarkIdState
                        : mark && mark.id
                            ? mark.id
                            : ""
                }
                handleChange={e => {
                    selectedPostMark( e );
                }}
            />
            {( mark && mark.id === 1 ) ||
      ( selectedPostMarkIdState && selectedPostMarkIdState === 1 ) ? (
                    <div className="set-infringing-status-type">
                        <ButtonHoverSVG onClick={() => setOptions( "type" )}>
                            {( data.infringementType && data.infringementType.name ) || "Set Type"}
                        </ButtonHoverSVG>
                        <ButtonHoverSVG onClick={() => setOptions( "status" )}>
                            {( data.enforcementStatus && data.enforcementStatus.name ) || "Set Status"}
                        </ButtonHoverSVG>
                    </div>
                ) : null}

            <ChangeLabel
                open={!!optionValue}
                title={optionValue}
                defaultValue={valueList}
                data={changeListValues}
                handleChange={setCurrentInfringementType}
            >
                <Button text="Apply" handleClick={clickApplyInfringement} />
                <Button text="Cancel" color="secondary" handleClick={closeList} />
            </ChangeLabel>
        </div>
    );
};

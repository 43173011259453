import React from "react";
import "./Loader.scss";

export const Loader = props => {
    return (
        <div className="lds-ellipsis" {...props}>
            <div />
            <div />
            <div />
            <div />
        </div>
    );
};

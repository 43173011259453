import React from "react";
import "./SliderModal.scss";
import { sliderPrevSvg, sliderNextSvg } from "components/Icons/Svg";
import { SlickSlider, ButtonClose } from "components";
import Modal from "@material-ui/core/Modal";

export const SliderModal = ( {
    slickGoTo,
    visible = null,
    title = "All Images",
    children = null,
    toggleDialog = ()=>{}
} ) => {
    const prevSvg = sliderPrevSvg();
    const nextSvg = sliderNextSvg();
    const PrevArrow = props => (
        <button
            onClick={props.onClick}
            type="button"
            className="slick-prev pull-left"
        >
            {prevSvg}
        </button>
    );
    const NextArrow = props => (
        <button
            onClick={props.onClick}
            type="button"
            className="slick-next pull-right"
        >
            {nextSvg}
        </button>
    );
    return (
        <Modal open={visible} disablePortal={true} onClose={toggleDialog}>
            <div className={"main-modal"}>
                <ButtonClose
                    color="#979797"
                    className="close-modal"
                    onClick={toggleDialog}
                />
                <div className="title-slider">{title}</div>
                <SlickSlider
                    multiple={true}
                    cssClass={"view-panel-slider"}
                    cssClass2={"view-panel-slider-list"}
                    next={<NextArrow />}
                    prev={<PrevArrow />}
                    slickGoTo={slickGoTo}
                    props1={{
                        fade: true,
                        infinite: true,
                        speed: 500
                    }}
                    props2={{
                        slidesToShow: 10,
                        infinite: true,
                        swipeToSlide: true,
                        focusOnSelect: true
                    }}
                >
                    {children}
                </SlickSlider>
            </div>
        </Modal>
    );
};

import { createAction } from "redux-actions";

export const pageListViewChangeAction = createAction(
    "pageListViewChangeAction"
);
export const pageCardDisplayViewChangeAction = createAction(
    "pageCardDisplayViewChangeAction"
);

export const tableViewChangeAction = createAction( "tableViewChangeAction" );
export const tableColumnSelectedChangeAction = createAction(
    "tableColumnSelectedChangeAction"
);
export const tableSequenceColumnChangeAction = createAction(
    "tableSequenceColumnChangeAction"
);

export const itemViewDetailsPostsSaveAction = createAction(
    "itemViewDetailsPostsSaveAction"
);
export const itemViewDetailsProfilesSaveAction = createAction(
    "itemViewDetailsProfilesSaveAction"
);

export const goToNextViewDetailsAction = createAction(
    "goToNextViewDetailsAction"
);

//Post
export const pageListViewPostsSaveAction = createAction(
    "pageListViewPostsSaveAction"
);

export const tableViewPostsSaveAction = createAction(
    "tableViewPostsSaveAction"
);
export const tableColumnPostsSelectedSaveAction = createAction(
    "tableColumnPostsSelectedSaveAction"
);
export const tableSequenceColumnPostsSaveAction = createAction(
    "tableSequenceColumnPostsSaveAction"
);

export const pageCardDisplayPostsViewSaveAction = createAction(
    "pageCardDisplayPostsViewSaveAction"
);

// Profiles
export const pageListViewProfilesSaveAction = createAction(
    "pageListViewProfilesSaveAction"
);

export const tableViewProfilesSaveAction = createAction(
    "tableViewProfilesSaveAction"
);
export const tableColumnProfilesSelectedSaveAction = createAction(
    "tableColumnProfilesSelectedSaveAction"
);
export const tableSequenceColumnProfilesSaveAction = createAction(
    "tableSequenceColumnProfilesSaveAction"
);

export const pageCardDisplayProfilesViewSaveAction = createAction(
    "pageCardDisplayProfilesViewSaveAction"
);

//Change PerPage value and make a call to get new Data list
export const getNewDataWithChangingUserViewAction = createAction(
    "getNewDataWithChangingUserViewAction"
);

import React from "react";
import { ReactDropZone, Button, Loader } from "components";
import { importSvg, warningSvg } from "components/Icons/Svg";

export const ImportFileModalAddFile = props => {
    const {
        activeModuleState,
        error,
        loading,
        file,
        sendImportData,
        toggleDialogImport,
        getFileData,
        removeError
    } = props;
    return (
        <div className="import-box">
            <div className="wrapper-import-box">
                {error ? (
                    <div>
                        <div className="svg-box">{warningSvg()}</div>
                        <div className="error-message-modal">{error}</div>
                        <div
                            className="error-btn"
                            onClick={removeError}
                            role="presentation"
                        >
              Back to Upload
                        </div>
                    </div>
                ) : loading ? (
                    <Loader />
                ) : (
                    <ReactDropZone
                        getFileData={getFileData}
                        title="Upload csv/xls/xlsx file"
                        svg={importSvg()}
                        className="drop-zone-import"
                    />
                )}
            </div>
            <div className="file-name">{file ? `File name: ${file}` : null}</div>
            <div className="footer-import">
                <div className="btn-row">
                    <Button
                        text="Upload"
                        disabled={!file || loading}
                        handleClick={() => sendImportData( false )}
                    />
                    <Button
                        text="Cancel"
                        color="secondary"
                        handleClick={toggleDialogImport}
                    />
                </div>
                <div>
                    <a
                        target="_self"
                        href={`https://revlect.com/bundles/pointersocialmediastorage/file/${activeModuleState}.csv`}
                        download
                        title="Download template example"
                    >
            Download template example
                    </a>
                </div>
            </div>
        </div>
    );
};

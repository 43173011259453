import { createSelector } from "reselect";
import { activeModuleStateSelector } from "services/UserView/selectors";

export function generateIsLoadingLabelsSelector( activeModule ) {
    return createSelector(
        state => state[activeModule].label.isLoadingLabels,
        state => state
    );
}

export function generateLabelsSelector( appModule  ) {
    return createSelector(
        state => {
            const activeModuleState = activeModuleStateSelector( state );

            if ( !activeModuleState ) return null;

            return state[appModule].label[activeModuleState];
        },
        state => state
    );
}
import { createSelector } from "reselect";

export const platformListSelector = createSelector(
    state => state.main.platform.platformList,
    state => state
);

export const platformListLoaderSelector = createSelector(
    state => state.main.platform.platformLoader,
    state => state
);

import { createSelector } from "reselect";
import { activeModuleStateSelector } from "services/UserView/selectors";

export const cardListLoaderSelector = createSelector(
    state => state.socialProtect.options.cardListLoader,
    state => state
);

export const currentPageSelector = createSelector(
    ( state, activeModuleState ) => {
        if ( !activeModuleState ) activeModuleState = state.main.userView.activeModuleState;

        return state.socialProtect[activeModuleState].card_list_data.page;
    },
    state => state
);

export const cardListDataSelector = createSelector(
    ( state, activeModuleState ) => {
        if ( !activeModuleState ) activeModuleState = state.main.userView.activeModuleState;
        return state.socialProtect[activeModuleState].card_list_data;
    },
    state => state
);

export const selectedCardsList = createSelector(
    state => {
        const activeModuleState = activeModuleStateSelector( state );
        return state.socialProtect[activeModuleState].selected_card.arrID;
    },
    state => state
);

export const selectedAllCardsStateSelector = createSelector(
    state => {
        const activeModuleState = activeModuleStateSelector( state );
        return state.socialProtect[activeModuleState].selected_card.selectAll;
    },
    state => state
);

export function genCharArray( charA, charZ ) {
    const a = [],
        j = charZ.charCodeAt( 0 );
    let i = charA.charCodeAt( 0 );

    for ( ; i <= j; ++i ) {
        a.push( String.fromCharCode( i ) );
    }

    return a;
}

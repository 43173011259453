import React from "react";
import { Button, Loader } from "components";
import { closeSvg, warningSvg, tikSvg } from "components/Icons/Svg";

export const ImportFileModalError = props => {
    const {
        exportErrorLinksToCsvAction,
        error,
        loading,
        dataAfterImportState,
        successLength,
        copyErrorLinksImportAction,
        sendImportData,
        removeError,
        toggleDialogImport
    } = props;
    return (
        <div className="import-error">
            <div className="wrapper-import-box scroll-m errors">
                {error ? (
                    <div>
                        <div className="svg-box">{warningSvg()}</div>
                        <div className="error-message-modal">{error}</div>
                        <div className="error-btn" onClick={removeError} role="presentation">
                            Back to Upload
                        </div>
                    </div>
                ) : loading ? (
                    <Loader />
                ) : (
                    <div className="table-wrapper">
                        <div className="header-row">
                            <div />
                            <div>Title</div>
                            <div>Status</div>
                            <div />
                        </div>
                        <div className="table-list">
                            <div className="table-list__body">
                                {dataAfterImportState &&
                  dataAfterImportState.map( ( e, i ) => (
                      <div key={i} className="item-row">
                          <div>
                              {e.listingImage ? (
                                  <img
                                      className="logo"
                                      src={e.listingImage}
                                      alt="logo-success"
                                  />
                              ) : (
                                  <div className="logo" />
                              )}
                          </div>
                          <div>
                              <a
                                  href={e.listingURL || e.profileURL || ""}
                                  rel="noopener noreferrer"
                                  target="_blank"
                              >
                                  {e.listingTitle ||
                            e.profileName ||
                            "Click here to see URL"}
                              </a>
                          </div>
                          <div>
                              {e.errorsArr.length ? (
                                  <div className="error-text">
                                      {`Row - ${e.errorsRow}`}
                                      {e.errorsArr.map( ( e, i ) => (
                                          <div key={i}>{e}</div>
                                      ) )}
                                  </div>
                              ) : (
                                  <div>Success</div>
                              )}
                          </div>
                          <div>
                              {e.errorsArr.length ? (
                                  <div className="circle success">
                                      {closeSvg( "#fff" )}
                                  </div>
                              ) : (
                                  <div className="circle error">{tikSvg( "#fff" )}</div>
                              )}
                          </div>
                      </div>
                  ) )}
                            </div>
                        </div>
                    </div>
                )}
            </div>
            <div className="footer-import">
                <div className="btn-row">
                    <Button
                        text="Add successfully links"
                        disabled={loading || !successLength}
                        handleClick={() => sendImportData( true )}
                    />
                    <Button
                        text="Export error links"
                        color="secondary"
                        handleClick={() => exportErrorLinksToCsvAction()}
                    />
                    <Button
                        text="Copy error links"
                        color="secondary"
                        handleClick={() => copyErrorLinksImportAction()}
                    />
                    <Button
                        text="Cancel"
                        color="secondary"
                        handleClick={toggleDialogImport}
                    />
                </div>
            </div>
        </div>
    );
};

import { loadInfringementTypeListAction, saveInfringementTypeListAction } from "./actions";
import { handleActions } from "redux-actions";

const initialState = {
    infringementTypeList: null,
    infringementTypeLoader: false
};

export const InfringementTypeReducer = handleActions(
    {
        [loadInfringementTypeListAction]: ( state, { payload } ) => ( {
            ...state,
            infringementTypeLoader: payload
        } ),
        [saveInfringementTypeListAction]: ( state, { payload } ) => ( {
            ...state,
            infringementTypeList: payload
        } )
    },
    initialState,
);

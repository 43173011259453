import React, { PureComponent } from "react";
import {
    Header,
    PageRoute,
    SideBar,
    ViewDetails
} from "pages/MarketProtect/components";
import { TabBar, NoMatch } from "components";
import { Route, Switch, Redirect, NavLink } from "react-router-dom";
import { OptionsTopBar } from "pages/MarketProtect/components";

export class Dashboard extends PureComponent {
    constructor( props ) {
        super( props );
        const { match } = this.props;
        this.baseUrl =
        match.url[match.url.length - 1] === "/" ? match.url : match.url + "/";
    }

    render() {
        const { sideBarIsOpen, activeModuleState } = this.props;
        let pageClassName = "page bg-primary-transparent";

        if ( sideBarIsOpen ) pageClassName += " with-open-side-bar";
        if ( !activeModuleState ) return <Redirect to={`${this.baseUrl}listings`} />;

        return (
            <div>
                {/*SideBar start*/}
                <SideBar />
                {/*SideBar end*/}

                {/*SpOptionsTopBar start*/}
                <OptionsTopBar />
                {/*SpOptionsTopBar end*/}

                <div className={pageClassName}>
                    <div className="page-container scroll-m">
                        {/*Header start*/}
                        <Header title="Market Protect">
                            <TabBar>
                                <NavLink to="/market-protect/listings" activeClassName="active">
                                    Listings
                                </NavLink>
                                <NavLink to="/market-protect/sellers" activeClassName="active">
                                    Sellers
                                </NavLink>
                            </TabBar>
                        </Header>
                        {/*Header end*/}

                        {/*View Details start*/}
                        <ViewDetails />
                        {/*View Details end*/}

                        <Switch>
                            <Route
                                exact
                                path={this.baseUrl}
                                render={() => <Redirect to={`${this.baseUrl}listings`} />}
                            />
                            <Route path={`${this.baseUrl}listings`} component={PageRoute} />
                            <Route path={`${this.baseUrl}sellers`} component={PageRoute} />
                            <Route component={NoMatch} />
                        </Switch>
                    </div>
                </div>
            </div>
        );
    }
}

import React, { PureComponent } from "react";
import {
    cardView1,
    cardView2,
    cardView6,
    cardView4,
    cardView5
} from "components/Icons/Svg";
import { connect } from "react-redux";
import { CustomizeBlockWrapper } from "components";
import {
    pageCardDisplayViewMPChangeAction
} from "pages/MarketProtect/services/UserView/actions";
import {
    pageCardDisplayViewSelector,
    pageViewStateSelector
} from "pages/MarketProtect/services/UserView/selectors";
import { activeModuleStateSelector } from "services/UserView/selectors";


class CustomizeBlockDisplay extends PureComponent {
    constructor( props ) {
        super( props );
        this.myRef = React.createRef();
        this.state = {
            open: false,
            selectAll: false
        };
    }

    static getDerivedStateFromProps( nextProps ) {
        if ( nextProps.tasks && nextProps.columns ) {
            let marker = true;
            nextProps.columns.taskIds.forEach( e => {
                if ( !nextProps.tasks[e].value ) marker = false;
            } );
            if ( marker ) {
                return {
                    selectAll: true
                };
            } else {
                return {
                    selectAll: false
                };
            }
        }
        return null;
    }

    componentDidMount() {
        document.addEventListener( "click", this.documentClick );
    }

    componentWillUnmount() {
        document.removeEventListener( "click", this.documentClick );
    }

    changeView = view => {
        const { tableViewChangeAction } = this.props;
        tableViewChangeAction( view );
    };

    changeCardDisplayView = value => {
        const { pageCardDisplayViewMPChangeAction } = this.props;
        this.setState( { open: false }, () => {
            pageCardDisplayViewMPChangeAction( value );
        } );
    };

    documentClick = e => {
        const { open } = this.state;
        const node = this.myRef.current;
        if ( e.target.closest( ".customize-body" ) !== node && open )
            this.closeDropDown();
    };

    changeStateDropDown = () => {
        const { disabled } = this.props;
        if ( disabled ) return;
        this.setState( prevState => ( { open: !prevState.open } ) );
    };

    closeDropDown = () => {
        this.setState( { open: false } );
    };

    onDragEnd = result => {
        const { columns, tableSequenceColumnChangeAction } = this.props;
        const { destination, source, draggableId } = result;
        if ( !destination ) return;
        if (
            destination.droppableId === source.droppableId &&
            destination.index === source.index
        ) {
            return;
        }
        const newTasksId = Array.from( columns.taskIds );
        newTasksId.splice( source.index, 1 );
        newTasksId.splice( destination.index, 0, draggableId );
        tableSequenceColumnChangeAction( newTasksId );
    };

    handleChangeCheckBox = event => {
        const { tableColumnSelectedChangeAction } = this.props;
        const { tasks } = this.props;
        const newTasks = { ...tasks };
        newTasks[event.name].value = event.checked;
        tableColumnSelectedChangeAction( newTasks );
    };

    changeAll = value => {
        const { tasks, tableColumnSelectedChangeAction } = this.props;
        const newTasks = {};
        for ( let key in tasks ) {
            let newItem = { ...tasks[key] };
            newItem.value = !tasks[key].visibility
                ? false
                : tasks[key].disabled
                    ? true
                    : value;
            newTasks[key] = newItem;
        }
        tableColumnSelectedChangeAction( newTasks );
    };

    render() {
        const { open, selectAll } = this.state;
        const {
            pageView,
            pageCardDisplayView,
            // activeModuleState,
            label
        } = this.props;
        if ( pageView === "mixed" ) return null;
        return (
            <CustomizeBlockWrapper
                myRef={this.myRef}
                open={open}
                pageView={pageView}
                label={label}
                changeStateDropDown={this.changeStateDropDown}
                cardList={(
                    <ul>
                        <li
                            className={
                                pageCardDisplayView === "block-description-x1"
                                    ? "active"
                                    : ""
                            }
                        >
                            <button
                                type="button"
                                onClick={() => {
                                    this.changeCardDisplayView( "block-description-x1" );
                                }}
                            >
                                {cardView1(
                                    pageCardDisplayView === "block-description-x1"
                                        ? "#445bf4"
                                        : "#809096"
                                )}
                            </button>
                        </li>

                        <li
                            className={
                                pageCardDisplayView === "block-description-x2"
                                    ? "active"
                                    : ""
                            }
                        >
                            <button
                                type="button"
                                onClick={() => {
                                    this.changeCardDisplayView( "block-description-x2" );
                                }}
                            >
                                {cardView2(
                                    pageCardDisplayView === "block-description-x2"
                                        ? "#445bf4"
                                        : "#809096"
                                )}
                            </button>
                        </li>

                        <li
                            className={
                                pageCardDisplayView === "block-img-x1" ? "active" : ""
                            }
                        >
                            <button
                                type="button"
                                onClick={() => {
                                    this.changeCardDisplayView( "block-img-x1" );
                                }}
                            >
                                {cardView4(
                                    pageCardDisplayView === "block-img-x1"
                                        ? "#445bf4"
                                        : "#809096"
                                )}
                            </button>
                        </li>

                        <li
                            className={
                                pageCardDisplayView === "block-img-x2" ? "active" : ""
                            }
                        >
                            <button
                                type="button"
                                onClick={() => {
                                    this.changeCardDisplayView( "block-img-x2" );
                                }}
                            >
                                {cardView5(
                                    pageCardDisplayView === "block-img-x2"
                                        ? "#445bf4"
                                        : "#809096"
                                )}
                            </button>
                        </li>

                        <li
                            className={
                                pageCardDisplayView === "block-img-x3" ? "active" : ""
                            }
                        >
                            <button
                                type="button"
                                onClick={() => {
                                    this.changeCardDisplayView( "block-img-x3" );
                                }}
                            >
                                {cardView6(
                                    pageCardDisplayView === "block-img-x3"
                                        ? "#445bf4"
                                        : "#809096"
                                )}
                            </button>
                        </li>
                    </ul>
                )}
                dragDropContext={ null }
                rowHeightList={ null }
                selectAll={selectAll}
                changeAl={this.changeAll}
            />
        );
    }
}

const mapDispatchToProps = {
    pageCardDisplayViewMPChangeAction
};

const mapStateToProps = state => {
    const activeModuleState = activeModuleStateSelector( state );
    const pageView = pageViewStateSelector( state );
    const pageCardDisplayView = pageCardDisplayViewSelector( state );
    return {
        pageCardDisplayView,
        activeModuleState,
        pageView
    };
};

export const CustomizeBlock = connect(
    mapStateToProps,
    mapDispatchToProps
)( CustomizeBlockDisplay );

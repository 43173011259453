import React from "react";
import "./ItemCardProfileWrapper.scss";

export const ItemCardProfileWrapper = ( {
    header = null,
    imgBox = null,
    description = null,
    footer = null
} ) => {
    return (
        <div className="card-item card-item__profile noselect">
            <div className="card-item-header">
                {header}
            </div>
            <div className="card-item-body">
                <div className="img-box">
                    {imgBox}
                </div>
                <div className="description">
                    {description}
                </div>
            </div>
            <div className="card-item-footer">
                {footer}
            </div>
        </div>
    );
};

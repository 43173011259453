import React from "react";
import { NavLink } from "react-router-dom";

import "./ModuleEntry.scss";

export const ModuleEntry = ( {
    prodPath,
    localPath,
    icon,
    label,
    preferLocalPath
} ) => {
    const EntryBody = () => (
        <>
            <div className="svg-icon">{icon}</div>
            {label}
        </>
    );


    return (
        <div className={"moduleEntry"}>
            {
                preferLocalPath && localPath
                    ?
                    <NavLink to={localPath} activeClassName="active">
                        <EntryBody />
                    </NavLink>
                    :
                    <a href={prodPath}>
                        <EntryBody />
                    </a>
            }
        </div>
    );
};
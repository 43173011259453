import React from "react";
import moment from "moment";
import { dateFormat } from "config";
import {
    ItemCardWrapper,
    Checkbox,
    CollapsibleParagraph,
    SocialTitle,
    StatusIcons,
    maximizeCard,
    externalLinkCard,
    // CardActions,
    SlickSlider
} from "components";
import { sliderPrevSvg, sliderNextSvg } from "components/Icons/Svg";

export const ItemCardView = ( props ) => {
    const prevSvg = sliderPrevSvg();
    const nextSvg = sliderNextSvg();
    const PrevArrow = props => (
        <button
            onClick={props.onClick}
            type="button"
            className="slick-prev pull-left"
        >
            {prevSvg}
        </button>
    );
    const NextArrow = props => (
        <button
            onClick={props.onClick}
            type="button"
            className="slick-next pull-right"
        >
            {nextSvg}
        </button>
    );
    return (
        <ItemCardWrapper
            header={
                <>
                    <Checkbox
                        handleChange={( { value } ) => {
                            props.chooseCardToSelect( { value: value, id: props.id } );
                        }}
                        defaultValue={props.selectedCardsList.indexOf( props.id ) !== -1}
                    />
                    <SocialTitle code={props.imgHeader} title={props.titleHeader} />
                    <div/>
                    {/*<CardActions*/}
                    {/*    id={props.id}*/}
                    {/*    deleteOneCardsAction={props.deleteOneCardsAction}*/}
                    {/*    editBtn={false}*/}
                    {/*/>*/}
                </>
            }
            body={
                <div className="card-item-body">
                    <div className="img-box">
                        <div className="card-slider">
                            {props.img && props.img.length ?
                                <SlickSlider
                                    cssClass={"view-panel-slider"}
                                    next={<NextArrow />}
                                    prev={<PrevArrow />}
                                    props1={{
                                        fade: true,
                                        infinite: true,
                                        speed: 500
                                    }}
                                    slickGoTo={ ()=>{} }
                                >
                                    {props.img.map( ( e, i )=> ( <div key={i}><img  src={e} alt={""}/></div> ) )}
                                </SlickSlider>
                                : props.img ?
                                    <img src={props.img} alt={props.imgCardAlt} />
                                    : null
                            }
                        </div>
                        <div className="card-item-body-footer">
                            <StatusIcons status={props.status} text={true} type="card-status" />
                        </div>
                    </div>
                    <div className="description">
                        <div className="date-price">
                            <div className="date">{moment( props.date ).format( dateFormat )}</div>
                            <div className="price">{props.symbol}{props.price || "No data"}</div>
                        </div>
                        <div className="title-description-card">
                            <div>{props.name || "No title"}</div>
                        </div>
                        <CollapsibleParagraph text={props.text} />
                        <div className="detail">
                            <div>
                                Seller: <span>{props.seller}</span>
                            </div>
                            <div className="views-card">
                                <div>
                                    Rating: <span>{props.rating || 0}</span>
                                </div>
                                <div>
                                    Quantity: <span>{props.quantity || 0}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
            footer={
                <>
                    <div>
                        <a
                            href="/"
                            onClick={e => {
                                e.preventDefault();
                                if ( props.cardURL )
                                    props.userPushToRouteAction( { profileURL: props.cardURL, module: "posts" } );
                            }}
                        >
                            {maximizeCard()}
                        </a>
                    </div>
                    <button
                        type={"button"}
                        onClick={() => props.showViewDetailsPanelMPAction( props.id ) }
                    >
                        View details
                    </button>
                    <div>
                        {props.cardURL ? (
                            <a href={props.cardURL} rel="noopener noreferrer" target="_blank">
                                {externalLinkCard()}
                            </a>
                        ) : null}
                    </div>
                </>
            }
        />
    );
};

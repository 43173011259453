import React from "react";
import {
    Checkbox,
    SocialTitle,
    StatusIcons,
    CollapsibleParagraph,
    Avatar,
    // CardActions,
    ItemCardProfileWrapper
} from "components";
import { maximizeCard, externalLinkCard } from "components/Icons/Svg";
import moment from "moment";
import { dateFormat } from "config";

export const ProfileCardItem = ( {
    img = null,
    titleHeader = "",
    cardURL = null,
    name = "",
    date = "",
    text = "",
    followers = "0",
    posts = "",
    views = "",
    status = "",
    children,
    imgHeader = null,
    selectedCardsList = [],
    chooseCardToSelect = () => {},
    showViewDetailsPanelAction = () => {},
    deleteOneCardsAction = () => {},
    userPushToRouteAction = () => {},
    element = { id: null }
} ) => {
    return (
        <ItemCardProfileWrapper
            header={
                <>
                    <Checkbox
                        handleChange={( { value } ) => {
                            chooseCardToSelect( {
                                value: value,
                                id: element.id
                            } );
                        }}
                        defaultValue={selectedCardsList.indexOf( element.id ) !== -1}
                    />
                    <SocialTitle code={imgHeader} title={titleHeader} />
                    <div/>
                    {/*<CardActions*/}
                    {/*    id={element.id}*/}
                    {/*    deleteOneCardsAction={deleteOneCardsAction}*/}
                    {/*    editBtn={false}*/}
                    {/*/>*/}
                </>
            }
            imgBox={
                <>
                    {img ? <Avatar size="65" data={[ img ]} /> : null}
                    {children ? <div className="children-box">{children}</div> : null}
                    <div className="card-item-body-footer">
                        <StatusIcons status={status} text={true} type="card-status" />
                    </div>
                </>
            }
            description={
                <>
                    <div className="title-description-card">
                        <div className="description-name">{name || "No title"}</div>
                        <span>{moment( date ).format( dateFormat )}</span>
                    </div>
                    <CollapsibleParagraph text={text} />
                    <div className="detail">
                        <div className="views-card">
                            <div>
                                Followers: <span>{followers || "0"}</span>
                            </div>
                            <div>
                                Posts: <span>{posts || "0"}</span>
                            </div>
                            <div>
                                Views: <span>{views || "0"}</span>
                            </div>
                        </div>
                    </div>
                </>
            }
            footer={
                <>
                    <div>
                        <a
                            href="/"
                            onClick={e => {
                                e.preventDefault();
                                if ( cardURL )
                                    userPushToRouteAction( { profileURL: cardURL, module: "posts" } );
                            }}
                        >
                            {maximizeCard()}
                        </a>
                    </div>
                    <button
                        type={"button"}
                        onClick={() => showViewDetailsPanelAction( element.id )}
                    >
                        View details
                    </button>
                    <div>
                        {cardURL ? (
                            <a href={cardURL} rel="noopener noreferrer" target="_blank">
                                {externalLinkCard()}
                            </a>
                        ) : null}
                    </div>
                </>
            }
        />
    );
};

import { globalError } from "./actions";
import { handleActions } from "redux-actions";

const initialState = {
    errorMessageGlobal: null
};

export const errorReducer = handleActions(
    {
        [globalError]: ( state, { payload } ) => ( {
            ...state,
            errorMessageGlobal: payload
        } )
    },
    initialState
);

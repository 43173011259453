import React from "react";
import { CollapsibleFilter, TextInput } from "components";

export const CollapsibleInputBlock = ( {
    title = "",
    name = "",
    type = "text",
    placeholder = "",
    defaultValue = "",
    onKeyPress = null,
    handleChange
} ) => {
    return (
        <CollapsibleFilter title={title} defaultExpanded={!!defaultValue}>
            <div>
                <TextInput
                    placeholder={placeholder}
                    type={type}
                    defaultValue={defaultValue}
                    onKeyPress={onKeyPress}
                    handleChange={el => {
                        handleChange( name, el.target.value );
                    }}
                />
                {/*<span>245</span>*/}
            </div>
        </CollapsibleFilter>
    );
};

import React from "react";

export const PaginationCounter = ( { limit, page, countAvailable, countLoaded } ) => {
    limit = Number( limit );

    const startCount = page === 1 ? 1 : page * limit + 1;
    const endCount = page === 1 ? limit : page * limit + limit;

    if ( countAvailable <= countLoaded ) return null;

    return (
        <div className="pagination-counter">
            {countLoaded > 0 && (
                <span>
                    {
                        //if has items added with load more
                        limit === countLoaded
                            ? startCount
                            : endCount - countLoaded === limit
                                ? endCount - countLoaded + 1 - limit
                                : endCount - countLoaded + 1
                    }
                    -{endCount}
                </span>
            )}
            of {countAvailable}
        </div>
    );
};
import React, { PureComponent } from "react";
import { arrow, closeSvg, searchSmall, TextInput } from "components/";
import "./Dropdown.scss";

export class Dropdown extends PureComponent {
    constructor( props ) {
        super( props );
        this.myRef = React.createRef();
        this.state = {
            open: false,
            selected: null,
            position: "bottom",
            ulHeight: null,
            horizontal: null,
            searchValue: "",
            initData: [],
            data: []
        };
    }

    static getDerivedStateFromProps( nextProps, prevState ) {
        if ( Object.prototype.hasOwnProperty.call( nextProps, "data" ) ) {
            let initData = nextProps.data;
            let newData = nextProps.data;
            const { defaultValue } = nextProps;
            if ( !Array.isArray( newData ) ) {
                let newArr = [];
                for ( let key in newData ) {
                    newArr.push( { name: newData[key], id: key } );
                }
                newData = newArr;
                initData = newArr;
            }
            if ( prevState.searchValue ) {
                newData = initData.filter( el => {
                    if (
                        el.name
                            .toLowerCase()
                            .search( prevState.searchValue.toLowerCase() ) !== -1
                    )
                        return el;
                    return false;
                } );
            }
            return {
                initData: initData,
                data: newData,
                selected: defaultValue
            };
        }
        return null;
    }

    componentDidMount() {
        document.addEventListener( "click", this.documentClick );
    }

    getLengthData = data => {
        if ( data === null || data === undefined ) return 1;
        return data.length || 1;
    };

    getPositionList = () => {
        const { data } = this.state;
        const { dropHeight, search = false } = this.props;
        const element = this.myRef.current;
        const domRect = element.getBoundingClientRect();
        const bottom = window.innerHeight - domRect.bottom;
        const lengthList = this.getLengthData( data );
        let ulHeight = null;
        if ( lengthList > 8 ) {
            ulHeight = 350;
        }
        let height = dropHeight
            ? dropHeight
            : ulHeight
                ? ulHeight
                : lengthList * 45;
        if ( search ) height += 76;
        this.setState( prevState => ( {
            open: !prevState.open,
            position: height > bottom ? "top" : "bottom",
            ulHeight: ulHeight,
            horizontal: domRect.x < 150 ? "left" : "right"
        } ) );
    };

    componentWillUnmount() {
        document.removeEventListener( "click", this.documentClick );
    }

    documentClick = e => {
        const node = this.myRef.current;
        if ( e.target.closest( ".dropdown-body" ) !== node ) this.closeDropDown();
    };

    changeStateDropDown = ( e ) => {
        const { selected } = this.state;
        const { clearable = false } = this.props;
        const { disabled } = this.props;
        if( !!e.target.closest( ".svg-icon" ) && clearable && selected ){
            this.selectItem( null );
            return;
        }

        if ( disabled ) return;
        this.getPositionList();
    };

    closeDropDown = () => {
        this.setState( { open: false, searchValue: "" } );
    };

    selectItem = e => {
        const { input, handleChange } = this.props;
        let value = ( e && e.value ) || ( e && e.id ) || e;
        this.setState( {
            selected: value,
            open: false,
            searchValue: ""
        } );
        if ( input ) input.onChange( value );
        if ( handleChange ) handleChange( value );
    };

    getValueFiler = elem => {
        const { initData } = this.state;
        if ( !initData ) return null;
        let value = elem;
        if ( initData[0] && initData[0].id ) {
            let arr = initData.filter( el => el.id.toString() === elem.toString() );
            if ( arr.length ) value = arr[0].name;
        }
        return value;
    };

    searchAction = e => {
        const { value } = e.target;
        this.setState( {
            searchValue: value
        } );
    };

    render() {
        const {
            wrapperClassName = "form-dropdown",
            defaultValue = null,
            dropHeight = null,
            defaultAll = false,
            placeholder = "Select...",
            label,
            disabled = false,
            unSelect = false,
            widthBtn = null,
            meta: { touched, error } = {},
            search = false,
            clearable = false,
            input
        } = this.props;
        let {
            open,
            selected,
            position,
            ulHeight,
            data,
            searchValue,
            horizontal
        } = this.state;
        const inputValue = input ? input.value : defaultValue || selected;
        let btnValue = inputValue ? this.getValueFiler( inputValue ) : placeholder;
        let wrapperClass = "dropdown " + wrapperClassName;
        if ( touched && error ) wrapperClass += " error";
        if ( search ) wrapperClass += " search";
        if ( disabled ) wrapperClass += " disabled";
        const config = {
            search: search
                ? {
                    action: this.searchAction,
                    value: searchValue
                }
                : null,
            dropHeight: dropHeight,
            ulHeight: ulHeight,
            defaultAll: defaultAll,
            unSelect: unSelect
        };
        //Style Object for Wrapper box
        const styleWrapperObj = {};
        if ( horizontal ) {
            styleWrapperObj.left = "inherit";
            styleWrapperObj.right = "inherit";
            styleWrapperObj[horizontal] = 0;
        }
        if ( position === "top" ) {
            styleWrapperObj.top = "inherit";
            styleWrapperObj.bottom = "100%";
        }
        return (
            <div className={wrapperClass}>
                {label ? <div className="title">{label}</div> : null}
                <div ref={this.myRef} className="dropdown-body">
                    <div
                        className={open ? "wrapper-button show" : "wrapper-button"}
                        onClick={this.changeStateDropDown}
                        role="presentation"
                    >
                        {buttonRender( widthBtn, btnValue, selected, clearable )}
                    </div>
                    <div
                        className={
                            open
                                ? `dropdown-list scroll-m show ${position}`
                                : `dropdown-menu scroll-m ${position}`
                        }
                        style={styleWrapperObj}
                    >
                        {open ? renderArr( data, selected, this.selectItem, config ) : null}
                    </div>
                    {touched && error ? (
                        <div className="error-message">{error}</div>
                    ) : null}
                </div>
            </div>
        );
    }
}

const renderArr = ( data, selected, selectItem, config, unSelect ) => {
    if ( !data ) return null;
    const { search, dropHeight, ulHeight, defaultAll } = config;
    const height = dropHeight || ulHeight;
    return (
        <ul
            style={height ? { maxHeight: height, overflowX: "auto" } : {}}
            className={search ? "search-box" : ""}
        >
            {defaultAll ? (
                <li onClick={() => selectItem( null )} role="presentation">
                    All
                </li>
            ) : null}
            {search ? inputSearch( search ) : null}
            {config.unSelect && selected ? (
                <li onClick={() => selectItem( null )} role="presentation">
                    Unselect
                </li>
            ) : null}
            {data.length ? (
                data.map( ( e, i ) => (
                    <li
                        key={e.id || i}
                        role="presentation"
                        onClick={() => selectItem( e.id || e )}
                        className={( ( e.id && "" + e.id ) || i ) === ( ""+selected ) ? "active" : ""}
                    >
                        {e.name || e}
                    </li>
                ) )
            ) : (
                <li>No Items</li>
            )}
        </ul>
    );
};

const inputSearch = search => (
    <li className="search-row">
        <TextInput
            placeholder={"Search…"}
            wrapperStyle={{ flexDirection: "row-reverse" }}
            childrenStyle={{
                marginRight: "10px"
            }}
            handleChange={search.action}
            defaultValue={search.value}
        >
            {searchSmall()}
        </TextInput>
    </li>
);

const buttonRender = ( widthBtn, btnValue, selected, clearable ) => {
    return(
        <button
            type="button"
            style={widthBtn ? { maxWidth: widthBtn } : null}
        >
            <span>{btnValue}</span>
            {selected && clearable ?
                <span className="svg-icon clearable">{closeSvg( "#445bf4" )}</span>
                :
                <span className="svg-icon">{arrow()}</span>
            }
        </button>
    );
};

import { connect } from "react-redux";
import { AddPost as component } from "./AddPost";
import {
    brandsSelector,
    brandsFavoriteListSelector,
    activeBrandSelector
} from "services/Brand/selectors";
import {
    addBrandToFavoritesAction,
    saveActiveBrandAction
} from "services/Brand/actions";

const mapDispatchToProps = {
    addBrandToFavoritesAction,
    saveActiveBrandAction
};

const mapStateToProps = state => {
    const brandsState = brandsSelector( state );
    const favouriteBrands = brandsFavoriteListSelector( state );
    const activeBrandListState = activeBrandSelector( state );
    return {
        brandsState,
        favouriteBrands,
        activeBrandListState
    };
};

export const SocialProtectAddPost = connect(
    mapStateToProps,
    mapDispatchToProps
)( component );

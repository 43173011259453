import React from "react";
import { Button } from "components";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

/*
    if shouldControlClosureLocally is true, it will render a button to control the open/closure status.
    if set to false, it relies on the parent to control whether the dialog should be open or not (through the isOpen prop)
*/
function AlertDialog( props ) {
    const {
        title,
        text,
        acceptanceLabel,
        rejectionLabel,
        onAccept,
        onReject,
        isOpen
    } = props;

    const [ localOpen, setLocalOpen ] = React.useState( false );
    const shouldControlClosureLocally = [ null, undefined ].includes( isOpen );

    function handleClickOpen() {
        if ( shouldControlClosureLocally ) setLocalOpen( true );
    }

    function handleClose( hasBeenAccepted ) {
        if ( shouldControlClosureLocally ) setLocalOpen( false );

        if ( hasBeenAccepted ) onAccept();
        else onReject();
    }

    return (
        <div>
            {shouldControlClosureLocally ? (
                <Button variant="outlined" color="primary" onClick={handleClickOpen}>
                    Open alert dialog
                </Button>
            ) : (
                ""
            )}

            <Dialog
                open={shouldControlClosureLocally ? localOpen : isOpen}
                onClose={() => handleClose( false )}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {text}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        text={acceptanceLabel}
                        color="primary"
                        handleClick={() => handleClose( true )}
                    />
                    <Button
                        text={rejectionLabel}
                        color="secondary"
                        handleClick={() => handleClose( false )}
                    />
                </DialogActions>
            </Dialog>
        </div>
    );
}

export { AlertDialog };

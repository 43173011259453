import { createAction } from "redux-actions";

export const importDataAction = createAction( "importDataAction" );
export const importDataSaveAction = createAction( "importDataSaveAction" );
export const sendImportFileAction = createAction( "sendImportFileAction" );
export const saveErrorsAfterImportFileAction = createAction(
    "saveErrorsAfterImportFileAction"
);
export const importFileStateAction = createAction( "importFileStateAction" );
export const dataAfterImportAction = createAction( "dataAfterImportAction" );
export const clearImportStoreAction = createAction( "clearImportStoreAction" );
export const copyErrorLinksImportAction = createAction(
    "copyErrorLinksImportAction"
);
export const sendOnlySuccessfullyImportDataAction = createAction(
    "sendOnlySuccessfullyImportDataAction"
);
export const exportErrorLinksToCsvAction = createAction(
    "exportErrorLinksToCsvAction"
);
export const goToThePostsAfterImportAction = createAction(
    "goToThePostsAfterImportAction"
);

import React from "react";
import moment from "moment";
import { dateFormat } from "config";
import { TitleDetails, ListDetails } from "components";

export const Enforcement = ( { data } ) => {
    return (
        <div className="info-wrapper">
            <TitleDetails text={"Enforcement status"} />
            <ListDetails
                data={[
                    { label: "Reported date", value: ( data.postEnforcedAt || data.profileEnforcedAt )
                            && moment( data.postEnforcedAt || data.profileEnforcedAt )
                                .format(
                                    dateFormat
                                ) },
                    { label: "Removed date", value: ( data.profileEnforcementSuccessfulAt ||
                        data.postEnforcementSuccessfulAt )
                            && moment(
                                data.profileEnforcementSuccessfulAt ||
                                data.postEnforcementSuccessfulAt
                            ).format( dateFormat )
                    }
                ]}
            />
        </div>
    );
};

import { call, put, select, takeLatest } from "redux-saga/effects";
import { loadInfringementTypeListAction, saveInfringementTypeListAction } from "./actions";
import * as InfringementTypeAPI from "./api";
import { activeBrandSwitchedAction } from "services/Brand/actions";
import { activeBrandSelector } from "services/Brand/selectors";

//WORKERS
function* infringementTypeWorker() {
    yield put( loadInfringementTypeListAction( true ) );
    //Get brands list from API
    const activeBrand = yield select( activeBrandSelector );
    const response = yield call( InfringementTypeAPI.getInfringementTypeList, activeBrand );
    if ( response ) yield put( saveInfringementTypeListAction( response ) );
    yield put( loadInfringementTypeListAction( false ) );
}

// WATCHERS
export function* infringementTypeInitialisationWatcher() {
    //yield call( infringementTypeWorker );
    yield takeLatest( activeBrandSwitchedAction, infringementTypeWorker );
}

import { call, put, select } from "redux-saga/effects";

import { urlUtil } from "utils";
import { activeBrandSelector } from "services/Brand/selectors";
import { activeModuleStateSelector } from "services/UserView/selectors";
import { getObjectFromURLParams } from "utils/connected";
import { applyUrlParametersToFilter } from "services/Filter/utils";
import { getCountItemsPerPage } from "utils";

const { modifyURLByFiltersWorker }  = urlUtil;

export function generateLoadMoreWorker( {
    API,
    incrementPageAction,
    storeIsLoadingMoreAction,
    saveEntriesAction,
    activeFilterSelector,
    loadedEntriesSelector
} ) {

    return function* () {
        const activeModuleState = yield select( activeModuleStateSelector );
        const activeBrand = yield select( activeBrandSelector );
        const baseFilter = yield select( activeFilterSelector );
        const loadedEntries = yield select( loadedEntriesSelector );

        const filter = {
            ...baseFilter,
            brand: activeBrand,
            module: activeModuleState,
            page: baseFilter.page + 1
        };

        yield put( storeIsLoadingMoreAction( true ) );

        yield call( modifyURLByFiltersWorker, filter );
        const response = yield call( API.getEntries, filter );
        yield put( saveEntriesAction( {
            data: {
                ...response,
                items: [
                    ...loadedEntries,
                    ...response.items
                ]
            },
            category: activeModuleState
        } ) );

        yield put( incrementPageAction( activeModuleState ) );
        yield put( storeIsLoadingMoreAction( false ) );
    };
}

//@TODO: Shitty name
export function generateRefreshEntriesParamsBuilder(
    pageCardDisplayViewSelector,
    currentPageSelector
) {
    return function* ( { payload, activeFilter } ) {
        const activeModuleState = yield select( activeModuleStateSelector );
        const activeBrand = yield select( activeBrandSelector );
        const currentPage = yield select( currentPageSelector );
        const urlParams = yield call( getObjectFromURLParams );
        const pageCardDisplayViewState = yield select( pageCardDisplayViewSelector );
        const hasUrlParameters = Object.values( urlParams ).length > 0;
        const shouldApplyUrlParameters = hasUrlParameters && !payload;

        let requestParameters = {};

        if ( shouldApplyUrlParameters ) {
            requestParameters = { ...urlParams };
        } else if ( !payload ) {
            requestParameters = { ...activeFilter };
        } else {
            requestParameters = { ...payload };
        }

        if ( currentPage && !requestParameters.page ) requestParameters.page = currentPage;

        return {
            ...requestParameters,
            limit: getCountItemsPerPage( pageCardDisplayViewState ),
            brand: activeBrand,
            module: activeModuleState
        };
    };
}

export function generatePopulateEntriesWorker( {
    activeFilterSelector,
    refreshEntries,
    saveFilterWorker,
    buildRequestParameters
} ) {

    return function* ( { payload } ) {
        const activeFilter = yield select( activeFilterSelector );
        const activeBrand = yield select( activeBrandSelector );

        const urlParams = yield call( getObjectFromURLParams );
        const hasUrlParameters = Object.values( urlParams ).length > 0;
        const shouldApplyUrlParameters = hasUrlParameters && !payload;

        if ( !activeBrand ) return;

        const requestParameters = yield call( buildRequestParameters, { payload, activeFilter } );

        //@TODO: Does it make sense that we are calling saveFilterWorker twice?
        if ( shouldApplyUrlParameters ) {
            yield call( saveFilterWorker, { payload: applyUrlParametersToFilter( activeFilter ) } );
        }

        yield call( saveFilterWorker, { payload: { limit: requestParameters.limit } } );

        /* -- This was in SocialProtect, but not in MarketProtect
        //@TODO: A third filter save?
        //Save only applied filter values
        yield put( saveFilterAppliedAction( { payload: requestParameters, activeModuleState } ) );
        */

        yield call( refreshEntries, requestParameters );
    };
}
import { createAction } from "redux-actions";
import { moduleSlug as namespace } from "pages/MarketProtect/constants";

export const storeFilterAction = createAction( `${namespace}/storeFilterAction` );
export const saveFilterAndSearchAction = createAction( `${namespace}/saveFilterAndSearchAction` );
export const searchByFilterAction = createAction( `${namespace}/searchByFilterAction` );
export const saveFilterAction = createAction( `${namespace}/saveFilterAction` );
export const incrementPageAction = createAction( `${namespace}/incrementPageAction` );
export const selectInfringementTypeInTopBarAction = createAction(
    `${namespace}/selectInfringementTypeInTopBarAction`
);
export const selectEnforcementStatusInTopBarAction = createAction(
    `${namespace}/selectEnforcementStatusInTopBarAction`
);
import { ApiCreator } from "utils/connected";
import { BASE_URL_API } from "config";
const api = ApiCreator( BASE_URL_API );

export const getCountryList = ( activeBrand = "1" ) =>
    api.get( "generic/storage/country/list", {
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json"
        }
    } );

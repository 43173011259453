import React from "react";
import { block, mixedSvg, row } from "components";
import "./ToggleView.scss";

export const ToggleView = ( { active, mixed, onChange } ) => {
    return (
        <div className="toggle-view-default">
            <ul className="list-view">
                <li>
                    <button type="button" onClick={() => onChange( "block" )}>
                        {block( active === "block" ? "#445bf4" : "#809096" )}
                    </button>
                </li>
                {mixed && (
                    <li>
                        <button type="button" onClick={() => onChange( "mixed" )}>
                            {mixedSvg( active === "mixed" ? "#445bf4" : "#809096" )}
                        </button>
                    </li>
                )}
                <li>
                    <button type="button" onClick={() => onChange( "row" )}>
                        {row( active === "row" ? "#445bf4" : "#809096" )}
                    </button>
                </li>
            </ul>
        </div>
    );
};

import React from "react";
import { EnhancedTableBody } from "components";
import Table from "@material-ui/core/Table";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";

const cellStyle = {
    maxWidth: "200px",
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis"
};

export const GridTableViewTopBar = ( {
    activeModuleState,
    data,
    cells,
    symbol,
    chooseItemByFieldName,
    tableView = "large"
} ) => {
    return (
        <Table
            className={`table-main ${tableView}`}
            aria-labelledby="tableTitle"
            aria-label="enhanced table"
        >
            <EnhancedTableBody >
                { data && renderCellsBody( data, activeModuleState, cells, chooseItemByFieldName, symbol ) }
            </EnhancedTableBody>
        </Table>
    );
};

const renderCellsBody = ( bodyCells, props, cells, chooseItemByFieldName, symbol ) => {
    const tableCells = ( cells, bodyCell ) => {
        return cells.map( ( el, i ) => {
            if ( !el.value ) return null;
            return (
                <TableCell
                    key={i}
                    align="left"
                    style={cellStyle}
                >
                    { chooseItemByFieldName(
                        bodyCell,
                        el,
                        null,
                        null,
                        symbol
                    ) }
                </TableCell>
            );
        } );
    };

    return bodyCells.map( ( bodyCell, index ) => {
        return(
            <TableRow
                role="checkbox"
                key={index}
            >
                {cells && tableCells( cells, bodyCell )}
            </TableRow>
        );
    } );
};

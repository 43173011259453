import React, { useState } from "react";
import {
    SlickSlider,
    TabBar,
    NoteBlockViewDetails,
    SocialTitle
} from "components";

import {
    SliderModal
} from "pages/MarketProtect/components";
import {
    Enforcement,
    Overview,
    Seller
} from "./components";
import moment from "moment";
import { dateFormat } from "config";
import "./ViewDetailsPanelMixed.scss";

export const ViewDetailsPanelMixed = ( {
    item = null,
    symbol = ""
} ) => {
    const [ activeTab, setActiveTab ] = useState( "overview" );
    const [ slickGoTo, setSlickGoTo ] = useState( 0 );
    const [ visible, seVisible ] = useState( false );
    if( !item ) return null;
    return (
        <div className="view-panel-mixed-wrapper">
            <div className="head-panel">
                <div className="slider-side">
                    {item.images && item.images.length ?
                        <>
                            <div className="slider-nav">
                                {item.images.map( ( e, i )=> {
                                    if( i>3 ) return null;
                                    return (
                                        <div
                                            key={i}
                                            className={i === slickGoTo ? "active" : ""}
                                            onClick={() => setSlickGoTo( i )}
                                            role="presentation"
                                        >
                                            <img src={e} alt={""}/>
                                        </div>
                                    );
                                } )}
                                { item.images.length>3 && (
                                    <div
                                        className="image-length"
                                        onClick={()=>seVisible( true )}
                                        role="presentation"
                                    >
                                        +{item.images.length-4}
                                    </div>
                                )}
                            </div>
                            <SlickSlider
                                cssClass={"view-panel-slider"}
                                slickGoTo={slickGoTo}
                                props1={{
                                    fade: true,
                                    infinite: true,
                                    speed: 500,
                                    arrows: false
                                }}
                            >
                                {item.images.map( ( e, i )=> ( <div key={i}><img  src={e} alt={""}/></div> ) )}
                            </SlickSlider>
                            { item.images.length>3 && (
                                <SliderModal
                                    visible={visible}
                                    slickGoTo={null}
                                    toggleDialog={()=>seVisible( !visible )}
                                >
                                    {visible && item.images.map( ( e, i )=> ( <div key={i}><img  src={e} alt={""}/></div> ) )}
                                </SliderModal>
                            )}
                        </>
                        : item.images ?
                            <img src={item.images} alt={""} />
                            : null
                    }
                </div>
                <div className="description-side">
                    <div className="">
                        {item.descriptionAnalyzer && item.descriptionAnalyzer.label && <SocialTitle code={null} title={item.descriptionAnalyzer.label} />}
                    </div>
                    <div className="title-post">{item.title}</div>
                    <div className="price-date">
                        <div className="price">{Number( item.price ).toFixed( 2 )}{symbol}</div>
                        <div className="date">{moment( item.publishDate ).format( dateFormat )}</div>
                    </div>
                </div>
            </div>

            <TabBar>
                <button
                    type="button"
                    className={activeTab === "overview" ? "active" : ""}
                    onClick={() => setActiveTab( "overview" )}
                >
                    Overview
                </button>
                <button
                    type="button"
                    className={activeTab === "seller" ? "active" : ""}
                    onClick={() => setActiveTab( "seller" )}
                >
                    Seller
                </button>
                <button
                    type="button"
                    className={activeTab === "enforcement" ? "active" : ""}
                    onClick={() => setActiveTab( "enforcement" )}
                >
                    Enforcement
                </button>
            </TabBar>

            <div className="tab-panel">
                {activeTab === "overview" ? (
                    <Overview data={item} />
                ) : activeTab === "seller" ? (
                    <Seller data={item} />
                ) : activeTab === "enforcement" ? (
                    <Enforcement data={item} />
                ) : activeTab === "activities" ? (
                    "Activities"
                ) : null}
            </div>

            <NoteBlockViewDetails
                id={null}
                note={null}
                updateCardItemAction={()=>{}}
            />
        </div>
    );
};

import { createAction } from "redux-actions";

export const pageListViewMPSaveAction = createAction(
    "pageListViewMPSaveAction"
);

export const pageListViewChangeMPAction = createAction(
    "pageListViewChangeMPAction"
);

export const pageCardDisplayViewMPChangeAction = createAction(
    "pageCardDisplayViewMPChangeAction"
);

export const pageCardDisplayMPViewSaveAction = createAction(
    "pageCardDisplayMPViewSaveAction"
);

export const showViewDetailsPanelMPAction = createAction(
    "showViewDetailsPanelMPAction"
);

export const goToNextViewDetailsMPAction = createAction(
    "goToNextViewDetailsMPAction"
);
import React from "react";
import "./OutlinedChips.scss";

export const OutlinedChips = ( props ) => {
    const { children, onCloseAll=()=>{}, clearAll = false } = props;
    return(
        <div className="out-lined-chips" >
            {children}
            {clearAll &&
                <button
                    onClick={onCloseAll}
                    className="clear-all-btn"
                >
                    Clear all
                </button>
            }
        </div>
    );
};

import { connect } from "react-redux";
import { PageRoute as component } from "./PageRoute";
import { showViewDetailsPanelAction } from "services/UserView/actions";
import { activeModuleStateSelector } from "services/UserView/selectors";
import { activeGridViewSelector } from "pages/Landing/services/Grid/selectors";

const mapDispatchToProps = {
    showViewDetailsPanelAction
};

const mapStateToProps = state => {
    const activeModuleState = activeModuleStateSelector( state ) || null;
    const activeGridView = activeGridViewSelector( state );

    return {
        activeModuleState,
        activeGridView
    };
};

export const PageRoute = connect(
    mapStateToProps,
    mapDispatchToProps,
)( component );

import moment from "moment";
import React from "react";
import { dateFormat } from "config";
import {
    RowLinkView,
    // SocialTitle,
    StatusIcons
    // Avatar
} from "components";

export const chooseItemByFieldName = (
    data,
    elem,
    showViewDetailsPanelAction,
    userPushToRouteAction,
    symbol
) => {
    //MARKET PROTECT
    if ( elem.droppableId === "Listing" ) {
        if( !showViewDetailsPanelAction ) return data.title || "No Listing";
        return (
            <RowLinkView
                label={data.title || null}
                handleClick={() => showViewDetailsPanelAction( data.id )}
            />
        );
    }
    if ( elem.droppableId === "Seller" ) {
        return (
            ( data.seller && data.seller.externalName ) || "No Seller"
        );
    }
    if ( elem.droppableId === "Price" ) {
        return (
            ( "" + symbol + data.price ) || "No Price"
        );
    }
    if ( elem.droppableId === "Date" ) {
        return moment( data.createdAt ).format( dateFormat );
    }
    if ( elem.droppableId === "Description" ) {
        return (
            <div className="description">
                {data.listingDescription || data.profileDescription || "No Description"}
            </div>
        );
    }
    if ( elem.droppableId === "Status" ) {
        return (
            <StatusIcons
                status={{
                    mark: ( data.mark && data.mark.name ) || null,
                    infringementType:
                        ( data.infringementType && data.infringementType.name ) || null,
                    enforcementStatus:
                        ( data.enforcementStatus && data.enforcementStatus.name ) || null
                }}
                text={true}
                tooltip={true}
            />
        );
    }
    return elem.label;
};
import React from "react";
import {
    TableCheckBox,
    TableCellItem,
    EnhancedTableHead,
    EnhancedTableBody
    // CardActions
} from "components";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import "./Table.scss";

const useStyles = makeStyles( {
    root: {
        width: "100%",
        overflowX: "auto"
    },
    table: {
        minWidth: 650
    }
} );

export function EnhancedTable( props ) {
    const classes = useStyles();
    const { cells, activeModuleState, check, bodyCells, tableView, chooseItemByFieldName, symbol } = props;

    if( !bodyCells ) return ( <div>"No Items!"</div> );
    return(
        <Paper className={classes.root+" scroll-m"}>
            <Table
                className={`table-main ${tableView}`}
                aria-labelledby="tableTitle"
                aria-label="enhanced table"
            >
                <EnhancedTableHead>
                    {check && <TableCheckBox {...props} />}
                    {
                        cells && cells.map( ( headCell, index ) => {
                            if( !headCell.value ) return null;
                            return (
                                <TableCell key={index}>
                                    <TableCellItem
                                        {...props}
                                        element={ headCell }
                                        fieldName={ headCell.fieldName[activeModuleState] }
                                    />
                                </TableCell>
                            );
                        } )
                    }
                    {/*<TableCell/>*/}
                </EnhancedTableHead>
                <EnhancedTableBody >
                    { bodyCells && renderCellsBody( bodyCells, props, cells, chooseItemByFieldName, symbol ) }
                </EnhancedTableBody>
            </Table>
        </Paper>
    );
}

const renderCellsBody = ( bodyCells, props, cells ) => {
    const { showViewDetailsPanelAction, userPushToRouteAction, chooseItemByFieldName, symbol } = props;

    const checkBox = ( id ) =>(
        <TableCheckBox
            handleChange={( { value } ) => {
                props.chooseCardToSelect( {
                    value: value,
                    id: id
                } );
            }}
            defaultValue={props.selectedCardsList.indexOf( id ) !== -1}
        />
    );

    const tableCells = ( cells, bodyCell ) => (
        cells.map( ( el, i ) => {
            if ( !el.value ) return null;
            return (
                <TableCell key={i} align="left" >
                    { chooseItemByFieldName(
                        bodyCell,
                        el,
                        showViewDetailsPanelAction,
                        userPushToRouteAction,
                        symbol
                    ) }
                </TableCell>
            );
        } )
    );

    // const cellActions = ( id ) => (
    //     <TableCell align="right">
    //         <CardActions
    //             editBtn={false}
    //             horizontally={true}
    //             id={id}
    //             deleteOneCardsAction={props.deleteOneCardsAction}
    //         />
    //     </TableCell>
    // );

    return bodyCells.map( ( bodyCell, index ) => {
        return(
            <TableRow
                hover
                role="checkbox"
                key={index}
            >
                {/********* CheckBox into cell *********/}
                {checkBox( bodyCell.id )}
                {/********* CheckBox into cell END *********/}

                {/********* TableCell render component *********/}
                {cells && tableCells( cells, bodyCell )}
                {/********* TableCell render component END *********/}

                {/********* TableCell actions *********/}
                {/*{cellActions( bodyCell.id )}*/}
                {/********* TableCell actions END *********/}
            </TableRow>
        );
    } );
};



import React from "react";
import { Checkbox } from "components";
import TableCell from "@material-ui/core/TableCell";

export const TableCheckBox = ( props ) => {
    return(
        <TableCell padding="checkbox">
            <Checkbox
                handleChange={props.handleChange}
                defaultValue={props.defaultValue}
            />
        </TableCell>
    );
};
import React from "react";
import { Tooltip } from "components";
import "./StatusIcons.scss";

export const StatusIcons = ( {
    status = {},
    type = "default",
    tooltip = false
} ) => {
    const {
        mark = null,
        infringementType = null,
        enforcementStatus = null
    } = status;
    let wrapperClassName = "status-icons " + type;
    if ( tooltip ) wrapperClassName += " tooltip-status";
    const wrapper = getWrapperBox.bind( null, tooltip );
    return (
        <div className={wrapperClassName}>
            <div className="icon-row">
                {mark
                    ? mark !== "Infringing"
                        ? wrapper( {
                            className: getClassName( mark ),
                            children: mark,
                            text: mark
                        } )
                        : null
                    : wrapper( {
                        className: "no-label",
                        children: "No Label",
                        text: "No Label"
                    } )}
                {infringementType
                    ? wrapper( {
                        className: "infringement",
                        children: infringementType,
                        text: infringementType
                    } )
                    : null}
                {enforcementStatus
                    ? wrapper( {
                        className: "infringement",
                        children: enforcementStatus,
                        text: enforcementStatus
                    } )
                    : null}
            </div>
        </div>
    );
};

const getClassName = value => {
    return value
        .toString()
        .toLowerCase()
        .replace( / /g, "-" );
};

const getWrapperBox = ( tooltip, props ) => {
    const { children, className, text } = props;
    return tooltip ? (
        <Tooltip title={text}>
            <div className={"circle-icon " + className} />
        </Tooltip>
    ) : (
        <div className={"circle-icon " + className}>{children}</div>
    );
};

import React from "react";
import "./PostCounter.scss";

export const PostCounter = ( { postCount = null } ) => {
    if ( !postCount ) return null;
    return (
        <div className="post-counter">
            {postCount} {Number( postCount ) === 1 ? "Item" : "Items"}
        </div>
    );
};
import React from "react";
import { NavLink } from "react-router-dom";
import {
    logoSmall
} from "components";

export const HomeBadge = () => {

    return (
        <NavLink to={"/"} activeClassName="active">
            {logoSmall()}
        </NavLink>
    );
};
import { connect } from "react-redux";
import { PageRoute as component } from "./PageRoute";

import {
    cardListDataSelector,
    selectedCardsList,
    selectedAllCardsStateSelector,
    cardListLoaderSelector
} from "pages/SocialProtect/services/Card/selectors";

import {
    chooseCardToSelectAction,
    chooseAllCardAction,
    deleteOneCardsAction,
    loadMoreAction,
    changeOrderDirectionAction
} from "pages/SocialProtect/services/Card/actions";
import {
    pageViewStateSelector,
    customizeTableViewSelector,
    pageCardDisplayViewSelector
} from "pages/SocialProtect/services/UserView/selectors";
import { pageListViewChangeAction } from "pages/SocialProtect/services/UserView/actions";
import {
    isLoadingMoreSelector,
    activeFilterSelector,
    activeFilterAppliedSelector
} from "pages/SocialProtect/services/Options/selectors";
import { saveFilterAndSearchAction } from "pages/SocialProtect/services/Options/actions";
import { userPushToRouteAction, removeChipsAction } from "pages/SocialProtect/components/PageRoute/services/actions";
import { activeBrandSelector } from "services/Brand/selectors";
import {
    platformListLoaderSelector,
    platformListSelector
} from "services/Platform/selectors";
import {
    statusListSelector,
    statusListLoaderSelector
} from "services/Status/selectors";
import {
    labelsSelector
} from "pages/SocialProtect/services/Label/selectors";
import { activeModuleStateSelector } from "services/UserView/selectors";
import { showViewDetailsPanelAction } from "services/UserView/actions";

import { applyFilterIfUpdated } from "services/Filter/utils";
import { handleChangeLimitPage, handlePageChange } from "services/Navigation/utils";

const mapDispatchToProps = {
    chooseCardToSelectAction,
    chooseAllCardAction,
    deleteOneCardsAction,
    pageListViewChangeAction,
    showViewDetailsPanelAction,
    loadMoreAction,
    changeOrderDirectionAction,
    saveFilterAndSearchAction,
    userPushToRouteAction,
    removeChipsAction
};

const mapStateToProps = state => {
    const activeModuleState = activeModuleStateSelector( state ) || null;
    const cardListState = cardListDataSelector( state ) || null;
    const pageViewState = pageViewStateSelector( state );
    const tableViewState = customizeTableViewSelector( state );
    const selectedCardsListState = selectedCardsList( state );
    const selectedAllCardsState = selectedAllCardsStateSelector( state );
    const pageCardDisplayViewState = pageCardDisplayViewSelector( state );
    const cardListLoaderState = cardListLoaderSelector( state ) || false;
    const isLoadingMore = isLoadingMoreSelector( state ) || false;
    const activeFilter = activeFilterSelector( state ) || false;
    const activeFilterAppliedState = activeFilterAppliedSelector( state ) || false;
    const platformListIsLoadingState = platformListLoaderSelector( state );
    const platformListState = platformListSelector( state );
    const statusListState = statusListSelector( state );
    const statusListIsLoadingState = statusListLoaderSelector( state );
    const activeBrandState = activeBrandSelector( state );
    const labelsState = labelsSelector( state );

    return {
        activeModuleState,
        cardListState,
        pageViewState,
        tableViewState,
        activeBrandState,
        selectedCardsListState,
        selectedAllCardsState,
        pageCardDisplayViewState,
        cardListLoaderState,
        isLoadingMore,
        activeFilter,
        activeFilterAppliedState,
        platformListIsLoadingState,
        statusListIsLoadingState,
        statusListState,
        platformListState,
        labelsState
    };
};

const mergeProps = ( stateProps, dispatchProps, ownProps ) => {
    const { saveFilterAndSearchAction } = dispatchProps;
    const { activeFilter, cardListState } = stateProps;
    const pageLimit = cardListState ? cardListState.limit : null;
    const currentPage = cardListState ? cardListState.page : null;

    return{
        ...stateProps,
        ...dispatchProps,
        ...ownProps,
        handleChangeLimitPage: ( newLimit ) => handleChangeLimitPage( pageLimit, newLimit, saveFilterAndSearchAction ),
        handlePageChange: ( { selected } ) => handlePageChange( activeFilter, selected, currentPage, saveFilterAndSearchAction ),
        handleChangeFilter: ( label, value ) => {
            applyFilterIfUpdated( activeFilter, label, value, saveFilterAndSearchAction );
        }
    };
};

export const PageRoute = connect(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps
)( component );

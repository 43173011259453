// This watches changes that need to be coordinated to different sub-services

import { takeLatest, call } from "redux-saga/effects";

import { setActiveModuleStateSPCardWorker } from "./Card/sagas";
import { labelsWorker } from "./Label/sagas";
import { activeServiceValidationWrapper } from "services/UserView/sagas";
import { setActiveModuleStateAction } from "services/UserView/actions";
import { serviceDefinition } from "./constants";

export function* setActiveModuleStateSPWatcher() {
    yield takeLatest(
        setActiveModuleStateAction,
        activeServiceValidationWrapper,
        {
            ...serviceDefinition,
            worker: setActiveModuleStateSPWorker
        }
    );
}

function* setActiveModuleStateSPWorker( { payload } ) {
    yield call( setActiveModuleStateSPCardWorker, payload );
    yield call( labelsWorker, payload ) ;
}

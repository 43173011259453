import moment from "moment";
import { dateFormat } from "config";

export const isEmpty = value =>
    value === undefined ||
  value === null ||
  ( typeof value === "object" && Object.keys( value ).length === 0 ) ||
  ( typeof value === "string" && value.trim().length === 0 );

export const uniqueId = () => {
    return ( Math.random().toString( 36 ) + "00000000000000000" ).slice( 2, 10 );
};

export function getCountItemsPerPage( views ) {
    switch ( views ) {
        case "block-description-x1":
            return "20";
        case "block-description-x2":
            return "20";
        case "block-img-x1":
            return "20";
        case "block-img-x2":
            return "200";
        case "block-img-x3":
            return "200";
        case "block":
            return "20";
        case "row":
            return "100";

        default:
            return "20";
    }
}

export function sortAlphabetically( array, isLowerCasedComparison, key ) {

    return array.sort( ( a, b ) => {
        let valA = a, valB = b;

        if ( key ) {
            valA = a[key];
            valB = b[key];
        }

        if ( isLowerCasedComparison ) {
            valA = valA.toLowerCase();
            valB = valB.toLowerCase();
        }

        if( valA < valB ) return -1;
        if( valA > valB ) return 1;

        return 0;
    } );
}

export function checkFieldAndSave( field, value, store ) {
    //check localStorage
    const userData = localStorage[store];
    //create object
    const objData = userData ? JSON.parse( userData ) : {};
    //add value
    objData[field] = value;
    //save settings
    localStorage[store] = JSON.stringify( objData );
}

export function copyStringToClipboard( str ) {
    // Create new element
    const el = document.createElement( "textarea" );
    document.body.appendChild( el );
    // Set value (string to be copied)
    el.value = str;
    el.textContent = str;
    // Set non-editable to avoid focus and move outside of view
    const sel = getSelection();
    const range = document.createRange();
    range.selectNode( el );
    sel.removeAllRanges();
    sel.addRange( range );
    if ( document.execCommand( "copy" ) ) {
        console.log( "copied" );
    }
    document.body.removeChild( el );
}

export const getCells = ( tableViewState, activeModuleState ) => {
    let cells = [];
    const columnSelected= tableViewState.list_enabled_item;
    const columnOrderList= tableViewState.columns.taskIds;
    for ( let key in columnSelected ) {
        const visibilityItemState =  columnSelected[key].visibility && columnSelected[key].visibility[activeModuleState];
        if ( visibilityItemState ) {
            columnSelected[key].order = columnOrderList.indexOf(
                columnSelected[key].droppableId
            );
            cells.push( columnSelected[key] );
        }
    }
    return cells.sort( ( a, b ) =>
        a.order > b.order ? 1 : b.order > a.order ? -1 : 0
    );
};

export const formatDate = value => moment( value ).format( dateFormat );

export function getClass ( obj ) {
    return {}.toString.call( obj ).slice( 8, -1 );
}

export const isDate = ( date ) => {
    if( date.toString() === parseInt( date ).toString()  ) return false;
    const tryDate = new Date( date );
    return ( tryDate && tryDate.toString() !== "NaN" && ""+tryDate !== "Invalid Date" );
};
import { loadStatusListAction, saveStatusListAction } from "./actions";
import { handleActions } from "redux-actions";

const initialState = {
    statusList: null,
    statusLoader: false
};

export const StatusReducer = handleActions(
    {
        [loadStatusListAction]: ( state, { payload } ) => ( {
            ...state,
            statusLoader: payload
        } ),
        [saveStatusListAction]: ( state, { payload } ) => ( {
            ...state,
            statusList: payload
        } )
    },
    initialState
);

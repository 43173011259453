import React, { PureComponent } from "react";
import { AddPostForm } from "./components";
import "pages/SocialProtect/styles/AddItem.scss";

export class AddPost extends PureComponent {
    render() {
        return (
            <div className="page-container__full-width scroll-m">
                {/*Form Add Post start*/}
                <AddPostForm />
                {/*Form Add Post end*/}
            </div>
        );
    }
}

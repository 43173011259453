import React from "react";
import { Link } from "react-router-dom";

import colors from "styles/variables.scss";
import {
    //Spacer,
    addIcon, arrow, addUser, addPost, SimpleDropdown } from "components";
import "./AddItem.scss";

const MenuOption = ( { icon, title, to, label } ) => (
    <Link
        title={title}
        to={to}
    >
        {icon}
        <p className="addItem__text">{label}</p>
    </Link>
);

export const AddItem = () => {

    return (
        <SimpleDropdown
            content={
                <div className="container">
                    {[
                        {
                            sectionName: "Social Protect",
                            items: [
                                <MenuOption
                                    title="Add a social protect post"
                                    to="/social-protect/posts/add"
                                    icon={addPost( colors.primary, 24, 24 )}
                                    label="Add Post"
                                />,
                                <MenuOption
                                    title="Add a social protect profile"
                                    to="/social-protect/profiles/add"
                                    icon={addUser( colors.primary, 24, 24 )}
                                    label="Add Profile"
                                />

                            ]
                        }
                        //<Spacer /> // Later, this will come when we have another section.
                    ].map( ( x, i ) => {
                        // Spacers
                        if ( !x.sectionName ) return x;

                        return (
                            // Section
                            <div className="row" key={i}>
                                <div className="col">
                                    <span className="addItem__sectionName">{x.sectionName}</span>

                                    {/* Section elements/links */}
                                    {x.items.map(
                                        ( x, i ) => (
                                            <div className="row" key={i}>
                                                <div className="col">
                                                    {x}
                                                </div>
                                            </div>
                                        )
                                    )}
                                </div>
                            </div>
                        );
                    } )}
                </div>
            }
            icon={
                <div className="addItem__trigger">
                    <div className="icon-wrapper-circle">{addIcon( "white" )}</div>
                    <div className="addItem__carrot">Add {arrow()}</div>
                </div>
            }
            wrapperClass="addItem"
            headerClass="addItem-header"
            bodyClass="addItem-body"
            dropdownClass="addItem-dropdown"
        />
    );
};
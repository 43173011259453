import { combineReducers } from "redux";
import { reducer as formReducer } from "redux-form";
import { routerReducer } from "react-router-redux";

import { socialProtectReducer } from "pages/SocialProtect/services/reducer";
import { marketProtectReducer } from "pages/MarketProtect/services/reducer";
import { landingReducer } from "pages/Landing/services/reducer";

import { globalServicesReducer } from "services/reducer";
import { errorReducer } from "services/Error/reducer";
import { messageReducer } from "services/Message/reducer";
import { modalReducer } from "services/Modal/reducer";

const rootReducer = combineReducers( {
    router: routerReducer,
    form: formReducer,
    errors: errorReducer,
    messages: messageReducer,
    modal: modalReducer,
    main: globalServicesReducer,
    socialProtect: socialProtectReducer,
    marketProtect: marketProtectReducer,
    landing: landingReducer
} );

export default rootReducer;

import React, { PureComponent } from "react";
import { HomeBadge, SideBarWrapper } from "components";
//import { settingsSmall } from "components/Icons/Svg";

export class SideBar extends PureComponent {
    render() {
        const { sideBarIsOpen } = this.props;
        let sideBarClassName = "side-bar side-bar-flex";

        if ( sideBarIsOpen ) sideBarClassName += " open";

        return (
            <SideBarWrapper
                sideBarClassName={sideBarClassName}
                top={( <HomeBadge /> )}
                //bottom={( <div className="icon-wrapper-circle">{settingsSmall()}</div> )}
            />
        );
    }
}

import React from "react";
import { Dropdown, TopFilterAndCustomize, PostCounter, ToggleView, Loader } from "components";
import { CustomizeBlock } from "../CustomizeBlock";

export const FilterCustomize = ( {
    cardListState,
    activeView,
    pageViewChange,
    handleChangeLimitPage,
    activeFilter,
    statusListState,
    statusListIsLoadingState,
    platformListIsLoadingState,
    platformListState,
    handleChangeFilter,
    activeModuleState
} ) => {
    const postPerPageList = [ "20", "50", "100", "200" ];
    if ( activeView !== "block" ) postPerPageList.push( "500" );
    return (
        <TopFilterAndCustomize
            left={(
                <>
                    {
                        statusListIsLoadingState || !statusListState ?
                            <Loader style={{ margin: "6px 0" }}/>
                            :
                            <Dropdown
                                data={statusListState}
                                defaultAll={true}
                                defaultValue={activeFilter.enforcement || "All"}
                                wrapperClassName="filter-dropdown"
                                widthBtn="120px"
                                label="Status:"
                                handleChange={id => handleChangeFilter( "enforcement", id )}
                            />
                    }
                    {
                        platformListIsLoadingState || !platformListState ?
                            <Loader style={{ margin: "6px 0" }}/>
                            :
                            <Dropdown
                                data={platformListState}
                                defaultAll={true}
                                dropHeight={"250px"}
                                defaultValue={activeFilter.platform || "All"}
                                wrapperClassName="filter-dropdown"
                                widthBtn="120px"
                                label="Platform:"
                                handleChange={id => handleChangeFilter( "platform", id )}
                            />
                    }
                </>
            )}
            center={(
                <PostCounter
                    postCount={cardListState ? cardListState.totalCount : null}
                />
            )}
            right={(
                <>
                    <Dropdown
                        data={postPerPageList}
                        defaultValue={cardListState ? cardListState.limit : "10"}
                        widthBtn="60px"
                        wrapperClassName="filter-dropdown"
                        label={`${
                            activeView === "block"
                                ? activeModuleState === "posts"
                                    ? "Posts"
                                    : "Profiles"
                                : "Rows"
                        } per page:`}
                        handleChange={handleChangeLimitPage}
                    />
                    <CustomizeBlock label="Customize:" />
                    <ToggleView
                        mixed={false}
                        active={activeView}
                        onChange={pageViewChange}
                    />
                </>
            )}
        />
    );
};

import React, { Component } from "react";
import {
    SideBar,
    Header,
    PageRoute
} from "pages/Landing/components";

export class Dashboard extends Component {

    render() {
        const { sideBarIsOpen } = this.props;
        let pageClassName = "page bg-primary-transparent";
        if ( sideBarIsOpen ) pageClassName += " with-open-side-bar";

        return (
            <div>
                <SideBar />

                <div className={pageClassName}>
                    <div className="container page-container scroll-m">
                        <div className="row">
                            <div className="col">
                                <Header title="Enforcement modules" />
                            </div>
                        </div>
                        <PageRoute />
                    </div>
                </div>
            </div>
        );
    }
}

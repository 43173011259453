import { urlUtil } from "utils";

import { AppSvg, DomainSvg, MarketSvg, SocialSvg } from "components/Icons/Svg";
const { getProdPath } = urlUtil;

export const modules = [
    {
        identifier: "market-protect",
        moduleStates: [ "listings", "sellers" ],
        //localPath: "/market-protect",
        prodPath: getProdPath( "market-protect", "listings" ),
        description: "Market Protect detects and combats counterfeits from over 650 (and counting) online marketplaces.",
        label: "Market Protect",
        icon: MarketSvg()
    },
    {
        identifier: "domain-protect",
        moduleStates: [ "posts", "profiles" ],
        prodPath: getProdPath( "domain-protect", "websites" ),
        description: "Finds fraudulent web shops and domains and takes thorough legal action to stop them for good.",
        label: "Domain Protect",
        icon: DomainSvg()
    },
    {
        identifier: "social-protect",
        moduleStates: [ "posts", "profiles" ],
        localPath: "/social-protect",
        prodPath: getProdPath( "social-protect", "profiles" ),
        description: "Identifies social media profiles, groups, and listings that impersonate your brand and sell counterfeits.",
        label: "Social Protect",
        icon: SocialSvg()
    },
    {
        identifier: "app-protect",
        moduleStates: [ "posts", "profiles" ],
        prodPath: getProdPath( "app-protect", "apps" ),
        description: "App Protect makes sure your IP rights are protected in all major mobile app stores.",
        label: "App Protect",
        icon: AppSvg()
    }
];

// @TODO: Can this be merged with modules above?
export const validateRoute = ( routeModule, routeModuleState ) => {
    const routes = {
        "landing": [
        ],
        "social-protect": [
            "posts",
            "profiles"
        ],
        "admin": [
            "users"
        ],
        "market-protect": [
            "listings",
            "sellers"
        ]
    };
    return routes[routeModule] && ( routes[routeModule] && routes[routeModule].includes( routeModuleState ) );
};
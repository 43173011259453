import React, { PureComponent } from "react";
import { Link } from "react-router-dom";
import readXlsxFile from "read-excel-file";
import Papa from "papaparse";
import {
    //settingsSmall,
    searchSmall,
    addSmall,
    uploadSmall,
    downloadSmall,
    arrow,
    userD,
    exportSvg
} from "components";
import { HomeBadge, TextInput, SideBarWrapper, CollapsibleDropDownBlock, CollapsibleInputBlock, CollapsibleDatePickerBlock } from "components";
import { ImportFileModalAddFile } from "./components/importFileModalAddFile";
import { ImportFileModalError } from "./components/importFileModalError";
import { ImportFileModalSuccess } from "./components/importFileModalSuccess";

import Modal from "@material-ui/core/Modal";
import { ButtonClose } from "components";

export class SideBar extends PureComponent {
    state = {
        file: null,
        error: null,
        loading: false,
        results: null
    };

    changeInputTitle = e => this.props.saveTitleFilterAction( e.target.value );
    changeInputProfileName = e => this.props.saveProfileFilterAction( e.target.value );

    toggleDialog = () => {
        this.props.toggleExportDataModalIsOpenAction();
    };

    toggleDialogImport = () => {
        const {
            importModalState,
            toggleImportModalStateAction,
            clearImportStoreAction
        } = this.props;
        if ( importModalState ) clearImportStoreAction();
        toggleImportModalStateAction();
        this.setState( {
            file: null
        } );
    };

    getFileData = file => {
        const { importDataAction } = this.props;
        const that = this;
        const newPromises = data => {
            that.setState( { loading: true } );
            return new Promise( ( resolve, reject ) => {
                importDataAction( {
                    data: data,
                    resolve,
                    reject
                } );
            } );
        };
        this.setState( {
            loading: true
        } );
        const fileType = file[0].name.split( "." )[1];
        if ( fileType === "xls" || fileType === "xlsx" ) {
            readXlsxFile( file[0] ).then( rows => {
                newPromises( rows )
                    .then(
                        that.setState( {
                            file: file[0].name,
                            loading: false
                        } )
                    )
                    .catch( err => {
                        that.removeLoader();
                        that.setState( { error: err.error } );
                    } );
            } );
        } else if ( fileType === "csv" ) {
            Papa.parse( file[0], {
                complete: function( results ) {
                    newPromises( results.data )
                        .then(
                            that.setState( {
                                file: file[0].name,
                                loading: false
                            } )
                        )
                        .catch( err => {
                            that.removeLoader();
                            that.setState( { error: err.error } );
                        } );
                }
            } );
        } else {
            this.setState( {
                error: "Wrong file type!",
                loading: false
            } );
            return false;
        }
    };

    removeError = () => {
        const { clearImportStoreAction } = this.props;
        this.setState(
            {
                error: null,
                file: null
            },
            () => clearImportStoreAction()
        );
    };

    removeLoader = () => {
        this.setState( {
            loading: false
        } );
    };

    sendImportData = ( onlySuccess = false ) => {
        const {
            sendOnlySuccessfullyImportDataAction,
            sendImportFileAction
        } = this.props;
        const activeAction = onlySuccess
            ? sendOnlySuccessfullyImportDataAction
            : sendImportFileAction;
        this.setState( {
            loading: true
        } );
        new Promise( ( resolve, reject ) => {
            activeAction( {
                resolve,
                reject
            } );
        } )
            .then( res => {
                this.setState( { results: res } );
                this.removeLoader();
            } )
            .catch( err => {
                if ( err && err.error ) this.setState( { error: err.error } );
                this.removeLoader();
            } );
    };

    goToPosts = () => {
        const {
            toggleImportModalStateAction,
            goToThePostsAfterImportAction
        } = this.props;
        const { results } = this.state;
        this.removeError();
        toggleImportModalStateAction();
        goToThePostsAfterImportAction( results.batchUploadID );
    };

    render() {
        const {
            toggleSideBarIsOpenAction,
            sideBarIsOpen,
            activeFilter,
            searchWordTitleState,
            searchWordProfileState,
            searchByFilterAction,
            statusListState,
            statusListIsLoadingState,
            isLoadingLabels,
            labelsState,
            platformListIsLoadingState,
            platformListState,
            activeModuleState,
            exportDataAction,
            exportModalState,
            importModalState,
            importFileState,
            dataAfterImportState,
            copyErrorLinksImportAction,
            exportErrorLinksToCsvAction,
            searchOnEnter,
            onChangeInputFilter,
            onChangeDropdownFilter,
            onChangeDateFilter
        } = this.props;
        const { error, file, loading, results } = this.state;
        let linkTo =
            activeModuleState === "posts"
                ? "/social-protect/posts/add"
                : "/social-protect/profiles/add";
        let sideBarClassName = "side-bar side-bar-flex";
        if ( sideBarIsOpen ) sideBarClassName += " open";
        const successLength =
            (
                dataAfterImportState &&
                dataAfterImportState
                    .filter( e => e.errorsArr && !e.errorsArr.length )
                    .length
            ) || false;
        return (
            <SideBarWrapper
                sideBarClassName={sideBarClassName}
                top={(
                    <>
                        <HomeBadge />

                        <ul className="side-bar-list-icon">
                            <li
                                role="presentation"
                                onClick={toggleSideBarIsOpenAction}
                                className="filters"
                            >
                                <div className="icon-wrapper-circle" title="Filters">
                                    {searchSmall()}
                                </div>
                            </li>
                            <li>
                                <Link
                                    to={linkTo}
                                    className="icon-wrapper-circle"
                                    title="Add Item"
                                >
                                    {addSmall()}
                                </Link>
                            </li>
                            <li>
                                <div
                                    className="icon-wrapper-circle"
                                    title="Import"
                                    onClick={this.toggleDialogImport}
                                    role="button"
                                    tabIndex={0}
                                    onKeyPress={e => e.keyCode === 13 ? this.toggleDialogImport() : false}
                                >
                                    {uploadSmall()}
                                </div>
                            </li>
                            <li>
                                <div
                                    className="icon-wrapper-circle"
                                    title="Export"
                                    onClick={this.toggleDialog}
                                    role="button"
                                    tabIndex={0}
                                    onKeyPress={e => e.keyCode === 13 ? this.toggleDialog() : false}
                                >
                                    {downloadSmall()}
                                </div>
                            </li>
                        </ul>
                    </>
                )}
                //bottom={( <div className="icon-wrapper-circle">{settingsSmall()}</div> )}
                slideBar={sideBarIsOpen ? (
                    <div className="side-bar-right">
                        <div className="top-panel">
                            <div className="logo-btn-box noselect">
                                <span>Search</span>
                                <div
                                    role="button"
                                    tabIndex={0}
                                    className="side-bar-hide"
                                    onClick={toggleSideBarIsOpenAction}
                                    onKeyPress={e => e.keyCode === 13 ? toggleSideBarIsOpenAction() : false}
                                >
                                    {arrow()}
                                </div>
                            </div>
                            <div className="input-panel noselect">
                                <TextInput
                                    placeholder={"e.g. title, criteria..."}
                                    childrenStyle={{
                                        marginRight: "10px"
                                    }}
                                    defaultValue={searchWordTitleState || ""}
                                    handleChange={this.changeInputTitle}
                                    onKeyPress={searchOnEnter}
                                >
                                    {searchSmall()}
                                </TextInput>
                                <TextInput
                                    placeholder={"Profile name"}
                                    childrenStyle={{
                                        marginRight: "10px"
                                    }}
                                    defaultValue={searchWordProfileState || ""}
                                    handleChange={this.changeInputProfileName}
                                    onKeyPress={searchOnEnter}
                                >
                                    {userD()}
                                </TextInput>
                            </div>
                            <div className="filter-panel scroll-m noselect">
                                <div>
                                    <CollapsibleDropDownBlock
                                        title="Platform"
                                        name="platform"
                                        clearable={true}
                                        loader={platformListIsLoadingState}
                                        data={platformListState}
                                        activeFilterData={activeFilter}
                                        handleChange={onChangeDropdownFilter}
                                        dropHeight={ "200px" }
                                    />
                                    <CollapsibleDropDownBlock
                                        title="Label"
                                        name="postMark"
                                        clearable={true}
                                        loader={isLoadingLabels}
                                        data={labelsState}
                                        activeFilterData={activeFilter}
                                        handleChange={onChangeDropdownFilter}
                                        dropHeight={ "200px" }
                                    />
                                    <CollapsibleDropDownBlock
                                        title="Enforcement status"
                                        name="enforcement"
                                        clearable={true}
                                        loader={statusListIsLoadingState}
                                        data={statusListState}
                                        activeFilterData={activeFilter}
                                        handleChange={onChangeDropdownFilter}
                                        dropHeight={ "200px" }
                                    />
                                    <CollapsibleInputBlock
                                        title="Profile name"
                                        name="profileName"
                                        defaultValue={activeFilter.profileName}
                                        handleChange={onChangeInputFilter}
                                        onKeyPress={searchOnEnter}
                                    />
                                    <CollapsibleInputBlock
                                        title="Profile ID"
                                        name="profileId"
                                        defaultValue={activeFilter.profileId}
                                        handleChange={onChangeInputFilter}
                                        onKeyPress={searchOnEnter}
                                    />
                                    {activeModuleState === "posts" && (
                                        <CollapsibleInputBlock
                                            title="Profile URL"
                                            name="profileUrl"
                                            defaultValue={activeFilter.profileUrl}
                                            handleChange={onChangeInputFilter}
                                            onKeyPress={searchOnEnter}
                                        />
                                    )}
                                    <CollapsibleInputBlock
                                        title="Listing ID"
                                        name="listingId"
                                        defaultValue={activeFilter.listingId}
                                        handleChange={onChangeInputFilter}
                                        onKeyPress={searchOnEnter}
                                    />
                                    <CollapsibleInputBlock
                                        title="Batch upload ID"
                                        name="batchUploadId"
                                        defaultValue={activeFilter.batchUploadId}
                                        handleChange={onChangeInputFilter}
                                        onKeyPress={searchOnEnter}
                                    />
                                    <CollapsibleDatePickerBlock
                                        title="Date"
                                        nameFrom="createdAtFrom"
                                        nameTo="createdAtTo"
                                        defaultValueFrom={activeFilter.createdAtFrom}
                                        defaultValueTo={activeFilter.createdAtTo}
                                        handleChange={onChangeDateFilter}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="button-panel">
                            <button
                                type="button"
                                className="btn btn-blue"
                                onClick={() => searchByFilterAction()}
                            >
                                Apply
                            </button>
                            {/*<button type="button" className="btn">*/}
                            {/*{starBtn()}Save*/}
                            {/*</button>*/}
                        </div>
                    </div>
                ) :   null  }
                modals={(
                    <>
                        <Modal
                            open={exportModalState}
                            disablePortal={true}
                            onClose={this.toggleDialog}
                        >
                            <div className={"main-modal export"}>
                                <ButtonClose
                                    color="#979797"
                                    className="close-modal"
                                    onClick={this.toggleDialog}
                                />
                                <div className="title-slider">Export Data</div>
                                <div className="export-blocks">
                                    <div
                                        onClick={() => exportDataAction( "export" )}
                                        role="presentation"
                                    >
                                        <div>{exportSvg( "#809096" )}</div>
                                        <div>Export for CSV</div>
                                    </div>
                                    <div
                                        onClick={() => exportDataAction( "exportExcel" )}
                                        role="presentation"
                                    >
                                        <div>{exportSvg( "#809096" )}</div>
                                        <div>Export for Excel</div>
                                    </div>
                                </div>
                            </div>
                        </Modal>

                        <Modal
                            open={importModalState}
                            disablePortal={true}
                            onClose={this.toggleDialogImport}
                        >
                            <div className={"main-modal import"}>
                                <ButtonClose
                                    color="#979797"
                                    className="close-modal"
                                    onClick={this.toggleDialogImport}
                                />
                                <div className="title-slider">Import data</div>
                                {!importFileState ? (
                                    <ImportFileModalAddFile
                                        activeModuleState={activeModuleState}
                                        error={error}
                                        loading={loading}
                                        file={file}
                                        sendImportData={this.sendImportData}
                                        toggleDialogImport={this.toggleDialogImport}
                                        getFileData={this.getFileData}
                                        removeError={this.removeError}
                                    />
                                ) : importFileState === "error" ? (
                                    <ImportFileModalError
                                        exportErrorLinksToCsvAction={exportErrorLinksToCsvAction}
                                        error={error}
                                        loading={loading}
                                        dataAfterImportState={dataAfterImportState}
                                        successLength={successLength}
                                        copyErrorLinksImportAction={copyErrorLinksImportAction}
                                        sendImportData={this.sendImportData}
                                        removeError={this.removeError}
                                        toggleDialogImport={this.toggleDialogImport}
                                    />
                                ) : importFileState === "success" ? (
                                    <ImportFileModalSuccess
                                        results={results}
                                        goToPosts={this.goToPosts}
                                        toggleDialogImport={this.toggleDialogImport}
                                    />
                                ) : null}
                            </div>
                        </Modal>
                    </>
                )}
            />
        );
    }
}

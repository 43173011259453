import { combineReducers } from "redux";

import { marketProtectEntriesReducer } from "pages/MarketProtect/services/Entries/reducer";
import { marketProtectOptionsReducer } from "pages/MarketProtect/services/Options/reducer";
import { marketProtectUserViewReducer } from "pages/MarketProtect/services/UserView/reducer";
import { StatusReducer } from "pages/MarketProtect/services/Status/reducer";
import { marketProtectLabelReducer } from "pages/MarketProtect/services/Label/reducer";

export const marketProtectReducer = combineReducers( {
    options: marketProtectOptionsReducer,
    status: StatusReducer,
    entries: marketProtectEntriesReducer,
    label: marketProtectLabelReducer,
    userView: marketProtectUserViewReducer
} );
import { loadPlatformListAction, savePlatformListAction } from "./actions";
import { handleActions } from "redux-actions";

const initialState = {
    platformList: null,
    platformLoader: false
};

export const PlatformReducer = handleActions(
    {
        [loadPlatformListAction]: ( state, { payload } ) => ( {
            ...state,
            platformLoader: payload
        } ),
        [savePlatformListAction]: ( state, { payload } ) => ( {
            ...state,
            platformList: payload
        } )
    },
    initialState
);

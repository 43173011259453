import React from "react";
import "./TopFilterAndCustomize.scss";

export const TopFilterAndCustomize = ( {
    left,
    center,
    right
} ) => {
    return (
        <div className="filter-customize">
            <div className="filter-customize__filter">
                {left}
            </div>
            <div className="filter-customize__counter">
                {center}
            </div>
            <div className="filter-customize__customize">
                {right}
            </div>
        </div>
    );
};

import React from "react";
import { TitleDetails, ListDetails, RowStatistics } from "components";
// import { dateFormat } from "config";
// import moment from "moment";

export const Overview = ( data ) => {
    return (
        <div className="info-wrapper">
            <TitleDetails text={"Listing information"} />
            <RowStatistics
                data={[
                    { label: "Views:", value: 0 },
                    { label: "Likes:", value: 0 },
                    { label: "Shares:", value: 0 },
                    { label: "Followers:", value: 0 }
                ]}
            />
            <ListDetails
                data={[
                    { label: "Reported date", value: 0 },
                    { label: "Removed date", value: 0 }
                ]}
            />
        </div>
    );
};

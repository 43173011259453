import React, { PureComponent } from "react";
import { checkedSVG, uncheckedSVG } from "components";
import "./Checkbox.scss";

export class Checkbox extends PureComponent {
    constructor( props ) {
        super( props );
        this.state = {
            value: false
        };
    }

    static getDerivedStateFromProps( nextProps, prevState ) {
        if ( Object.prototype.hasOwnProperty.call( nextProps, "defaultValue" ) ) {
            return {
                value: nextProps.defaultValue
            };
        }
        return null;
    }

    componentDidMount() {
        const { value } = this.props;
        if ( value ) this.setState( { value } );
    }

    onChange = event => {
        const { handleChange = val => {} } = this.props;
        this.setState( { value: event.target.checked }, handleChange( event.target ) );
    };

    render() {
        const {
            input,
            name = "",
            label = null,
            disabled = false,
            labelClassName = null,
            color = null
        } = this.props;
        let { value } = this.state;
        let labelClass = "label-checkbox-default ";
        if ( labelClassName ) labelClass += labelClassName;
        return (
            <label className={labelClass}>
                <input
                    {...input}
                    name={name}
                    type="checkbox"
                    checked={value}
                    onChange={this.onChange}
                    disabled={disabled}
                />
                <span>
                    {value
                        ? checkedSVG( disabled ? "#809096" : "#445bf4" )
                        : uncheckedSVG( color ? color : "#809096" )}
                </span>
                {label ? <span className="title">{label}</span> : null}
            </label>
        );
    }
}

import React, { PureComponent } from "react";
import { arrowLeft } from "components/Icons/Svg";
import { Field, reduxForm, SubmissionError, reset } from "redux-form";
import { TextInput, Button, Dropdown, TextAreaInput, Loader } from "components";
import { validationUtil } from "utils";
import { NavLink } from "react-router-dom";

const { required, numericValidation, expressionURL } = validationUtil;

export class AddProfileFormDisplay extends PureComponent {
    SubmitForm = data => {
        const { addCardItemAction } = this.props;
        let sendData = {
            enforcementStatus: ( data.label && data.label === 1 && data.enforcementStatus ) || null,
            infringementType: ( data.label && data.label === 1 && data.infringementType ) || null,
            mark: data.label || null,
            platform: data.platform,
            connectedTo: data.profileConnectedTo || "",
            countryCode2: data.profileCountry || "",
            evidenceURL: data.profileEvidenceURL || "",
            note: data.profileComments || "",
            profileDescription: data.profileDescription || "",
            profileFollowers: data.profileFollowers || null,
            profileID: data.profileId || "",
            profileImage: data.profileImage || "",
            profileLikes: data.profileLikes || null,
            profileName: data.profileName || "",
            profilePosts: data.profilePosts || null,
            profileURL: data.profileURL || "",
            profileViews: data.profileViews || null,
            submittedBy: data.profileSubmittedBy || ""
        };
        return new Promise( ( resolve, reject ) => {
            addCardItemAction( {
                data: sendData,
                resolve,
                reject
            } );
        } )
            .then( res => {
                //Success
                this.clearReduxForm( "AddProfileForm" );
            } )
            .catch( errors => {
                throw new SubmissionError( {
                    ...errors,
                    _error: "Error adding item, check your fields!"
                } );
            } );
    };

    clearReduxForm = name => {
        const { dispatch } = this.props;
        dispatch( reset( name ) );
    };

    render() {
        const {
            handleSubmit,
            countryListState,
            platformListState,
            submitting,
            statusListState,
            labelsState,
            formFieldsValues,
            infringementTypeListState,
            error
        } = this.props;
        return (
            <div className="add-item-inner">
                {/* eslint-disable-next-line */}
                {/*<a className={"back-link"} onClick={history.goBack} />*/}
                <NavLink className={"back-link"} to={"/social-protect/profiles"} activeClassName="active">
                    {arrowLeft( "#546369" )} Back
                </NavLink>
                <div className="add-item-inner__form">
                    <div className="form-title">Submit a profile</div>
                    {platformListState ? (
                        <Field
                            name="platform"
                            data={platformListState}
                            component={Dropdown}
                            label="Select Platform (required)"
                            placeholder="Select"
                            search={true}
                            validate={[ required ]}
                        />
                    ) : (
                        <Loader style={{ margin: "50px auto" }} />
                    )}
                    <form onSubmit={handleSubmit( this.SubmitForm )}>
                        <div>
                            <Field
                                name="profileName"
                                type="text"
                                component={TextInput}
                                label="Profile name"
                                placeholder="Profile name of the posts"
                            />
                            <Field
                                name="profileURL"
                                type="text"
                                component={TextInput}
                                label="Profile URL (required)"
                                validate={[ required ]}
                                placeholder="https://"
                            />
                            {countryListState ? (
                                <Field
                                    name="profileCountry"
                                    data={countryListState}
                                    component={Dropdown}
                                    label="Profile country (required)"
                                    placeholder="Select a Country"
                                    search={true}
                                    validate={[ required ]}
                                />
                            ) : (
                                <Loader style={{ margin: "40px auto" }} />
                            )}
                            {labelsState ? (
                                <Field
                                    name="label"
                                    data={labelsState}
                                    unSelect={true}
                                    component={Dropdown}
                                    label="Label"
                                    placeholder="Select label"
                                />
                            ) : (
                                <Loader style={{ margin: "40px auto" }} />
                            )}
                            {formFieldsValues.label === 1 ? (
                                <Field
                                    name="infringementType"
                                    data={infringementTypeListState}
                                    component={Dropdown}
                                    label="Infringement type (required)"
                                    placeholder="Select Type"
                                    validate={[ required ]}
                                />
                            ) : null}
                            {formFieldsValues.label === 1 ? (
                                <Field
                                    name="enforcementStatus"
                                    data={statusListState}
                                    component={Dropdown}
                                    label="Enforcement status (required)"
                                    placeholder="Select Status"
                                    validate={[ required ]}
                                />
                            ) : null}
                            <Field
                                name="profileId"
                                type="text"
                                component={TextInput}
                                label="Profile ID"
                                placeholder="Profile ID of the posts"
                            />
                            <div className="fields-row">
                                <Field
                                    name="profilePosts"
                                    type="number"
                                    validate={[ numericValidation ]}
                                    component={TextInput}
                                    label="Profile posts"
                                    placeholder=""
                                />
                                <Field
                                    name="profileFollowers"
                                    type="number"
                                    validate={[ numericValidation ]}
                                    component={TextInput}
                                    label="Profile followers"
                                    placeholder=""
                                />
                                <Field
                                    name="profileViews"
                                    type="number"
                                    validate={[ numericValidation ]}
                                    component={TextInput}
                                    label="Profile views"
                                    placeholder=""
                                />
                                <Field
                                    name="profileLikes"
                                    type="number"
                                    validate={[ numericValidation ]}
                                    component={TextInput}
                                    label="Profile likes"
                                    placeholder=""
                                />
                            </div>
                            <Field
                                name="profileEvidenceURL"
                                type="text"
                                component={TextInput}
                                label="Profile evidence URL"
                                placeholder="Link to the evidence URL"
                            />
                            <Field
                                name="profileImage"
                                type="text"
                                component={TextInput}
                                label="Profile picture"
                                placeholder="https://"
                            />
                            <Field
                                name="profileDescription"
                                type="text"
                                component={TextAreaInput}
                                label="Profile description"
                                placeholder="Description of the profile"
                            />
                            <Field
                                name="profileConnectedTo"
                                type="text"
                                component={TextInput}
                                label="Profile connected to"
                                placeholder="Information on where the post is linked to"
                            />
                            <Field
                                name="profileSubmittedBy"
                                type="text"
                                component={TextInput}
                                label="Profile submitted by"
                                placeholder="From which person did we receive the post"
                            />
                            <Field
                                name="profileComments"
                                type="text"
                                component={TextAreaInput}
                                label="Profile comments"
                                placeholder="Add notes and extra comments"
                            />
                        </div>
                        {error ? <div className="error-message">{error}</div> : null}
                        <div className="btn-row">
                            <Button text="Add" typeBtn="submit" disabled={submitting} />
                            <Button
                                text="Clear"
                                color="secondary"
                                handleClick={() => this.clearReduxForm( "AddProfileForm" )}
                            />
                        </div>
                    </form>
                </div>
            </div>
        );
    }
}

const validate = values => {
    const errors = {};
    if (
        values.profileImage &&
    !new RegExp( expressionURL ).test( values.profileImage )
    ) {
        errors.profileImage = "Invalid URL";
    }
    if (
        values.profileEvidenceURL &&
    !new RegExp( expressionURL ).test( values.profileEvidenceURL )
    ) {
        errors.profileEvidenceURL = "Invalid URL";
    }
    if ( values.profileURL && !new RegExp( expressionURL ).test( values.profileURL ) ) {
        errors.profileURL = "Invalid URL";
    }
    return errors;
};

export const AddProfileForm = reduxForm( {
    form: "AddProfileForm",
    enableReinitialize: true,
    validate
} )( AddProfileFormDisplay );

import { getObjectFromURLParams } from "utils/connected";

export function applyFilterIfUpdated( activeFilter, label, value, action ) {
    if ( activeFilter[label] === value ) return;

    const newFilter = { ...activeFilter, [label]: value };
    action( newFilter );
}

export function applyUrlParametersToFilter( filter ) {
    const newFilter = { ...filter };
    const urlParams = getObjectFromURLParams();

    for ( const key in newFilter ) {
        const paramIsValid = Object.keys( urlParams ).includes( key )
        && Object.keys( newFilter ).includes( key );

        if ( paramIsValid )
            newFilter[key] = urlParams[key];
        else
            newFilter[key] = null;
    }

    return newFilter;
}
import React, { PureComponent } from "react";
import { arrowLeft } from "components/Icons/Svg";
import { Field, reduxForm, SubmissionError, reset } from "redux-form";
import { TextInput, Button, Dropdown, TextAreaInput, Loader } from "components";
import { validationUtil } from "utils";
import { NavLink } from "react-router-dom";


const { required, numericValidation, expressionURL } = validationUtil;

export class AddPostFormDisplay extends PureComponent {
    state = {
        postMore: false,
        profileMore: false,
        tabActive: "manually"
    };

    handleChangeRadio = e => {
        this.setState( { radioValue: e.target.value } );
    };

    SubmitForm = data => {
        const { addCardItemAction } = this.props;
        let sendData = {
            connectedTo: data.connectedTo || "",
            countryCode2: data.countryCode2,
            enforcementStatus: ( data.label && data.label === 1 && data.enforcementStatus ) || null,
            evidenceURL: data.evidenceURL || "",
            infringementType: ( data.label && data.label === 1 && data.infringementType ) || null,
            listingDescription: data.listingDescription || "",
            listingFollowers: data.listingFollowers || null,
            listingID: data.listingID || "",
            listingImage: data.listingImage || "",
            listingLikes: data.listingLikes || null,
            listingShares: data.listingShares || null,
            listingTitle: data.listingTitle || "",
            listingURL: data.listingURL,
            listingViews: data.listingViews || null,
            mark: data.label || null,
            note: data.note || "",
            platform: data.platform,
            submittedBy: data.submittedBy || "",
            profile: {
                connectedTo: data.profileConnectedTo || "",
                countryCode2: data.profileCountry || "",
                evidenceURL: data.profileEvidenceURL || "",
                note: data.profileComments || "",
                profileDescription: data.profileDescription || "",
                profileFollowers: data.profileFollowers || null,
                profileID: data.profileId || "",
                profileImage: data.profileImage || "",
                profileLikes: data.profileLikes || null,
                profileName: data.profileName || "",
                profilePosts: data.profilePosts || null,
                profileURL: data.profileUrl || "",
                profileViews: data.profileViews || null,
                submittedBy: data.profileSubmittedBy || ""
            }
        };
        return new Promise( ( resolve, reject ) => {
            addCardItemAction( {
                data: sendData,
                resolve,
                reject
            } );
        } )
            .then( res => {
                //Success
                this.clearReduxForm( "AddPostForm" );
            } )
            .catch( errors => {
                throw new SubmissionError( {
                    ...errors,
                    _error: "Error adding item, check your fields!"
                } );
            } );
    };

    changeMoreInfoPost = () => {
        this.setState( { postMore: !this.state.postMore } );
    };

    changeMoreInfoProfile = () => {
        this.setState( { profileMore: !this.state.profileMore } );
    };

    changeTab = val => {
        this.setState( { tabActive: val } );
    };

    clearReduxForm = name => {
        const { dispatch } = this.props;
        dispatch( reset( name ) );
    };

    render() {
        const {
            handleSubmit,
            countryListState,
            platformListState,
            submitting,
            statusListState,
            labelsState,
            formFieldsValues,
            infringementTypeListState,
            error
        } = this.props;
        const { postMore, tabActive, profileMore } = this.state;
        return (
            <div className="add-item-inner">
                {/* eslint-disable-next-line */}
                {/*<a className={"back-link"} onClick={history.goBack} />*/}
                <NavLink className={"back-link"} to={"/social-protect/posts"} activeClassName="active">
                    {arrowLeft( "#546369" )} Back
                </NavLink>


                <div className="add-item-inner__form">
                    <div className="form-title">Submit a post</div>
                    {platformListState ? (
                        <Field
                            name="platform"
                            data={platformListState}
                            component={Dropdown}
                            label="Platform (required)"
                            placeholder="Select"
                            search={true}
                            validate={[ required ]}
                        />
                    ) : (
                        <Loader style={{ margin: "50px auto" }} />
                    )}
                    {/*<TabBar>*/}
                    {/*<button*/}
                    {/*type="button"*/}
                    {/*onClick={() => changeTab('manually')}*/}
                    {/*className={tabActive === 'manually' ? 'active' : ''}*/}
                    {/*>*/}
                    {/*Enter manually*/}
                    {/*</button>*/}
                    {/*<button*/}
                    {/*type="button"*/}
                    {/*onClick={() => changeTab('urls')}*/}
                    {/*className={tabActive === 'urls' ? 'active' : ''}*/}
                    {/*>*/}
                    {/*Add from urls*/}
                    {/*</button>*/}
                    {/*</TabBar>*/}
                    <form onSubmit={handleSubmit( this.SubmitForm )}>
                        {tabActive === "manually" ? (
                            <div>
                                <div className="row-title">Post information</div>
                                <Field
                                    name="listingID"
                                    type="text"
                                    component={TextInput}
                                    label="Listing ID"
                                    placeholder="Unique ID of the listing"
                                />
                                <Field
                                    name="listingURL"
                                    type="text"
                                    component={TextInput}
                                    label="Listing URL (required)"
                                    placeholder="URL to the post/video/tweet"
                                    validate={[ required ]}
                                />
                                <Field
                                    name="evidenceURL"
                                    type="text"
                                    component={TextInput}
                                    label="Evidence URL"
                                    placeholder="Link to the evidence URL"
                                />
                                <Field
                                    name="listingTitle"
                                    type="text"
                                    component={TextInput}
                                    label="Listing title"
                                    placeholder="Title of the listing"
                                />
                                {countryListState ? (
                                    <Field
                                        name="countryCode2"
                                        data={countryListState}
                                        component={Dropdown}
                                        label="Country (required)"
                                        placeholder="Select a Country"
                                        search={true}
                                        validate={[ required ]}
                                    />
                                ) : (
                                    <Loader style={{ margin: "40px auto" }} />
                                )}
                                {labelsState ? (
                                    <Field
                                        name="label"
                                        data={labelsState}
                                        unSelect={true}
                                        component={Dropdown}
                                        label="Label"
                                        placeholder="Select label"
                                    />
                                ) : (
                                    <Loader style={{ margin: "40px auto" }} />
                                )}
                                {formFieldsValues.label === 1 ? (
                                    <Field
                                        name="infringementType"
                                        data={infringementTypeListState}
                                        component={Dropdown}
                                        label="Infringement type (required)"
                                        placeholder="Select Type"
                                        validate={[ required ]}
                                    />
                                ) : null}
                                {formFieldsValues.label === 1 ? (
                                    <Field
                                        name="enforcementStatus"
                                        data={statusListState}
                                        component={Dropdown}
                                        label="Enforcement status (required)"
                                        placeholder="Select Status"
                                        validate={[ required ]}
                                    />
                                ) : null}
                                <div
                                    className={postMore ? "add-more-box show" : "add-more-box"}
                                >
                                    <Field
                                        name="listingDescription"
                                        type="text"
                                        component={TextAreaInput}
                                        label="Listing description"
                                        placeholder="Description of the listing"
                                    />
                                    <Field
                                        name="listingImage"
                                        type="text"
                                        component={TextInput}
                                        label="Listing image URL"
                                        placeholder="https://"
                                    />
                                    <div className="fields-row">
                                        <Field
                                            name="listingLikes"
                                            type="number"
                                            validate={[ numericValidation ]}
                                            component={TextInput}
                                            label="Post likes"
                                            placeholder=""
                                        />
                                        <Field
                                            name="listingFollowers"
                                            type="number"
                                            validate={[ numericValidation ]}
                                            component={TextInput}
                                            label="Post followers"
                                            placeholder=""
                                        />
                                        <Field
                                            name="listingViews"
                                            type="number"
                                            validate={[ numericValidation ]}
                                            component={TextInput}
                                            label="Post views"
                                            placeholder=""
                                        />
                                        <Field
                                            name="listingShares"
                                            type="number"
                                            validate={[ numericValidation ]}
                                            component={TextInput}
                                            label="Post shares"
                                            placeholder=""
                                        />
                                    </div>
                                    <Field
                                        name="connectedTo"
                                        type="text"
                                        component={TextInput}
                                        label="Connected to"
                                        placeholder="Information on where the post is linked to"
                                    />
                                    <Field
                                        name="submittedBy"
                                        type="text"
                                        component={TextInput}
                                        label="Submitted by"
                                        placeholder="From which person did we receive the post"
                                    />
                                    <Field
                                        name="note"
                                        type="text"
                                        component={TextAreaInput}
                                        label="Comments"
                                        placeholder="Add notes and extra comments"
                                    />
                                </div>
                                <button
                                    type={"button"}
                                    className="btn-list-primary"
                                    onClick={this.changeMoreInfoPost}
                                >
                                    {postMore ? "Less" : "Add more information"}
                                </button>
                                <div className="row-title">Profile information</div>
                                <Field
                                    name="profileName"
                                    type="text"
                                    component={TextInput}
                                    label="Profile name"
                                    placeholder="Profile name of the posts"
                                />
                                <Field
                                    name="profileUrl"
                                    type="text"
                                    component={TextInput}
                                    label="Profile URL (required)"
                                    placeholder="https://"
                                />
                                {countryListState ? (
                                    <Field
                                        name="profileCountry"
                                        data={countryListState}
                                        component={Dropdown}
                                        label="Profile country (required)"
                                        placeholder="Select a Country"
                                        search={true}
                                    />
                                ) : (
                                    <Loader style={{ margin: "40px auto" }} />
                                )}
                                <div
                                    className={profileMore ? "add-more-box show" : "add-more-box"}
                                >
                                    <Field
                                        name="profileId"
                                        type="text"
                                        component={TextInput}
                                        label="Profile ID"
                                        placeholder="Profile ID of the posts"
                                    />
                                    <div className="fields-row">
                                        <Field
                                            name="profilePosts"
                                            type="number"
                                            validate={[ numericValidation ]}
                                            component={TextInput}
                                            label="Profile posts"
                                            placeholder=""
                                        />
                                        <Field
                                            name="profileFollowers"
                                            type="number"
                                            validate={[ numericValidation ]}
                                            component={TextInput}
                                            label="Profile followers"
                                            placeholder=""
                                        />
                                        <Field
                                            name="profileViews"
                                            type="number"
                                            validate={[ numericValidation ]}
                                            component={TextInput}
                                            label="Profile views"
                                            placeholder=""
                                        />
                                        <Field
                                            name="profileLikes"
                                            type="number"
                                            validate={[ numericValidation ]}
                                            component={TextInput}
                                            label="Profile likes"
                                            placeholder=""
                                        />
                                    </div>
                                    <Field
                                        name="profileEvidenceURL"
                                        type="text"
                                        component={TextInput}
                                        label="Profile evidence URL"
                                        placeholder="Link to the evidence URL"
                                    />
                                    <Field
                                        name="profileImage"
                                        type="text"
                                        component={TextInput}
                                        label="Profile picture"
                                        placeholder="https://"
                                    />
                                    <Field
                                        name="profileDescription"
                                        type="text"
                                        component={TextAreaInput}
                                        label="Profile description"
                                        placeholder="Description of the profile"
                                    />
                                    <Field
                                        name="profileConnectedTo"
                                        type="text"
                                        component={TextInput}
                                        label="Profile connected to"
                                        placeholder="Information on where the post is linked to"
                                    />
                                    <Field
                                        name="profileSubmittedBy"
                                        type="text"
                                        component={TextInput}
                                        label="Profile submitted by"
                                        placeholder="From which person did we receive the post"
                                    />
                                    <Field
                                        name="profileComments"
                                        type="text"
                                        component={TextAreaInput}
                                        label="Profile comments"
                                        placeholder="Add notes and extra comments"
                                    />
                                </div>
                                <button
                                    type={"button"}
                                    className="btn-list-primary"
                                    onClick={this.changeMoreInfoProfile}
                                >
                                    {profileMore ? "Less" : "Add more information"}
                                </button>
                            </div>
                        ) : tabActive === "urls" ? (
                            <div>
                                <div className="row-title">Submission screen</div>
                                <Field
                                    name="urls"
                                    type="text"
                                    component={TextAreaInput}
                                    height="150px"
                                    label="Maximum 20 URL's at once recommended"
                                    placeholder="https://"
                                />
                            </div>
                        ) : null}
                        {error ? <div className="error-message">{error}</div> : null}
                        <div className="btn-row">
                            <Button text="Add" typeBtn="submit" disabled={submitting} />
                            <Button
                                text="Clear"
                                color="secondary"
                                handleClick={() => this.clearReduxForm( "AddPostForm" )}
                            />
                        </div>
                    </form>
                </div>
            </div>
        );
    }
}

const checkFieldsProfile = values => {
    if (
        values.profileConnectedTo ||
    values.profileCountry ||
    values.profileEvidenceURL ||
    values.profileComments ||
    values.profileDescription ||
    values.profileFollowers ||
    values.profileId ||
    values.profileImage ||
    values.profileLikes ||
    values.profileName ||
    values.profilePosts ||
    values.profileUrl ||
    values.profileViews ||
    values.profileSubmittedBy
    ) {
        return true;
    } else {
        return false;
    }
};

const validate = values => {
    const errors = {};
    if ( checkFieldsProfile( values ) && !values.profileCountry ) {
        errors.profileCountry = "Required";
    }
    if ( checkFieldsProfile( values ) && !values.profileUrl ) {
        errors.profileUrl = "Required";
    }
    if ( values.listingURL && !new RegExp( expressionURL ).test( values.listingURL ) ) {
        errors.listingURL = "Invalid URL";
    }
    if (
        values.evidenceURL &&
    !new RegExp( expressionURL ).test( values.evidenceURL )
    ) {
        errors.evidenceURL = "Invalid URL";
    }
    if (
        values.profileImage &&
    !new RegExp( expressionURL ).test( values.profileImage )
    ) {
        errors.profileImage = "Invalid URL";
    }
    if (
        values.profileEvidenceURL &&
    !new RegExp( expressionURL ).test( values.profileEvidenceURL )
    ) {
        errors.profileEvidenceURL = "Invalid URL";
    }
    if ( values.profileUrl && !new RegExp( expressionURL ).test( values.profileUrl ) ) {
        errors.profileUrl = "Invalid URL";
    }
    if (
        values.listingImage &&
    !new RegExp( expressionURL ).test( values.listingImage )
    ) {
        errors.listingImage = "Invalid URL";
    }
    return errors;
};

export const AddPostForm = reduxForm( {
    form: "AddPostForm",
    enableReinitialize: true,
    validate
} )( AddPostFormDisplay );

import React from "react";

export const Seller = ( { data } ) => {
    return (
        <div className="info-wrapper">
            <div className="tab-title">Seller status</div>

            <ul className="list-info">
                <li>
                    <span className="list-title">Publish date</span>
                    <span></span>
                </li>
                <li>
                    <span className="list-title">Expire date</span>
                    <span></span>
                </li>
            </ul>
        </div>
    );
};

import React from "react";
import "./ImagesRowPreview.scss";

export const ImagesRowPreview = ( props ) => {
    const {
        images,
        lengthInRow = 3,
        handleClickImg = () => {},
        activeImgIndex = 0,
        itemClickable = false
    } = props;
    if( !images || ( images && !images.length ) ) return null;
    const classNameRowArr = [ "images-row-list" ];
    if( itemClickable ) classNameRowArr.push( "hover-active" );
    return (
        <div className="images-row-preview">
            <div className={classNameRowArr.join( " " )}>
                {images.map( ( e, i )=> {
                    if( i > lengthInRow - 1 ) return null;
                    return (
                        <div
                            key={i}
                            className={i === activeImgIndex ? "active" : ""}
                            onClick={() => itemClickable ? handleClickImg( i ) : false }
                            role="presentation"
                            style={{ background: `url(${e}) center center / cover no-repeat rgb(235, 240, 244)` }}
                        />
                    );
                } )}
                { images.length > lengthInRow && (
                    <div
                        className="image-length"
                        onClick={()=>handleClickImg( true )}
                        role="presentation"
                    >
                        +{images.length - ( lengthInRow )}
                    </div>
                )}
            </div>
            {props.children}
        </div>
    );
};
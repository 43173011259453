import { handleActions } from "redux-actions";

import {
    savePostEntriesAction,
    chooseAllCardSaveAction,
    selectedCardSaveAction
} from "pages/SocialProtect/services/Card/actions";
import {
    saveSearchWordTitlePostAction,
    saveSearchWordProfileNameInPostAction
} from "pages/SocialProtect/services/Options/actions";
import {
    itemViewDetailsPostsSaveAction
} from "pages/SocialProtect/services/UserView/actions";

const initialState = {
    searchTitle: null, //@TODO: This can currently also be the description, either need to rename it or create an additional key
    search_profile_name: null,
    card_list_data: null,
    item_view_detail: null,
    selected_card: {
        selectAll: false,
        arrID: []
    }
};

export const socialProtectPostReducer = handleActions(
    {
        [saveSearchWordTitlePostAction]: ( state, { payload } ) => {
            return {
                ...state,
                searchTitle: payload
            };
        },

        [saveSearchWordProfileNameInPostAction]: ( state, { payload } ) => {
            return {
                ...state,
                search_profile_name: payload
            };
        },

        [itemViewDetailsPostsSaveAction]: ( state, { payload } ) => {
            return {
                ...state,
                item_view_detail: payload
            };
        },

        [savePostEntriesAction]: ( state, { payload } ) => {
            return {
                ...state,
                card_list_data: payload
            };
        },

        [chooseAllCardSaveAction]: ( state, { payload } ) => {
            let newSelectedCardState = state.selected_card;
            newSelectedCardState.selectAll = payload;
            return {
                ...state,
                selected_card: newSelectedCardState
            };
        },

        [selectedCardSaveAction]: ( state, { payload } ) => {
            let newSelectedCard = state.selected_card;
            newSelectedCard.arrID = payload;
            return {
                ...state,
                selected_card: newSelectedCard
            };
        }
    },
    initialState,
);

import React, { PureComponent } from "react";
import { AddProfileForm } from "./components/";
import "pages/SocialProtect/styles/AddItem.scss";

export class AddProfile extends PureComponent {
    render() {
        return (
            <div className="page-container__full-width scroll-m">
                {/*Form Add Post start*/}
                <AddProfileForm />
                {/*Form Add Post end*/}
            </div>
        );
    }
}

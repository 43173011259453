import { compose } from "redux";
import { connect } from "react-redux";

import { errorsDecorator } from "components";
import { Dashboard } from "./Dashboard";
import { sideBarIsOpenSelector, activeModuleStateSelector } from "services/UserView/selectors";
import { setActiveModuleStateAction } from "services/UserView/actions";

const mapStateToProps = state => {
    const sideBarIsOpen = sideBarIsOpenSelector( state ) || false;
    const activeModuleState = activeModuleStateSelector( state ) || null;

    return {
        sideBarIsOpen,
        activeModuleState
    };
};

const mapDispatchToProps = {
    setActiveModuleStateAction
};

export const LandingDashboard = compose(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    ),
    errorsDecorator(),
)( Dashboard );

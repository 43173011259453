import React from "react";
import {
    PostCounter,
    CardListView,
    loadMore
} from "components";
import { ViewDetailsPanelMixed } from "pages/MarketProtect/components";
import "./MixedCardView.scss";

export const MixedCardView = ( {
    cardListState,
    viewDetailItemDataState,
    showDetailsMixedViewAction
} ) => {
    return(
        <div className="col-md-12">
            <div className="mixed-view">
                <div>
                    <div className="filter-header">
                        <PostCounter
                            postCount={cardListState.items ? cardListState.items.length : 0}
                        />
                    </div>
                    {
                        cardListState.items && cardListState.items.length ?
                            (
                                cardListState.items.map( ( el,i ) => (
                                    <CardListView
                                        key={i}
                                        id={el.id}
                                        img={el.images[0]}
                                        name={el.title}
                                        symbol={cardListState.currency.symbol}
                                        price={Number( el.price ).toFixed( 2 )}
                                        active={
                                            ( el.id === ( viewDetailItemDataState && viewDetailItemDataState.id ) ) ||
                                            ( i === 0 && !viewDetailItemDataState )
                                        }
                                        handleClick={showDetailsMixedViewAction}
                                        status={el.enforcementStatus}
                                    />
                                ) )
                            ) :
                            ( "No Items..." )
                    }
                    <div className={"see-more"}>
                        {cardListState.total > cardListState.count
                            ?
                            <div
                                role={"presentation"}
                                // className={false ? "load-more loading" : "load-more"}
                                className={"load-more"}
                                onClick={()=>{}}
                            >
                                {loadMore()} See more
                            </div>
                            : null}
                    </div>
                </div>
                <div>
                    <ViewDetailsPanelMixed
                        item={viewDetailItemDataState}
                        symbol={cardListState.currency.symbol}
                    />
                </div>
            </div>
        </div>
    );
};
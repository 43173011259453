import {
    pageListViewPostsSaveAction,
    pageCardDisplayPostsViewSaveAction,
    tableViewPostsSaveAction,
    tableColumnPostsSelectedSaveAction,
    tableSequenceColumnPostsSaveAction,
    pageListViewProfilesSaveAction,
    pageCardDisplayProfilesViewSaveAction,
    tableViewProfilesSaveAction,
    tableColumnProfilesSelectedSaveAction,
    tableSequenceColumnProfilesSaveAction
} from "./actions";
import { handleActions } from "redux-actions";
import { Map } from "immutable";
import { columnsPosts, listEnabledItem } from "pages/SocialProtect/services/Post/models";
import { columnsProfiles } from "pages/SocialProtect/services/Profile/models";

const initialState = {
    profiles: {
        page_list_view: "block",
        page_card_display_view: "block-description-x1",
        customize_table_view: {
            view: "large",
            list_enabled_item: listEnabledItem,
            columns: columnsProfiles
        }
    },
    posts: {
        page_list_view: "block",
        page_card_display_view: "block-description-x1",
        customize_table_view: {
            view: "large",
            list_enabled_item: listEnabledItem,
            columns: columnsPosts
        }
    }
};

export const socialProtectUserViewReducer = handleActions(
    {
        // Post
        [pageListViewPostsSaveAction]: ( state, { payload } ) => {
            const newObjectPosts = Map( state.posts ).toJS();
            newObjectPosts.page_list_view = payload;
            return {
                ...state,
                posts: newObjectPosts
            };
        },
        [pageCardDisplayPostsViewSaveAction]: ( state, { payload } ) => {
            const newObjectPosts = Map( state.posts ).toJS();
            newObjectPosts.page_card_display_view = payload;
            return {
                ...state,
                posts: newObjectPosts
            };
        },
        [tableViewPostsSaveAction]: ( state, { payload } ) => {
            const newObjectPosts = Map( state.posts ).toJS();
            newObjectPosts.customize_table_view.view = payload;
            return {
                ...state,
                posts: newObjectPosts
            };
        },
        [tableColumnPostsSelectedSaveAction]: ( state, { payload } ) => {
            const newObjectPosts = Map( state.posts ).toJS();
            newObjectPosts.customize_table_view.list_enabled_item = payload;
            return {
                ...state,
                posts: newObjectPosts
            };
        },
        [tableSequenceColumnPostsSaveAction]: ( state, { payload } ) => {
            const newObjectPosts = Map( state.posts ).toJS();
            newObjectPosts.customize_table_view.columns = { taskIds: payload };
            return {
                ...state,
                posts: newObjectPosts
            };
        },

        //Profiles
        [pageListViewProfilesSaveAction]: ( state, { payload } ) => {
            const newObjectPosts = Map( state.profiles ).toJS();
            newObjectPosts.page_list_view = payload;
            return {
                ...state,
                profiles: newObjectPosts
            };
        },
        [pageCardDisplayProfilesViewSaveAction]: ( state, { payload } ) => {
            const newObjectPosts = Map( state.profiles ).toJS();
            newObjectPosts.page_card_display_view = payload;
            return {
                ...state,
                profiles: newObjectPosts
            };
        },
        [tableViewProfilesSaveAction]: ( state, { payload } ) => {
            const newObjectPosts = Map( state.profiles ).toJS();
            newObjectPosts.customize_table_view.view = payload;
            return {
                ...state,
                profiles: newObjectPosts
            };
        },
        [tableColumnProfilesSelectedSaveAction]: ( state, { payload } ) => {
            const newObjectPosts = Map( state.profiles ).toJS();
            newObjectPosts.customize_table_view.list_enabled_item = payload;
            return {
                ...state,
                profiles: newObjectPosts
            };
        },
        [tableSequenceColumnProfilesSaveAction]: ( state, { payload } ) => {
            const newObjectPosts = Map( state.profiles ).toJS();
            newObjectPosts.customize_table_view.columns = { taskIds: payload };
            return {
                ...state,
                profiles: newObjectPosts
            };
        }
    },
    initialState,
);
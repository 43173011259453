import React, { PureComponent } from "react";
import "./SimpleDropdown.scss";

export class SimpleDropdown extends PureComponent {
    constructor( props ) {
        super( props );
        this.myRef = React.createRef();
        this.state = {
            open: false
        };
    }

    componentDidMount() {
        document.addEventListener( "click", this.documentClick );
    }

    componentWillUnmount() {
        document.removeEventListener( "click", this.documentClick );
    }

    documentClick = e => {
        const { bodyClass } = this.props;
        const node = this.myRef.current;

        if ( e.target.closest( bodyClass ? `.${bodyClass}` : ".simpleDropdown-body" ) !== node ) this.closeDropDown();
    };

    changeStateDropDown = () => {
        const { disabled } = this.props;

        if ( disabled ) return;

        this.setState( prevState => ( {
            open: !prevState.open
        } ) );
    };

    closeDropDown = () => {
        this.setState( { open: false } );
    };

    render() {
        const { icon, content, wrapperClass, headerClass, bodyClass, dropdownClass } = this.props;

        let { open } = this.state;
        const wrapper = wrapperClass || "simpleDropdown";

        // @TODO: These overrides could be done cleaner through making use of styled-components, and changing individual attributes
        return (
            <div className={`${wrapper} ${open ? `${wrapper}-open` : ""}`}>
                <div ref={this.myRef} className={bodyClass || "simpleDropdown-body"}>
                    <div
                        className={headerClass || "simpleDropdown-header"}
                        onClick={this.changeStateDropDown}
                        role="presentation"
                    >
                        {icon}
                    </div>
                    {open && (
                        <div className={dropdownClass || "simpleDropdown-dropdown"}>
                            {content}
                        </div>
                    )}
                </div>
            </div>
        );
    }
}

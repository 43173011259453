import { createSelector } from "reselect";

export const statusListSelector = createSelector(
    state => state.marketProtect.status.statusList,
    state => state
);

export const statusListIsLoadingSelector = createSelector(
    state => state.marketProtect.status.statusListIsLoading,
    state => state
);

import React from "react";
import "./TextAreaInput.scss";

export const TextAreaInput = ( {
    input,
    label,
    labelClassName = "label-default",
    inputClassName = null,
    placeholder = null,
    id = null,
    wrapperStyle = null,
    childrenStyle = null,
    defaultValue = null,
    meta: { touched, error, warning, success } = {},
    children,
    height = "50px",
    handleChange = () => {}
} ) => {
    if ( children ) labelClassName += " with-children";
    if ( touched && error ) labelClassName += " error";
    if ( success ) labelClassName += " success";
    return (
        <label className={labelClassName}>
            {label ? <div className="title">{label}</div> : null}
            <div className="wrapper-input" style={wrapperStyle}>
                {children ? <div style={childrenStyle}>{children}</div> : null}
                {defaultValue !== null ? (
                    <textarea
                        {...input}
                        style={{ height: height }}
                        className={inputClassName}
                        placeholder={placeholder}
                        id={id}
                        autoComplete="new-password"
                        onChange={handleChange}
                        value={defaultValue}
                    />
                ) : (
                    <textarea
                        {...input}
                        style={{ height: height }}
                        className={inputClassName}
                        placeholder={placeholder}
                        id={id}
                        autoComplete="new-password"
                    />
                )}
            </div>
            {touched && error ? <div className="error-message">{error}</div> : null}
        </label>
    );
};

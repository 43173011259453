import { connect } from "react-redux";
import { Header as component } from "./Header";
import {
    loadingBrandSelector,
    brandsSelector,
    brandsFavoriteListSelector,
    activeBrandSelector
} from "services/Brand/selectors";
import { activeModuleStateSelector } from "services/UserView/selectors";
import {
    addBrandToFavoritesAction,
    saveActiveBrandAction
} from "services/Brand/actions";

const mapDispatchToProps = {
    addBrandToFavoritesAction,
    saveActiveBrandAction
};

const mapStateToProps = state => {
    const loadingBrandState = loadingBrandSelector( state );
    const brandsState = brandsSelector( state );
    const favouriteBrands = brandsFavoriteListSelector( state );
    const activeBrandState = activeBrandSelector( state );
    const activeModuleState = activeModuleStateSelector( state );

    return {
        brandsState,
        loadingBrandState,
        favouriteBrands,
        activeBrandState,
        activeModuleState
    };
};

export const Header = connect(
    mapStateToProps,
    mapDispatchToProps
)( component );

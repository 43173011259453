import React from "react";
import { CollapsibleFilter, Loader, Dropdown } from "components";

export const CollapsibleDropDownBlock = ( props ) => {
    const {
        title = "",
        name = "",
        loader = false,
        data = null,
        activeFilterData = {},
        handleChange = () => {}
    } = props;
    let dataExist = !!( data && data.length );
    return (
        <CollapsibleFilter
            title={title}
            defaultExpanded={!!activeFilterData[name] || activeFilterData[name] === 0}
        >
            {loader ? (
                <Loader style={{ margin: "20px auto" }} />
            ) : dataExist ? (
                <Dropdown
                    {...props}
                    data={data}
                    defaultAll={false}
                    placeholder = "Select an option"
                    defaultValue={activeFilterData[name]}
                    wrapperClassName="form-dropdown"
                    search={true}
                    handleChange={el => handleChange( name, el )}
                />
            ) : (
                <div>No items</div>
            )}
        </CollapsibleFilter>
    );
};

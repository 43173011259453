import React from "react";
import "./ItemCardImageSmallWrapper.scss";

export const ItemCardImageSmallWrapper = ( {
    id, footer = null, addClassName = "", onChange = () => {}, checked = false, body = null
} ) => {
    return (
        <div className={`card-item-image-view-small noselect ${addClassName}`}>
            <label>
                <input
                    type="checkbox"
                    onChange={event => onChange( {
                        value: event.target,
                        id: id
                    } ) }
                    checked={checked}
                />
                <div className="card-item-body">
                    {body}
                </div>
                <div className="footer-card">
                    {footer}
                </div>
            </label>
        </div>
    );
};

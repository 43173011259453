import React from "react";
import "./SideBar.scss";

export const SideBarWrapper = ( props ) => {
    const { sideBarClassName="", top, bottom, slideBar, modals  } = props;
    return (
        <div className={sideBarClassName}>
            <div className='side-bar-left'>
                <div>
                    {top}
                </div>
                <div>
                    {bottom}
                </div>
            </div>
            {slideBar}

            {modals}
        </div>
    );
};

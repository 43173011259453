import { connect } from "react-redux";
import { SideBar as component } from "./SideBar";

import {
    sideBarContainerProps,
    mapSideBarContainerStateToProps
} from "components/SideBar/Container";

import {
    saveTitleFilterAction,
    saveProfileFilterAction,
    saveFilterAction,
    searchByFilterAction,
    saveFilterAndSearchAction
} from "pages/SocialProtect/services/Options/actions";

import {
    searchWordTitleSelector,
    getSearchWordProfileNameSelector,
    activeFilterSelector
} from "pages/SocialProtect/services/Options/selectors";


import { exportDataAction } from "./services/ExportData/actions";

import {
    exportErrorLinksToCsvAction,
    importDataAction,
    sendImportFileAction,
    importDataSaveAction,
    clearImportStoreAction,
    copyErrorLinksImportAction,
    sendOnlySuccessfullyImportDataAction,
    goToThePostsAfterImportAction
} from "./services/ImportData/actions";

import {
    isLoadingLabelsSelector,
    labelsSelector
} from "pages/SocialProtect/services/Label/selectors";

import { validationUtil, formatDate } from "utils";
import { applyFilterIfUpdated } from "services/Filter/utils";

const mapDispatchToProps = {
    ...sideBarContainerProps,
    saveProfileFilterAction,
    saveFilterAction,
    saveTitleFilterAction,
    searchByFilterAction,
    saveFilterAndSearchAction,
    exportDataAction,
    importDataAction,
    sendImportFileAction,
    importDataSaveAction,
    clearImportStoreAction,
    copyErrorLinksImportAction,
    sendOnlySuccessfullyImportDataAction,
    exportErrorLinksToCsvAction,
    goToThePostsAfterImportAction
};

const mapStateToProps = state => {
    const searchWordTitleState = searchWordTitleSelector( state );
    const searchWordProfileState = getSearchWordProfileNameSelector( state );
    const activeFilter = activeFilterSelector( state );
    const isLoadingLabels = isLoadingLabelsSelector( state );
    const labelsState = labelsSelector( state );
    return {
        ...mapSideBarContainerStateToProps( state ),
        searchWordTitleState,
        searchWordProfileState,
        activeFilter,
        isLoadingLabels,
        labelsState
    };
};

const mergeProps = ( stateProps, dispatchProps, ownProps ) => {
    const { activeFilter } = stateProps;
    const {
        saveTitleFilterAction,
        toggleSideBarIsOpenAction,
        searchByFilterAction,
        saveFilterAndSearchAction,
        saveFilterAction
    } = dispatchProps;

    return {
        ...stateProps,
        ...dispatchProps,
        ...ownProps,
        changeInputTitle: e => saveTitleFilterAction( e.target.value ),
        toggleSideBarIsOpen: () => toggleSideBarIsOpenAction(),
        onChangeDropdownFilter: ( label, value ) => applyFilterIfUpdated( activeFilter, label, value, saveFilterAndSearchAction ),
        onChangeInputFilter: ( label, value ) => applyFilterIfUpdated( activeFilter, label, value, saveFilterAction ),
        onChangeDateFilter: ( label, value ) => {
            if ( value ) formatDate( value );

            applyFilterIfUpdated( activeFilter, label, value, saveFilterAction );
        },
        searchOnEnter: e => validationUtil.enterIsPressed( e ) && searchByFilterAction()
    };
};

export const SideBar = connect(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps
)( component );

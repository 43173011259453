import {
    setGridView
} from "./actions";

import { handleActions } from "redux-actions";

const initialState = {
    gridView: "default"
};

export const gridReducer = handleActions(
    {
        [setGridView]: ( state, { payload } ) => {
            return {
                ...state,
                gridView: payload
            };
        }
    },
    initialState
);

import React, { useState } from "react";
import { dateFormat } from "config";
import {
    ButtonClose,
    arrowPrev,
    arrowNext,
    ImagesRowPreview,
    TabBar,
    NoteBlockViewDetails,
    Loader,
    MarkEditorInViewDetailPanel
} from "components";
import {
    SliderModal
} from "pages/MarketProtect/components";
import { Overview } from "../TabComponents/";
import moment from "moment";

export const ViewDetailsPanel = ( {
    index,
    closePanel,
    lengthList,
    goToNextViewDetailsAction,
    data,
    startEnforcementLoaderState,
    labelsState,
    selectedPostMarkIdState,
    selectedPostMark,
    optionValue,
    valueList,
    setOptions,
    changeListValues,
    closeList,
    setCurrentInfringementType,
    clickApplyInfringement
} ) => {
    const [ activeTab, setActiveTab ] = useState( "overview" );
    const [ visible, setVisible ] = useState( false );
    const [ slickGoTo ] = useState( 0 );
    const clickBlock = ( value ) => {
        if( typeof value === "boolean" && value ){
            setVisible( true );
        }
    };
    return (
        <div className="view-panel-wrapper">
            <div className="view-details-panel__actions">
                <div className="nav-block">
                    <ButtonClose color="#979797" onClick={closePanel} />
                    <button
                        type={"button"}
                        className={index === 0 ? "hidden" : ""}
                        onClick={() =>
                            goToNextViewDetailsAction( { index: index, mode: "--" } )
                        }
                    >
                        {arrowPrev( "#979797" )}
                    </button>
                    <button
                        type={"button"}
                        className={index === lengthList - 1 ? "hidden" : ""}
                        onClick={() =>
                            goToNextViewDetailsAction( { index: index, mode: "++" } )
                        }
                    >
                        {arrowNext( "#979797" )}
                    </button>
                </div>
            </div>
            <ImagesRowPreview
                images={ data.images }
                lengthInRow = { 7 }
                handleClickImg = { clickBlock }
            >
                { data.images.length > 7 && (
                    <SliderModal
                        visible={ visible }
                        slickGoTo={ slickGoTo }
                        toggleDialog={()=>setVisible( !visible )}
                    >
                        {visible && data.images.map( ( e, i )=> ( <div key={i}><img src={e} alt={""}/></div> ) )}
                    </SliderModal>
                )}
            </ImagesRowPreview>

            {startEnforcementLoaderState ? (
                <Loader style={{ margin: "19px auto 19px" }} />
            ) : (
                <MarkEditorInViewDetailPanel
                    data={data}
                    labelsState={labelsState}
                    selectedPostMarkIdState={selectedPostMarkIdState}
                    selectedPostMark={selectedPostMark}
                    optionValue={optionValue}
                    valueList={valueList}
                    setOptions={setOptions}
                    changeListValues={changeListValues}
                    closeList={closeList}
                    setCurrentInfringementType={setCurrentInfringementType}
                    clickApplyInfringement={clickApplyInfringement}
                />
            )}

            <div className="date-price">
                <div className="date">{moment( data.date ).format( dateFormat )}</div>
                <div className="price">{ data.price && data.currencySymbol }{ data.price || "No data" }</div>
            </div>
            <div className="item-name">{ data.title }</div>
            <div className="view-details-panel__body">
                <TabBar>
                    <button
                        type="button"
                        className={activeTab === "overview" ? "active" : ""}
                        onClick={() => setActiveTab( "overview" )}
                    >
                        Overview
                    </button>

                    <button
                        type="button"
                        className={activeTab === "seller" ? "active" : ""}
                        onClick={() => setActiveTab( "seller" )}
                    >
                        Seller
                    </button>

                    <button
                        type="button"
                        className={activeTab === "enforcement" ? "active" : ""}
                        onClick={() => setActiveTab( "enforcement" )}
                    >
                        Enforcement
                    </button>

                    <button
                        type="button"
                        className={activeTab === "activities" ? "active" : ""}
                        onClick={() => setActiveTab( "activities" )}
                    >
                        Activities
                    </button>
                </TabBar>

                <div className="tab-panel">
                    {activeTab === "overview" ? ( <Overview data={data} /> ) : null }
                    {/*    activeTab === "profile" ? (*/}
                    {/*    <ProfileInfo data={data} />*/}
                    {/*) : activeTab === "enforcement" ? (*/}
                    {/*        <Enforcement data={data} />*/}
                    {/*    ) :*/}
                    {/*: activeTab === 'activities' ? (*/}
                    {/*    <Activities />*/}
                    {/*)*/}
                    {/*null}*/}
                </div>

                <NoteBlockViewDetails
                    id={data.id}
                    note={data.notes}
                    // updateCardItemAction={updateCardItemAction}
                />
            </div>
        </div>
    );
};

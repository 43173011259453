import React from "react";
import {
    FooterWrapper,
    PaginationButtons,
    PaginationCounter,
    Paginator,
    LoadMore
} from "components";


export const PaginationFooter = props => {
    const {
        countAvailable,
        countLoaded,
        handlePageChange,
        handleLoadMore,
        isLoadingMore,
        limit,
        page
    } = props;

    const isAbleToPaginate = countAvailable > limit && countAvailable > countLoaded;

    if ( !isAbleToPaginate ) return null;

    return (
        <FooterWrapper
            left={
                <Paginator
                    page={page}
                    countAvailable={countAvailable}
                    limit={limit}
                    handlePageChange={handlePageChange}
                />
            }
            center={
                <LoadMore
                    isLoadingMore={isLoadingMore}
                    handleLoadMore={handleLoadMore}
                />
            }
            right={
                <>
                    <PaginationCounter
                        limit={limit}
                        page={page}
                        countAvailable={countAvailable}
                        countLoaded={countLoaded}
                    />

                    <PaginationButtons
                        page={page}
                        handlePageChange={handlePageChange}
                        countAvailable={countAvailable}
                        limit={limit}
                    />
                </>
            }
        />
    );
};


import React, { PureComponent } from "react";
import "./RadioInput.scss";

export class RadioInput extends PureComponent {
    render() {
        const {
            input,
            name = "",
            label = null,
            disabled = false,
            labelClassName = null,
            defaultValue = "",
            value = "",
            onChange = () => {}
        } = this.props;
        let labelClass = "label-radio-input-default ";
        if ( labelClassName ) labelClass += labelClassName;
        const checkedState =
            value && defaultValue
                ? value.toString() === defaultValue.toString()
                : false;
        return (
            <label className={labelClass}>
                <input
                    {...input}
                    name={name}
                    type="radio"
                    checked={checkedState}
                    value={value}
                    onChange={onChange}
                    disabled={disabled}
                />
                <span className={checkedState ? "checked" : ""} />
                {label ? <span className="title">{label}</span> : null}
            </label>
        );
    }
}

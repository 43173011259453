import { loadStatusListMPAction, saveStatusListMPAction } from "./actions";
import { handleActions } from "redux-actions";

const initialState = {
    statusList: null,
    statusListIsLoading: false
};

export const StatusReducer = handleActions(
    {
        [loadStatusListMPAction]: ( state, { payload } ) => ( {
            ...state,
            statusListIsLoading: payload
        } ),
        [saveStatusListMPAction]: ( state, { payload } ) => ( {
            ...state,
            statusList: payload
        } )
    },
    initialState
);

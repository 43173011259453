import { call, put, select, delay } from "redux-saga/effects";
import * as StatusAPI from "./api";
import { loadStatusListMPAction, saveStatusListMPAction } from "./actions";
import { statusListSelector } from "./selectors";

//WORKERS
export function* statusInitialize() {
    const statusListState = yield select( statusListSelector );
    //data already exist
    if( statusListState ) return;
    //Loading Brands
    yield put( loadStatusListMPAction( true ) );
    //TODO: remove delay when we will have work API
    yield delay( 2000 );
    //Get brands list from API
    const response = yield call( StatusAPI.getStatusList );
    if ( response ) yield put( saveStatusListMPAction( response ) );
    yield put( loadStatusListMPAction( false ) );
}

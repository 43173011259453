import React from "react";
import reactDOM from "react-dom";
import { Provider } from "react-redux";
import { getStore } from "store";
import { Routes } from "pages/routes";
import { Router } from "react-router-dom";
import * as serviceWorker from "utils/serviceWorker";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import colors from "styles/variables.scss";
import { history } from "services/Navigation/history";


export const store = getStore();

//https://material-ui.com/style/typography/#migration-to-typography-v2
// The material design specification changed concerning variant names and styles. To allow a smooth transition we kept
// old variants and restyled variants for backwards compatibility but we log deprecation warnings. We will remove the
// old typography variants in the next major release v4.0.0 (Q1 2019).
window.__MUI_USE_NEXT_TYPOGRAPHY_VARIANTS__ = true;

const theme = createMuiTheme( {
    palette: {
        primary: {
            //light: colors.primaryLighter,
            main: colors.primary
            //dark: colors.primaryDark,
            //contrastText: colors.white,
        }
    }
} );

reactDOM.render(
    <Provider store={store}>
        <MuiThemeProvider theme={theme}>
            <Router history={history} children={Routes} />
        </MuiThemeProvider>
    </Provider>,
    document.getElementById( "root" )
);

serviceWorker.unregister();

import { createAction } from "redux-actions";

export const loadStartEnforcementAction = createAction(
    "loadStartEnforcementAction"
);
export const startEnforcementAction = createAction( "startEnforcementAction" );
export const selectedPostMarkIdAction = createAction(
    "selectedPostMarkIdAction"
);
export const selectedNewPostMarkAction = createAction(
    "selectedNewPostMarkAction"
);

import React from "react";
import moment from "moment";
import { dateFormat } from "config";

export const Enforcement = ( { data } ) => {
    return (
        <div className="info-wrapper">
            <div className="tab-title">Enforcement status</div>

            <ul className="list-info">
                <li>
                    <span className="list-title">Publish date</span>
                    <span>
                        {data.publishDate
                            ? moment( data.publishDate ).format(
                                dateFormat
                            )
                            : "No Data"}
                    </span>
                </li>
                <li>
                    <span className="list-title">Expire date</span>
                    <span>
                        {data.expireDate
                            ? moment(
                                data.expireDate
                            ).format( dateFormat )
                            : "No Data"}
                    </span>
                </li>
                <li>
                    <span className="list-title">End date</span>
                    <span>
                        {data.endDate
                            ? moment(
                                data.endDate
                            ).format( dateFormat )
                            : "No Data"}
                    </span>
                </li>
                <li>
                    <span className="list-title">Last modified date</span>
                    <span>
                        {data.docLastModified
                            ? moment(
                                data.docLastModified
                            ).format( dateFormat )
                            : "No Data"}
                    </span>
                </li>
            </ul>
        </div>
    );
};

import React from "react";
import "./ItemCardImageWrapper.scss";

export const ItemCardImageWrapper = ( {
    addClassName = "",
    checked = null,
    header = null,
    body = null,
    description = null,
    footer = null
} ) => {
    return (
        <div
            className={`card-item-image-view noselect ${addClassName} ${checked ? "checked" : ""}`}
        >
            <div className="card-item-header">
                {header}
            </div>
            <div className="card-item-body">
                {body}
            </div>
            <div className="footer-card">
                <div className="description">
                    {description}
                </div>
                <div className="card-item-footer">
                    {footer}
                </div>
            </div>
        </div>
    );
};

import { takeLatest, put, delay, select } from "redux-saga/effects";
import {
    loadStartEnforcementAction,
    startEnforcementAction,
    selectedPostMarkIdAction
} from "./actions";
import {
    chooseAllCardSaveAction,
    selectedCardSaveAction
} from "pages/MarketProtect/services/Card/actions";
import { selectedNewPostMarkAction } from "pages/MarketProtect/services/Enforcement/actions";

//Selectors
import { activeModuleStateSelector } from "services/UserView/selectors";

// Workers
function* startEnforcementWorker( { payload = {} } ) {
    const {
        optionValue,
        resolve
    } = payload;
    //Loader
    yield put( loadStartEnforcementAction( true ) );
    const activeModuleState = yield select( activeModuleStateSelector );
    yield delay( 1000 );
    alert( `start startEnforcementWorker - ${optionValue}` );

    //Clear values in store
    yield put( selectedPostMarkIdAction( null ) );
    yield put( chooseAllCardSaveAction( false ) );

    yield put( selectedCardSaveAction( { data: [], category: activeModuleState } ) );

    if ( resolve && typeof resolve === "function" ) resolve();
    //Loader
    yield put( loadStartEnforcementAction( false ) );
}

function* selectedNewPostMarkWorker( { payload = {} } ) {
    //Loader
    yield put( loadStartEnforcementAction( true ) );
    yield delay( 1000 );
    alert( `start selectedNewPostMarkWorker - ${payload}` );
    //Loader
    yield put( loadStartEnforcementAction( false ) );
}

// WATCHERS

export function* startEnforcementMPWatcher() {
    yield takeLatest( startEnforcementAction, startEnforcementWorker );
}

export function* selectedNewPostMarMPkWatcher() {
    yield takeLatest( selectedNewPostMarkAction, selectedNewPostMarkWorker );
}

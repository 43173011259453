import moment from "moment";
import React from "react";
import { dateFormat } from "config";
import { RowLinkView, SocialTitle, StatusIcons, Avatar } from "components";

export const chooseItemByFieldName = (
    data,
    elem,
    showViewDetailsPanelAction,
    userPushToRouteAction,
    symbol
) => {
    if ( elem.droppableId === "Title" || elem.droppableId === "Profile" ) {
        const URL = data.profileURL || data.listingURL || null;
        const avatarImg = data.listingImage || data.profileImage;
        return (
            <div style={{ display: "flex", justifyContent: "flex-start", alignItem: "center" }}>
                {avatarImg ?
                    <Avatar
                        size = "35"
                        data={[ avatarImg ]}
                        style={{ marginRight: "15px" }}
                    /> :
                    <div style={{ width: "35px", height: "35px", marginRight: "15px" }}/>
                }
                <RowLinkView
                    label={data.listingTitle || data.profileName || null}
                    handleClick={
                        showViewDetailsPanelAction ?
                            () => showViewDetailsPanelAction( data.id )
                            : null
                    }
                    iconLink={URL}
                    handleClickIcon={() => {
                        if ( URL ) window.open( URL, "_blank" );
                    }}
                />
            </div>
        );
    }
    if ( elem.droppableId === "Profile name" ) {
        if ( !userPushToRouteAction ) return ( data.profile && data.profile.profileName ) || "No data";
        return (
            <RowLinkView
                channel={true}
                iconLink={( data.profile && data.profile.profileURL ) || null}
                label={( data.profile && data.profile.profileName ) || null}
                tooltip={
                    data.profile && data.profile.profileURL
                        ? "Show all posts from this channel"
                        : false
                }
                handleClickIcon={() =>
                    userPushToRouteAction( {
                        profileURL: data.profile.profileURL,
                        module: "posts"
                    } )
                }
            />
        );
    }
    if ( elem.droppableId === "Platform" ) {
        if( !data.platform ) return "No Platform";
        return (
            <SocialTitle code={data.platform.code} title={data.platform.name} />
        );
    }
    if ( elem.droppableId === "Posts" ) {
        if ( Object.prototype.hasOwnProperty.call( data, "profile" ) ) {
            return ( data.profile && data.profile.profilePosts ) || 0;
        } else {
            return (
                <RowLinkView
                    label={data.countPostsInDB || 0}
                    iconLink={!!data.profileURL}
                    handleClickIcon={() =>
                        userPushToRouteAction( {
                            profileURL: data.profileURL,
                            module: "posts"
                        } )
                    }
                    tooltip={
                        data.profileURL ? "Show all posts from this channel" : false
                    }
                    channel={true}
                />
            );
        }
    }
    if ( elem.droppableId === "Posts in DB" ) {
        if ( Object.prototype.hasOwnProperty.call( data, "profile" ) || !userPushToRouteAction ) {
            return ( data.profile && data.profile.profilePosts ) || "0";
        } else {
            return (
                <RowLinkView
                    label={data.countPostsInDB || "0"}
                    iconLink={!!data.profileURL}
                    handleClickIcon={() =>
                        userPushToRouteAction( {
                            profileURL: data.profileURL,
                            module: "posts"
                        } )
                    }
                    tooltip={
                        data.profileURL ? "Show all posts from this channel" : false
                    }
                    channel={true}
                />
            );
        }
    }
    if ( elem.droppableId === "Views" ) {
        return (
            data.profileViews || ( data.profile && data.profile.profileViews ) || 0
        );
    }
    if ( elem.droppableId === "Date" ) {
        return moment( data.createdAt ).format( dateFormat );
    }
    if ( elem.droppableId === "Description" ) {
        return (
            <div className="description">
                {data.listingDescription || data.profileDescription || "No Description"}
            </div>
        );
    }
    if ( elem.droppableId === "Status" ) {
        return (
            <StatusIcons
                status={{
                    mark: ( data.mark && data.mark.name ) || null,
                    infringementType:
                        ( data.infringementType && data.infringementType.name ) || null,
                    enforcementStatus:
                        ( data.enforcementStatus && data.enforcementStatus.name ) || null
                }}
                text={true}
                tooltip={true}
            />
        );
    }
    return elem.label;
};
export const columnsListing = {
    taskIds: [
        "Listing",
        "Platform",
        "Seller",
        "Price",
        "Quantity",
        "Date",
        "Rating",
        "Analyzer",
        "Status"
    ]
};
export const columnsSellers = {
    taskIds: [
        "Status",
        "Platform",
        "Profile",
        "Posts in DB",
        "Views",
        "Date",
        "Description"
    ]
};

export const listEnabledItem = {
    Listing: {
        droppableId: "Listing",
        label: "Listing",
        value: true,
        disabled: true,
        headerTable: false,
        visibility: {
            listings: true,
            sellers: false,
            listingsTopBar: true
        },
        fieldName: {
        }
    },
    Platform: {
        droppableId: "Platform",
        label: "Platform",
        value: true,
        disabled: true,
        headerTable: false,
        visibility: {
            listings: true,
            sellers: false,
            listingsTopBar: true
        },
        fieldName: {
        }
    },
    Seller: {
        droppableId: "Seller",
        label: "Seller",
        value: true,
        disabled: true,
        headerTable: false,
        visibility: {
            listings: true,
            sellers: false,
            listingsTopBar: true
        },
        fieldName: {
        }
    },
    Price: {
        droppableId: "Price",
        label: "Price",
        value: true,
        disabled: true,
        headerTable: false,
        visibility: {
            listings: true,
            sellers: false,
            listingsTopBar: true
        },
        fieldName: {
        }
    },
    Quantity: {
        droppableId: "Quantity",
        label: "Quantity",
        value: true,
        disabled: true,
        headerTable: false,
        visibility: {
            listings: true,
            sellers: false
        },
        fieldName: {
        }
    },
    Date: {
        droppableId: "Date",
        label: "Date",
        value: true,
        disabled: true,
        headerTable: false,
        visibility: {
            listings: true,
            sellers: false,
            listingsTopBar: true
        },
        fieldName: {
        }
    },
    Rating: {
        droppableId: "Rating",
        label: "Rating",
        value: true,
        disabled: true,
        headerTable: false,
        visibility: {
            listings: true,
            sellers: false
        },
        fieldName: {
        }
    },
    Analyzer: {
        droppableId: "Analyzer",
        label: "Analyzer",
        value: true,
        disabled: true,
        headerTable: false,
        visibility: {
            listings: true,
            sellers: false
        },
        fieldName: {
        }
    },
    Status: {
        droppableId: "Status",
        label: "Status",
        value: true,
        disabled: true,
        headerTable: false,
        visibility: {
            listings: true,
            sellers: false
        },
        fieldName: {
        }
    }
};
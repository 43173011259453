import { takeLatest, call, put, select } from "redux-saga/effects";

import { getCountItemsPerPage, urlUtil } from "utils";

// API
import * as EntriesAPI from "pages/SocialProtect/services/Entries/api";

// Actions
import { navigationCompletedAction } from "services/Navigation/actions";
import { activeBrandSwitchedAction } from "services/Brand/actions";
import { storeFilterAction, saveFilterAppliedAction } from "pages/SocialProtect/services/Options/actions";
import {
    saveProfileEntriesAction,
    chooseAllProfileSaveAction,
    selectedProfileSaveAction
} from "pages/SocialProtect/services/Profile/actions";
import {
    savePostEntriesAction,
    updateCardItemAction,
    addCardItemAction,
    populateCardsAction,
    chooseCardToSelectAction,
    chooseAllCardAction,
    deleteOneCardsAction,
    deleteAllSelectedCardsAction,
    chooseAllCardSaveAction,
    selectedCardSaveAction,
    loadCardListAction,
    changeOrderDirectionAction,
    loadMoreAction,
    storeIsLoadingMoreAction
} from "pages/SocialProtect/services/Card/actions";

// Selectors
import {
    spViewDetailItemDataSelector,
    pageCardDisplayViewSelector
} from "pages/SocialProtect/services/UserView/selectors";
import {
    cardListDataSelector,
    selectedCardsList,
    selectedAllCardsStateSelector
} from "pages/SocialProtect/services/Card/selectors";
import { activeFilterSelector, allFiltersSelector } from "pages/SocialProtect/services/Options/selectors";
import { activeModuleStateSelector } from "services/UserView/selectors";
import { activeBrandSelector } from "services/Brand/selectors";

// Sagas
import { saveFilterWorker } from "pages/SocialProtect/services/Options/sagas";
import { showAndHideGlobalMessageAction } from "services/Message/actions";
import { activeServiceValidationWrapper } from "services/UserView/sagas";

//Utils
import { generateApplyFilterFromUrlWorker } from "services/Navigation/sagas";
import { serviceDefinition } from "./constants";
import { getObjectFromURLParams } from "utils/connected";

const { modifyURLByFiltersWorker, checkAndModifyURL } = urlUtil;

function* updateCardItemActionWorker( { payload } ) {
    const { id, text, resolve, reject } = payload;
    const activeModuleState = yield select( activeModuleStateSelector );
    const activeBrand = yield select( activeBrandSelector );
    const { data } = yield select( spViewDetailItemDataSelector );
    const cardListData = yield select( cardListDataSelector );
    let newObj = {};
    newObj.brand = activeBrand;
    newObj.module = activeModuleState;
    newObj.id = id;
    newObj.data = { ...data };

    if ( activeModuleState === "posts" ) {
        newObj.data.platform = ( data.platform && data.platform.id ) || null;
        newObj.data.countryCode2 = data.countryCode2 || "ZZ";
    }
    if ( newObj.data.profile ) {
        newObj.data.profile.platform =
      ( data.profile.platform && data.profile.platform.id ) || null;
        newObj.data.profile.mark =
      ( data.profile.mark && data.profile.mark.id ) || null;
        newObj.data.profile.countryCode2 = data.profile.countryCode2 || "ZZ";
        newObj.data.profile.infringementType =
      ( data.profile.infringementType && data.profile.infringementType.id ) ||
      null;
        newObj.data.profile.enforcementStatus =
      ( data.profile.enforcementStatus && data.profile.enforcementStatus.id ) ||
      null;
    }

    //Modify Note
    newObj.data.note = text;
    //Make request
    const response = yield call( EntriesAPI.updateItem, newObj );
    //Check request body API call
    if ( response && Object.values( response.result ).length ) {
        const { result } = response;
        resolve();
        //Change Data without make API call
        const newData = {};
        newData.page = cardListData.page;
        newData.totalCount = cardListData.totalCount;
        newData.results = cardListData.results.map( el => {
            if ( el.id === result.id ) {
                return result;
            }
            return el;
        } );
        //Save newData
        if ( activeModuleState === "posts" ) {
            yield put( savePostEntriesAction( newData ) );
        } else if ( activeModuleState === "profiles" ) {
            yield put( saveProfileEntriesAction( newData ) );
        }
    } else {
        reject( response.errors );
    }
}

function* addCardItemActionWorker( { payload } ) {
    const { data, resolve, reject } = payload;
    const activeModuleState = yield select( activeModuleStateSelector );
    const activeBrand = yield select( activeBrandSelector );
    let newObj = {};
    newObj.brand = activeBrand;
    newObj.module = activeModuleState;
    newObj.data = data;

    const response = yield call( EntriesAPI.addItemCard, newObj );
    if ( response && response.errors && Object.values( response.errors ).length ) {
        reject( response.errors );
    } else if ( response ) {
        resolve( response );
        const data = {
            status: "success",
            body: "You have added a new list item."
        };
        yield call( showAndHideGlobalMessageAction, data );
    }
}

function* deleteOneCardsWorker( { payload } ) {
    const activeModuleState = yield select( activeModuleStateSelector );
    const brand = yield select( activeBrandSelector );
    const post_ids = [ payload ];
    const response = yield call( EntriesAPI.deleteItem, {
        ids: { post_ids },
        brand: brand,
        module: activeModuleState
    } );
    if ( response.success ) {
        yield call( populateCardsWorker, {} );
    }
}

function* deleteAllSelectedCardsWorker() {
    const activeModuleState = yield select( activeModuleStateSelector );
    const selectedCardCurrentState = yield select( selectedCardsList );
    const brand = yield select( activeBrandSelector );
    const response = yield call( EntriesAPI.deleteItem, {
        ids: selectedCardCurrentState,
        brand
    } );
    if ( activeModuleState === "posts" ) {
        if ( response.success ) {
            yield call( populateCardsWorker, {} );
            yield put( chooseAllCardSaveAction( false ) );
            yield put( selectedCardSaveAction( [] ) );
        }
    } else if ( activeModuleState === "profiles" ) {
        if ( response.success ) {
            yield call( populateCardsWorker, {} );
            yield put( chooseAllProfileSaveAction( false ) );
            yield put( selectedProfileSaveAction( [] ) );
        }
    }
}

function* chooseCardToSelectWorker( { payload: { value, id } = {} } ) {
    const activeModuleState = yield select( activeModuleStateSelector );
    let selectedCardCurrentState = yield select( selectedCardsList );
    const cardListData = yield select( cardListDataSelector );
    const selectedCardState = yield select( selectedAllCardsStateSelector );
    let newArr = [ ...selectedCardCurrentState ];
    if ( activeModuleState === "posts" ) {
        if ( newArr.indexOf( id ) === -1 && value ) {
            newArr.push( id );
        } else {
            newArr = newArr.filter( el => el !== id );
            if ( selectedCardState ) yield put( chooseAllCardSaveAction( false ) );
        }
        if ( cardListData.results && newArr.length === cardListData.results.length ) {
            yield put( chooseAllCardSaveAction( true ) );
        }
        yield put( selectedCardSaveAction( newArr ) );
    } else if ( activeModuleState === "profiles" ) {
        if ( newArr.indexOf( id ) === -1 && value ) {
            newArr.push( id );
        } else {
            newArr = newArr.filter( el => el !== id );
            if ( selectedCardState ) yield put( chooseAllProfileSaveAction( false ) );
        }
        if ( cardListData.results && newArr.length === cardListData.results.length ) {
            yield put( chooseAllProfileSaveAction( true ) );
        }
        yield put( selectedProfileSaveAction( newArr ) );
    }
}

function* selectAllCardWorker( { payload } ) {
    const activeModuleState = yield select( activeModuleStateSelector );
    if ( activeModuleState === "posts" ) {
        if ( !payload ) {
            yield put( chooseAllCardSaveAction( false ) );
            yield put( selectedCardSaveAction( [] ) );
        } else {
            const cardListData = yield select( cardListDataSelector );
            const arrID = cardListData.results.map( e => e.id );
            yield put( selectedCardSaveAction( arrID ) );
            yield put( chooseAllCardSaveAction( true ) );
        }
    } else if ( activeModuleState === "profiles" ) {
        if ( !payload ) {
            yield put( chooseAllProfileSaveAction( false ) );
            yield put( selectedProfileSaveAction( [] ) );
        } else {
            const cardListData = yield select( cardListDataSelector );
            const arrID = cardListData.results.map( e => e.id );
            yield put( selectedProfileSaveAction( arrID ) );
            yield put( chooseAllProfileSaveAction( true ) );
        }
    }
}

//************************ Make request to select Cards list and change params *****************************************
function* changeOrderDirectionWorker( { payload } ) {
    //Change order direction on post/profiles
    //select data
    const activeBrand = yield select( activeBrandSelector );
    const activeModuleState = yield select( activeModuleStateSelector );
    const activeFilters = yield select( activeFilterSelector );
    //Start loading
    yield put( loadCardListAction( true ) );
    let newObj = { ...activeFilters };
    let fieldName = null;
    if ( activeModuleState === "posts" ) {
        fieldName = "fieldNamePosts";
    } else if ( activeModuleState === "profiles" ) {
        fieldName = "fieldNameProfiles";
    }
    newObj.orderDirection =
    activeFilters.orderDirection === payload[fieldName]
        ? "-" + payload[fieldName]
        : payload[fieldName];
    //save filter value
    yield put(
        storeFilterAction( { payload: newObj, activeModuleState } )
    );
    //Save only applied filter values
    yield put( saveFilterAppliedAction( { payload: newObj, activeModuleState } ) );

    newObj.brand = activeBrand;
    //select list if exists
    newObj.module = activeModuleState;
    //Modify URL
    yield call( modifyURLByFiltersWorker, newObj );
    //Make request
    const response = yield call( EntriesAPI.getEntries, newObj );
    if ( activeModuleState === "posts" && response ) {
        yield put( savePostEntriesAction( response ) );
        yield put( storeFilterAction( { payload: newObj, activeModuleState } ) );
    } else if ( activeModuleState === "profiles" && response ) {
        yield put( saveProfileEntriesAction( response ) );
        yield put( storeFilterAction( { payload: newObj, activeModuleState } ) );
    }
    //end loading
    yield put( loadCardListAction( false ) );
}

/*
* @TODO: This function is a good candidate for refactoring, and does too much itself.
* There is a refactored version available in MP. If we continue with SP, we should reimplement it here as well
*/
export function* populateCardsWorker( { payload } ) {
    const urlParams = yield call( getObjectFromURLParams );
    const activeModuleState = yield select( activeModuleStateSelector );
    const pageCardDisplayViewState = yield select( pageCardDisplayViewSelector );
    const activeFilters = yield select( activeFilterSelector );
    const activeBrand = yield select( activeBrandSelector );

    if ( !activeBrand ) return;
    //Add filter object to request
    let newObj = {};

    //Save filter params from url in reducer
    if ( Object.values( urlParams ).length && !payload ) {
        let newFilterData = { ...activeFilters };
        for ( let key in newFilterData ) {
            if (
                Object.prototype.hasOwnProperty.call( urlParams, key ) &&
                Object.prototype.hasOwnProperty.call( newFilterData, key )
            ) {
                newFilterData[key] = urlParams[key];
            } else {
                newFilterData[key] = null;
            }
        }
        yield call( saveFilterWorker, { payload: newFilterData } );
        newObj = { ...urlParams };
    } else if ( !payload ) {
        newObj = { ...activeFilters };
    } else {
        newObj = { ...payload };
    }
    //start Loader
    yield put( loadCardListAction( true ) );
    //select list if exists to find
    const cardList = yield select( cardListDataSelector );
    //Check if exists params from URL or add from store
    if ( cardList ) if ( !newObj.page ) newObj.page = cardList.page;
    //get perPage value
    const perPageValue = getCountItemsPerPage( pageCardDisplayViewState );
    //Save perPage value
    yield call( saveFilterWorker, {
        payload: { limit: perPageValue }
    } );
    newObj.limit = perPageValue;
    newObj.brand = activeBrand;
    newObj.module = activeModuleState;

    //Save only applied filter values
    yield put( saveFilterAppliedAction( { payload: newObj, activeModuleState } ) );

    //Make request
    const response = yield call( EntriesAPI.getEntries, newObj );
    if ( response ) {
        if ( activeModuleState === "posts" ) {
            yield put( savePostEntriesAction( response ) );
        } else if ( activeModuleState === "profiles" ) {
            yield put( saveProfileEntriesAction( response ) );
        }
    }
    yield put( loadCardListAction( false ) );
}


/**
 * @TODO: Implement the abstracted loadMoreWorker (see generateLoadMoreWorker)
 * That implementation is dependent on making the reducer structure similar to market protect,
 * which we should strive to do anyway for the sake of consistency.
 */
function* loadMoreActionWorker() {
    const activeModuleState = yield select( activeModuleStateSelector );
    const cardListData = yield select( cardListDataSelector );
    const activeBrand = yield select( activeBrandSelector );
    const activeFilters = yield select( activeFilterSelector );

    let newObj = { ...activeFilters };
    //select list if exists
    if ( cardListData ) {
        newObj.page = cardListData.page + 1;
    }
    newObj.brand = activeBrand;
    newObj.module = activeModuleState;
    //Modify URL
    yield call( modifyURLByFiltersWorker, newObj );
    //Call API and get DATA
    yield put( storeIsLoadingMoreAction( true ) );

    //Make request
    const response = yield call( EntriesAPI.getEntries, newObj );
    // Create new Obj for store with ald and new DATA
    const newState = { ...response };
    newState.results = [ ...cardListData.results, ...newState.results ];
    if ( activeModuleState === "posts" ) {
        yield put( savePostEntriesAction( newState ) );
    } else if ( activeModuleState === "profiles" ) {
        yield put( saveProfileEntriesAction( newState ) );
    }
    yield put( storeIsLoadingMoreAction( false ) );
}

function* activeBrandSwitchedWorker( ) {
    yield put( savePostEntriesAction( null ) );
    yield put( saveProfileEntriesAction( null ) );
    yield put( populateCardsAction() );
}

export function* setActiveModuleStateSPCardWorker( activeModuleState ) {
    const urlParams = yield call( getObjectFromURLParams );
    const activeFilterMainObjectValues = yield select( allFiltersSelector );
    //Check value in filter
    //Modify URL by filter values if user open page without url params
    if ( !Object.values( urlParams ).length ){
        checkAndModifyURL( activeFilterMainObjectValues[activeModuleState], window.location.pathname );
    }
    const hasCardDataAvailable = yield call( getLoadedCardData, activeModuleState );

    //make api call
    if ( activeModuleState && !hasCardDataAvailable ) yield put( populateCardsAction() );
}

function* getLoadedCardData( activeModuleState ) {
    const card_list_data = yield select( cardListDataSelector, activeModuleState );
    return card_list_data && card_list_data.results ? card_list_data : null;
}

const applyFilterFromUrl = generateApplyFilterFromUrlWorker( activeFilterSelector, populateCardsAction );


//************************ END Make request to select Cards list and change params *************************************

// Watchers
export function* updateCardItemActionWatcher() {
    yield takeLatest( updateCardItemAction, updateCardItemActionWorker );
}
export function* addCardItemActionWatcher() {
    yield takeLatest( addCardItemAction, addCardItemActionWorker );
}
export function* cardListWatcher() {
    yield takeLatest( populateCardsAction, populateCardsWorker );
}
export function* selectCardWatcher() {
    yield takeLatest( chooseCardToSelectAction, chooseCardToSelectWorker );
}
export function* selectAllCardWatcher() {
    yield takeLatest( chooseAllCardAction, selectAllCardWorker );
}
export function* deleteOneCardsWatcher() {
    yield takeLatest( deleteOneCardsAction, deleteOneCardsWorker );
}
export function* deleteAllSelectedCardsWatcher() {
    yield takeLatest( deleteAllSelectedCardsAction, deleteAllSelectedCardsWorker );
}
export function* loadMoreActionWatcher() {
    yield takeLatest( loadMoreAction, loadMoreActionWorker );
}
export function* changeOrderDirectionWatcher() {
    yield takeLatest( changeOrderDirectionAction, changeOrderDirectionWorker );
}
export function* cardActiveBrandSwitchedSPWatcher() {
    yield takeLatest(
        activeBrandSwitchedAction,
        activeServiceValidationWrapper,
        {
            ...serviceDefinition,
            worker: activeBrandSwitchedWorker
        }
    );
}
export function* applyFilterFromUrlSPWatcher() {
    yield takeLatest(
        navigationCompletedAction,
        activeServiceValidationWrapper,
        {
            ...serviceDefinition,
            worker: applyFilterFromUrl
        }
    );
}

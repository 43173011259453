// import { ApiCreator } from "utils/connected";
// import { BASE_URL_API } from "config";
import { statusFixture } from "./fixtures";
// const api = ApiCreator( BASE_URL_API );

export const getStatusList = ( ) => {
    // api.get( "generic/storage/enforcementStatus/list", {
    //     headers: {
    //         Accept: "application/json",
    //         "Content-Type": "application/json"
    //     }
    // } );
    return Promise.resolve( statusFixture );
};

// Used by anyone that wants to wrap a SideBar, this ensures we can wrap without including the presentation layer.
// Any implementation of a SideBar can wrap itself with this container to gain access to shared state.

import {
    activeModuleStateSelector,
    sideBarIsOpenSelector
} from "services/UserView/selectors";

import { toggleSideBarIsOpenAction } from "services/UserView/actions";

import {
    toggleExportDataModalIsOpenAction,
    toggleImportModalStateAction
} from "services/Modal/actions";

import {
    exportModalSelector,
    importModalSelector
} from "services/Modal/selectors";

import {
    statusListSelector,
    statusListLoaderSelector
} from "services/Status/selectors";

import {
    platformListLoaderSelector,
    platformListSelector
} from "services/Platform/selectors";

// Import the props & state below to gain access to the shared state
export const sideBarContainerProps = {
    toggleSideBarIsOpenAction,
    toggleExportDataModalIsOpenAction,
    toggleImportModalStateAction
};

export const mapSideBarContainerStateToProps = state => {
    const activeModuleState = activeModuleStateSelector( state );
    const sideBarIsOpen = sideBarIsOpenSelector( state ) || false;
    const statusListState = statusListSelector( state );
    const statusListIsLoadingState = statusListLoaderSelector( state );
    const platformListIsLoadingState = platformListLoaderSelector( state );
    const platformListState = platformListSelector( state );
    const exportModalState = exportModalSelector( state );
    const importModalState = importModalSelector( state );

    return {
        activeModuleState,
        sideBarIsOpen,
        statusListState,
        statusListIsLoadingState,
        platformListIsLoadingState,
        platformListState,
        exportModalState,
        importModalState
    };
};
import { createSelector } from "reselect";

export const countryListSelector = createSelector(
    state => state.main.country.countryList,
    state => state
);

export const countryLoaderSelector = createSelector(
    state => state.main.country.countryLoader,
    state => state
);

import React from "react";
import { urlUtil } from "utils";
import { RowLinkView, TitleDetails, RowStatistics, ListDetails } from "components";
const { extractHostname } = urlUtil;

export const ProfileInfo = ( { data } ) => {
    const connectedTo = data.connectedTo || null;

    return (
        <div className="info-wrapper">
            <TitleDetails text={"Profile information"} />
            <RowStatistics
                data={[
                    { label: "Views:", value: data.profileViews || ( data.profile && data.profile.profileViews ) },
                    { label: "Likes:", value: data.profileLikes || ( data.profile && data.profile.profileLikes ) },
                    { label: "Posts:", value: data.profilePosts || ( data.profile && data.profile.profilePosts ) },
                    { label: "Followers:", value: data.profileFollowers || ( data.profile && data.profile.profileFollowers ) }
                ]}
            />
            <ListDetails
                data={[
                    { label: "Profile picture", value: (
                        <img
                            src={
                                data.profileImage || ( data.profile && data.profile.profileImage )
                            }
                            alt=""
                        />
                    ) },
                    { label: "Profile name", value: data.profileName || ( data.profile && data.profile.profileName ) },
                    {
                        label: "Profile connected to", value:
                            connectedTo && (
                                <RowLinkView
                                    label={extractHostname( connectedTo )}
                                    iconLink={connectedTo}
                                    targetBlank={true}
                                    handleClickIcon={() => {
                                        window.open( connectedTo, "_blank" );
                                    }}
                                />
                            )
                    },
                    { label: "Profile submitted by", value: data.submittedBy || ( data.profile && data.profile.submittedBy ) },
                    { label: "Country", value: data.countryName || ( data.profile && data.profile.countryName ) },
                    { label: "Profile URL", value: ( data.profileURL || ( data.profile && data.profile.profileURL ) ) && (
                        <RowLinkView
                            label={
                                data.profileURL || ( data.profile && data.platform.profileURL )
                            }
                            iconLink={
                                data.profileURL || ( data.profile && data.profile.profileURL )
                            }
                            targetBlank={true}
                            handleClickIcon={() => {
                                window.open(
                                    data.profileURL ||
                                        ( data.profile && data.profile.profileURL ),
                                    "_blank"
                                );
                            }}
                        />
                    ) },
                    { label: "Profile ID", value: data.profileID || ( data.profile && data.profile.profileID ) }
                ]}
            />
        </div>
    );
};

import React from "react";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";


export const EnhancedTableHead = ( props ) => {
    return (
        <TableHead>
            <TableRow>
                {props.children}
            </TableRow>
        </TableHead>
    );
};
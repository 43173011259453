import { createAction } from "redux-actions";

export const populateCardsAction = createAction( "populateCardsAction" );
export const savePostEntriesAction = createAction( "savePostEntriesAction" );
export const loadCardListAction = createAction( "loadCardListAction" );
export const chooseCardToSelectAction = createAction( "chooseCardToSelectAction" );
export const selectedCardSaveAction = createAction( "selectedCardSaveAction" );
export const chooseAllCardAction = createAction( "chooseAllCardAction" );
export const chooseAllCardSaveAction = createAction( "chooseAllCardSaveAction" );
export const deleteOneCardsAction = createAction( "deleteOneCardsAction" );
export const addCardItemAction = createAction( "addCardItemAction" );
export const updateCardItemAction = createAction( "updateCardItemAction" );
export const deleteAllSelectedCardsAction = createAction( "deleteAllSelectedCardsAction" );
export const loadMoreAction = createAction( "loadMoreAction" );
export const storeIsLoadingMoreAction = createAction( "storeIsLoadingMoreAction" );
export const changeOrderDirectionAction = createAction( "changeOrderDirectionAction" );

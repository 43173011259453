import React from "react";
import { Tooltip, extrLink, chanel } from "components";
import "./RowLinkView.scss";

export const RowLinkView = ( {
    channel = false,
    labelLink = null,
    iconLink = null,
    handleClick = null,
    handleClickIcon = null,
    handleClickLabel = null,
    label = null,
    tooltip = null
} ) => {
    let icon = null;
    let iconSvg = extrLink();
    if ( channel ) iconSvg = chanel();
    if ( iconLink ) {
        icon = (
            <a
                href={iconLink}
                target={!channel ? "_blank" : "_self"}
                onClick={e => {
                    e.preventDefault();
                    return handleClickIcon && typeof handleClickIcon === "function"
                        ? handleClickIcon()
                        : false;
                }}
            >
                {iconSvg}
            </a>
        );
    }

    return (
        <div className="external-link">
            {tooltip ? <Tooltip title={tooltip}>{icon}</Tooltip> : icon}
            {labelLink ? (
                <div className="label">
                    <a
                        href={labelLink}
                        onClick={e => {
                            e.preventDefault();
                            return handleClickLabel && typeof handleClickLabel === "function"
                                ? handleClickLabel()
                                : false;
                        }}
                    >
                        {label}
                    </a>
                </div>
            ) : (
                <div
                    className={handleClick ? "label clickable" : "label"}
                    role="presentation"
                    onClick={e => {
                        e.preventDefault();
                        return handleClick && typeof handleClick === "function"
                            ? handleClick()
                            : false;
                    }}
                >
                    {label ? label : "No Data"}
                </div>
            )}
        </div>
    );
};

import { ApiCreator } from "utils/connected";
import { BASE_URL_API } from "config";
const api = ApiCreator( BASE_URL_API );

export const getMarkList = ( { markType = "post" } ) =>
    api.get( `generic/storage/${markType}Mark/list`, {
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json"
        }
    } );

import { connect } from "react-redux";
import { SideBar as component } from "./SideBar";
import {
    sideBarContainerProps,
    mapSideBarContainerStateToProps
} from "components/SideBar/Container";

import {
    isLoadingLabelsSelector,
    labelsSelector
} from "pages/MarketProtect/services/Label/selectors";

import {
    activeFilterSelector,
    searchWordTitleSelector
} from "pages/MarketProtect/services/Options/selectors";

import {
    saveFilterAndSearchAction,
    searchByFilterAction,
    saveFilterAction
} from "pages/MarketProtect/services/Options/actions";

import { validationUtil, formatDate } from "utils";
import { applyFilterIfUpdated } from "services/Filter/utils";

const mapDispatchToProps = {
    ...sideBarContainerProps,
    saveFilterAction,
    saveFilterAndSearchAction,
    searchByFilterAction
};
const mapStateToProps = ( state, ownProps ) => {
    const searchWordTitleState = searchWordTitleSelector( state );
    const activeFilter = activeFilterSelector( state );
    const isLoadingLabels = isLoadingLabelsSelector( state );
    const labelsState = labelsSelector( state );

    return {
        ...mapSideBarContainerStateToProps( state ),
        activeFilter,
        searchWordTitleState,
        isLoadingLabels,
        labelsState
    };
};

const mergeProps = ( stateProps, dispatchProps, ownProps ) => {
    const { activeFilter } = stateProps;
    const {
        saveTitleFilterAction,
        toggleSideBarIsOpenAction,
        searchByFilterAction,
        saveFilterAndSearchAction,
        saveFilterAction
    } = dispatchProps;

    return {
        ...stateProps,
        ...dispatchProps,
        ...ownProps,
        changeInputTitle: e => saveTitleFilterAction( e.target.value ),
        toggleSideBarIsOpen: () => toggleSideBarIsOpenAction(),
        onChangeDropdownFilter: ( label, value ) => applyFilterIfUpdated( activeFilter, label, value, saveFilterAndSearchAction ),
        onChangeInputFilter: ( label, value ) => applyFilterIfUpdated( activeFilter, label, value, saveFilterAction ),
        onChangeDateFilter: ( label, value ) => {
            if ( value ) formatDate( value );

            applyFilterIfUpdated( activeFilter, label, value, saveFilterAction );
        },
        searchOnEnter: e => validationUtil.enterIsPressed( e ) && searchByFilterAction(),
        searchByFilter: () => searchByFilterAction()
    };
};

export const SideBar = connect(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps
)( component );

import { saveMessageBodyAction } from "./actions";
import { handleActions } from "redux-actions";

const initialState = {
    messageBody: null
};

export const messageReducer = handleActions(
    {
        [saveMessageBodyAction]: ( state, { payload } ) => ( {
            ...state,
            messageBody: payload
        } )
    },
    initialState
);

import React from "react";
import {
    EnhancedTable
} from "components";
import { getCells } from "utils";
import { MixedCardView } from "./components";
import { chooseItemByFieldName } from "pages/MarketProtect/chooseItemByFieldName";
import {
    ItemImageView,
    ItemImageViewSmall,
    ItemCardView
} from "pages/MarketProtect/components";

export const CardView = ( {
    cardListState,
    pageViewState,
    tableViewState,
    chooseCardToSelectAction,
    selectedCardsListState,
    chooseAllCardAction,
    selectedAllCardsState,
    pageCardDisplayViewState,
    deleteOneCardsAction,
    activeModuleState,
    showViewDetailsPanelMPAction,
    activeFilter,
    changeOrderDirectionAction,
    userPushToRouteAction,
    showDetailsMixedViewAction,
    viewDetailItemDataState
} ) => {

    if ( !cardListState || ( cardListState && !cardListState.items ) ) return null;
    if ( cardListState && cardListState.items && !cardListState.items.length )
        return <div className="col-lg-12">No Items...</div>;

    const cells = getCells( tableViewState, activeModuleState );
    if ( pageViewState === "row" ) {
        return (
            <div className="col-lg-12 col-md-12">
                <EnhancedTable
                    //props check box
                    check={ true }
                    handleChange={() => { chooseAllCardAction( !selectedAllCardsState );}}
                    defaultValue={selectedAllCardsState}
                    //props THEAD
                    cells={ cells }
                    activeModuleState={ activeModuleState }
                    chooseItemByFieldName={ chooseItemByFieldName }
                    orderDirection={ activeFilter.orderDirection }
                    createSortHandler={ changeOrderDirectionAction }
                    //props TBODY
                    bodyCells={ cardListState.items }
                    symbol={ cardListState.currency.symbol }
                    deleteOneCardsAction={ deleteOneCardsAction }
                    chooseCardToSelect={ chooseCardToSelectAction }
                    selectedCardsList={ selectedCardsListState }
                    showViewDetailsPanelAction={ showViewDetailsPanelMPAction }
                    userPushToRouteAction={ userPushToRouteAction }
                    tableView={ tableViewState.view }
                />
            </div>
        );
    }else if ( pageViewState === "mixed" ){
        return(
            <MixedCardView
                cardListState={cardListState}
                viewDetailItemDataState={viewDetailItemDataState}
                showDetailsMixedViewAction={showDetailsMixedViewAction}
            />
        );
    } else {
        return ( <div className={ `row ${pageCardDisplayViewState}` }>{(
            cardListState.items.map( ( e, i ) => {
                const props = {
                    id: e.id,
                    element: e,
                    img: e.images,
                    cardURL: e.listingURL || e.profileURL,
                    titleHeader: null,
                    name: e.title,
                    date: e.endDate,
                    text: e.description,
                    seller: e.seller.externalName,
                    rating: e.seller.rating,
                    quantity: e.itemsAvailable,
                    price: e.price,
                    symbol: cardListState.currency.symbol,
                    imgHeader: null,
                    status: {
                        mark: ( e.mark && e.mark.name ) || null,
                        infringementType:
                            ( e.infringementType && e.infringementType.name ) || null,
                        enforcementStatus:
                            ( e.enforcementStatus && e.enforcementStatus.name ) || null
                    },
                    chooseCardToSelect: chooseCardToSelectAction,
                    selectedCardsList: selectedCardsListState,
                    deleteOneCardsAction: deleteOneCardsAction,
                    showViewDetailsPanelMPAction: showViewDetailsPanelMPAction,
                    followers: e.profileFollowers || "0"
                };
                if ( pageCardDisplayViewState === "block-description-x1" ) {
                    return (
                        <div className="col-lg-4 col-md-4" key={i}>
                            <ItemCardView {...props} />
                        </div>
                    );
                }
                if ( pageCardDisplayViewState === "block-description-x2" ) {
                    return (
                        <div className="col-lg-3 col-md-3" key={i}>
                            <ItemCardView {...props} />
                        </div>
                    );
                }
                if ( pageCardDisplayViewState === "block-img-x1" ) {
                    return (
                        <div className="col-lg-4 col-md-4 col-sm-6" key={i}>
                            <ItemImageView {...props} />
                        </div>
                    );
                }
                if ( pageCardDisplayViewState === "block-img-x2" ) {
                    return (
                        <ItemImageView
                            key={i}
                            addClassName={"small"}
                            {...props}
                            titleHide={true}
                        />
                    );
                }
                if ( pageCardDisplayViewState === "block-img-x3" ) {
                    return (
                        <div className="card-image-view-small-wrapper" key={i}>
                            <ItemImageViewSmall {...props} />
                        </div>
                    );
                }
                return (
                    <div className="col-lg-4 col-md-4" key={i}>
                        <ItemCardView {...props} />
                    </div>
                );
            } )
        )}</div> );
    }
};
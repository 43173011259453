import React from "react";
import { tikSvg, editSvgSmall } from "components";
import "./ButtonHoverSVG.scss";

export const ButtonHoverSVG = props => {
    const { className = "", children } = props;
    return (
        <button {...props} className={`btn-svg-hover ${className}`}>
            {children}
            {tikSvg( "#4cad3a", Math.random().toFixed( 3 ) )}
            {editSvgSmall( "#445bf4", Math.random().toFixed( 3 ) )}
        </button>
    );
};

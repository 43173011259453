export function PostsTemplate() {
    return {
        Brand: { key: "brandID" },
        Platform: { key: "platform" },
        "Profile name": { key: "profileName", profile: true },
        "Profile ID": { key: "profileID", profile: true },
        "Profile URL": { key: "profileURL", profile: true },
        "Profile followers": { key: "profileFollowers", profile: true },
        "Profile views": { key: "profileViews", profile: true },
        "Profile likes": { key: "profileLikes", profile: true },
        "Profile posts": { key: "profilePosts", profile: true },
        "Profile picture": { key: "profileImage", profile: true },
        "Profile description": { key: "profileDescription", profile: true },
        "Profile evidence URL": { key: "evidenceURL", profile: true },
        "Profile connected to": { key: "connectedTo", profile: true },
        "Profile country": { key: "countryCode2", profile: true },
        "Profile submitted by": { key: "submittedBy", profile: true },
        "Profile comments": { key: "note", profile: true },
        "Listing ID": { key: "listingID" },
        "Listing URL": { key: "listingURL" },
        "Evidence URL": { key: "evidenceURL" },
        "Listing title": { key: "listingTitle" },
        "Listing description": { key: "listingDescription" },
        "Listing image URL": { key: "listingImage" },
        "Post likes": { key: "listingLikes" },
        "Post shares": { key: "listingShares" },
        "Post followers": { key: "listingFollowers" },
        "Post views": { key: "listingViews" },
        "Connected to": { key: "connectedTo" },
        Country: { key: "countryCode2" },
        Label: { key: "mark" },
        "Infringement type": { key: "infringementType" },
        "Enforcement status": { key: "enforcementStatus" },
        "Submitted by": { key: "submittedBy" },
        Comments: { key: "note" }
    };
}

export const columnsPosts = {
    taskIds: [
        "Status",
        "Platform",
        "Profile name",
        "Posts",
        "Title",
        "Views",
        "Date",
        "Description"
    ]
};

export const listEnabledItem = {
    Title: {
        droppableId: "Title",
        label: "Title",
        value: true,
        disabled: true,
        headerTable: true,
        visibility: {
            posts: true,
            profiles: false,
            postsTopBar: true
        },
        fieldName: {
            posts: "listingTitle",
            profiles: null
        }
    },
    Profile: {
        droppableId: "Profile",
        label: "Profile name",
        value: true,
        disabled: true,
        headerTable: true,
        visibility: {
            posts: false,
            profiles: true,
            profilesTopBar: true
        },
        fieldName: {
            posts: "profileName",
            profiles: "profileName"
        }
    },
    "Profile name": {
        droppableId: "Profile name",
        label: "Profile name",
        value: true,
        disabled: false,
        headerTable: true,
        visibility: {
            posts: true,
            profiles: false,
            postsTopBar: true
        },
        fieldName: {
            posts: "profileName",
            profiles: "profileName"
        }
    },
    Platform: {
        droppableId: "Platform",
        label: "Platform",
        value: true,
        disabled: false,
        headerTable: true,
        visibility: {
            posts: true,
            profiles: true,
            postsTopBar: true,
            profilesTopBar: true
        },
        fieldName: {
            posts: "platform.name.raw",
            profiles: "platform.name.raw"
        }
    },
    "Posts in DB": {
        droppableId: "Posts in DB",
        label: "Posts in DB",
        value: true,
        disabled: false,
        headerTable: true,
        visibility: {
            posts: false,
            profiles: true,
            profilesTopBar: true
        },
        fieldName: {
            posts: "countPostsInDB",
            profiles: "countPostsInDB"
        }
    },
    Posts: {
        droppableId: "Posts",
        label: "Posts",
        value: true,
        disabled: false,
        headerTable: false,
        visibility: {
            posts: true,
            profiles: false
        },
        fieldName: {
            posts: "profilePosts",
            profiles: "profilePosts"
        }
    },
    Views: {
        droppableId: "Views",
        label: "Views",
        value: true,
        disabled: false,
        headerTable: true,
        visibility: {
            posts: true,
            profiles: true
        },
        fieldName: {
            posts: "listingViews",
            profiles: "profileViews"
        }
    },
    Date: {
        droppableId: "Date",
        label: "Date",
        value: true,
        disabled: false,
        headerTable: true,
        visibility: {
            posts: true,
            profiles: true,
            postsTopBar: true
        },
        fieldName: {
            posts: "createdAt",
            profiles: "createdAt"
        }
    },
    Description: {
        droppableId: "Description",
        label: "Description",
        value: true,
        disabled: false,
        headerTable: true,
        visibility: {
            posts: true,
            profiles: true
        },
        fieldName: {
            posts: "listingDescription.raw",
            profiles: "profileDescription"
        }
    },
    Status: {
        droppableId: "Status",
        label: "Status",
        value: true,
        disabled: false,
        headerTable: false,
        visibility: {
            posts: true,
            profiles: true
        },
        fieldName: {
            posts: "status",
            profiles: "status"
        }
    }
};

import { connect } from "react-redux";
import { SideBar as component } from "./SideBar";
import { activeModuleStateSelector, sideBarIsOpenSelector } from "services/UserView/selectors";

const mapDispatchToProps = {};

const mapStateToProps = state => {
    const activeModuleState = activeModuleStateSelector( state );
    const sideBarIsOpen = sideBarIsOpenSelector( state ) || false;

    return {
        activeModuleState,
        sideBarIsOpen
    };
};

export const SideBar = connect(
    mapStateToProps,
    mapDispatchToProps,
)( component );

import React from "react";
import "./Header.scss";

export const HeaderWrapper = ( { left, center, right } ) => {
    return (
        <header className="page-header">
            <div>
                {left}
            </div>
            <div>{center}</div>
            <div>
                <div className="flex">
                    {right}
                </div>
            </div>
        </header>
    );
};

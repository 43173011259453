import React from "react";
import PropTypes from "prop-types";
import { MessageGlobal, AlertDialog } from "components";
import "./App.scss";

export class App extends React.Component {
    closeMessage = () => {
        this.props.saveMessageBodyAction( null );
    };

    closeModal = () => {
        const { setAlertModalIsOpenAction, setAlertModalConfigurationAction } = this.props;

        setAlertModalIsOpenAction( false );
        setAlertModalConfigurationAction( null );
    }

    onAcceptModal = () => {
        const { alertModalConfiguration } = this.props;
        alertModalConfiguration.onAccept();

        this.closeModal();
    }

    onRejectModal = () => {
        const { alertModalConfiguration } = this.props;
        alertModalConfiguration.onReject();

        this.closeModal();
    }

    render() {
        const { message, alertModalIsOpen, alertModalConfiguration } = this.props;

        return (
            <div>
                { alertModalIsOpen &&
                    <AlertDialog
                        {...alertModalConfiguration}
                        onAccept={this.onAcceptModal}
                        onReject={this.onRejectModal}
                        shouldControlClosureLocally={false}
                        isOpen={true}
                    />
                }
                {message && (
                    <MessageGlobal
                        status={message.status}
                        left={message.body}
                        close={this.closeMessage}
                    />
                )}
                {this.props.children}
            </div>
        );
    }
}

App.propTypes = {
    children: PropTypes.object.isRequired
};
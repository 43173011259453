import React, { PureComponent } from "react";
import { arrow } from "components";
import "./StatusDropdown.scss";

export class StatusDropdown extends PureComponent {
    constructor( props ) {
        super( props );
        this.myRef = React.createRef();
        this.state = {
            open: false,
            selected: null
        };
    }

    componentDidMount() {
        document.addEventListener( "click", this.documentClick );
    }

    componentWillUnmount() {
        document.removeEventListener( "click", this.documentClick );
    }

    documentClick = e => {
        const node = this.myRef.current;
        if ( e.target.closest( ".dropdown-body" ) !== node ) this.closeDropDown();
    };

    changeStateDropDown = () => {
        const { disabled } = this.props;
        if ( disabled ) return;
        this.setState( prevState => ( { open: !prevState.open } ) );
    };

    closeDropDown = () => {
        this.setState( { open: false } );
    };

    selectItem = e => {
        const { handleChange = val => {} } = this.props;
        this.setState(
            {
                selected: e.id || e,
                open: false
            },
            () => handleChange( e.id || e )
        );
    };

    getValueFiler = ( data, elem ) => {
        if ( elem === "" ) return "Change status";
        let value = elem;
        if ( data && data[0] && data[0].id ) {
            let arr = data.filter( el => +el.id === +elem );
            if ( arr.length ) value = arr[0].name;
        }
        return value;
    };

    render() {
        const {
            wrapperClassName = "",
            data = null,
            defaultValue = null,
            disabled = false
        } = this.props;
        let { open, selected } = this.state;
        let btnValue =
            defaultValue || defaultValue === ""
                ? this.getValueFiler( data, defaultValue )
                : selected
                    ? selected
                    : "Change status";
        let wrapperClass = "dropdown " + wrapperClassName;
        if ( disabled ) wrapperClass += " disabled";
        if ( !data || ( data && !data.length ) ) return null;
        return (
            <div className={wrapperClass}>
                <div ref={this.myRef} className="dropdown-body">
                    <div
                        className={open ? "wrapper-button show" : "wrapper-button"}
                        onClick={this.changeStateDropDown}
                        role="presentation"
                    >
                        <button
                            type="button"
                            className={
                                "btn-status " +
                                btnValue
                                    .toString()
                                    .toLowerCase()
                                    .replace( / /g, "-" )
                            }
                        >
                            {btnValue}
                            {arrow()}
                        </button>
                    </div>
                    <div className={open ? "dropdown-list show" : "dropdown-list"}>
                        {data && data.length ? (
                            <ul>
                                {data.map( e => (
                                    <li
                                        key={e.id}
                                        onClick={() => this.selectItem( e )}
                                        role="presentation"
                                        className={
                                            "list-status " +
                                                e.name
                                                    .toString()
                                                    .toLowerCase()
                                                    .replace( / /g, "-" )
                                        }
                                    >
                                        {e.name}
                                    </li>
                                ) )}
                            </ul>
                        ) : null}
                    </div>
                </div>
            </div>
        );
    }
}

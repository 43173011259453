import React from "react";
import "./TextInput.scss";

export const TextInput = ( {
    input,
    label,
    labelClassName = "label-default",
    inputClassName = null,
    placeholder = null,
    id = null,
    wrapperStyle = null,
    childrenStyle = null,
    type = "text",
    defaultValue = null,
    onKeyPress = null,
    meta: { touched, error, success } = {},
    children,
    handleChange = () => {}
} ) => {
    if ( children ) labelClassName += " with-children";
    if ( touched && error ) labelClassName += " error";
    if ( success ) labelClassName += " success";
    return (
        <label className={labelClassName}>
            {label ? <div className="title">{label}</div> : null}
            <div className="wrapper-input" style={wrapperStyle}>
                {children ? <div style={childrenStyle}>{children}</div> : null}
                <input
                    {...input}
                    className={inputClassName}
                    placeholder={placeholder}
                    id={id}
                    type={type}
                    onKeyPress={onKeyPress}
                    autoComplete="new-password"
                    onChange={e => {
                        handleChange( e );
                        if (
                            input &&
                            input.onChange &&
                            typeof input.onChange === "function"
                        ) {
                            input.onChange( e );
                        }
                    }}
                    value={( input && input.value ) || defaultValue || ""}
                />
            </div>
            {touched && error ? <div className="error-message">{error}</div> : null}
        </label>
    );
};

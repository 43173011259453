import React from "react";
import moment from "moment";
import { dateFormat } from "config";
import {
    ItemCardWrapper,
    Checkbox,
    CollapsibleParagraph,
    SocialTitle,
    StatusIcons,
    maximizeCard,
    externalLinkCard
    // CardActions
} from "components";

export const ItemCardView = ( props ) => {
    return (
        <ItemCardWrapper
            header={
                <>
                    <Checkbox
                        handleChange={( { value } ) => {
                            props.chooseCardToSelect( { value: value, id: props.element.id } );
                        }}
                        defaultValue={props.selectedCardsList.indexOf( props.element.id ) !== -1}
                    />
                    <SocialTitle code={props.imgHeader} title={props.titleHeader} />
                    <div/>
                    {/*<CardActions*/}
                    {/*    id={props.element.id}*/}
                    {/*    deleteOneCardsAction={props.deleteOneCardsAction}*/}
                    {/*    editBtn={false}*/}
                    {/*/>*/}
                </>
            }
            body={
                <>
                    <div className="img-box">
                        {props.img ? <img src={props.img} alt={props.imgCardAlt} /> : null}
                        <div className="card-item-body-footer">
                            <StatusIcons status={props.status} text={true} type="card-status" />
                        </div>
                    </div>
                    <div className="description">
                        <div className="title-description-card">
                            <div>{props.name || "No title"}</div>
                            <span>{moment( props.date ).format( dateFormat )}</span>
                        </div>
                        <CollapsibleParagraph text={props.text} />
                        <div className="detail">
                            <div>
                                Profile: <span>{props.profile}</span>
                            </div>
                            <div className="views-card">
                                <div>
                                    Posts: <span>{props.posts || 0}</span>
                                </div>
                                <div>
                                    Views: <span>{props.views || 0}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            }
            footer={
                <>
                    <div>
                        <a
                            href="/"
                            onClick={e => {
                                e.preventDefault();
                                if ( props.cardURL )
                                    props.userPushToRouteAction( { profileURL: props.cardURL, module: "posts" } );
                            }}
                        >
                            {maximizeCard()}
                        </a>
                    </div>
                    <button
                        type={"button"}
                        onClick={() => props.showViewDetailsPanelAction( props.element.id )}
                    >
                        View details
                    </button>
                    <div>
                        {props.cardURL ? (
                            <a href={props.cardURL} rel="noopener noreferrer" target="_blank">
                                {externalLinkCard()}
                            </a>
                        ) : null}
                    </div>
                </>
            }
        />
    );
};

import React from "react";
import moment from "moment";
import {
    Checkbox,
    SocialTitle,
    StatusIcons,
    maximizeCard,
    externalLinkCard,
    viewsSvg,
    // CardActions,
    ItemCardImageWrapper
} from "components";
import { dateFormat } from "config";

export const ItemImageView = ( {
    img = null,
    imgCardAlt = "",
    cardURL = null,
    titleHeader = "",
    name = "",
    date = "",
    addClassName = "",
    views = "",
    status = "",
    children,
    imgHeader = null,
    titleHide = false,
    selectedCardsList = [],
    chooseCardToSelect = () => {},
    deleteOneCardsAction = () => {},
    showViewDetailsPanelAction = () => {},
    userPushToRouteAction = () => {},
    element = { id: null }
} ) => {
    const checked = selectedCardsList.indexOf( element.id ) !== -1;
    return (
        <ItemCardImageWrapper
            addClassName={addClassName}
            checked={checked}
            header={
                <>
                    <Checkbox
                        handleChange={( { value } ) => {
                            chooseCardToSelect( { value: value, id: element.id } );
                        }}
                        color={"#fff"}
                        defaultValue={checked}
                    />
                    <SocialTitle code={imgHeader} title={titleHeader} />
                    <div/>
                    {/*<CardActions*/}
                    {/*    color={"#fff"}*/}
                    {/*    id={element.id}*/}
                    {/*    deleteOneCardsAction={deleteOneCardsAction}*/}
                    {/*    editBtn={false}*/}
                    {/*/>*/}
                </>
            }
            body={
                <div className="img-box" style={img ? { background: `url(${img}) no-repeat center`, backgroundSize: "cover" } : {}}>
                    {children ? <div className="children-box">{children}</div> : null}
                </div>
            }
            description={
                <>
                    <div className="title-box">
                        {!titleHide ? (
                            <div className="title-description-card">
                                <span>{moment( date ).format( dateFormat )}</span>
                                <div>{name || "No title"}</div>
                            </div>
                        ) : null}
                    </div>
                    <div className="detail">
                        <div>
                            <StatusIcons status={status} tooltip={true} />
                        </div>
                        <span className="views">
                            {viewsSvg()}
                            {views || 0}
                        </span>
                    </div>
                </>
            }
            footer={
                <>
                    <div>
                        <a
                            href="/"
                            onClick={e => {
                                e.preventDefault();
                                if ( cardURL )
                                    userPushToRouteAction( {
                                        profileURL: cardURL,
                                        module: "posts"
                                    } );
                            }}
                        >
                            {maximizeCard()}
                        </a>
                    </div>
                    <button
                        type={"button"}
                        onClick={() => showViewDetailsPanelAction( element.id )}
                    >
                        View details
                    </button>
                    <div>
                        {cardURL ? (
                            <a href={cardURL} rel="noopener noreferrer" target="_blank">
                                {externalLinkCard()}
                            </a>
                        ) : null}
                    </div>
                </>
            }
        />
    );
};

export const getModuleFromUrl = () => window.location.pathname.split( "/" )[1];

// filter fixes input of e.g.: "admin/users/"
const splitUrl = url => url.split( "/" ).filter( Boolean );

export const getModuleStateFromUrl = pathname => {
    const url = splitUrl( pathname || window.location.pathname );

    // so that we can see if no module state is available yet, e.g. pathname input of "/admin"
    if ( url.length <= 1 ) return null;

    return url[1];
};

export function modifyURLByFiltersWorker( payload ) {
    const params = returnQueriesURL( payload );
    const newurl =
    window.location.protocol +
    "//" +
    window.location.host +
    window.location.pathname +
    ( params && params !== "" ? params : "" );
    window.history.pushState( { path: newurl }, "", newurl );
}

export function returnQueriesURL( payload ) {
    let params = "";
    let index = 1;
    for ( let key in payload ) {
        if ( key === "brand" || key === "limit" || key === "module" ) continue;
        if ( payload[key] ) {
            index === 1 ? ( params += "?" ) : ( params += "&" );
            params += `${key}=${encodeURIComponent( payload[key] )}`;
            index++;
        }
    }
    return params;
}

function addHttp( url ) {
    //eslint-disable-next-line
    if ( !/^(?:f|ht)tps?\:\/\//.test( url ) ) {
        url = "http://" + url;
    }

    return url;
}

function getLocation( href ) {
    //eslint-disable-next-line
    const match = href.match( /^(?:(https?\:)\/\/)?(([^:\/?#]*)(?:\:([0-9]+))?)([\/]{0,1}[^?#]*)(\?[^#]*|)(#.*|)$/ );

    return match && {
        href: href,
        protocol: match[1],
        host: match[2],
        hostname: match[3],
        port: match[4],
        pathname: match[5],
        search: match[6],
        hash: match[7]
    };
}

export function extractHostname( url ) {
    url = addHttp( url ); // otherwise this won't parse.

    const location = getLocation( url );

    return location ? location.hostname : url;
}

export function checkAndModifyURL ( objToArg, pathName ) {
    //Do not modify URL not subModule page
    const subModule =  pathName.split( "/" ).length > 3;
    if( !subModule ) modifyURLByFiltersWorker( objToArg );
}

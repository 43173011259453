import { connect } from "react-redux";
import { Dashboard } from "./Dashboard";
import { compose } from "redux";
import { errorsDecorator } from "components";

import { sideBarIsOpenSelector, activeModuleStateSelector } from "services/UserView/selectors";

const mapStateToProps = state => {
    const sideBarIsOpen = sideBarIsOpenSelector( state ) || false;
    const activeModuleState = activeModuleStateSelector( state ) || null;
    return {
        sideBarIsOpen,
        activeModuleState
    };
};

const mapDispatchToProps = {

};

export const SocialProtectDashboard = compose(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    ),
    errorsDecorator(),
)( Dashboard );

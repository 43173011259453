import { storeSideBarIsOpenAction, storeActiveModuleStateAction, storeActiveModuleAction } from "./actions";
import { handleActions } from "redux-actions";

const initialState = {
    sideBarIsOpen: false,
    activeModule: null
};

export const SideBarUserViewStateReducer = handleActions(
    {
        [storeSideBarIsOpenAction]: ( state, { payload } ) => ( {
            ...state,
            sideBarIsOpen: payload
        } ),
        [storeActiveModuleStateAction]: ( state, { payload } ) => {
            return {
                ...state,
                activeModuleState: payload
            };
        },
        [storeActiveModuleAction]: ( state, { payload } ) => {
            return {
                ...state,
                activeModule: payload
            };
        }
    },
    initialState
);

import { applyFilterIfUpdated } from "services/Filter/utils";

export const handleChangeLimitPage = ( currentLimit, newLimit, saveFilterAndSearchAction ) => {
    if ( currentLimit === Number( newLimit ) ) return;

    saveFilterAndSearchAction( { limit: newLimit, page: 1 } );
};

export const handlePageChange = ( activeFilter, selected, currentPage, saveFilterAndSearchAction ) => {
    if ( currentPage === selected + 1 ) return;

    applyFilterIfUpdated( activeFilter, "page", selected + 1, saveFilterAndSearchAction );
};
import { ApiCreator } from "utils/connected";
import { BASE_URL_API } from "config";
const api = ApiCreator( BASE_URL_API );

export const importFile = ( { brand = "1", data, module = null } = {} ) => {
    if ( !module )
        return Promise.reject( {
            message: "getEntries - Not exist module name in API call"
        } );
    module = module.slice( 0, -1 );
    let url = `generic/storage/${module}/bulkAdd`;
    return api.post( url, {
        body: JSON.stringify( { [module + "s"]: data } ),
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json"
        }
    } );
};

import { takeLatest, call, put, select } from "redux-saga/effects";
import { checkFieldAndSave, getCountItemsPerPage } from "utils";

import { serviceDefinition } from "./constants";

import {
    tableColumnSelectedChangeAction,
    tableViewChangeAction,
    tableSequenceColumnChangeAction,
    tableSequenceColumnPostsSaveAction,
    tableColumnPostsSelectedSaveAction,
    tableViewProfilesSaveAction,
    tableColumnProfilesSelectedSaveAction,
    tableSequenceColumnProfilesSaveAction,
    tableViewPostsSaveAction,
    pageListViewChangeAction,
    pageCardDisplayViewChangeAction,
    pageListViewPostsSaveAction,
    pageCardDisplayPostsViewSaveAction,
    pageListViewProfilesSaveAction,
    pageCardDisplayProfilesViewSaveAction,
    getNewDataWithChangingUserViewAction,
    itemViewDetailsPostsSaveAction,
    itemViewDetailsProfilesSaveAction,
    goToNextViewDetailsAction
} from "./actions";
import { storeSideBarIsOpenAction, showViewDetailsPanelAction } from "services/UserView/actions";

// Selectors
import { activeModuleStateSelector } from "services/UserView/selectors";
import { cardListDataSelector } from "pages/SocialProtect/services/Card/selectors";
import { activeFilterSelector } from "pages/SocialProtect/services/Options/selectors";

// Sagas
import { saveFilterAndSearchWorker } from "pages/SocialProtect/services/Options/sagas";
import { activeServiceValidationWrapper } from "services/UserView/sagas";

//WORKERS
function* fetchUserViewSettingsWorker() {
    //check localStorage SideBar
    const sideBar = localStorage.sideBar;
    if ( sideBar ) {
        const objData = JSON.parse( sideBar );
        if ( Object.prototype.hasOwnProperty.call( objData, "sideBarIsOpen" ) )
            yield put( storeSideBarIsOpenAction( objData.sideBarIsOpen ) );
    }

    //check localStorage Post
    const userDataPosts = localStorage.posts;
    if ( userDataPosts ) {
        const objData = JSON.parse( userDataPosts );
        if ( Object.prototype.hasOwnProperty.call( objData, "page_list_view" ) )
            yield put( pageListViewPostsSaveAction( objData.page_list_view ) );
        if ( Object.prototype.hasOwnProperty.call( objData, "customize_table_view" ) )
            yield put( tableViewPostsSaveAction( objData.customize_table_view ) );
        if ( Object.prototype.hasOwnProperty.call( objData, "list_enabled_item" ) )
            yield put( tableColumnPostsSelectedSaveAction( objData.list_enabled_item ) );
        if ( Object.prototype.hasOwnProperty.call( objData, "taskIds" ) )
            yield put( tableSequenceColumnPostsSaveAction( objData.taskIds ) );
        if ( Object.prototype.hasOwnProperty.call( objData, "card_display_view" ) )
            yield put( pageCardDisplayPostsViewSaveAction( objData.card_display_view ) );
    }

    //check localStorage Profiles
    const userDataProfiles = localStorage.profiles;
    if ( userDataProfiles ) {
        const objData = JSON.parse( userDataProfiles );
        if ( Object.prototype.hasOwnProperty.call( objData, "page_list_view" ) )
            yield put( pageListViewProfilesSaveAction( objData.page_list_view ) );
        if ( Object.prototype.hasOwnProperty.call( objData, "customize_table_view" ) )
            yield put( tableViewProfilesSaveAction( objData.customize_table_view ) );
        if ( Object.prototype.hasOwnProperty.call( objData, "list_enabled_item" ) )
            yield put(
                tableColumnProfilesSelectedSaveAction( objData.list_enabled_item )
            );
        if ( Object.prototype.hasOwnProperty.call( objData, "taskIds" ) )
            yield put( tableSequenceColumnProfilesSaveAction( objData.taskIds ) );
        if ( Object.prototype.hasOwnProperty.call( objData, "card_display_view" ) )
            yield put(
                pageCardDisplayProfilesViewSaveAction( objData.card_display_view )
            );
    }
}

function* tableViewChangeWorker( { payload } ) {
    const activeModuleState = yield select( activeModuleStateSelector );
    if ( activeModuleState === "posts" ) {
        yield put( tableViewPostsSaveAction( payload ) );
    } else if ( activeModuleState === "profiles" ) {
        yield put( tableViewProfilesSaveAction( payload ) );
    }
    checkFieldAndSave( "customize_table_view", payload, activeModuleState );
}

function* tableColumnSelectedChangeWorker( { payload } ) {
    const activeModuleState = yield select( activeModuleStateSelector );
    if ( activeModuleState === "posts" ) {
        yield put( tableColumnPostsSelectedSaveAction( payload ) );
    } else if ( activeModuleState === "profiles" ) {
        yield put( tableColumnProfilesSelectedSaveAction( payload ) );
    }
    checkFieldAndSave( "list_enabled_item", payload, activeModuleState );
}

function* pageCardDisplayViewChangeWorker( { payload } ) {
    const activeModuleState = yield select( activeModuleStateSelector );
    if ( activeModuleState === "posts" ) {
        yield put( pageCardDisplayPostsViewSaveAction( payload ) );
    } else if ( activeModuleState === "profiles" ) {
        yield put( pageCardDisplayProfilesViewSaveAction( payload ) );
    }
    //get perPage value
    const perPageValue = getCountItemsPerPage( payload );
    checkFieldAndSave( "card_display_view", payload, activeModuleState );
    //Make a new Api call with changing perPage value
    yield put( getNewDataWithChangingUserViewAction( perPageValue ) );
}

function* tableSequenceColumnsChangeWorker( { payload } ) {
    const activeModuleState = yield select( activeModuleStateSelector );
    if ( activeModuleState === "posts" ) {
        yield put( tableSequenceColumnPostsSaveAction( payload ) );
    } else if ( activeModuleState === "profiles" ) {
        yield put( tableSequenceColumnProfilesSaveAction( payload ) );
    }
    checkFieldAndSave( "taskIds", payload, activeModuleState );
}

export function* fetchUserViewSettings() {
    yield call( fetchUserViewSettingsWorker );
}

function* goToNextViewDetailsWorker( { payload: { index, mode } } ) {
    const activeModuleState = yield select( activeModuleStateSelector );
    const cardListData = yield select( cardListDataSelector );
    const lengthList = cardListData.results.length;
    if (
        ( mode === "++" && index === lengthList - 1 ) ||
    ( mode === "--" && index === 0 ) ||
    !mode
    )
        return;

    let id;
    if ( mode === "++" ) {
        id = cardListData.results[++index].id;
    } else {
        id = cardListData.results[--index].id;
    }

    if ( activeModuleState === "posts" ) {
        yield put( itemViewDetailsPostsSaveAction( id ) );
    } else if ( activeModuleState === "profiles" ) {
        yield put( itemViewDetailsProfilesSaveAction( id ) );
    }
}

function* getNewDataWithChangingUserViewWorker( { payload } ) {
    const activeFilters = yield select( activeFilterSelector );
    if ( payload.toString() !== activeFilters.limit.toString() ) {
        yield call( saveFilterAndSearchWorker, { payload: { limit: payload } } );
    }
}

function* pageListViewChangeWorker( { payload } ) {
    const activeModuleState = yield select( activeModuleStateSelector );

    if ( activeModuleState === "posts" ) {
        yield put( pageListViewPostsSaveAction( payload ) );
    } else if ( activeModuleState === "profiles" ) {
        yield put( pageListViewProfilesSaveAction( payload ) );
    }

    checkFieldAndSave( "page_list_view", payload, activeModuleState );
}

function* sPShowViewDetailsPanelWorker( { payload } ) {
    const activeModuleState = yield select( activeModuleStateSelector );

    if ( activeModuleState === "posts" ) {
        yield put( itemViewDetailsPostsSaveAction( payload ) );
    } else if ( activeModuleState === "profiles" ) {
        yield put( itemViewDetailsProfilesSaveAction( payload ) );
    }
}

// Watchers
export function* pageListViewChangeWatcher() {
    yield takeLatest( pageListViewChangeAction, pageListViewChangeWorker );
}

export function* tableViewChangeWatcher() {
    yield takeLatest( tableViewChangeAction, tableViewChangeWorker );
}
export function* tableColumnSelectedChangeWatcher() {
    yield takeLatest(
        tableColumnSelectedChangeAction,
        tableColumnSelectedChangeWorker
    );
}
export function* tableSequenceColumnsChangeWatcher() {
    yield takeLatest(
        tableSequenceColumnChangeAction,
        tableSequenceColumnsChangeWorker
    );
}

export function* pageCardDisplayViewChangeWatcher() {
    yield takeLatest(
        pageCardDisplayViewChangeAction,
        pageCardDisplayViewChangeWorker
    );
}

export function* goToNextViewDetailsWatcher() {
    yield takeLatest( goToNextViewDetailsAction, goToNextViewDetailsWorker );
}
export function* getNewDataWithChangingUserViewWatcher() {
    yield takeLatest(
        getNewDataWithChangingUserViewAction,
        getNewDataWithChangingUserViewWorker
    );
}
export function* sPShowViewDetailsPanelWatcher() {
    yield takeLatest(
        showViewDetailsPanelAction,
        activeServiceValidationWrapper,
        {
            ...serviceDefinition,
            worker: sPShowViewDetailsPanelWorker
        }
    );
}
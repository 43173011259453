import React from "react";
import { Checkbox, Avatar, StatusIcons } from "components/";
import "./CardListView.scss";

export const CardListView = ( { img="", name="", price="0", symbol="", active=false, id=null, handleClick=()=>{}, status=null } ) => {
    return (
        <div
            className={`card-list-view ${active ? "active" : ""}`}
            role="presentation"
            onClick={ () => handleClick( id ) }
        >
            <div className="check-block">
                <Checkbox
                    handleChange={() => {
                        // chooseAllCardAction( !selectedAllCardsState );
                    }}
                    defaultValue={false}
                />
            </div>
            <div className="image-block">
                <Avatar
                    data={[ img ]}
                    size={"80"}
                />
            </div>
            <div className="description-status">
                <div>
                    {status ? <StatusIcons status={status} text={true} type="card-status" /> : null}
                </div>
                <div className="title">{name}</div>
            </div>
            <div className="price">
                {price}{symbol}
            </div>
        </div>
    );
};

import React from "react";
import "./FooterWrapper.scss";

export const FooterWrapper = ( { left, center, right } ) => {
    return (
        <footer className="page-footer page-footer__-protect">
            <div>{left}</div>
            <div>{center}</div>
            <div>{right}</div>
        </footer>
    );
};

import { handleActions } from "redux-actions";
import {
    importDataSaveAction,
    saveErrorsAfterImportFileAction,
    importFileStateAction,
    dataAfterImportAction
} from "./actions";

const initialState = {
    importData: null,
    errorsImportData: null,
    importFileState: null,
    dataAfterImport: null
};

export const socialProtectImportDataReducer = handleActions(
    {
        [importDataSaveAction]: ( state, { payload } ) => {
            return {
                ...state,
                importData: payload
            };
        },
        [saveErrorsAfterImportFileAction]: ( state, { payload } ) => {
            return {
                ...state,
                errorsImportData: payload
            };
        },
        [importFileStateAction]: ( state, { payload } ) => {
            return {
                ...state,
                importFileState: payload
            };
        },
        [dataAfterImportAction]: ( state, { payload } ) => {
            return {
                ...state,
                dataAfterImport: payload
            };
        }
    },
    initialState
);

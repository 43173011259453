import React from "react";
import { App, Redirecter } from "components";
import { Route, Switch } from "react-router-dom";
import {
    NoMatch,
    SocialProtectDashboard,
    LandingDashboard,
    MarketProtectDashboard
} from "pages";
import { BASE_URL } from "config";

export const Routes = (
    <App>
        <Switch>
            <Route exact path="/" component={LandingDashboard} />
            <Route path="/market-protect" component={MarketProtectDashboard} />
            <Route path="/social-protect" component={SocialProtectDashboard} />
            <Route
                exact path="/login"
                render={ () => <Redirecter url={`${BASE_URL}/login`} /> }
            />
            <Route component={NoMatch} />
        </Switch>
    </App>
);
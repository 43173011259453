import React from "react";
import { loadMore } from "components";

export const LoadMore = ( { isLoadingMore, handleLoadMore } ) => {

    return (
        <div
            role={"presentation"}
            className={isLoadingMore ? "load-more loading" : "load-more"}
            onClick={isLoadingMore ? null : handleLoadMore}
        >
            {loadMore()} See more
        </div>
    );
};
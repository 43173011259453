import React from "react";
import "./SocialTitle.scss";

export const SocialTitle = ( { code = null, title = "title" } ) => {
    let img = null;
    return (
        <div className="title">
            {img ? (
                <img className="img-header-card" src={img.src} alt={img.title} />
            ) : null}
            {title}
        </div>
    );
};

import { call, put, select } from "redux-saga/effects";
import { activeBrandSelector } from "services/Brand/selectors";

export const generateLabelsWorker = ( labelsSelector, storeIsLoadingLabelsAction, fetchLabelWorker ) => {

    return function* baseLabelsWorker( activeModuleState ) {
        const activeBrand = yield select( activeBrandSelector );
        const labels = yield select( labelsSelector );

        if ( !activeBrand || !activeModuleState ) return null;

        if( !labels ) {
            yield put( storeIsLoadingLabelsAction( true ) );
            yield call( fetchLabelWorker, { activeBrand, activeModuleState }  );
            yield put( storeIsLoadingLabelsAction( false ) );
        }
    };
};



import React from "react";
import "./ItemCardWrapper.scss";

export const ItemCardWrapper = ( {
    header = null,
    body = null,
    footer = null
} ) => {
    return (
        <div className="card-item">
            <div className="card-item-header">{header}</div>
            <div className="card-item-body">{body}</div>
            <div className="card-item-footer">{footer}</div>
        </div>
    );
};

import { createSelector } from "reselect";

export const statusListSelector = createSelector(
    state => state.main.status.statusList,
    state => state
);

export const statusListLoaderSelector = createSelector(
    state => state.main.status.statusLoader,
    state => state
);

import { createAction } from "redux-actions";
import { moduleSlug as namespace } from "pages/MarketProtect/constants";


//make API call
export const populateCardsAction = createAction( `${namespace}/populateCardsAction` );
//loading
export const isLoadingEntriesAction = createAction( `${namespace}/isLoadingEntriesAction` );
//Save Data
export const saveEntriesAction = createAction( `${namespace}/saveEntriesAction` );
//Save ID Item for view details
export const saveIDItemSelectedAction = createAction( `${namespace}/saveIDItemSelectedAction` );
//Select cards
export const chooseCardToSelectAction = createAction( `${namespace}/chooseCardToSelectAction` );
export const chooseAllCardAction = createAction( `${namespace}/chooseAllCardAction` );
export const chooseAllCardSaveAction = createAction( `${namespace}/chooseAllCardSaveAction` );
export const selectedCardSaveAction = createAction( `${namespace}/selectedCardSaveAction` );
export const showDetailsMixedViewAction = createAction( `${namespace}/showDetailsMixedViewAction` );
//Delete all cards
export const deleteAllSelectedCardsAction = createAction( `${namespace}/deleteAllSelectedCardsAction` );
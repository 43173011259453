import { createSelector } from "reselect";
import { activeModuleStateSelector } from "services/UserView/selectors";

export const getCategorySelectionKey = category => `${category}Selection`;

export const isLoadingCardListSelector = createSelector(
    state => state.marketProtect.entries.isLoading,
    state => state
);

export const entriesOverviewSelector = createSelector(
    ( state, category ) => {
        if ( !category ) category = activeModuleStateSelector( state );

        return state.marketProtect.entries[category];
    },
    state => state
);

export const selectedEntriesSelector = createSelector(
    state => {
        const category = activeModuleStateSelector( state );

        return state.marketProtect.entries[getCategorySelectionKey( category )].arrID;
    },
    state => state
);

export const countAvailableEntriesSelector = createSelector(
    state =>  {
        const entries = entriesOverviewSelector( state );

        if ( !entries ) return 0;

        return entries.total;
    },
    state => state
);

export const countLoadedEntriesSelector = createSelector(
    state => {
        const entries = loadedEntriesSelector( state );

        if ( !entries ) return 0;

        return entries.length;
    },
    state => state
);

export const loadedEntriesSelector = createSelector(
    state => {
        const entries = entriesOverviewSelector( state );

        if ( !entries ) return null;

        return entries.items;
    },
    state => state
);

export const hasSelectedAllEntriesSelector = createSelector(
    state => {
        const category = activeModuleStateSelector( state );
        return state.marketProtect.entries[getCategorySelectionKey( category )].hasSelectedAll;
    },
    state => state
);

export const viewDetailItemDataSelector = createSelector(
    state => {
        const category = activeModuleStateSelector( state );
        const arrData = state.marketProtect.entries[category];
        const id = state.marketProtect.entries.ID_item_mixed_view;

        if( !id && arrData && arrData.items && arrData.items.length ) return arrData.items[0];
        if ( !id && ( !arrData || !arrData.items || ( arrData.items && !arrData.items.length ) ) ) return null;

        return arrData.items.find( e => e.id === id );
    },
    state => state
);

export const viewDetailItemSelector = createSelector(
    state => {
        const category = activeModuleStateSelector( state );
        const categoryState = state.marketProtect.entries[category];

        if ( !categoryState ) return null;

        const items = categoryState.items;
        const id = state.marketProtect.entries[getCategorySelectionKey( category )].itemIdViewDetail;

        if ( !id ) return null;

        const currentItem = items.find( e => e.id === id );

        return {
            data: currentItem,
            index: items.indexOf( currentItem ),
            lengthList: items.length
        };
    },
    state => state
);

export const isLoadingMoreSelector = createSelector(
    state => {

        return state.marketProtect.entries.isLoadingMore;
    },
    state => state
);
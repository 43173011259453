import React from "react";
import "./ListDetails.scss";

export const ListDetails = ( props ) => {
    const { data } = props;
    if ( !data ) return null;
    return (
        <ul className="list-info">
            {
                data.map( ( e, i ) =>(
                    <li key={i}>
                        <span className="list-title">{e.label}</span>
                        <span>{e.value || "No Data"}</span>
                    </li>
                ) )
            }
        </ul>
    );
};
import { handleActions } from "redux-actions";
import {
    storeFilterAction,
    incrementPageAction,
    selectEnforcementStatusInTopBarAction,
    selectInfringementTypeInTopBarAction
} from "./actions";
import {
    loadStartEnforcementAction,
    selectedPostMarkIdAction
} from "pages/MarketProtect/services/Enforcement/actions";

const initialCategoryState = {
    searchTitle: null,
    page: 1,
    limit: 20,
    platform: null,
    enforcement: null,
    listingId: null,
    profileId: null,
    profileUrl: null,
    batchUploadId: null,
    createdAtFrom: null,
    createdAtTo: null
};

const initialState = {
    startEnforcementLoader: false,
    selectedInfringementTypeId: null,
    selectedEnforcementStatusId: null,
    selectedPostMarkId: null,
    filter: {
        listings: { ...initialCategoryState },
        sellers: { ...initialCategoryState }
    }
};

export const marketProtectOptionsReducer = handleActions(
    {

        [incrementPageAction]: ( state, { payload: activeModuleState } ) => ( {
            ...state,
            filter: {
                ...state.filter,
                [activeModuleState]: {
                    ...state.filter[activeModuleState],
                    page: Number( state.filter[activeModuleState].page ) + 1
                }
            }
        } ),
        [storeFilterAction]: ( state, { payload, payload: { activeModuleState } } ) => {
            return {
                ...state,
                filter: {
                    ...state.filter,
                    [activeModuleState]: payload.payload
                }
            };
        },
        [selectInfringementTypeInTopBarAction]: ( state, { payload } ) => {
            return {
                ...state,
                selectedInfringementTypeId: payload
            };
        },
        [selectedPostMarkIdAction]: ( state, { payload } ) => {
            return {
                ...state,
                selectedPostMarkId: payload
            };
        },
        [selectEnforcementStatusInTopBarAction]: ( state, { payload } ) => {
            return {
                ...state,
                selectedEnforcementStatusId: payload
            };
        },
        [loadStartEnforcementAction]: ( state, { payload } ) => {
            return {
                ...state,
                startEnforcementLoader: payload
            };
        }
    },
    initialState,
);

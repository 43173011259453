import { takeLatest, call, put, select } from "redux-saga/effects";
import { history } from "services/Navigation/history";

//Actions
import { userPushToRouteAction, removeChipsAction } from "./actions";
import { savePostEntriesAction } from "pages/SocialProtect/services/Card/actions";
import { saveFilterAndSearchAction } from "pages/SocialProtect/services/Options/actions";
import { activeFilterSelector } from "pages/SocialProtect/services/Options/selectors";

// Sagas
import { saveFilterWorker } from "pages/SocialProtect/services/Options/sagas";

function* userPushToRouteWorker( { payload } ) {
    const { profileURL, module } = payload;
    if ( profileURL && module ) {
        yield put( savePostEntriesAction( {} ) );

        //save filter value
        yield call( saveFilterWorker, {
            payload: {
                profileUrl: payload.profileURL,
                moduleState: module,
                postMark: null,
                page: 1,
                selectedPostMarkId: null,
                selectedInfringementTypeId: null,
                platform: null,
                enforcement: null,
                orderDirection: "-createdAt",
                profileName: null,
                profileId: null,
                listingId: null,
                batchUploadId: null,
                createdAtFrom: null,
                createdAtTo: null
            }
        } );
        //make api call by filter values from listen
        //push to post module
        history.push( "/social-protect/posts" );
    }
}

function* removeAllChipsWorker( { payload } ) {
    const activeFilter = yield select( activeFilterSelector );
    const newFilter = { ...activeFilter };
    if( payload ){
        newFilter[payload] = null;
    }else{
        for( let key in activeFilter ){
            if( activeFilter[key] && key !== "page" && key !== "limit" && key !== "orderDirection" ){
                newFilter[key] = null;
            }
        }
    }
    yield put( saveFilterAndSearchAction( newFilter ) );
}

export function* userPushToRouteWatcher() {
    yield takeLatest( userPushToRouteAction, userPushToRouteWorker );
}

export function* removeChipsWatcher() {
    yield takeLatest( removeChipsAction, removeAllChipsWorker );
}

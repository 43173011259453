import React from "react";
import { StatusIcons, ItemCardImageSmallWrapper } from "components";
import { tikSvg } from "components";

export const ItemImageViewSmall = ( {
    img = null,
    addClassName = "",
    status = "",
    chooseCardToSelect = () => {},
    element = { id: null },
    selectedCardsList = []
} ) => {
    return (
        <ItemCardImageSmallWrapper
            id={element.id}
            addClassName={addClassName}
            onChange={chooseCardToSelect}
            checked={selectedCardsList.indexOf( element.id ) !== -1}
            body={
                <>
                    <div className="img-box" style={{ background: `url(${img[0]}) no-repeat center`, backgroundSize: "cover" }}/>
                    <div className="svg-box">{tikSvg()}</div>
                </>
            }
            footer={<StatusIcons status={status} tooltip={true} />}
        />
    );
};

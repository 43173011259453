import {
    pageListViewMPSaveAction,
    pageCardDisplayMPViewSaveAction
} from "./actions";
import { handleActions } from "redux-actions";
import { Map } from "immutable";
import { columnsListing, columnsSellers, listEnabledItem } from "pages/MarketProtect/services/Entries/models";

const initialState = {
    listings: {
        page_list_view: "block",
        page_card_display_view: "block-description-x1",
        customize_table_view: {
            view: "large",
            list_enabled_item: listEnabledItem,
            columns: columnsListing
        }
    },
    sellers: {
        page_list_view: "block",
        page_card_display_view: "block-description-x1",
        customize_table_view: {
            view: "large",
            list_enabled_item: listEnabledItem,
            columns: columnsSellers
        }
    }
};

export const marketProtectUserViewReducer = handleActions(
    {
        // Post
        [pageListViewMPSaveAction]: ( state, { payload } ) => {
            const { data, activeModuleState } = payload;
            const newObjectPosts = Map( state[activeModuleState] ).toJS();
            newObjectPosts.page_list_view = data;
            return {
                ...state,
                [activeModuleState]: newObjectPosts
            };
        },
        [pageCardDisplayMPViewSaveAction]: ( state, { payload } ) => {
            const { data, category } = payload;
            return {
                ...state,
                [category]: {
                    ...state[category],
                    page_card_display_view: data
                }
            };
        }
    },
    initialState,
);
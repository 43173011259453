import React from "react";
import { addIcon } from "components/Icons/Svg";
import "./Button.scss";

export const Button = props => {
    const {
        children,
        text = null,
        type = null,
        id = null,
        color = null,
        size = "large",
        add = false,
        buttonClassName = null,
        handleClick = () => {},
        disabled = false,
        typeBtn = "button"
    } = props;
    let classNameButton = "";
    let colorIcon = null;
    //btn type
    if ( type === "circle" ) {
        classNameButton += "btn-type-circle";
    } else {
        classNameButton += "btn-type-default";
    }
    //btn color
    if ( color === "secondary" ) {
        classNameButton += " btn-color-secondary";
        colorIcon = "#546369";
    } else {
        classNameButton += " btn-color-primary";
        colorIcon = "#fff";
    }
    //btn size
    if ( size === "large" ) {
        classNameButton += " btn-size-large";
    } else if ( size === "small" ) {
        classNameButton += " btn-size-small";
    }
    //add className if needed
    if ( buttonClassName ) classNameButton += " " + buttonClassName;
    const idf = id => ( id ? { id: id } : {} );
    return (
        <button
            {...idf( id )}
            type={typeBtn}
            className={classNameButton}
            disabled={disabled}
            onClick={handleClick}
        >
            {children}
            {text}
            {add ? addIcon( colorIcon ) : null}
        </button>
    );
};

import React, { PureComponent } from "react";
import { DefaultGridView } from "pages/Landing/components";
import { modules } from "services/Navigation/models";

export class PageRoute extends PureComponent {
    getRoute = () => {
        const { activeGridView } = this.props;

        switch ( activeGridView ) {
            case "default":
                return <DefaultGridView modules={modules} />;

            default:
                return <DefaultGridView modules={modules} />;
        }
    };

    render() {
        return (
            <div className="landingContainer">
                {this.getRoute()}
            </div>
        );
    }
}

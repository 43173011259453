import React from "react";
import {
    ProfileCardItem
} from "pages/SocialProtect/components";

import {
    EnhancedTable
} from "components";
import { chooseItemByFieldName } from "pages/SocialProtect/chooseItemByFieldName";
import {
    ItemImageView,
    ItemImageViewSmall,
    ItemCardView
} from "pages/SocialProtect/components";
import { getCells } from "utils";
import "./CardView.scss";

export const CardView = ( {
    cardListState,
    pageViewState,
    tableViewState,
    chooseCardToSelectAction,
    selectedCardsListState,
    chooseAllCardAction,
    selectedAllCardsState,
    pageCardDisplayViewState,
    deleteOneCardsAction,
    activeModuleState,
    showViewDetailsPanelAction,
    activeFilter,
    changeOrderDirectionAction,
    userPushToRouteAction
} ) => {
    if ( !cardListState || ( cardListState && !cardListState.results ) ) return null;
    if ( cardListState && cardListState.results && !cardListState.results.length )
        return <div className="col-lg-12">No Items...</div>;

    const cells = getCells( tableViewState, activeModuleState );

    if ( pageViewState === "row" ) {
        return (
            <div className={"row"}>
                <div className="col-lg-12 col-md-12">
                    <EnhancedTable
                        //props check box
                        check={ true }
                        handleChange={() => { chooseAllCardAction( !selectedAllCardsState );}}
                        defaultValue={selectedAllCardsState}
                        //props THEAD
                        cells={ cells }
                        activeModuleState={ activeModuleState }
                        chooseItemByFieldName={ chooseItemByFieldName }
                        orderDirection={ activeFilter.orderDirection }
                        createSortHandler={ changeOrderDirectionAction }
                        //props TBODY
                        bodyCells={ cardListState.results }
                        deleteOneCardsAction={ deleteOneCardsAction }
                        chooseCardToSelect={ chooseCardToSelectAction }
                        selectedCardsList={ selectedCardsListState }
                        showViewDetailsPanelAction={ showViewDetailsPanelAction }
                        userPushToRouteAction={ userPushToRouteAction }
                        tableView={ tableViewState.view }
                    />
                </div>
            </div>
        );
    } else {
        return ( <div className={ `row ${pageCardDisplayViewState}` }>{(
            cardListState.results.map( ( e, i ) => {
                const props = {
                    element: e,
                    img: e.listingImage || e.profileImage,
                    cardURL: e.listingURL || e.profileURL,
                    titleHeader: e.platform.name,
                    name: e.listingTitle || e.profileName || "No Title",
                    date: e.createdAt,
                    text: e.profileDescription || e.listingDescription || "No description",
                    profile: e.profileName || ( e.profile && e.profile.profileName ) || "",
                    posts: e.profilePosts || ( e.profile && e.profile.profilePosts ) || "0",
                    views: e.profilePosts || ( e.profile && e.profile.profileViews ) || "0",
                    imgHeader: e.platform.code,
                    status: {
                        mark: ( e.mark && e.mark.name ) || null,
                        infringementType:
                            ( e.infringementType && e.infringementType.name ) || null,
                        enforcementStatus:
                            ( e.enforcementStatus && e.enforcementStatus.name ) || null
                    },
                    chooseCardToSelect: chooseCardToSelectAction,
                    selectedCardsList: selectedCardsListState,
                    deleteOneCardsAction: deleteOneCardsAction,
                    showViewDetailsPanelAction: showViewDetailsPanelAction,
                    userPushToRouteAction: userPushToRouteAction,
                    followers: e.profileFollowers || "0"
                };
                if ( activeModuleState === "profiles" ) {
                    return (
                        <div className="col-lg-4 col-md-4" key={i}>
                            <ProfileCardItem {...props} />
                        </div>
                    );
                }
                if ( pageCardDisplayViewState === "block-description-x1" ) {
                    return (
                        <div className="col-lg-4 col-md-4" key={i}>
                            <ItemCardView {...props} />
                        </div>
                    );
                }
                if ( pageCardDisplayViewState === "block-description-x2" ) {
                    return (
                        <div className="col-lg-3 col-md-3" key={i}>
                            <ItemCardView {...props} />
                        </div>
                    );
                }
                if ( pageCardDisplayViewState === "block-img-x1" ) {
                    return (
                        <div className="col-lg-4 col-md-3" key={i}>
                            <ItemImageView {...props} />
                        </div>
                    );
                }
                if ( pageCardDisplayViewState === "block-img-x2" ) {
                    return (
                        <div key={i}>
                            <ItemImageView
                                addClassName={"small"}
                                {...props}
                                titleHide={true}
                            />
                        </div>
                    );
                }
                if ( pageCardDisplayViewState === "block-img-x3" ) {
                    return (
                        <div className="card-image-view-small-wrapper" key={i}>
                            <ItemImageViewSmall {...props} />
                        </div>
                    );
                }
                return (
                    <div className="col-lg-4 col-md-4" key={i}>
                        <ItemCardView {...props} />
                    </div>
                );
            } )
        )}</div> );
    }
};

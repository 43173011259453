import { connect } from "react-redux";
import { PageRoute as component } from "./PageRoute";

//actions
import {
    saveFilterAndSearchAction
} from "pages/MarketProtect/services/Options/actions";
import { pageListViewChangeMPAction } from "pages/MarketProtect/services/UserView/actions";
import { showViewDetailsPanelMPAction } from "pages/MarketProtect/services/UserView/actions";
import {
    chooseCardToSelectAction,
    chooseAllCardAction,
    showDetailsMixedViewAction
} from "pages/MarketProtect/services/Card/actions";

//selectors
import {
    activeFilterSelector
} from "pages/MarketProtect/services/Options/selectors";
import {
    statusListSelector,
    statusListIsLoadingSelector
} from "pages/MarketProtect/services/Status/selectors";
import {
    activeModuleStateSelector
} from "services/UserView/selectors";
import { activeBrandSelector } from "services/Brand/selectors";
import {
    pageViewStateSelector,
    customizeTableViewSelector,
    pageCardDisplayViewSelector
} from "pages/MarketProtect/services/UserView/selectors";
import {
    isLoadingCardListSelector,
    entriesOverviewSelector,
    selectedEntriesSelector,
    hasSelectedAllEntriesSelector,
    viewDetailItemDataSelector,
    isLoadingMoreSelector,
    countAvailableEntriesSelector,
    countLoadedEntriesSelector
} from "pages/MarketProtect/services/Entries/selectors";
import {
    loadMoreAction
} from "pages/MarketProtect/services/Entries/actions";
import {
    filterEntriesLimitSelector,
    filterPageNumberSelector
} from "pages/MarketProtect/services/Options/selectors";
import {
    platformListLoaderSelector,
    platformListSelector
} from "services/Platform/selectors";

import { applyFilterIfUpdated } from "services/Filter/utils";
import { handleChangeLimitPage, handlePageChange } from "services/Navigation/utils";

const mapDispatchToProps = {
    loadMoreAction,
    chooseCardToSelectAction,
    saveFilterAndSearchAction,
    pageListViewChangeMPAction,
    showViewDetailsPanelMPAction,
    chooseAllCardAction,
    showDetailsMixedViewAction
};

const mapStateToProps = state => {
    const statusListState = statusListSelector( state ) || null;
    const statusListIsLoadingState = statusListIsLoadingSelector( state ) || null;
    const activeModuleState = activeModuleStateSelector( state ) || null;
    const isLoadingCardListState = isLoadingCardListSelector( state ) || false;
    const entries = entriesOverviewSelector( state ) || null;
    const activeFilter = activeFilterSelector( state ) || false;
    const platformListLoaderState = platformListLoaderSelector( state );
    const platformListState = platformListSelector( state );
    const pageViewState = pageViewStateSelector( state );
    const activeBrandState = activeBrandSelector( state );
    const tableViewState = customizeTableViewSelector( state );
    const selectedCardsListState = selectedEntriesSelector( state );
    const selectedAllCardsState = hasSelectedAllEntriesSelector( state );
    const viewDetailItemDataState = viewDetailItemDataSelector( state );
    const pageCardDisplayViewState = pageCardDisplayViewSelector( state );
    const isLoadingMore = isLoadingMoreSelector( state );
    const countAvailableEntries = countAvailableEntriesSelector( state );
    const countLoadedEntries = countLoadedEntriesSelector( state );
    const filterEntriesLimit = filterEntriesLimitSelector( state );
    const filterPageNumber = filterPageNumberSelector( state );

    return {
        statusListState,
        pageCardDisplayViewState,
        statusListIsLoadingState,
        activeModuleState,
        isLoadingCardListState,
        entries,
        filterEntriesLimit,
        filterPageNumber,
        activeFilter,
        platformListLoaderState,
        platformListState,
        isLoadingMore,
        activeBrandState,
        pageViewState,
        countAvailableEntries,
        countLoadedEntries,
        tableViewState,
        selectedCardsListState,
        selectedAllCardsState,
        viewDetailItemDataState
    };
};

const mergeProps = ( stateProps, dispatchProps, ownProps ) => {
    const { saveFilterAndSearchAction } = dispatchProps;
    const { activeFilter, entries } = stateProps;
    const pageLimit = entries ? entries.limit : null;
    const currentPage = entries ? entries.page : null;

    return{
        ...stateProps,
        ...dispatchProps,
        ...ownProps,
        handleChangeLimitPage: ( newLimit ) => handleChangeLimitPage( pageLimit, newLimit, saveFilterAndSearchAction ),
        handlePageChange: ( { selected } ) => handlePageChange( activeFilter, selected, currentPage, saveFilterAndSearchAction ),
        handleChangeFilter: ( label, value ) => {
            applyFilterIfUpdated( activeFilter, label, value, saveFilterAndSearchAction );
        }
    };
};

export const PageRoute = connect(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps
)( component );

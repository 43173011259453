import React, { useCallback } from "react";
import { useDropzone } from "react-dropzone";
import "./ReactDropZone.scss";

export function ReactDropZone( { getFileData, className, title, svg } ) {
    const onDrop = useCallback(
        acceptedFiles => {
            const getFiles = getFileData;

            // Do something with the files
            getFiles && typeof getFiles === "function" && getFiles( acceptedFiles );
        },
        [ getFileData ]
    );
    const { getRootProps, getInputProps, isDragActive } = useDropzone( { onDrop } );

    return (
        <div {...getRootProps()} className={className}>
            <input {...getInputProps()} />
            {isDragActive ? (
                <div>
                    {svg ? <div className="svg">{svg}</div> : null}
          Drop the files here ...
                </div>
            ) : (
                <div>
                    {svg ? <div className="svg">{svg}</div> : null}
                    {title ? <div className="title">{title}</div> : null}
                    <i>Drag 'n' drop some files here, or click to select files</i>
                </div>
            )}
        </div>
    );
}

import { takeLatest, call, put, select } from "redux-saga/effects";
import * as BrandAPI from "./api";
import {
    saveBrandsAction,
    addBrandToFavoritesAction,
    saveBrandsFavoriteListAction,
    saveActiveBrandAction,
    storeActiveBrandAction,
    activeBrandSwitchedAction,
    isSwitchingBrandAction,
    isLoadingBrandsAction,
    activeBrandConflictErrorAction
} from "./actions";
import {
    brandsFavoriteListSelector,
    activeBrandSelector,
    isSwitchingBrandSelector,
    brandsSelector
} from "./selectors";

import { setAlertModalIsOpenAction, setAlertModalConfigurationAction } from "services/Modal/actions";

import { store } from "index";

// UTILS
function saveInLocalStorage( field, value ) {
    localStorage[field] = JSON.stringify( value );
}

//WORKERS
function getBrandFromLocalStorage() {
    return localStorage.brandsActive ? JSON.parse( localStorage.brandsActive ) : null;
}

function* initialiseBrands() {
    const storedBrand = getBrandFromLocalStorage();

    yield call( loadBrands );
    yield call( loadFavoritesFromLocalStorage );
    const response = yield call( BrandAPI.getActiveBrand );
    const defaultBrand = "1";
    let sessionBrand;

    if ( response ) {
        sessionBrand = Number( response.data.id );

        if ( storedBrand && Number( sessionBrand ) !== Number( storedBrand ) ) {
            yield put( activeBrandConflictErrorAction( { sessionBrand, storedBrand } ) );
        } else if ( Number( sessionBrand ) === Number( storedBrand ) ) {
            yield put( saveActiveBrandAction( { id: storedBrand, shouldUpdateSession: false } ) );
        } else {
            yield put( saveActiveBrandAction( { id: storedBrand || defaultBrand, shouldUpdateSession: true } ) );
        }
    }
}

function* loadFavoritesFromLocalStorage() {
    const userDataBrandsFavorite = localStorage.brandsFavorite;

    if ( userDataBrandsFavorite ) {
        const objData = JSON.parse( userDataBrandsFavorite );
        yield put( saveBrandsFavoriteListAction( objData ) );
    }
}

function* loadBrands() {
    yield put( isLoadingBrandsAction( true ) );
    //Get brands list from API
    const activeBrand = yield select( activeBrandSelector );
    const response = yield call( BrandAPI.getBrands, activeBrand );
    if ( response && response.brands ) yield put( saveBrandsAction( response.brands ) );
    yield put( isLoadingBrandsAction( false ) );
}

function* addBrandToFavoritesWorker( { payload } ) {
    const brandsFavoriteList = yield select( brandsFavoriteListSelector );
    let newArr = [ ...brandsFavoriteList ];
    if ( newArr.indexOf( payload ) === -1 ) {
        newArr.push( payload );
    } else {
        newArr = newArr.filter( el => el !== payload );
    }
    saveInLocalStorage( "brandsFavorite", newArr );
    yield put( saveBrandsFavoriteListAction( newArr ) );
}


function* brandIdToName( id ) {
    if ( !id ) return null;

    const brands = yield select( brandsSelector );
    const brand = brands ? brands.find( brand => Number( brand.id ) === Number( id ) ) : null;

    return brand ? brand.name : null;
}

// This triggers when the front-end and back-end have a disagreement about the user's active brand
// "storedBrand" can either be local storage or from redux store
function* resolveActiveBrandConflictWorker( { payload: { sessionBrand, storedBrand } } ) {
    const isSwitchingBrand = yield select( isSwitchingBrandSelector );
    if ( isSwitchingBrand ) return;

    const sessionBrandName = yield call( brandIdToName, sessionBrand, storedBrand );
    const storedBrandName = yield call( brandIdToName, storedBrand );

    /*
        We set isSwitchingBrand to true so that this worker only triggers once.
        Upon accepting or rejecting, we handle the choice and set it back to false.
    */
    yield put( isSwitchingBrandAction( true ) );
    yield put( setAlertModalConfigurationAction( {
        title: "Active brand has changed",
        text: `The active brand in your session has changed, do you want to reload the page to switch
            from ${ storedBrandName ?  storedBrandName : "your current brand"}
            to ${ sessionBrandName ? `${sessionBrandName}` : "the other brand"}?
        `,
        acceptanceLabel: "Switch my brand",
        rejectionLabel: "Stay on current brand",
        // @TODO: This seems to work as intended, but putting functions in the Redux store is a trade-off and usually not recommended
        // Potentially better: https://stackoverflow.com/questions/35325195/should-i-store-function-references-in-redux-store
        onAccept: () => {
            store.dispatch( saveActiveBrandAction( { id: sessionBrand, shouldUpdateSession: false } )  );
        },
        onReject: async () => {
            /*
                @TODO: It would be great not to have to send out the saveActiveBrandAction for this usecase

                If I could just do the two steps I commented out before, the page wouldn't need to reload.
                The problem with that is that when the brand was switched, and I open a submodule,
                then the response data will be for the brand that the server has loaded. Thus, I need to reload the page with new data anyway.
                Would not be an issue if I send the intended brand with each request.

                //await BrandAPI.setActiveBrand( storedBrand );
                //store.dispatch( isSwitchingBrandAction( false ) );
            */

            store.dispatch( saveActiveBrandAction( { id: storedBrand, shouldUpdateSession: true } )  );
        }
    } ) );
    yield put( setAlertModalIsOpenAction( true ) );
}


function* chooseBrandAsActiveWorker( { payload: { id, shouldUpdateSession } } ) {
    if ( !id ) id = "1";

    yield put( isSwitchingBrandAction( true ) );
    saveInLocalStorage( "brandsActive", id );

    if ( shouldUpdateSession ) {
        const response = yield call( BrandAPI.setActiveBrand, id );

        if ( !response ) {
            console.warn( "No response from updating brand in session" );
            yield put( isSwitchingBrandAction( false ) );
            return;
        }
    }
    yield put( storeActiveBrandAction( id ) );
    yield put( activeBrandSwitchedAction( id ) );
    yield put( isSwitchingBrandAction( false ) );
}

// WATCHERS
export function* brandsInitialisationWatcher() {
    yield call( initialiseBrands );
}
export function* addBrandToFavoritesWatcher() {
    yield takeLatest( addBrandToFavoritesAction, addBrandToFavoritesWorker );
}
export function* chooseActiveBrandWatcher() {
    yield takeLatest( saveActiveBrandAction, chooseBrandAsActiveWorker );
}
export function* activeBrandConflictErrorActionWatcher() {
    yield takeLatest( activeBrandConflictErrorAction,  resolveActiveBrandConflictWorker );
}

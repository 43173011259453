import React from "react";
import { NavLink } from "react-router-dom";
import "./ModuleCard.scss";

export const ModuleCard = ( {
    className,
    identifier,
    prodPath,
    localPath,
    icon,
    label,
    description,
    preferLocalPath
} ) => {
    const EntryBody = () => (
        <div>
            <div className="ModuleCard__header">
                <div className="row">
                    <div className="col svg-icon">
                        {icon}
                    </div>
                </div>

                <div className="row">
                    <div className="col">{label}</div>
                </div>
            </div>

            <div className="description row">
                <div className="col">{description}</div>
            </div>
        </div>
    );


    return (
        <div className={`ModuleCard ${className}`}>
            <div>
                {
                    preferLocalPath && localPath
                        ?
                        <NavLink to={localPath} activeClassName="active">
                            <EntryBody />
                        </NavLink>
                        :
                        <a href={prodPath}>
                            <EntryBody />
                        </a>
                }
            </div>
        </div>
    );
};
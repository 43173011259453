import React from "react";
import "./OptionsTopBarWrapper.scss";

export const OptionsTopBarWrapper = ( { background, wrapperClassName, body } ) => {
    return (
        <>
            { background }
            <div className={wrapperClassName}>
                { body }
            </div>
        </>
    );
};

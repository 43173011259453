import React from "react";
// import moment from "moment";
// import { dateFormat } from "config";

export const Overview = ( { data } ) => {
    return (
        <div className="info-wrapper">
            <div className="statistic">
                <div>Rating:<span>{data.rating}</span></div>
                <div>Fake:<span>{data.descriptionAnalyzer.probability}</span></div>
                <div>Condition:<span>{}</span></div>
                <div>Quantity:<span>{}</span></div>
            </div>
            <ul className="list-info">
                <li>
                    <span className="list-title">Platform</span>
                    <span>{data.descriptionAnalyzer.label}</span>
                </li>
                <li>
                    <span className="list-title">Listing ID</span>
                    <span>{data.descriptionAnalyzer.label}</span>
                </li>
                <li>
                    <span className="list-title">Country</span>
                    <span>{data.descriptionAnalyzer.label}</span>
                </li>
                <li>
                    <span className="list-title">Seller</span>
                    <span>{data.descriptionAnalyzer.label}</span>
                </li>
                <li>
                    <span className="list-title">Listing URL</span>
                    <span>{data.descriptionAnalyzer.label}</span>
                </li>
                <li>
                    <span className="list-title">Screenshots</span>
                    <span>{data.descriptionAnalyzer.label}</span>
                </li>
                <li>
                    <span className="list-title">Listing condition</span>
                    <span>{data.descriptionAnalyzer.label}</span>
                </li>
                <li>
                    <span className="list-title">Listing summary</span>
                    <span>{data.descriptionAnalyzer.label}</span>
                </li>
            </ul>
        </div>
    );
};

import { createSelector } from "reselect";

export const sideBarIsOpenSelector = createSelector(
    state => state.main.userView.sideBarIsOpen,
    state => state
);

export const activeModuleStateSelector = createSelector(
    state => state.main.userView.activeModuleState,
    state => state
);
export const activeModuleSelector = createSelector(
    state => state.main.userView.activeModule,
    state => state
);

export const detailItemIdentifierSelector = (
    state,
    activeModule,
    activeModuleState
) => {
    const store = state[activeModule][activeModuleState];
    const key = store.item_view_detail_key;
    const value = store.item_view_detail_value;

    return { key, value };
};

export const detailItemSelector = ( state, activeModule, activeModuleState ) => {
    const store = state[activeModule][activeModuleState];
    const data = store.data;

    if ( !data ) return null;

    const { key, value } = detailItemIdentifierSelector(
        state,
        activeModule,
        activeModuleState
    );

    return data.find( item => item[key] === value );
};

import { createSelector } from "reselect";

export const exportModalSelector = createSelector(
    state => state.modal.exportDataModalIsOpen,
    state => state
);

export const importModalSelector = createSelector(
    state => state.modal.importDataModalIsOpen,
    state => state
);

export const alertModalIsOpenSelector = createSelector(
    state => state.modal.alertModalIsOpen,
    state => state
);
export const alertModalConfigurationSelector = createSelector(
    state => state.modal.alertModalConfiguration,
    state => state
);

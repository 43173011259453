import React, { PureComponent } from "react";
import { Loader, OutlinedChips, PaginationFooter } from "components";
import { CardView, Chips, FilterCustomize } from "./components/";

const unnecessaryFields = [
    "brand",
    "module",
    "page",
    "limit",
    "orderDirection"
];

export class PageRoute extends PureComponent {
    render() {
        const {
            cardListState,
            activeModuleState,
            pageViewState,
            pageListViewChangeAction,
            cardListLoaderState,
            loadMoreAction,
            isLoadingMore,
            activeFilter,
            statusListIsLoadingState,
            platformListState,
            platformListIsLoadingState,
            activeBrandState,
            statusListState,
            handleChangeLimitPage,
            handleChangeFilter,
            handlePageChange,
            labelsState,
            removeChipsAction,
            activeFilterAppliedState
        } = this.props;
        const dataChips = {
            platform: platformListState,
            postMark: labelsState,
            enforcement: statusListState
        };
        const clearAll = Object.keys( activeFilterAppliedState ).filter(
            ( e ) => !unnecessaryFields.includes( e ) && activeFilterAppliedState[e]
        ).length > 1;
        return (
            <div>
                {/*FilterCustomize start*/}
                <FilterCustomize
                    activeModuleState={activeModuleState}
                    cardListState={cardListState}
                    activeView={pageViewState}
                    pageViewChange={pageListViewChangeAction}
                    activeFilter={activeFilter}
                    platformListIsLoadingState={platformListIsLoadingState}
                    platformListState={platformListState}
                    statusListIsLoadingState={statusListIsLoadingState}
                    statusListState={statusListState}
                    handleChangeLimitPage={handleChangeLimitPage}
                    handleChangeFilter={handleChangeFilter}
                />
                {/*FilterCustomize end*/}

                {/*OutlinedChips start*/}
                <OutlinedChips
                    onCloseAll={ () => removeChipsAction( null ) }
                    clearAll = {clearAll}
                >
                    <Chips
                        dataChips={dataChips}
                        unnecessaryFields={unnecessaryFields}
                        removeChipsAction={removeChipsAction}
                        activeFilter={activeFilterAppliedState}
                    />
                </OutlinedChips>
                {/*OutlinedChips end*/}

                {/*Mapping Cards start*/}
                {cardListLoaderState || !activeBrandState ? (
                    <div className="col-lg-12">
                        <Loader style={{ margin: "20% auto" }} />
                    </div>
                ) : cardListState ? (
                    <CardView {...this.props} />
                ) : null}
                {/*Mapping Cards end*/}

                {/*Footer start*/}
                {!cardListLoaderState && cardListState ? (
                    <PaginationFooter
                        handlePageChange={handlePageChange}
                        handleLoadMore={loadMoreAction}
                        isLoadingMore={isLoadingMore}
                        countAvailable={cardListState.totalCount}
                        countLoaded={cardListState.results ? cardListState.results.length : 0}
                        limit={cardListState.limit}
                        page={cardListState.page}
                    />
                ) : null}
                {/*Footer end*/}
            </div>
        );
    }
}

import React, { Component } from "react";
import { connect } from "react-redux";
import { globalErrorSelector } from "services/Error/selectors";
import { Redirecter, Loader } from "components";
import { BASE_URL } from "config";

const styleDiv = {
    margin: "100px 0",
    fontSize: "25px",
    textAlign: "center",
    color: "#3d51db"
};

export const Error = ( {
    title,
    error,
    action,
    description,
    shouldShowLoader
} ) => (
    <div style={styleDiv}>
        <div className="info-box">
            { title && <div className="big-title">{title}</div> }
            {error && error.errorMessage && (
                <div className="error-text">
                    Message: {error.errorMessage || ""}
                </div>
            )}
            {
                description &&
                <>
                    <br />
                    <div className="error-text">
                        {description}
                    </div>
                </>
            }
            { action &&
                <>
                    <br />
                    <div className="action-box">
                        <div className="link common-link">{action}</div>
                    </div>
                </>
            }
            { shouldShowLoader && <Loader style={{ margin: "auto", marginTop: "1em" }} /> }
        </div>
    </div>
);


// Detect when user is navigating away from the app
let isNavigatingExternally = false;
window.addEventListener( "beforeunload", function ( e ) {
    isNavigatingExternally = true;
} );
window.addEventListener( "unload", function( e ) {
    isNavigatingExternally = false;
} );

export const errorsDecorator = () => WrappedComponent => {
    class ErrorsDecoratorDisplay extends Component {
        render() {
            const { error } = this.props;

            /*
                If we are navigating away from our app, don't show an error.

                Could also just show the wrapped component so the user can still abort,
                but couldn't figure out how to listen for the window.stop() action (when clicking stop loading in the browser) event,
                so showing the loader instead.
            */
            if ( isNavigatingExternally ) return <Error shouldShowLoader={true} />;

            if ( error ) {
                if ( error.errorCode === 401 ) {
                    return <Redirecter
                        message={
                            <Error
                                title="Sorry! Something went wrong."
                                description="You weren't logged in anymore."
                                action="Redirecting you to the login page"
                                shouldShowLoader={true}
                            />
                        }
                        url={`${BASE_URL}/login`}
                        timeout={3000}
                    />;
                }

                return <Error
                    title="Sorry! Something went wrong."
                    error={error}
                    description="This shouldn't have happened. Please ask your administrator for assistance."
                    action="Try Again"
                />;
            }

            return <WrappedComponent {...this.props} />;
        }
    }

    const mapStateToProps = state => {
        const error = globalErrorSelector( state ) || null;
        return {
            error
        };
    };

    const ErrorsDecorator = connect(
        mapStateToProps,
        null
    )( ErrorsDecoratorDisplay );

    return ErrorsDecorator;
};

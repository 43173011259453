import { handleActions } from "redux-actions";

import { storeIsLoadingLabelsAction, storeLabelsAction } from "./actions";
import { initialStateLabelService } from "services/Label/reducer";

const initialState = {
    ...initialStateLabelService,
    listings: null
};

export const marketProtectLabelReducer = handleActions(
    {
        [storeIsLoadingLabelsAction]: ( state, { payload } ) => ( {
            ...state,
            isLoadingLabels: payload
        } ),
        [storeLabelsAction]: ( state, { payload: { labels, activeModuleState } } ) => ( {
            ...state,
            [activeModuleState]: labels
        } )
    },
    initialState
);
import { combineReducers } from "redux";

import { BrandReducer } from "services/Brand/reducer";
import { CountryReducer } from "services/Country/reducer";
import { StatusReducer } from "services/Status/reducer";
import { PlatformReducer } from "services/Platform/reducer";
import { InfringementTypeReducer } from "services/InfringementType/reducer";
import { SideBarUserViewStateReducer } from "services/UserView/reducer";

export const globalServicesReducer = combineReducers( {
    brands: BrandReducer,
    status: StatusReducer,
    country: CountryReducer,
    platform: PlatformReducer,
    infringementType: InfringementTypeReducer,
    userView: SideBarUserViewStateReducer
} );

import React from "react";
import TableBody from "@material-ui/core/TableBody";


export const EnhancedTableBody = ( props ) => {
    return(
        <TableBody>
            {props.children}
        </TableBody>
    );
};
import { loadCountryListAction, saveCountryListAction } from "./actions";
import { handleActions } from "redux-actions";

const initialState = {
    countryList: null,
    countryLoader: false
};

export const CountryReducer = handleActions(
    {
        [loadCountryListAction]: ( state, { payload } ) => ( {
            ...state,
            countryLoader: payload
        } ),
        [saveCountryListAction]: ( state, { payload } ) => ( {
            ...state,
            countryList: payload
        } )
    },
    initialState
);

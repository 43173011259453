import React from "react";
import "./SelectButtons.scss";

export const SelectButtons = props => {
    return (
        <div className="select-box">
            <span
                role="button"
                tabIndex={0}
                onClick={props.handleClick}
                onKeyPress={e => e.keyCode === 13 ? props.handleClick() : false}
            >
                {props.length} selected
            </span>
            <span className="border" />
            <button
                type="button"
                className="select-all"
                onClick={() => props.chooseAll( false )}
            >
                Deselect All
            </button>
            <button
                type="button"
                className="select-all"
                onClick={() => props.chooseAll( true )}
            >
                Select All
            </button>
        </div>
    );
};

import { takeLatest, call, put, select, delay } from "redux-saga/effects";
import { activeBrandSwitchedAction } from "services/Brand/actions";
import { urlUtil } from "utils";
import { getObjectFromURLParams } from "utils/connected";
// API
import * as API from "pages/MarketProtect/services/Entries/api";
//Selectors
import {
    allFiltersSelector,
    currentPageSelector,
    activeFilterSelector
} from "pages/MarketProtect/services/Options/selectors";
import {
    entriesOverviewSelector,
    selectedEntriesSelector,
    loadedEntriesSelector,
    hasSelectedAllEntriesSelector
} from "pages/MarketProtect/services/Entries/selectors";
import { activeBrandSelector } from "services/Brand/selectors";
import { activeModuleStateSelector } from "services/UserView/selectors";
import { pageCardDisplayViewSelector } from "pages/MarketProtect/services/UserView/selectors";
//actions
import {
    populateCardsAction,
    isLoadingEntriesAction,
    saveEntriesAction,
    chooseCardToSelectAction,
    chooseAllCardSaveAction,
    selectedCardSaveAction,
    chooseAllCardAction,
    deleteAllSelectedCardsAction
} from "pages/MarketProtect/services/Card/actions";
import { loadMoreAction, storeIsLoadingMoreAction } from "pages/MarketProtect/services/Entries/actions";
import { incrementPageAction } from "pages/MarketProtect/services/Options/actions";

//Sagas
import { saveFilterWorker } from "pages/MarketProtect/services/Options/sagas";
import { generateLoadMoreWorker, generatePopulateEntriesWorker, generateRefreshEntriesParamsBuilder } from "services/Entries/sagas";
import { activeServiceValidationWrapper } from "services/UserView/sagas";
//Constants
import { serviceDefinition } from "pages/MarketProtect/services/constants";
//Utils
const { checkAndModifyURL } = urlUtil;

function* activeBrandSwitchedWorker() {
    yield put( saveEntriesAction( { data: null, category: "listings" } ) );
    yield put( saveEntriesAction( { data: null, category: "sellers" } ) );
    yield put( populateCardsAction() );
}


const buildRequestParameters = generateRefreshEntriesParamsBuilder(
    pageCardDisplayViewSelector,
    currentPageSelector
);

export const populateCardsWorker = generatePopulateEntriesWorker( {
    activeFilterSelector,
    refreshEntries,
    saveFilterWorker,
    buildRequestParameters
} );

function* refreshEntries( requestParameters ) {
    const activeModuleState = yield select( activeModuleStateSelector );

    yield put( isLoadingEntriesAction( true ) );
    yield delay( 1000 );

    const response = yield call( API.getEntries, requestParameters );

    if ( response ) {
        yield put( saveEntriesAction( { data: response, category: activeModuleState } ) );
    }

    yield put( isLoadingEntriesAction( false ) );
}

export function* setActiveModuleStateMPCardWorker( activeModuleState ) {
    const urlParams = yield call( getObjectFromURLParams );
    const activeFilterMainObjectValues = yield select( allFiltersSelector );
    //Check value in filter
    //Modify URL by filter values if user open page without url params
    if ( !Object.values( urlParams ).length ){
        checkAndModifyURL( activeFilterMainObjectValues[activeModuleState], window.location.pathname );
    }
    const hasCardDataAvailable = yield call( getLoadedCardData, activeModuleState );
    //make api call
    if ( activeModuleState && !hasCardDataAvailable ) yield put( populateCardsAction() );
}

//@TODO: Move this, this is a selector and shouldn't be here.
function* getLoadedCardData( activeModuleState ) {
    const card_list_data = yield select( entriesOverviewSelector, activeModuleState );
    return card_list_data && card_list_data.items ? card_list_data : false;
}

function* chooseCardToSelectWorker( { payload: { value, id } = {} } ) {
    const activeModuleState = yield select( activeModuleStateSelector );
    let selectedCardCurrentState = yield select( selectedEntriesSelector );
    const cardListData = yield select( entriesOverviewSelector );
    const selectedCardState = yield select( hasSelectedAllEntriesSelector );
    let newArr = [ ...selectedCardCurrentState ];
    if ( newArr.indexOf( id ) === -1 && value ) {
        newArr.push( id );
    } else {
        newArr = newArr.filter( el => el !== id );
        if ( selectedCardState ) yield put( chooseAllCardSaveAction( { data: false, category: activeModuleState, arr: newArr } ) );
    }
    if ( cardListData.items && newArr.length === cardListData.items.length ) {
        yield put( chooseAllCardSaveAction( { status: true, category: activeModuleState, arr: newArr } ) );
        return;
    }
    yield put( selectedCardSaveAction( { data: newArr, category: activeModuleState } ) );
}

function* selectAllCardWorker( { payload } ) {
    const activeModuleState = yield select( activeModuleStateSelector );
    if ( !payload ) {
        yield put( chooseAllCardSaveAction( { status: false, category: activeModuleState, arr: [] } ) );
        yield put( selectedCardSaveAction( { data: [], category: activeModuleState } ) );
    } else {
        const cardListData = yield select( entriesOverviewSelector );
        const arrID = cardListData.items.map( e => e.id );
        yield put( chooseAllCardSaveAction( { status: true, category: activeModuleState, arr: arrID } ) );
    }
}

function* deleteAllSelectedCardsWorker() {
    const activeModuleState = yield select( activeModuleStateSelector );
    const selectedCardCurrentState = yield select( selectedEntriesSelector );
    const brand = yield select( activeBrandSelector );
    alert( `make API call to REMOVE ITEM/s with params - ids: ${selectedCardCurrentState}, brand:${brand}, ${activeModuleState}` );
    // const response = yield call( EntriesAPI.deleteItem, {
    //     ids: selectedCardCurrentState,
    //     brand
    // } );
    // if ( activeModuleState === "posts" ) {
    //     if ( response.success ) {
    //         yield call( populateCardsWorker, {} );
    //         yield put( chooseAllCardSaveAction( false ) );
    //         yield put( selectedCardSaveAction( [] ) );
    //     }
    // } else if ( activeModuleState === "profiles" ) {
    //     if ( response.success ) {
    //         yield call( populateCardsWorker, {} );
    //         yield put( chooseAllProfileSaveAction( false ) );
    //         yield put( selectedProfileSaveAction( [] ) );
    //     }
    // }
}

const loadMoreWorker = generateLoadMoreWorker( {
    API,
    storeIsLoadingMoreAction,
    incrementPageAction,
    saveEntriesAction,
    activeFilterSelector,
    loadedEntriesSelector
} );

// Watchers
export function* populateCardsMRWatcher() {
    yield takeLatest( populateCardsAction, populateCardsWorker );
}
export function* selectCardMRWatcher() {
    yield takeLatest( chooseCardToSelectAction, chooseCardToSelectWorker );
}
export function* selectAllCardMPWatcher() {
    yield takeLatest( chooseAllCardAction, selectAllCardWorker );
}
export function* loadMoreMPWatcher() {
    yield takeLatest( loadMoreAction, loadMoreWorker );
}
export function* deleteAllSelectedCardsMPWatcher() {
    yield takeLatest( deleteAllSelectedCardsAction, deleteAllSelectedCardsWorker );
}

export function* cardActiveBrandSwitchedMPWatcher() {
    yield takeLatest(
        activeBrandSwitchedAction,
        activeServiceValidationWrapper,
        {
            ...serviceDefinition,
            worker: activeBrandSwitchedWorker
        }
    );
}
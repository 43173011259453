import { urlUtil } from "utils";

import { takeLatest, call, put, select } from "redux-saga/effects";

// API
import * as ImportDataAPI from "./api";

// Actions
import {
    dataAfterImportAction,
    saveErrorsAfterImportFileAction,
    importFileStateAction,
    importDataSaveAction,
    importDataAction,
    sendImportFileAction,
    clearImportStoreAction,
    copyErrorLinksImportAction,
    goToThePostsAfterImportAction,
    sendOnlySuccessfullyImportDataAction
} from "./actions";
import { activeModuleStateSelector } from "services/UserView/selectors";
import { storeFilterAction } from "pages/SocialProtect/services/Options/actions";

// Selectors
import { activeBrandSelector } from "services/Brand/selectors";
import { activeFilterSelector } from "pages/SocialProtect/services/Options/selectors";
import {
    importDataSelector,
    dataAfterImportSelector,
    errorsImportSelector
} from "./selectors";
import { statusListSelector } from "services/Status/selectors";
import { infringementTypeListSelector } from "services/InfringementType/selectors";
import { labelsSelector } from "pages/SocialProtect/services/Label/selectors";
import { countryListSelector } from "services/Country/selectors";
import { platformListSelector } from "services/Platform/selectors";
import { brandsSelector } from "services/Brand/selectors";

//Models
import { PostsTemplate } from "pages/SocialProtect/services/Post/models";
import { ProfilesTemplate } from "pages/SocialProtect/services/Profile/models";

//Sagas
import { populateCardsWorker } from "pages/SocialProtect/services/Card/sagas";

//Utilities
import { copyStringToClipboard } from "utils";
const { modifyURLByFiltersWorker } = urlUtil;

// Workers
function* clearImportStoreWorker() {
    yield put( importDataSaveAction( null ) );
    yield put( saveErrorsAfterImportFileAction( null ) );
    yield put( importFileStateAction( null ) );
    yield put( dataAfterImportAction( null ) );
}

function* copyErrorLinksImportWorker() {
    const dataAfterImportState = yield select( dataAfterImportSelector );
    const arrListOnlyWithError = dataAfterImportState.filter(
        el => el.errorsArr && el.errorsArr.length
    );
    const listURL = arrListOnlyWithError.map(
        e => e.listingURL || e.profileURL || "No URL"
    );
    const value = listURL.join( "\n" );
    copyStringToClipboard( value );
}

function* sendImportFileWorker( { payload } ) {
    const { resolve, reject } = payload;
    const importDataState = yield select( importDataSelector );
    const response = yield call( ImportDataAPI.importFile, importDataState );
    if (
        response &&
    ( Object.values( response.errors ).length ||
      Object.values( response.duplicates_errors ).length )
    ) {
        yield put( importFileStateAction( "error" ) );
        yield put( saveErrorsAfterImportFileAction( response ) );
        yield call( combineDataAndErrorImport );
        reject();
    } else {
        const objToRes = {
            batchUrl: response.batch_url,
            batchUploadID: response.result[0].batchUploadID,
            length: response.result.length
        };
        resolve( objToRes );
        yield put( importFileStateAction( "success" ) );
    }
}

function* sendOnlySuccessfullyImportDataWorker( { payload } ) {
    const { resolve, reject } = payload;
    const importDataState = yield select( importDataSelector );
    const newData = {};
    newData.data = importDataState.data.filter(
        e => e.errorsArr && !e.errorsArr.length
    );
    newData.brand = importDataState.brand;
    newData.module = importDataState.module;
    const response = yield call( ImportDataAPI.importFile, newData );
    if ( response.result ) {
        const objToRes = {
            batchUrl: response.batch_url,
            batchUploadID: response.result[0].batchUploadID,
            length: response.result.length
        };
        resolve( objToRes );
        yield put( importFileStateAction( "success" ) );
    } else {
        reject( { error: "Something went wrong! Try Again" } );
    }
}

function* combineDataAndErrorImport() {
    let activeModuleState = yield select( activeModuleStateSelector );
    const importDataState = yield select( importDataSelector );
    const errorsImportState = yield select( errorsImportSelector );
    const { duplicates_errors, errors } = errorsImportState;
    const newData = importDataState.data.map( ( e, i ) => {
        e.errorsRow = i + 2;
        const template =
      activeModuleState === "posts" ? PostsTemplate() : ProfilesTemplate();
        let errorsList = [];
        let errorsObj = {};
        //Check duplicates
        if ( duplicates_errors[i] ) errorsObj = { ...duplicates_errors[i] };
        //Check errors
        if ( errors[i] ) errorsObj = { ...errors[i], ...errorsObj };
        for ( let key in errorsObj ) {
            let innerKey = key;
            if ( key.indexOf( "." ) !== -1 ) innerKey = key.split( "." )[1];
            for ( let index in template ) {
                if (
                    template[index].key === innerKey &&
          !template[index].profile &&
          key.indexOf( "." ) === -1
                ) {
                    innerKey = index;
                }
                if (
                    template[index].key === innerKey &&
          template[index].profile &&
          key.indexOf( "." ) !== -1
                ) {
                    innerKey = index;
                }
            }
            errorsList.push( `${innerKey} - ${errorsObj[key]}` );
        }
        e.errorsArr = errorsList;
        return e;
    } );
    yield put( dataAfterImportAction( newData ) );
}

function* importDataWorker( { payload } ) {
    const { data, reject } = payload;
    const activeModuleState = yield select( activeModuleStateSelector );

    const activeBrand = yield select( activeBrandSelector );
    const statusListState = yield select( statusListSelector );
    const infringementTypeListState = yield select( infringementTypeListSelector );
    const labelsState = yield select( labelsSelector );
    const countryListState = yield select( countryListSelector );
    const platformListState = yield select( platformListSelector );
    const brandListState = yield select( brandsSelector );
    //make object with values/id as key
    const objStatus = {};
    const objInfringementType = {};
    const objPostMark = {};
    const objCountryList = {};
    const objPlatformList = {};
    const objBrandList = {};

    if (
        !statusListState ||
    !infringementTypeListState ||
    !labelsState ||
    !platformListState ||
    !brandListState ||
    !countryListState
    )
        return reject( { error: "Something went wrong! Try Again" } );

    statusListState.forEach( e => {
        objStatus[e.name.toLowerCase()] = e.id;
    } );
    infringementTypeListState.forEach( e => {
        objInfringementType[e.name.toLowerCase()] = e.id;
    } );
    labelsState.forEach( e => {
        objPostMark[e.name.toLowerCase()] = e.id;
    } );
    platformListState.forEach( e => {
        objPlatformList[e.name.toLowerCase()] = e.id;
    } );
    brandListState.forEach( e => {
        objBrandList[e.name.toLowerCase()] = e.id;
    } );
    for ( let key in countryListState ) {
        objCountryList[countryListState[key].toLowerCase()] = key;
    }

    //Template
    const objData =
    activeModuleState === "posts" ? PostsTemplate() : ProfilesTemplate();
    //Check empty Data row
    const newClearData = [];
    data.forEach( ( e, i ) => {
        if ( e.length && e.length > 5 && i !== 0 ) newClearData.push( e );
    } );
    //Make new object with fieldName and index
    const netTitle = {};

    data[0].forEach( ( e, i ) => {
        if ( objData[e] ) {
            netTitle[i] = {
                name: objData[e].key,
                profile: objData[e].profile || false
            };
        } else {
            netTitle[i] = undefined;
        }
    } );

    //make object to api call
    let newObj = {};
    newObj.brand = activeBrand;
    newObj.module = activeModuleState;
    newObj.data = newClearData.map( e => {
        const newData = {};
        if ( activeModuleState === "posts" ) newData.profile = {};
        e.forEach( ( e, index ) => {
            let currentItem = netTitle[index];
            if ( !currentItem ) return false;
            if ( currentItem.profile ) {
                if ( !e ) {
                    newData.profile[currentItem.name] = null;
                    return false;
                }
                newData.profile[currentItem.name] = e;
                if ( currentItem.name === "countryCode2" )
                    newData.profile[currentItem.name] = objCountryList[e.toLowerCase()];
            } else {
                if ( !e ) {
                    newData[currentItem.name] = null;
                    return false;
                }
                newData[currentItem.name] = e;
                if ( currentItem.name === "brandID" )
                    newData[currentItem.name] = objBrandList[e.toLowerCase()] || null;
                if ( currentItem.name === "platform" )
                    newData[currentItem.name] = objPlatformList[e.toLowerCase()] || null;
                if ( currentItem.name === "countryCode2" )
                    newData[currentItem.name] = objCountryList[e.toLowerCase()] || null;
                if ( currentItem.name === "mark" )
                    newData[currentItem.name] = objPostMark[e.toLowerCase()] || null;
                if ( currentItem.name === "infringementType" )
                    newData[currentItem.name] =
            objInfringementType[e.toLowerCase()] || null;
                if ( currentItem.name === "enforcementStatus" )
                    newData[currentItem.name] = objStatus[e.toLowerCase()] || null;
            }
        } );
        return newData;
    } );
    //save data to props
    yield put( importDataSaveAction( newObj ) );
}

function* goToThePostsAfterImportWorker( { payload } ) {
    const activeFilters = yield select( activeFilterSelector );
    const activeModuleState = yield select( activeModuleStateSelector );
    let newFilterData = {};
    for ( let key in activeFilters ) {
        if ( Object.prototype.hasOwnProperty.call( activeFilters, "key" ) ) {
            if ( key === "page" || key === "limit" ) {
                newFilterData[key] = activeFilters[key];
                continue;
            }
            newFilterData[key] = null;
        }
    }
    newFilterData.batchUploadId = payload;
    yield put(
        storeFilterAction( {
            payload: newFilterData,
            activeModuleState: activeModuleState
        } )
    );
    //Modify URL
    yield call( modifyURLByFiltersWorker, newFilterData );
    yield call( populateCardsWorker, { payload: newFilterData } );
}

// WATCHERS
export function* importDataWatcher() {
    yield takeLatest( importDataAction, importDataWorker );
}
export function* sendImportFileWatcher() {
    yield takeLatest( sendImportFileAction, sendImportFileWorker );
}
export function* clearImportStoreWatcher() {
    yield takeLatest( clearImportStoreAction, clearImportStoreWorker );
}
export function* copyErrorLinksImportWatcher() {
    yield takeLatest( copyErrorLinksImportAction, copyErrorLinksImportWorker );
}
export function* sendOnlySuccessfullyImportDataWatcher() {
    yield takeLatest(
        sendOnlySuccessfullyImportDataAction,
        sendOnlySuccessfullyImportDataWorker
    );
}
export function* goToThePostsAfterImportWatcher() {
    yield takeLatest(
        goToThePostsAfterImportAction,
        goToThePostsAfterImportWorker
    );
}

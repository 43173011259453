import React from "react";
import moment from "moment";
import { dateFormat } from "config";
import {
    arrowNext,
    arrowPrev,
    Loader,
    CollapsibleParagraph,
    SocialTitle,
    TabBar,
    ButtonClose,
    CardActions,
    NoteBlockViewDetails,
    MarkEditorInViewDetailPanel
} from "components";

import {
    Enforcement,
    PostInfo,
    ProfileInfo
} from "pages/SocialProtect/components/ViewDetails/components/";

export const ViewDetailsPanel = ( {
    data,
    index,
    lengthList,
    deleteOneCardsAction,
    activeTab,
    closePanel,
    clickTab,
    goToNextViewDetailsAction,
    labelsState,
    selectedPostMarkIdState,
    startEnforcementLoaderState,
    selectedPostMark,
    optionValue,
    valueList,
    changeListValues,
    setOptions,
    closeList,
    updateCardItemAction,
    setCurrentInfringementType,
    clickApplyInfringement,
    activeModuleState
} ) => {
    return (
        <div className="view-panel-wrapper">
            <div className="view-details-panel__actions">
                <div className="nav-block">
                    <ButtonClose color="#979797" onClick={closePanel} />

                    <button
                        type={"button"}
                        className={index === 0 ? "hidden" : ""}
                        onClick={() =>
                            goToNextViewDetailsAction( { index: index, mode: "--" } )
                        }
                    >
                        {arrowPrev( "#979797" )}
                    </button>
                    <button
                        type={"button"}
                        className={index === lengthList - 1 ? "hidden" : ""}
                        onClick={() =>
                            goToNextViewDetailsAction( { index: index, mode: "++" } )
                        }
                    >
                        {arrowNext( "#979797" )}
                    </button>
                </div>
                <CardActions
                    editBtn={false}
                    id={data.id}
                    deleteOneCardsAction={deleteOneCardsAction}
                />
            </div>

            <div className="view-details-panel__header">
                <SocialTitle code={data.platform.code} title={data.platform.name} />
                {activeModuleState === "posts" ? (
                    <div className="image-block">
                        {data.listingImage ? (
                            <img src={data.listingImage} alt="" />
                        ) : (
                            "No image"
                        )}
                    </div>
                ) : activeModuleState === "profiles" ? (
                    <div className="profile-box">
                        <div className="img-logo">
                            <img src={data.profileImage} alt="" />
                        </div>
                        <div className="title-description-card">
                            <div>{data.profileName || "No title"}</div>
                            <span>{moment( data.createdAt ).format( dateFormat )}</span>
                        </div>
                    </div>
                ) : null}

                {startEnforcementLoaderState ? (
                    <Loader style={{ margin: "19px auto 19px" }} />
                ) : (
                    <MarkEditorInViewDetailPanel
                        data={data}
                        labelsState={labelsState}
                        selectedPostMarkIdState={selectedPostMarkIdState}
                        selectedPostMark={selectedPostMark}
                        optionValue={optionValue}
                        valueList={valueList}
                        setOptions={setOptions}
                        changeListValues={changeListValues}
                        closeList={closeList}
                        setCurrentInfringementType={setCurrentInfringementType}
                        clickApplyInfringement={clickApplyInfringement}
                    />
                )}

                {activeModuleState === "posts" ? (
                    <div>
                        <div className="date">
                            {moment( data.createdAt ).format( dateFormat )}
                        </div>
                        <div className="name">{data.listingTitle || "No title"}</div>
                    </div>
                ) : null}
            </div>

            <CollapsibleParagraph
                text={
                    data.profileDescription || data.listingDescription || "No description"
                }
            />

            <div className="view-details-panel__body">
                <TabBar>
                    {activeModuleState === "posts" ? (
                        <button
                            type="button"
                            className={activeTab === "posts" ? "active" : ""}
                            onClick={() => clickTab( "posts" )}
                        >
              Post info
                        </button>
                    ) : null}
                    <button
                        type="button"
                        className={activeTab === "profile" ? "active" : ""}
                        onClick={() => clickTab( "profile" )}
                    >
            Profile info
                    </button>
                    <button
                        type="button"
                        className={activeTab === "enforcement" ? "active" : ""}
                        onClick={() => clickTab( "enforcement" )}
                    >
            Enforcement
                    </button>
                    {/*<button*/}
                    {/*type="button"*/}
                    {/*className={activeTab === 'activities' ? 'active' : ''}*/}
                    {/*onClick={() => clickTab('activities')}*/}
                    {/*>*/}
                    {/*Activities*/}
                    {/*</button>*/}
                </TabBar>

                <div className="tab-panel">
                    {activeTab === "posts" && activeModuleState === "posts" ? (
                        <PostInfo data={data} />
                    ) : activeTab === "profile" ? (
                        <ProfileInfo data={data} />
                    ) : activeTab === "enforcement" ? (
                        <Enforcement data={data} />
                    ) : // : activeTab === 'activities' ? (
                    //     <Activities />
                    // )
                        null}
                </div>

                <NoteBlockViewDetails
                    id={data.id}
                    note={data.note}
                    updateCardItemAction={updateCardItemAction}
                />
            </div>
        </div>
    );
};

import { ApiCreator } from "utils/connected";
import { BASE_URL_API } from "config";
const api = ApiCreator( BASE_URL_API );

export const getBrands = ( activeBrand = "1" ) =>
    api.get( "user/profile", {
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json"

        }
    } );

export const setActiveBrand = ( activeBrand ) =>
    api.post( "user/brand", {
        body: JSON.stringify( { id: activeBrand } ),
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json"
        }
    } );

export const getActiveBrand = () =>
    api.get( "user/brand", {
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json"
        }
    } );

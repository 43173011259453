import React from "react";
import { TableSortLabel } from "components";

export const TableCellItem = ( props ) => {
    if( !props.element.headerTable ) return props.element.label || "No title!";
    return(
        <TableSortLabel
            orderDirection={props.orderDirection}
            label={props.element.label}
            fieldName={props.fieldName}
            handleClick={()=>props.createSortHandler( props.element )}
        />
    );
};
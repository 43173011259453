import { takeLatest, put, select, call } from "redux-saga/effects";

import {
    toggleSideBarIsOpenAction,
    storeSideBarIsOpenAction,
    setActiveModuleStateAction,
    setActiveModuleAction,
    storeActiveModuleStateAction,
    storeActiveModuleAction
} from "./actions";
import { checkFieldAndSave } from "utils";

import {
    activeModuleSelector,
    activeModuleStateSelector,
    sideBarIsOpenSelector
} from "./selectors";


// Workers
// Wraps actions and prevents non-active (sub)modules from triggering from shared actions
export function* activeServiceValidationWrapper( { worker, moduleUrlSlug, serviceModuleState }, payload  ) {
    const isActive = yield call( serviceIsActive, moduleUrlSlug, serviceModuleState );

    if ( isActive ) yield call( worker, payload );
}

function* serviceIsActive( expectedModule, expectedModuleState ) {
    const activeModule = yield select( activeModuleSelector );
    const activeModuleState = yield select( activeModuleStateSelector );

    if (
        activeModule === expectedModule &&
        ( !expectedModuleState || activeModuleState === expectedModuleState )
    ) {
        return true;
    }

    return false;
}

function* sideBarStateChangeWorker( { payload } ) {
    const sideBarCurrentState = yield select( sideBarIsOpenSelector );
    yield put( storeSideBarIsOpenAction( !sideBarCurrentState ) );
    checkFieldAndSave( "sideBarIsOpen", !sideBarCurrentState, "sideBar" );
}

function* setActiveModuleStateWorker( { payload: activeModuleState } ) {
    yield put( storeActiveModuleStateAction( activeModuleState ) );
}

function* setActiveModuleWorker( { payload: activeModule } ) {
    yield put( storeActiveModuleAction( activeModule ) );
}


// WATCHERS
export function* sideBarStateChangeWatcher() {
    yield takeLatest( toggleSideBarIsOpenAction, sideBarStateChangeWorker );
}

export function* setActiveModuleStateUserViewWatcher() {
    yield takeLatest( setActiveModuleStateAction, setActiveModuleStateWorker );
}

export function* setActiveModuleUserViewWatcher() {
    yield takeLatest( setActiveModuleAction, setActiveModuleWorker );
}
import { createSelector } from "reselect";
import { activeModuleStateSelector } from "services/UserView/selectors";

export const searchWordTitleSelector = createSelector(
    state => {
        const activeModuleState = activeModuleStateSelector( state );
        return state.socialProtect[activeModuleState].searchTitle;
    },
    state => state
);

export const getSearchWordProfileNameSelector = createSelector(
    state => {
        const activeModuleState = activeModuleStateSelector( state );
        return state.socialProtect[activeModuleState].search_profile_name;
    },
    state => state
);

export const allFiltersSelector = createSelector(
    state => {
        return state.socialProtect.options.filter;
    },
    state => state
);

export const activeFilterSelector = createSelector(
    state => {
        const activeModuleState = activeModuleStateSelector( state );
        return state.socialProtect.options.filter[activeModuleState];
    },
    state => state
);

export const activeFilterAppliedSelector = createSelector(
    state => {
        const activeModuleState = activeModuleStateSelector( state );
        return state.socialProtect.options.filter[`${activeModuleState}Applied`];
    },
    state => state
);

export const isLoadingMoreSelector = createSelector(
    state => {
        return state.socialProtect.options.isLoadingMore;
    },
    state => state
);

export const selectedPostMarkIdSelector = createSelector(
    state => {
        return state.socialProtect.options.selectedPostMarkId;
    },
    state => state
);

export const selectedInfringementTypeIdSelector = createSelector(
    state => {
        return state.socialProtect.options.selectedInfringementTypeId;
    },
    state => state
);

export const selectedEnforcementStatusIdSelector = createSelector(
    state => {
        return state.socialProtect.options.selectedEnforcementStatusId;
    },
    state => state
);

export const startEnforcementLoaderSelector = createSelector(
    state => state.socialProtect.options.startEnforcementLoader,
    state => state
);

import React from "react";
import "./RowStatistics.scss";

export const RowStatistics = ( props ) => {
    const { data } = props;
    if ( !data ) return null;
    return (
        <ul className="profile-info">
            {
                data.map( ( e, i ) =>(
                    <li key={i}>
                        <span>{e.label}</span> {e.value || "0"}
                    </li>
                ) )
            }
        </ul>
    );
};
import { ApiCreator } from "utils/connected";
import { BASE_URL_API } from "config";
const api = ApiCreator( BASE_URL_API );

export const getEntries = ( {
    module = null,
    brand = "1",
    page = "1",
    limit = "20",
    platform = null,
    postMark = null,
    enforcement = null,
    orderDirection = null,
    profileName = null,
    profileId = null,
    profileUrl = null,
    listingId = null,
    batchUploadId = null,
    createdAtFrom = null,
    createdAtTo = null,
    criteria = null,
    use_title = false,
    use_description = false

} = {} ) => {
    if ( !module )
        return Promise.reject( {
            message: "getEntries - Not exist module name in API call"
        } );
    module = module.slice( 0, -1 );
    let orderBy = null;
    if ( orderDirection ) {
        orderBy =
      orderDirection.indexOf( "-" ) !== -1
          ? orderDirection.slice( 1 )
          : orderDirection;
        orderDirection = orderDirection.indexOf( "-" ) !== -1 ? "desc" : "asc";
    }
    let url = `generic/storage/${module}/list?page=${page || "1"}&limit=${limit}`;
    if ( orderDirection )
        url += `&order_direction=${orderDirection}&order_by=${orderBy}`;
    if ( enforcement ) url += `&enforcement_status_id=${enforcement}`;
    if ( platform ) url += `&platform_id=${platform}`;
    if ( postMark ) url += `&mark_id=${postMark}`;
    if ( profileName ) url += `&profile_name=${profileName}`;
    if ( profileId ) url += `&profile_id=${profileId}`;
    if ( profileUrl ) url += `&profile_url=${encodeURIComponent( profileUrl )}`;
    if ( listingId ) url += `&listing_id=${listingId}`;
    if ( batchUploadId ) url += `&batch_upload_id=${batchUploadId}`;
    if ( createdAtFrom ) url += `&created_at_from=${createdAtFrom}`;
    if ( createdAtTo ) url += `&created_at_to=${createdAtTo}`;
    if ( criteria ) url += `&criteria=${criteria}`;
    if ( use_title ) url += `&use_title=${true}`;
    if ( use_description ) url += `&use_description=${true}`;

    return api.get( url, {
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json"
        }
    } );
};

export const deleteItem = ( { brand = "1", ids, module = null } = {} ) => {
    if ( !module )
        return Promise.reject( {
            message: "getEntries - Not exist module name in API call"
        } );
    module = module.slice( 0, -1 );
    let url = `generic/storage/${module}/delete`;
    return api.post( url, {
        body: JSON.stringify( ids ),
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json"
        }
    } );
};

export const addItemCard = ( { brand = "1", data, module = null } = {} ) => {
    if ( !module )
        return Promise.reject( {
            message: "getEntries - Not exist module name in API call"
        } );
    module = module.slice( 0, -1 );
    let url = `generic/storage/${module}/add`;
    return api.post( url, {
        body: JSON.stringify( data ),
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json"
        }
    } );
};

export const updateItem = ( {
    brand = "1",
    data,
    module = null,
    id = null
} = {} ) => {
    if ( !module || !id )
        return Promise.reject( {
            message: "getEntries - Not exist module name or ID ItemCardWrapper in API call"
        } );
    module = module.slice( 0, -1 );
    let url = `generic/storage/${module}/update/${id}`;
    return api.post( url, {
        body: JSON.stringify( data ),
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json"
        }
    } );
};

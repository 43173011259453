import { combineReducers } from "redux";

import { socialProtectOptionsReducer } from "./Options/reducer";
import { socialProtectProfilesReducer } from "./Profile/reducer";
import { socialProtectPostReducer } from "./Post/reducer";
import { socialProtectUserViewReducer } from "./UserView/reducer";
import { socialProtectLabelReducer } from "./Label/reducer";
import {
    socialProtectImportDataReducer
} from "pages/SocialProtect/components/SideBar/services/ImportData/reducer";

const socialProtectReducer = combineReducers( {
    options: socialProtectOptionsReducer,
    profiles: socialProtectProfilesReducer,
    label: socialProtectLabelReducer,
    posts: socialProtectPostReducer,
    userView: socialProtectUserViewReducer,
    import: socialProtectImportDataReducer
} );

export { socialProtectReducer };
import { handleActions } from "redux-actions";

import {
    selectEnforcementStatusInTopBarAction,
    selectInfringementTypeInTopBarAction,
    saveFilterAppliedAction,
    storeFilterAction
} from "pages/SocialProtect/services/Options/actions";
import { loadStartEnforcementAction, selectedPostMarkIdAction } from "pages/SocialProtect/services/Enforcement/actions";
import { loadCardListAction, storeIsLoadingMoreAction } from "pages/SocialProtect/services/Card/actions";

const initialState = {
    isLoadingMore: false,
    cardListLoader: false,
    startEnforcementLoader: false,
    selectedInfringementTypeId: null,
    selectedEnforcementStatusId: null,
    selectedPostMarkId: null,
    filter: {
        posts: {
            page: 1,
            limit: 20,
            platform: null,
            postMark: "0",
            enforcement: null,
            orderDirection: "-createdAt",
            profileName: null,
            profileId: null,
            profileUrl: null,
            listingId: null,
            batchUploadId: null,
            createdAtFrom: null,
            createdAtTo: null
        },
        postsApplied: {},
        profiles: {
            page: 1,
            limit: 20,
            platform: null,
            postMark: "0",
            enforcement: null,
            orderDirection: "-createdAt",
            profileName: null,
            profileId: null,
            listingId: null,
            batchUploadId: null,
            createdAtFrom: null,
            createdAtTo: null
        },
        profilesApplied: {}
    }
};

export const socialProtectOptionsReducer = handleActions(
    {
        [selectInfringementTypeInTopBarAction]: ( state, { payload } ) => {
            return {
                ...state,
                selectedInfringementTypeId: payload
            };
        },
        [selectedPostMarkIdAction]: ( state, { payload } ) => {
            return {
                ...state,
                selectedPostMarkId: payload
            };
        },
        [selectEnforcementStatusInTopBarAction]: ( state, { payload } ) => {
            return {
                ...state,
                selectedEnforcementStatusId: payload
            };
        },
        [loadStartEnforcementAction]: ( state, { payload } ) => {
            return {
                ...state,
                startEnforcementLoader: payload
            };
        },
        [storeIsLoadingMoreAction]: ( state, { payload } ) => {
            return {
                ...state,
                isLoadingMore: payload
            };
        },
        [loadCardListAction]: ( state, { payload } ) => {
            return {
                ...state,
                cardListLoader: payload
            };
        },
        [storeFilterAction]: ( state, { payload, payload: { activeModuleState } } ) => {
            const newData = { ...state.filter };
            newData[activeModuleState] = payload.payload;
            return {
                ...state,
                filter: newData
            };
        },
        [saveFilterAppliedAction]: ( state, { payload, payload: { activeModuleState } } ) => {
            const newData = { ...state.filter };
            newData[`${activeModuleState}Applied`] = payload.payload;
            return {
                ...state,
                filter: newData
            };
        }
    },
    initialState,
);


import { takeLatest, put, call, select } from "redux-saga/effects";
import { urlUtil } from "utils";

import { searchWordTitleSelector, getSearchWordProfileNameSelector } from "pages/SocialProtect/services/Options/selectors";

//API
import * as EntriesAPI from "pages/SocialProtect/services/Entries/api";

// Actions
import {
    saveFilterAction,
    saveTitleFilterAction,
    saveProfileFilterAction,
    saveFilterAndSearchAction,
    searchByFilterAction,
    saveSearchWordTitlePostAction,
    saveSearchWordTitleProfileAction,
    saveSearchWordProfileNameInPostAction,
    saveSearchWordProfileNameInProfileAction,
    storeFilterAction,
    saveFilterAppliedAction
} from "pages/SocialProtect/services/Options/actions";
import {
    savePostEntriesAction,
    loadCardListAction
} from "pages/SocialProtect/services/Card/actions";
import { saveProfileEntriesAction } from "pages/SocialProtect/services/Profile/actions";

// Selectors
import { activeModuleStateSelector } from  "services/UserView/selectors";
import { activeBrandSelector } from  "services/Brand/selectors";
import {
    allFiltersSelector,
    activeFilterSelector
}from "pages/SocialProtect/services/Options/selectors";

//Utils
const { modifyURLByFiltersWorker }  = urlUtil;

// Workers
function* saveTitleFilterWorker( { payload } ) {
    const activeModuleState = yield select( activeModuleStateSelector );
    if ( activeModuleState === "posts" ) {
        yield put( saveSearchWordTitlePostAction( payload ) );
    } else if ( activeModuleState === "profiles" ) {
        yield put( saveSearchWordTitleProfileAction( payload ) );
    }
}

function* saveProfileFilterWorker( { payload } ) {
    const activeModuleState = yield select( activeModuleStateSelector );
    if ( activeModuleState === "posts" ) {
        yield put( saveSearchWordProfileNameInPostAction( payload ) );
    } else if ( activeModuleState === "profiles" ) {
        yield put( saveSearchWordProfileNameInProfileAction( payload ) );
    }
}

export function* saveFilterWorker( { payload } ) {
    if ( !payload ) {
        console.warn( "Called saveFilterWorker without payload" );
        return;
    }

    const { moduleState } = payload;
    //Add filter value in store without api call
    const activeModuleState = yield select( activeModuleStateSelector );
    const activeFilters = moduleState
        ? yield select( allFiltersSelector )
        : yield select( activeFilterSelector );
    let newFilterData = { ...activeFilters };
    if ( moduleState ) newFilterData = { ...activeFilters[moduleState] };
    for ( let key in payload ) {
        if ( Object.prototype.hasOwnProperty.call( payload, key ) ) {
            if ( Object.prototype.hasOwnProperty.call( newFilterData, key ) ) newFilterData[key] = payload[key];
        }
    }
    yield put(
        storeFilterAction( { payload: newFilterData, activeModuleState: moduleState || activeModuleState } ),
    );
}

export function* saveFilterAndSearchWorker( { payload } ) {
    if ( !payload ) {
        console.warn( "Called saveFilterAndSearchWorker without payload" );
        return;
    }

    // Init save value and later API call by filters values
    yield call( saveFilterWorker, { payload } );
    yield call( searchByFilterWorker );
}

function* searchByFilterWorker() {
    //select data
    const activeBrand = yield select( activeBrandSelector );
    const activeModuleState = yield select( activeModuleStateSelector );
    const activeFilters = yield select( activeFilterSelector );
    //Start loading
    yield put( loadCardListAction( true ) );
    let newObj = { ...activeFilters };
    newObj.brand = activeBrand;
    //select list if exists
    newObj.module = activeModuleState;

    // @TODO: Why is this in a different reducer from the activeFilter?
    const searchCriteria = yield select( searchWordTitleSelector );
    const searchProfileName = yield select( getSearchWordProfileNameSelector );

    //Save only applied filter values
    yield put( saveFilterAppliedAction( { payload: newObj, activeModuleState } ) );

    //Modify URL
    yield call( modifyURLByFiltersWorker, newObj );

    newObj = {
        ...newObj,
        criteria: searchCriteria,
        profileName: searchProfileName,
        use_title: searchCriteria,
        use_description: searchCriteria
    };

    //Make request
    const response = yield call( EntriesAPI.getEntries, newObj );
    if ( activeModuleState === "posts" && response ) {
        yield put( savePostEntriesAction( response ) );
    } else if ( activeModuleState === "profiles" && response ) {
        yield put( saveProfileEntriesAction( response ) );
    }
    //end loading
    yield put( loadCardListAction( false ) );
}

// Watchers
export function* saveTitleFilterWatcher() {
    yield takeLatest( saveTitleFilterAction, saveTitleFilterWorker );
}
export function* saveProfileFilterWatcher() {
    yield takeLatest( saveProfileFilterAction, saveProfileFilterWorker );
}
export function* saveFilterWatcher() {
    yield takeLatest( saveFilterAction, saveFilterWorker );
}
export function* saveFilterAndSearchWatcher() {
    yield takeLatest( saveFilterAndSearchAction, saveFilterAndSearchWorker );
}
export function* searchByFilterWatcher() {
    yield takeLatest( searchByFilterAction, searchByFilterWorker );
}
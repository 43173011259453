import { setActiveModuleAction, setActiveModuleStateAction } from "services/UserView/actions";
import { activeModuleStateSelector, activeModuleSelector } from "services/UserView/selectors";
import { navigationCompletedAction } from "services/Navigation/actions";
import { onNavigationAction } from "./actions";
import { urlUtil } from "utils";
import { store } from "index";
import { history } from "./history";
import { call, put, select, takeLatest } from "redux-saga/effects";
import { validateRoute } from "./models";
import { storeSideBarIsOpenAction } from "services/UserView/actions";
import { getObjectFromURLParams } from "utils/connected";

// Utils
const {  getModuleStateFromUrl, getModuleFromUrl } = urlUtil;

function* fetchCurrentPageWorker() {
    if ( history ) {
        yield put( onNavigationAction( { location: history.location, firstInit: true } ) );
        yield put( storeSideBarIsOpenAction( false ) );

        // Listen to navigation changes
        history.listen( location => {
            store.dispatch( onNavigationAction( { location } ) );
        } );
    }
}

function* onNavigation( { payload: { location } } ) {
    const activeModuleStateInStore = yield select( activeModuleStateSelector );
    const activeModuleInStore = yield select( activeModuleSelector );
    const activeModule = getModuleFromUrl( location.pathname );
    const activeModuleState = getModuleStateFromUrl( location.pathname );
    const isSwitchingModule = activeModuleInStore !== activeModule && activeModuleStateInStore && !activeModuleState;

    if( isSwitchingModule ){
        //clear ActiveModuleState when going to another module
        yield put( setActiveModuleStateAction( null ) );
        yield put( storeSideBarIsOpenAction( false ) );
    }

    // In case we get a 404, do not update the store because we'll error out
    if ( !validateRoute( activeModule, activeModuleState ) ) return;

    yield put( setActiveModuleAction( activeModule ) );
    yield put( setActiveModuleStateAction( activeModuleState ) );

    yield put( navigationCompletedAction() );
}

export function filterMatchesUrlParameters( filter, urlParams ) {
    let initValue = false;
    const hasUrlParameters = Object.values( urlParams ).length > 0;

    if ( filter && hasUrlParameters ) {
        for ( let key in filter ) {
            if (
                Object.prototype.hasOwnProperty.call( urlParams, key ) &&
                Object.prototype.hasOwnProperty.call( filter, key ) &&
                filter[key] !== urlParams[key]
            ) {
                initValue = true;
            }
        }
    }
    return initValue;
}

export function generateApplyFilterFromUrlWorker( activeFilterSelector, onMatch ) {

    return function* (){
        const urlParams = getObjectFromURLParams();
        const activeFilter = yield select( activeFilterSelector );

        if( filterMatchesUrlParameters( activeFilter, urlParams ) ) yield put( onMatch() );
    };
}

export function* watchingPage() {
    yield takeLatest( onNavigationAction, onNavigation );
    yield call( fetchCurrentPageWorker );
}

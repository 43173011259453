import { connect } from "react-redux";
import { ViewDetails as component } from "./ViewDetails";

import { showViewDetailsPanelAction } from "services/UserView/actions";
import { activeModuleStateSelector } from "services/UserView/selectors";

import { labelsSelector } from "pages/SocialProtect/services/Label/selectors";

import { infringementTypeListSelector } from "services/InfringementType/selectors";

import { statusListSelector } from "services/Status/selectors";

import {
    updateCardItemAction,
    deleteOneCardsAction
} from "pages/SocialProtect/services/Card/actions";

import { spViewDetailItemDataSelector } from "pages/SocialProtect/services/UserView/selectors";

import { goToNextViewDetailsAction } from "pages/SocialProtect/services/UserView/actions";

import {
    startEnforcementAction,
    selectedPostMarkIdAction,
    selectedNewPostMarkAction
} from "pages/SocialProtect/services/Enforcement/actions";
import {
    selectInfringementTypeInTopBarAction,
    selectEnforcementStatusInTopBarAction
} from "pages/SocialProtect/services/Options/actions";
import {
    selectedInfringementTypeIdSelector,
    selectedPostMarkIdSelector,
    startEnforcementLoaderSelector,
    selectedEnforcementStatusIdSelector
} from "pages/SocialProtect/services/Options/selectors";

const mapDispatchToProps = {
    deleteOneCardsAction,
    showViewDetailsPanelAction,
    goToNextViewDetailsAction,
    startEnforcementAction,
    selectedPostMarkIdAction,
    updateCardItemAction,
    selectInfringementTypeInTopBarAction,
    selectEnforcementStatusInTopBarAction,
    selectedNewPostMarkAction
};

const mapStateToProps = state => {
    const activeModuleState = activeModuleStateSelector( state );
    const viewDetailItemDataState = spViewDetailItemDataSelector( state );
    const labelsState = labelsSelector( state );
    const selectedPostMarkIdState = selectedPostMarkIdSelector( state );
    const startEnforcementLoaderState = startEnforcementLoaderSelector( state );

    const statusListState = statusListSelector( state );
    const selectedEnforcementStatusIdState = selectedEnforcementStatusIdSelector(
        state
    );
    const infringementTypeListState = infringementTypeListSelector( state );
    const infringementTypeIdState = selectedInfringementTypeIdSelector( state );
    return {
        activeModuleState,
        viewDetailItemDataState,
        labelsState,
        selectedPostMarkIdState,
        startEnforcementLoaderState,
        infringementTypeIdState,
        infringementTypeListState,
        statusListState,
        selectedEnforcementStatusIdState
    };
};

export const ViewDetails = connect(
    mapStateToProps,
    mapDispatchToProps
)( component );

import { createSelector } from "reselect";

export const importDataSelector = createSelector(
    state => state.socialProtect.import.importData,
    state => state
);

export const errorsImportSelector = createSelector(
    state => state.socialProtect.import.errorsImportData,
    state => state
);

export const importFileStateSelector = createSelector(
    state => state.socialProtect.import.importFileState,
    state => state
);

export const dataAfterImportSelector = createSelector(
    state => state.socialProtect.import.dataAfterImport,
    state => state
);

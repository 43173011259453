import { handleActions } from "redux-actions";

import {
    saveSearchWordTitleProfileAction,
    saveSearchWordProfileNameInProfileAction
} from "pages/SocialProtect/services/Options/actions";
import {
    itemViewDetailsProfilesSaveAction
} from "pages/SocialProtect/services/UserView/actions";
import {
    saveProfileEntriesAction,
    chooseAllProfileSaveAction,
    selectedProfileSaveAction
} from "pages/SocialProtect/services/Profile/actions";

const initialState = {
    searchTitle: null, //@TODO: This can currently also be the description, either need to rename it or create an additional key
    search_profile_name: null,
    card_list_data: null,
    item_view_detail: null,
    selected_card: {
        selectAll: false,
        arrID: []
    }
};

export const socialProtectProfilesReducer = handleActions(
    {
        [saveSearchWordTitleProfileAction]: ( state, { payload } ) => {
            return {
                ...state,
                searchTitle: payload
            };
        },

        [saveSearchWordProfileNameInProfileAction]: ( state, { payload } ) => {
            return {
                ...state,
                search_profile_name: payload
            };
        },

        [itemViewDetailsProfilesSaveAction]: ( state, { payload } ) => {
            return {
                ...state,
                item_view_detail: payload
            };
        },

        [saveProfileEntriesAction]: ( state, { payload } ) => {
            return {
                ...state,
                card_list_data: payload
            };
        },

        [chooseAllProfileSaveAction]: ( state, { payload } ) => {
            let newSelectedCardState = state.selected_card;
            newSelectedCardState.selectAll = payload;
            return {
                ...state,
                selected_card: newSelectedCardState
            };
        },

        [selectedProfileSaveAction]: ( state, { payload } ) => {
            let newSelectedCard = state.selected_card;
            newSelectedCard.arrID = payload;
            return {
                ...state,
                selected_card: newSelectedCard
            };
        }
    },
    initialState,
);

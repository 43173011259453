import { createSelector } from "reselect";

export const brandsSelector = createSelector(
    state => state.main.brands.brandList,
    state => state
);

export const brandsFavoriteListSelector = createSelector(
    state => state.main.brands.favorite,
    state => state
);

export const activeBrandSelector = createSelector(
    state => state.main.brands.activeBrand,
    state => state
);

export const loadingBrandSelector = createSelector(
    state => state.main.brands.isLoadingBrands,
    state => state
);

export const isSwitchingBrandSelector = createSelector(
    state => state.main.brands.isSwitchingBrand,
    state => state
);
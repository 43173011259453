import React from "react";
import "./TableSortLabel.scss";

export const TableSortLabel = ( {
    orderDirection = null,
    label = "",
    fieldName = null,
    handleClick
} ) => {
    if ( orderDirection === fieldName ) orderDirection = "asc";
    if ( orderDirection === "-" + fieldName ) orderDirection = "desc";
    return (
        <span
            className={`filter ${orderDirection || ""}`}
            onClick={handleClick}
            role="presentation"
        >
            {label}
        </span>
    );
};

import React from "react";
import { arrowLeft, arrowRight } from "components";

export const PaginationButtons = ( { page, handlePageChange, countAvailable, limit } ) => {

    return (
        <ul className="pagination-buttons">
            <li>
                <button
                    disabled={page - 1 === 0}
                    onClick={e => {
                        e.preventDefault();

                        if ( page - 1 === 0 ) return;

                        handlePageChange( { selected: page - 2 } );
                    }}
                >
                    {arrowLeft()}
                </button>
            </li>
            <li>
                <button
                    disabled={ page + 1 > Math.ceil( countAvailable / limit ) }
                    onClick={e => {
                        e.preventDefault();

                        if ( page + 1 > Math.ceil( countAvailable / limit ) )
                            return;

                        handlePageChange( { selected: page } );
                    }}
                >
                    {arrowRight()}
                </button>
            </li>
        </ul>
    );
};
import { call, put, select } from "redux-saga/effects";
import { activeBrandSelector } from "services/Brand/selectors";

import * as CountryAPI from "./api";
import { loadCountryListAction, saveCountryListAction } from "./actions";

//WORKERS
function* countryWorker() {
    //Loading Brands
    yield put( loadCountryListAction( true ) );
    //Get brands list from API
    const activeBrand = yield select( activeBrandSelector );
    const response = yield call( CountryAPI.getCountryList, activeBrand );
    if ( response ) yield put( saveCountryListAction( response ) );
    yield put( loadCountryListAction( false ) );
}

// WATCHERS
export function* watchingCountry() {
    yield call( countryWorker );
}

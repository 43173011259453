import { takeLatest, call, put, select } from "redux-saga/effects";
import {
    loadStartEnforcementAction,
    startEnforcementAction,
    selectedPostMarkIdAction
} from "./actions";

// API
import * as EnforcementAPI from "./api";

//Actions
import { selectedCardSaveAction, chooseAllCardSaveAction } from "pages/SocialProtect/services/Card/actions";
import { selectedProfileSaveAction } from "pages/SocialProtect/services/Profile/actions";
import { showViewDetailsPanelAction } from "services/UserView/actions";
import {
    selectEnforcementStatusInTopBarAction,
    selectInfringementTypeInTopBarAction
} from "pages/SocialProtect/services/Options/actions";

import { selectedNewPostMarkAction } from "pages/SocialProtect/services/Enforcement/actions";

//Selectors
import { activeModuleStateSelector } from "services/UserView/selectors";
import { activeBrandSelector } from "services/Brand/selectors";
import { selectedCardsList } from "pages/SocialProtect/services/Card/selectors";
import {
    selectedInfringementTypeIdSelector,
    selectedEnforcementStatusIdSelector,
    selectedPostMarkIdSelector
} from "pages/SocialProtect/services/Options/selectors";

// Sagas
import { populateCardsWorker } from "pages/SocialProtect/services/Card/sagas";

// Workers
function* startEnforcementWorker( { payload = {} } ) {
    const { optionValue, resolve } = payload;
    //Loader
    yield put( loadStartEnforcementAction( true ) );
    const active_module = yield select( activeModuleStateSelector );
    const ids = payload.id ? payload.id : yield select( selectedCardsList );

    const brand = yield select( activeBrandSelector );
    const mark_id = yield select( selectedPostMarkIdSelector );
    const type_id = yield select( selectedInfringementTypeIdSelector );
    const status_id = yield select( selectedEnforcementStatusIdSelector );

    let response = null;
    let newObj = { brand, ids, active_module };

    if ( optionValue === "status" ) {
        newObj.status_id = status_id;
        response = yield call( EnforcementAPI.startSetStatus, newObj ); //setStatus
        //Clear values in store
        yield put( selectEnforcementStatusInTopBarAction( null ) );
    } else if ( optionValue === "type" ) {
        newObj.type_id = type_id;
        response = yield call( EnforcementAPI.startInfringementType, newObj ); //setType
        //Clear values in store
        yield put( selectInfringementTypeInTopBarAction( null ) );
    } else {
        newObj.mark_id = mark_id;
        response = yield call( EnforcementAPI.startEnforcement, newObj ); //postMark
    }

    //Clear values in store
    yield put( selectedPostMarkIdAction( null ) );
    yield put( chooseAllCardSaveAction( false ) );

    if ( active_module === "posts" ) {
        yield put( selectedCardSaveAction( [] ) );
    } else if ( active_module === "profiles" ) {
        yield put( selectedProfileSaveAction( [] ) );
    }

    if ( resolve && typeof resolve === "function" ) resolve();

    //Loader
    yield put( loadStartEnforcementAction( false ) );
    if ( response && response.success ) {
        yield call( populateCardsWorker, {} );
    }
}

function* selectedNewPostMarkWorker( { payload = {} } ) {
    //Loader
    yield put( loadStartEnforcementAction( true ) );
    const brand = yield select( activeBrandSelector );
    const active_module = yield select( activeModuleStateSelector );
    const { ids, mark_id } = payload;
    let newObj = { brand, ids, active_module };
    newObj.mark_id = mark_id;
    const response = yield call( EnforcementAPI.startEnforcement, newObj ); //postMark
    if ( response && response.success ) {
        //Clear values in store
        yield put( selectedPostMarkIdAction( null ) );
        yield put( showViewDetailsPanelAction( null ) );
        if ( active_module === "posts" ) {
            yield put( selectedCardSaveAction( [] ) );
        } else if ( active_module === "profiles" ) {
            yield put( selectedProfileSaveAction( [] ) );
        }
        //make call to get new data
        yield call( populateCardsWorker, {} );
    }
    //Loader
    yield put( loadStartEnforcementAction( false ) );
}

// WATCHERS

export function* startEnforcementWatcher() {
    yield takeLatest( startEnforcementAction, startEnforcementWorker );
}

export function* selectedNewPostMarkWatcher() {
    yield takeLatest( selectedNewPostMarkAction, selectedNewPostMarkWorker );
}

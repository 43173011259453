import React, { PureComponent } from "react";
import "./CollapsibleParagraph.scss";

export class CollapsibleParagraph extends PureComponent {
    constructor( props ) {
        super( props );
        this.myRef = React.createRef();
        this.myRefd = React.createRef();
        this.state = {
            open: false,
            hideBtn: false
        };
    }

    componentDidMount() {
        document.addEventListener( "click", this.documentClick );
        const node = this.myRefd.current;
        if ( node.clientHeight > 65 ) {
            this.setState( {
                hideBtn: true
            } );
        }
    }

    componentWillUnmount() {
        document.removeEventListener( "click", this.documentClick );
    }

    documentClick = e => {
        const node = this.myRef.current;
        if ( e.target.closest( ".text-box-card" ) !== node ) this.closeDropDown();
    };

    changeStateCollapsibleParagraph = () => {
        const { disabled } = this.props;
        if ( disabled ) return;
        this.setState( prevState => ( { open: !prevState.open } ) );
    };

    closeDropDown = () => {
        this.setState( { open: false } );
    };

    render() {
        const { text } = this.props;
        const { open, hideBtn } = this.state;
        let wrapperClassName = "text-box-card";
        if ( open ) wrapperClassName += " show";
        return (
            <div ref={this.myRef} className={wrapperClassName}>
                <p className="text">{text}</p>
                <div className="all-text" ref={this.myRefd}>
                    <span>{text}</span>
                    <div>
                        <span
                            className="show-text"
                            onClick={this.changeStateCollapsibleParagraph}
                            role="presentation"
                        >
              Less
                        </span>
                    </div>
                </div>
                {hideBtn ? (
                    <div className="row-btn-text">
                        <span
                            className="show-text"
                            onClick={this.changeStateCollapsibleParagraph}
                            role="presentation"
                        >
              Read more…
                        </span>
                    </div>
                ) : null}
            </div>
        );
    }
}

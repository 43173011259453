import React from "react";
import "./Avatar.scss";

export const Avatar = ( { data = [], size = "35", style={} } ) => {
    return (
        <div className="avatar-box" style={style}>
            {data
                ? data.map( ( e, i ) => (
                    <div key={i} style={{ zIndex: i - i * 2 }}>
                        <img
                            src={checkImageSrc( e )}
                            alt=""
                            style={{ height: size + "px", width: size + "px", display: "flex" }}
                        />
                    </div>
                ) )
                : null}
        </div>
    );
};

const checkImageSrc = src => {
    if ( !src ) return "";
    if ( src.toString().charAt( 0 ) === "/" ) {
        src = "https://revlect.com" + src;
    }
    return src;
};

import React from "react";
import { CollapsibleFilter, Datepicker } from "components";

export const CollapsibleDatePickerBlock = ( {
    title = "",
    nameFrom = "",
    nameTo = "",
    defaultValueFrom = null,
    defaultValueTo = null,
    handleChange
} ) => {
    return (
        <CollapsibleFilter title={title}>
            <Datepicker
                defaultValue={defaultValueFrom}
                handleChange={value => handleChange( nameFrom, value )}
            />
            <Datepicker
                defaultValue={defaultValueTo}
                handleChange={value => handleChange( nameTo, value )}
            />
        </CollapsibleFilter>
    );
};

import React from "react";
import { Button } from "../Button";

export const ProdSwitcher = props => {
    const { activeModule, getProdPath, activeModuleState, className } = props;

    return (
        <a
            href={getProdPath( activeModule, activeModuleState )}
            className={className}
        >
            <Button
                className={className}
                text="Old Revlect"
                color="secondary"
                size="small"
            />
        </a>
    );
};

import React, { PureComponent } from "react";
import ReactDOM from "react-dom";
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import { calendar } from "components";
import "./Datepicker.scss";

export class Datepicker extends PureComponent {
    constructor( props ) {
        super( props );
        this.myRef = React.createRef();
        this.state = {
            value: null
        };
    }

    static getDerivedStateFromProps( nextProps ) {
        if ( Object.prototype.hasOwnProperty.call( nextProps, "defaultValue" ) ) {
            return {
                value:
                nextProps.defaultValue &&
                isNaN( new Date( nextProps.defaultValue ) ) === false
                    ? new Date( nextProps.defaultValue )
                    : null
            };
        }
        return null;
    }

    componentDidMount() {
        const svg = calendar( "#809096" );
        this.container = document.createElement( "div" );
        this.container.className = "icon-date";
        ReactDOM.render( svg, this.container );
        const node = this.myRef.current;
        const element = node.getElementsByClassName( "datepicker-main" )[0];
        if ( element ) element.appendChild( this.container );
    }

  handleChangeData = date => {
      const { handleChange } = this.props;
      this.setState( { value: date }, () => handleChange( date ) );
  };

  render() {
      let { value } = this.state;
      return (
          <div ref={this.myRef}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <DatePicker
                      margin="normal"
                      value={value}
                      clearable
                      placeholder={"__ / __ / __"}
                      className={"datepicker-main"}
                      onChange={this.handleChangeData}
                      format="dd MMM yyyy"
                  />
              </MuiPickersUtilsProvider>
          </div>
      );
  }
}

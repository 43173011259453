import React from "react";
import "./ChangeLabel.scss";

export const ChangeLabel = props => {
    const {
        data = [],
        open = false,
        handleChange,
        defaultValue = null,
        title = "label",
        cssInner = ""
    } = props;
    let cssClassInner = "inner-list " + cssInner;
    return (
        <div className="change-label-box">
            {open && ( data && data.length ) ? (
                <div className={cssClassInner}>
                    <span>Change {title}</span>
                    <ul>
                        {data.map( e => (
                            <li
                                key={e.id}
                                role="presentation"
                                onClick={() => handleChange( e.id )}
                                className={defaultValue === e.id ? "active" : ""}
                            >
                                {e.name}
                            </li>
                        ) )}
                    </ul>
                    <div className="children">{props.children}</div>
                </div>
            ) : null}
        </div>
    );
};

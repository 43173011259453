import { createSelector } from "reselect";

export const infringementTypeListSelector = createSelector(
    state => state.main.infringementType.infringementTypeList,
    state => state
);

export const infringementTypeListLoaderSelector = createSelector(
    state => state.main.infringementType.infringementTypeLoader,
    state => state
);

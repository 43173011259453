import React from "react";
import { tikSvg, warningSvg, message } from "components/Icons/Svg";
import { ButtonClose } from "components";
import "./MessageGlobal.scss";

const statusSvgs = {
    "success": tikSvg(),
    "info": message(),
    "warning": warningSvg(),
    "default": message()
};

const getStatusIcon = status => statusSvgs[status] || statusSvgs.default;

export const MessageGlobal = props => {
    const {
        status,
        left = null,
        center = null,
        right = null,
        close = () => {}
    } = props;

    return (
        <div className={`message-global ${status}`}>
            <div className={"left"}>
                <div className="circle">{getStatusIcon( status )}</div>
                {left}
            </div>
            <div className={"center"}>{center}</div>
            <div className={"right"}>
                {right}
                <ButtonClose color="#979797" onClick={close} />
            </div>
        </div>
    );
};

import React, { PureComponent } from "react";
import Slider from "react-slick";
import "./SlickSlider.scss";

export class SlickSlider extends PureComponent {
    constructor( props ) {
        super( props );
        this.slider = React.createRef();
        this.slider2 = React.createRef();
        this.state = {
            nav1: null,
            nav2: null
        };
    }


    slickGoTo = () => {
        const { slickGoTo } = this.props;
        if ( this.slider.current && ( slickGoTo || slickGoTo === 0 ) ) this.slider.current.slickGoTo( slickGoTo );
        if ( this.slider2.current && ( slickGoTo || slickGoTo === 0 ) ) this.slider2.current.slickGoTo( slickGoTo );
    };

    componentDidMount() {
        this.setState(
            {
                nav1: this.slider.current,
                nav2: this.slider2.current
            },
            this.slickGoTo
        );
    }

    componentDidUpdate( prevProps ) {
        const { slickGoTo } = this.props;
        if ( prevProps.slickGoTo !== slickGoTo ) {
            this.slickGoTo();
        }
    }

    render() {
        const { nav2, nav1 } = this.state;
        const { props } = this;
        if ( props.multiple ) {
            return (
                <div>
                    <Slider
                        {...props.props1}
                        prevArrow={props.prev || null}
                        nextArrow={props.next || null}
                        asNavFor={nav2}
                        ref={this.slider}
                        className={props.cssClass || ""}
                    >
                        {props.children}
                    </Slider>
                    <Slider
                        asNavFor={nav1}
                        ref={this.slider2}
                        className={props.cssClass2 || ""}
                        {...props.props2}
                    >
                        {props.children}
                    </Slider>
                </div>
            );
        }
        return (
            <Slider
                {...props.props1}
                className={props.cssClass || ""}
                ref={this.slider}
                prevArrow={props.prev || null}
                nextArrow={props.next || null}
            >
                {props.children}
            </Slider>
        );
    }
}

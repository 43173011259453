import { takeLatest, call, put, select } from "redux-saga/effects";

import {
    pageListViewMPSaveAction,
    pageListViewChangeMPAction,
    pageCardDisplayViewMPChangeAction,
    pageCardDisplayMPViewSaveAction,
    goToNextViewDetailsMPAction
} from "./actions";
import {
    checkFieldAndSave
    //getCountItemsPerPage
} from "utils";
import { activeModuleStateSelector } from "services/UserView/selectors";
import { saveIDItemSelectedAction } from "pages/MarketProtect/services/Card/actions";
import { entriesOverviewSelector } from "pages/MarketProtect/services/Entries/selectors";
import { showViewDetailsPanelMPAction } from "pages/MarketProtect/services/UserView/actions";
import { activeServiceValidationWrapper } from "services/UserView/sagas";
import { serviceDefinition } from "./constants";

//WORKERS
function* fetchUserViewSettingsWorker() {

    //check localStorage Post
    const userDataPosts = localStorage.listings;
    if ( userDataPosts ) {
        const objData = JSON.parse( userDataPosts );
        if ( Object.prototype.hasOwnProperty.call( objData, "page_list_view" ) )
            yield put( pageListViewMPSaveAction( { data: objData.page_list_view, activeModuleState: "listings" }  ) );
        if ( Object.prototype.hasOwnProperty.call( objData, "card_display_view" ) )
            yield put( pageCardDisplayMPViewSaveAction( { data: objData.card_display_view, activeModuleState: "listings" }  ) );
    }

}

function* pageListViewChangeWorker( { payload } ) {
    const activeModuleState = yield select( activeModuleStateSelector );
    yield put( pageListViewMPSaveAction( { data: payload, activeModuleState: activeModuleState }  ) );

    checkFieldAndSave( "page_list_view", payload, activeModuleState );
}

function* showViewDetailsPanelMPWorker( { payload } ) {
    const activeModuleState = yield select( activeModuleStateSelector );
    yield put( saveIDItemSelectedAction( { data: payload, category: activeModuleState } ) );
}

function* pageCardDisplayViewMPChangeWorker( { payload } ) {
    const activeModuleState = yield select( activeModuleStateSelector );
    yield put( pageCardDisplayMPViewSaveAction( { data: payload, category: activeModuleState }  ) );
    //get perPage value
    //const perPageValue = getCountItemsPerPage( payload );
    checkFieldAndSave( "card_display_view", payload, activeModuleState );
    //Make a new Api call with changing perPage value
    //@TODO
    // yield put( getNewDataWithChangingUserViewAction( perPageValue ) );
}

function* goToNextViewDetailsWorker( { payload: { index, mode } } ) {
    const activeModuleState = yield select( activeModuleStateSelector );
    const cardListData = yield select( entriesOverviewSelector );
    const lengthList = cardListData.items.length;
    if (
        ( mode === "++" && index === lengthList - 1 ) ||
        ( mode === "--" && index === 0 ) ||
        !mode
    )
        return;

    let id;
    if ( mode === "++" ) {
        id = cardListData.items[++index].id;
    } else {
        id = cardListData.items[--index].id;
    }
    yield put( saveIDItemSelectedAction( { data: id, category: activeModuleState } ) );
}

// Watchers
export function* goToNextViewDetailsMPWatcher() {
    yield takeLatest( goToNextViewDetailsMPAction, goToNextViewDetailsWorker );
}
export function* pageCardDisplayViewMPChangeWatcher() {
    yield takeLatest(
        pageCardDisplayViewMPChangeAction,
        pageCardDisplayViewMPChangeWorker
    );
}
export function* fetchUserViewMPSettings() {
    yield call( fetchUserViewSettingsWorker );
}
export function* pageListViewChangeMPWatcher() {
    yield takeLatest( pageListViewChangeMPAction, pageListViewChangeWorker );
}
export function* showViewDetailsPanelMPWatcher() {
    yield takeLatest(
        showViewDetailsPanelMPAction,
        activeServiceValidationWrapper,
        {
            ...serviceDefinition,
            worker: showViewDetailsPanelMPWorker
        }
    );
}
import React from "react";
import { modules } from "services/Navigation/models";
import { menuHeader, SimpleDropdown } from "components";
import { ModuleEntry } from "./ModuleEntry";
import "./MenuModules.scss";

export const MenuModules = () => (
    <SimpleDropdown
        content={
            modules.map( ( x, i ) => <ModuleEntry {...x} key={`entry_${i}`} preferLocalPath={true} /> )
        }
        icon={menuHeader()}
        wrapperClass="menu-modules"
        headerClass="menu-modules-header"
        bodyClass="menu-modules-body"
        dropdownClass="menu-modules-dropdown"
    />
);
import React from "react";
import Chip from "@material-ui/core/Chip";
import { getClass, formatDate, isDate } from "utils";

export const Chips = ( props ) =>{
    const { removeChipsAction, activeFilter, unnecessaryFields, dataChips } = props;
    
    return Object.keys( activeFilter )
        .filter( ( e, index ) => {
            const label = activeFilter[e];

            if( !label || unnecessaryFields.includes( e ) ) return false;

            if( Object.keys( dataChips ).includes( e ) && !dataChips[e] ) return false;

            return true;
        } )
        .map( ( e, index ) => {
            const currentValue = activeFilter[e];
            const currentArr = dataChips[e];
            let label = getLabel( currentValue, currentArr );
            label = checkDate( label );
            return (
                <Chip
                    key={index}
                    label={label}
                    onDelete={ ()=> removeChipsAction( e ) }
                    variant="outlined"
                />
            );
        } );
};

const getLabel = ( value, currentArr ) => {
    if( currentArr && currentArr.length ){
        return currentArr.find( ( e ) => +e.id === +value ).name;
    }
    return value;
};

const checkDate = ( label ) => {
    let result = label;
    const className = getClass( label );
    const date = ( className === "Date" );
    const stringToDate = ( className === "String" && isDate( label ) );
    if( date || stringToDate ) result = formatDate( new Date( label ) );
    return result;
};
import { connect } from "react-redux";
import { OptionTopBarDisplay } from "components";
import { getCells } from "utils";
import {
    chooseAllCardAction,
    deleteAllSelectedCardsAction
} from "pages/MarketProtect/services/Card/actions";
import {
    selectedEntriesSelector,
    entriesOverviewSelector,
    hasSelectedAllEntriesSelector
} from "pages/MarketProtect/services/Entries/selectors";
import {
    startEnforcementAction,
    selectedPostMarkIdAction
} from "pages/MarketProtect/services/Enforcement/actions";
import {
    selectInfringementTypeInTopBarAction,
    selectEnforcementStatusInTopBarAction
} from "pages/MarketProtect/services/Options/actions";
import {
    selectedPostMarkIdSelector,
    startEnforcementLoaderSelector,
    selectedEnforcementStatusIdSelector,
    selectedInfringementTypeIdSelector
} from "pages/MarketProtect/services/Options/selectors";
import {
    labelsSelector
} from "pages/MarketProtect/services/Label/selectors";
import { customizeTableViewSelector } from "pages/MarketProtect/services/UserView/selectors";
import { activeModuleStateSelector } from "services/UserView/selectors";
import { statusListSelector } from "services/Status/selectors";
import { infringementTypeListSelector } from "services/InfringementType/selectors";
import { chooseItemByFieldName } from "pages/MarketProtect/chooseItemByFieldName";

const mapDispatchToProps = {
    chooseAllCardAction,
    deleteAllSelectedCardsAction,
    startEnforcementAction,
    selectedPostMarkIdAction,
    selectInfringementTypeInTopBarAction,
    selectEnforcementStatusInTopBarAction
};

const mapStateToProps = state => {
    const selectedCardsListState = selectedEntriesSelector( state );
    const selectedAllCardsState = hasSelectedAllEntriesSelector( state );
    const labelListState = labelsSelector( state );
    const selectedPostMarkIdState = selectedPostMarkIdSelector( state );
    const startEnforcementLoaderState = startEnforcementLoaderSelector( state );
    const selectedEnforcementStatusIdState = selectedEnforcementStatusIdSelector( state );
    const infringementTypeIdState = selectedInfringementTypeIdSelector( state );
    const activeModuleState = activeModuleStateSelector( state );
    const statusListState = statusListSelector( state );
    const infringementTypeListState = infringementTypeListSelector( state );
    const tableViewState = customizeTableViewSelector( state );
    const cardListState = entriesOverviewSelector( state ) || null;
    return {
        selectedCardsListState,
        activeModuleState,
        statusListState,
        infringementTypeListState,
        selectedAllCardsState,
        labelListState,
        selectedPostMarkIdState,
        startEnforcementLoaderState,
        selectedEnforcementStatusIdState,
        infringementTypeIdState,
        tableViewState,
        cardListState
    };
};

const mergeProps = ( stateProps, dispatchProps, ownProps ) => {
    const { tableViewState, activeModuleState, cardListState } = stateProps;
    const cardListOnPageState = ( cardListState && cardListState.items ) || null;
    const length = ( cardListOnPageState && cardListOnPageState.length ) || null;
    const symbol = ( cardListState && cardListState.currency && cardListState.currency.symbol ) || null;
    return {
        ...stateProps,
        ...dispatchProps,
        ...ownProps,
        length,
        cardListOnPageState,
        tableProps: {
            cells: getCells( tableViewState, `${activeModuleState}TopBar` ),
            chooseItemByFieldName: chooseItemByFieldName,
            symbol: symbol
        }
    };
};

export const OptionsTopBar = connect(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps
)( OptionTopBarDisplay );

import React, { PureComponent } from "react";
import {
    SideBarWrapper,
    HomeBadge,
    CollapsibleInputBlock,
    CollapsibleDatePickerBlock,
    CollapsibleDropDownBlock,
    searchSmall,
    TextInput,
    arrow
    /*
    settingsSmall,
    addSmall,
    uploadSmall,
    downloadSmall,
    */
} from "components";

export class SideBar extends PureComponent {

    render() {
        const {
            sideBarIsOpen,
            toggleSideBarIsOpen,
            onChangeInputFilter,
            searchOnEnter,
            onChangeDateFilter,
            changeInputTitle,
            onChangeDropdownFilter,
            searchByFilter,
            //activeModuleState,
            activeFilter,
            labelsState,
            isLoadingLabels,
            platformListIsLoadingState,
            searchWordTitleState,
            platformListState,
            statusListIsLoadingState,
            statusListState
        } = this.props;
        let sideBarClassName = "side-bar side-bar-flex";
        if ( sideBarIsOpen ) sideBarClassName += " open";

        return (
            <SideBarWrapper
                sideBarClassName={sideBarClassName}
                top={(
                    <>
                        <HomeBadge />
                        <ul className="side-bar-list-icon">
                            <li
                                role="presentation"
                                onClick={toggleSideBarIsOpen}
                                className="filters"
                            >
                                <div
                                    className="icon-wrapper-circle"
                                    title="Filters"
                                >
                                    {searchSmall()}
                                </div>
                            </li>
                            {
                                /*
                                <li>
                                    <Link
                                        to={linkTo}
                                        className="icon-wrapper-circle"
                                        title="Add Item"
                                    >
                                        {addSmall()}
                                    </Link>
                                </li>
                                <li>
                                    <div
                                        className="icon-wrapper-circle"
                                        title="Import"
                                        role="button"
                                        tabIndex={0}
                                    >
                                        {uploadSmall()}
                                    </div>
                                </li>
                                <li>
                                    <div
                                        className="icon-wrapper-circle"
                                        title="Export"
                                        role="button"
                                        tabIndex={0}
                                    >
                                        {downloadSmall()}
                                    </div>
                                </li>
                                */
                            }
                        </ul>
                        </>
                )}
                //bottom={( <div className="icon-wrapper-circle">{settingsSmall()}</div> )}
                slideBar={sideBarIsOpen && (
                    <div className="side-bar-right">
                        <div className="top-panel">
                            <div className="logo-btn-box noselect">
                                <span>Search</span>
                                <div
                                    role="button"
                                    tabIndex={0}
                                    className="side-bar-hide"
                                    onClick={toggleSideBarIsOpen}
                                    onKeyPress={e => e.keyCode === 13 ? toggleSideBarIsOpen() : false}
                                >
                                    {arrow()}
                                </div>
                            </div>
                            <div className="input-panel noselect">
                                <TextInput
                                    placeholder={"e.g. title, criteria..."}
                                    childrenStyle={{
                                        marginRight: "10px"
                                    }}
                                    defaultValue={searchWordTitleState || ""}
                                    handleChange={changeInputTitle}
                                    onKeyPress={searchOnEnter}
                                >
                                    {searchSmall()}
                                </TextInput>
                            </div>
                            <div className="filter-panel scroll-m noselect">
                                <div>
                                    <CollapsibleDropDownBlock
                                        title="Platform"
                                        name="platform"
                                        clearable={true}
                                        loader={platformListIsLoadingState}
                                        data={platformListState}
                                        activeFilterData={activeFilter}
                                        handleChange={onChangeDropdownFilter}
                                        dropHeight={ "200px" }
                                    />
                                    <CollapsibleDropDownBlock
                                        title="Label"
                                        name="postMark"
                                        clearable={true}
                                        loader={isLoadingLabels}
                                        data={labelsState}
                                        activeFilterData={activeFilter}
                                        handleChange={onChangeDropdownFilter}
                                        dropHeight={ "200px" }
                                    />
                                    <CollapsibleDropDownBlock
                                        title="Enforcement status"
                                        name="enforcement"
                                        clearable={true}
                                        loader={statusListIsLoadingState}
                                        data={statusListState}
                                        activeFilterData={activeFilter}
                                        handleChange={onChangeDropdownFilter}
                                        dropHeight={ "200px" }
                                    />
                                    <CollapsibleInputBlock
                                        title="Profile ID"
                                        name="profileId"
                                        defaultValue={activeFilter.profileId}
                                        handleChange={onChangeInputFilter}
                                        onKeyPress={searchOnEnter}
                                    />
                                    <CollapsibleInputBlock
                                        title="Profile URL"
                                        name="profileUrl"
                                        defaultValue={activeFilter.profileUrl}
                                        handleChange={onChangeInputFilter}
                                        onKeyPress={searchOnEnter}
                                    />
                                    <CollapsibleInputBlock
                                        title="Listing ID"
                                        name="listingId"
                                        defaultValue={activeFilter.listingId}
                                        handleChange={onChangeInputFilter}
                                        onKeyPress={searchOnEnter}
                                    />
                                    <CollapsibleInputBlock
                                        title="Batch upload ID"
                                        name="batchUploadId"
                                        defaultValue={activeFilter.batchUploadId}
                                        handleChange={onChangeInputFilter}
                                        onKeyPress={searchOnEnter}
                                    />
                                    <CollapsibleDatePickerBlock
                                        title="Date"
                                        nameFrom="createdAtFrom"
                                        nameTo="createdAtTo"
                                        defaultValueFrom={activeFilter.createdAtFrom}
                                        defaultValueTo={activeFilter.createdAtTo}
                                        handleChange={onChangeDateFilter}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="button-panel">
                            <button
                                type="button"
                                className="btn btn-blue"
                                onClick={searchByFilter}
                            >
                                Apply
                            </button>
                        </div>
                    </div>
                ) }
            />
        );
    }
}

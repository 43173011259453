import React from "react";
import { Button } from "components";
import checkCircle from "./img/check-circle.jpg";
import checkCircle2x from "./img/check-circle@2x.jpg";
import checkCircle3x from "./img/check-circle@3x.jpg";

export const ImportFileModalSuccess = props => {
    const { results, goToPosts, toggleDialogImport } = props;
    return (
        <div className="import-success">
            <div className="wrapper-import-box success">
                <div>
                    <div className="img-success">
                        <picture>
                            <source srcSet={checkCircle3x} media="(min-width: 2521px)" />
                            <source srcSet={checkCircle2x} media="(min-width: 1261px)" />
                            <img src={checkCircle} alt="Market protect" />
                        </picture>
                    </div>
                    <div className="title">
            The {results && results.length}{" "}
                        {results && results.length === 1 ? "post" : "posts"} has been added!
                    </div>
                    <div className="text">
            Your data are being processed. You can track progress{" "}
                        <a
                            href={results && results.batchUrl}
                            rel="noopener noreferrer"
                            target="_blank"
                        >
              here
                        </a>
                    </div>
                </div>
            </div>
            <div className="footer-import">
                <div className="btn-row">
                    <Button text="Go to the posts" handleClick={goToPosts} />
                    <Button
                        text="Close"
                        color="secondary"
                        handleClick={toggleDialogImport}
                    />
                </div>
            </div>
        </div>
    );
};

import { createStore, applyMiddleware, compose } from "redux";
import createSagaMiddleware from "redux-saga";
import { initSagas } from "./initSagas";

import rootReducer from "./rootReducer";

export const getStore = () => {
    const sagaMiddleware = createSagaMiddleware();

    const middleWares = [ sagaMiddleware ];

    const composables = [ applyMiddleware( ...middleWares ) ];
    // Add devTools in development state
    const composeTools = compose;

    let enhancer = composeTools( ...composables );

    if ( process.env.NODE_ENV === "development" ) {
        const actionSanitizer = action =>
            action.type === "FILE_DOWNLOAD_SUCCESS" && action.data
                ? { ...action, data: "<<LONG_BLOB>>" }
                : action;

        if ( window.__REDUX_DEVTOOLS_EXTENSION__ ) {
            composables.push(
                window.__REDUX_DEVTOOLS_EXTENSION__( {
                    actionSanitizer,
                    stateSanitizer: state =>
                        state.data ? { ...state, data: "<<LONG_BLOB>>" } : state
                } )
            );
        }

        enhancer = composeTools( ...composables );
    }

    const store = createStore( rootReducer, enhancer );

    initSagas( sagaMiddleware );
    return store;
};

// This watches changes that need to be coordinated to different sub-services

import { takeLatest, call } from "redux-saga/effects";

import { activeServiceValidationWrapper } from "services/UserView/sagas";
import { setActiveModuleStateAction } from "services/UserView/actions";
import { setActiveModuleStateMPCardWorker } from "./Card/sagas";
import { statusInitialize } from "./Status/sagas";
import { serviceDefinition } from "pages/MarketProtect/services/constants";
import { labelsWorker } from "./Label/sagas";

export function* setActiveModuleStateMPWatcher() {
    yield takeLatest(
        setActiveModuleStateAction,
        activeServiceValidationWrapper,
        {
            ...serviceDefinition,
            worker: setActiveModuleStateMPWorker
        }
    );
}

function* setActiveModuleStateMPWorker( { payload } ) {
    yield call( setActiveModuleStateMPCardWorker, payload );
    yield call( statusInitialize, payload ) ;
    yield call( labelsWorker, payload ) ;
}

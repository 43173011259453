/**
 * Returns the URL path in production based on a target module and sub-module in the new frontend
 *
 * @param {string} targetModule - The target module in Revlect
 * @param {string} targetModuleSubCategory - The target sub-module in Revlect
 *
 * @returns {string}
 */
export const getProdPath = ( targetModule, targetModuleSubCategory ) => {
    // Map each module to a corresponding url in production
    const urlMappings = {
        "social-protect": {
            basePath: "socialmedia/generic/storage/#/enforcement",
            modules: {
                profiles: "profile",
                posts: "post"
            }
        },
        "market-protect": {
            basePath: null,
            modules: {
                listings: "enforcement",
                sellers: "seller"
            }
        },
        "domain-protect": {
            basePath: "domains",
            modules: {
                websites: "website"
            }
        },
        "app-protect": {
            basePath: "socialmedia/app-generic/storage/#/enforcement",
            modules: {
                apps: "app"
            }
        },
        admin: {
            basePath: null,
            modules: {
                users: "users"
            }
        }
    };

    if ( !targetModule || !urlMappings[targetModule] ) return null;

    const hostname = process.env.REACT_APP_REVLECT_BASE_URL;
    const moduleBasePath = urlMappings[targetModule].basePath
        ? `/${urlMappings[targetModule].basePath}`
        : "";
    const moduleStatePath = `/${
        urlMappings[targetModule].modules[targetModuleSubCategory]
    }`;

    return hostname + moduleBasePath + moduleStatePath;
};

import { connect } from "react-redux";
import { OptionTopBarDisplay } from "components";
import { getCells } from "utils";
import {
    chooseAllCardAction,
    deleteAllSelectedCardsAction
} from "pages/SocialProtect/services/Card/actions";
import {
    selectedCardsList,
    cardListDataSelector,
    selectedAllCardsStateSelector
} from "pages/SocialProtect/services/Card/selectors";
import {
    startEnforcementAction,
    selectedPostMarkIdAction
} from "pages/SocialProtect/services/Enforcement/actions";
import {
    selectInfringementTypeInTopBarAction,
    selectEnforcementStatusInTopBarAction
} from "pages/SocialProtect/services/Options/actions";
import {
    selectedPostMarkIdSelector,
    startEnforcementLoaderSelector,
    selectedEnforcementStatusIdSelector,
    selectedInfringementTypeIdSelector
} from "pages/SocialProtect/services/Options/selectors";
import {
    labelsSelector
} from "pages/SocialProtect/services/Label/selectors";

import { customizeTableViewSelector } from "pages/SocialProtect/services/UserView/selectors";

import { statusListSelector } from "services/Status/selectors";
import { infringementTypeListSelector } from "services/InfringementType/selectors";
import { activeModuleStateSelector } from "services/UserView/selectors";
import { chooseItemByFieldName } from "pages/SocialProtect/chooseItemByFieldName";

const mapDispatchToProps = {
    chooseAllCardAction,
    deleteAllSelectedCardsAction,
    selectedPostMarkIdAction,
    startEnforcementAction,
    selectInfringementTypeInTopBarAction,
    selectEnforcementStatusInTopBarAction
};

const mapStateToProps = state => {
    const selectedCardsListState = selectedCardsList( state );
    const cardListState = cardListDataSelector( state );
    const selectedAllCardsState = selectedAllCardsStateSelector( state );
    const labelListState = labelsSelector( state );
    const selectedPostMarkIdState = selectedPostMarkIdSelector( state );
    const activeModuleState = activeModuleStateSelector( state );
    const startEnforcementLoaderState = startEnforcementLoaderSelector( state );
    const statusListState = statusListSelector( state );
    const selectedEnforcementStatusIdState = selectedEnforcementStatusIdSelector( state );
    const infringementTypeListState = infringementTypeListSelector( state );
    const infringementTypeIdState = selectedInfringementTypeIdSelector( state );
    const tableViewState = customizeTableViewSelector( state );
    return {
        activeModuleState,
        selectedCardsListState,
        selectedAllCardsState,
        labelListState,
        selectedPostMarkIdState,
        startEnforcementLoaderState,
        statusListState,
        selectedEnforcementStatusIdState,
        infringementTypeListState,
        infringementTypeIdState,
        tableViewState,
        cardListState
    };
};

const mergeProps = ( stateProps, dispatchProps, ownProps ) => {
    const { tableViewState, activeModuleState, cardListState } = stateProps;
    const cardListOnPageState = ( cardListState && cardListState.results ) || null;
    const length = ( cardListOnPageState && cardListOnPageState.length ) || null;
    return {
        ...stateProps,
        ...dispatchProps,
        ...ownProps,
        cardListOnPageState,
        length,
        tableProps: {
            cells: getCells( tableViewState, `${activeModuleState}TopBar` ),
            chooseItemByFieldName: chooseItemByFieldName
        }
    };
};

export const OptionsTopBar = connect(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps
)( OptionTopBarDisplay );

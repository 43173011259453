import { store } from "index";
import { globalError } from "services/Error/actions";
import { activeBrandConflictErrorAction  } from "services/Brand/actions";
import { activeBrandSelector,isSwitchingBrandSelector } from "services/Brand/selectors";

export const ApiCreator = baseUrl => {
    const fetchCreator = method => async ( url, options ) => {
        try {
            const response = await fetch( `${baseUrl}/${url}`, {
                method,
                credentials: "include",
                ...options
            } );

            await checkStatus( response );
            checkActiveBrandConflict( getActiveBrandFromHeaders( response ) );

            return await response.json();
        } catch( err ) {
            store.dispatch( globalError( err ) );
        }
    };

    return {
        get: fetchCreator( "GET" ),
        post: fetchCreator( "POST" ),
        patch: fetchCreator( "PATCH" ),
        delete: fetchCreator( "DELETE" ),
        put: fetchCreator( "PUT" )
    };
};

const checkStatus = response => {
    if ( response.status >= 200 && response.status < 300 ) {
        return Promise.resolve( response );
    } else {
        return Promise.reject( {
            errorCode: response.status,
            errorMessage: response.statusText
        } );
    }
};

const getActiveBrandFromHeaders = response => {
    for ( let entry of response.headers.entries() ) {
        const key = entry[0];
        const value = entry[1];

        if ( key === "x-brand-id" ) return value;
    }
};

// Checks if the back-end and front-end still have the same brand as the active brand
const checkActiveBrandConflict = sessionBrand => {
    if ( !sessionBrand ) return;

    const state = store.getState();

    const storedBrand = activeBrandSelector( state );
    const isSwitchingBrand = isSwitchingBrandSelector( state );

    if ( !storedBrand || isSwitchingBrand ) return;

    if ( Number( storedBrand ) !== Number( sessionBrand ) ) {
        store.dispatch( activeBrandConflictErrorAction( { sessionBrand, storedBrand } ) );
    }
};


import { takeLatest, put, select } from "redux-saga/effects";

// Actions
import { activeModuleStateSelector } from "services/UserView/selectors";
import { toggleExportDataModalIsOpenAction } from "services/Modal/actions";
import {
    exportDataAction
} from "./actions";
import { exportErrorLinksToCsvAction } from "pages/SocialProtect/components/SideBar/services/ImportData/actions";

// Selectors
import { activeFilterSelector } from "pages/SocialProtect/services/Options/selectors";
import { dataAfterImportSelector } from "pages/SocialProtect/components/SideBar/services/ImportData/selectors";
import { statusListSelector } from "services/Status/selectors";
import { infringementTypeListSelector } from "services/InfringementType/selectors";
import { labelsSelector } from "pages/SocialProtect/services/Label/selectors";
import { countryListSelector } from "services/Country/selectors";
import { platformListSelector } from "services/Platform/selectors";
import { brandsSelector } from "services/Brand/selectors";

// Models
import { PostsTemplate } from "pages/SocialProtect/services/Post/models";
import { ProfilesTemplate } from "pages/SocialProtect/services/Profile/models";

// Workers
function* exportDataActionWorker( { payload } ) {
    //select data
    let activeModuleState = yield select( activeModuleStateSelector );
    const activeFilters = yield select( activeFilterSelector );
    //Make request
    let {
        platform = null,
        postMark = null,
        enforcement = null,
        orderDirection = null,
        profileName = null,
        profileId = null,
        profileUrl = null,
        listingId = null,
        batchUploadId = null,
        createdAtFrom = null,
        createdAtTo = null
    } = activeFilters;
    activeModuleState = activeModuleState.slice( 0, -1 );
    let url = `https://revlect.com/socialmedia/generic/storage/${activeModuleState}/${payload}`;
    let orderBy = null;
    if ( orderDirection ) {
        orderBy = orderDirection.indexOf( "-" ) !== -1 ? orderDirection.slice( 1 ) : orderDirection;
        orderDirection = orderDirection.indexOf( "-" ) !== -1 ? "desc" : "asc";
    }
    if ( orderDirection ) url += `&order_direction=${orderDirection}&order_by=${orderBy}`;
    if ( enforcement ) url += `&enforcement_status_id=${enforcement}`;
    if ( platform ) url += `&platform_id=${platform}`;
    if ( postMark ) url += `&mark_id=${postMark}`;
    if ( profileName ) url += `&profile_name=${profileName}`;
    if ( profileId ) url += `&profile_id=${profileId}`;
    if ( profileUrl ) url += `&profile_url=${profileUrl}`;
    if ( listingId ) url += `&listing_id=${listingId}`;
    if ( batchUploadId ) url += `&batch_upload_id=${batchUploadId}`;
    if ( createdAtFrom ) url += `&created_at_from=${createdAtFrom}`;
    if ( createdAtTo ) url += `&created_at_to=${createdAtTo}`;
    //Close modal
    yield put( toggleExportDataModalIsOpenAction( false ) );
    window.open( url, "_blank" );
}

function* exportErrorLinksToCsvWorker() {
    let template;
    let activeModuleState = yield select( activeModuleStateSelector );
    const dataAfterImportState = yield select( dataAfterImportSelector );

    const statusListState = yield select( statusListSelector );
    const infringementTypeListState = yield select( infringementTypeListSelector );
    const labelsState = yield select( labelsSelector );
    const countryListState = yield select( countryListSelector );
    const platformListState = yield select( platformListSelector );
    const brandListState = yield select( brandsSelector );
    //make object with values/id as key
    const objStatus = {};
    const objInfringementType = {};
    const objPostMark = {};
    const objPlatformList = {};
    const objBrandList = {};

    statusListState.forEach( e => {
        objStatus[e.id] = e.name;
    } );
    infringementTypeListState.forEach( e => {
        objInfringementType[e.id] = e.name;
    } );
    labelsState.forEach( e => {
        objPostMark[e.id] = e.name;
    } );
    platformListState.forEach( e => {
        objPlatformList[e.id] = e.name;
    } );
    brandListState.forEach( e => {
        objBrandList[e.id] = e.name;
    } );

    const arrListOnlyWithError = dataAfterImportState.filter( el => el.errorsArr && el.errorsArr.length );
    const firstRow = arrListOnlyWithError[0];

    if ( activeModuleState === "posts" ) template = PostsTemplate();
    else if ( activeModuleState === "profiles" ) template = ProfilesTemplate();
    else throw new Error( "Unknown module state" ); //panic!

    const titleRow = [];
    for ( let key in firstRow ) {
        for ( let index in template ) {
            if ( key === "profile" ) {
                const profileRow = firstRow.profile;
                for ( let indexProfile in profileRow ) {
                    if ( template[index].key === indexProfile && template[index].profile ) {
                        titleRow.push( index );
                    }
                }
            } else {
                if ( template[index].key === key && !template[index].profile ) {
                    titleRow.push( index );
                }
            }
        }
    }
    titleRow.push( "Errors" );
    const arrData = arrListOnlyWithError.map( e => {
        let rowData = [];
        for ( let key in e ) {
            if ( key === "errorsArr" || key === "errorsRow" ) continue;
            if ( key === "profile" ) {
                for ( let profileKey in e[key] ) {
                    let dataString = e[key][profileKey] ? e[key][profileKey].toString() : "";
                    if ( profileKey === "countryCode2" )
                        dataString = countryListState[e[key][profileKey]] || "";
                    rowData.push( dataString );
                }
            } else {
                let dataString = e[key] ? e[key].toString() : "";
                if ( key === "brandID" ) dataString = objBrandList[e[key]] || "";
                if ( key === "platform" ) dataString = objPlatformList[e[key]] || "";
                if ( key === "countryCode2" ) dataString = countryListState[e[key]] || "";
                if ( key === "mark" ) dataString = objPostMark[e[key]] || "";
                if ( key === "infringementType" ) dataString = objInfringementType[e[key]] || "";
                if ( key === "enforcementStatus" ) dataString = objStatus[e[key]] || "";
                rowData.push( dataString );
            }
        }
        for ( let key in e ) {
            if ( key === "errorsArr" ) {
                let errorList = "";
                e[key].forEach( ( e, i ) => {
                    errorList += e.toString() + " ";
                } );
                rowData.push( errorList );
            }
        }
        return rowData;
    } );
    const arrToCreate = [ ...[ titleRow ], ...arrData ];
    let csvContent = "data:text/csv;charset=utf-8,";

    arrToCreate.forEach( function( rowArray ) {
        rowArray = rowArray.map( e => e.replace( ",", "." ) );
        let row = rowArray.join( "," );
        csvContent += row + "\r\n";
    } );

    let encodedUri = encodeURI( csvContent );
    let link = document.createElement( "a" );
    link.setAttribute( "href", encodedUri );
    link.setAttribute( "download", "errors.csv" );
    document.body.appendChild( link ); // Required for FF
    link.click();
    document.body.removeChild( link );
}


// Watchers
export function* exportDataWatcher() {
    yield takeLatest( exportDataAction, exportDataActionWorker );
}

export function* exportErrorLinksToCsvWatcher() {
    yield takeLatest( exportErrorLinksToCsvAction, exportErrorLinksToCsvWorker );
}
import { App as component } from "./App";
import { connect } from "react-redux";
import { alertModalIsOpenSelector, alertModalConfigurationSelector } from "services/Modal/selectors";
import { setAlertModalIsOpenAction, setAlertModalConfigurationAction } from "services/Modal/actions";
import { saveMessageBodyAction } from "services/Message/actions";
import { globalMessageSelector } from "services/Message/selectors";

const mapDispatchToProps = {
    saveMessageBodyAction,
    setAlertModalIsOpenAction,
    setAlertModalConfigurationAction
};

const mapStateToProps = state => {
    return {
        message: globalMessageSelector( state ),
        alertModalIsOpen: alertModalIsOpenSelector( state ),
        alertModalConfiguration: alertModalConfigurationSelector( state )
    };
};

export const App = connect(
    mapStateToProps,
    mapDispatchToProps,
    undefined,
    { pure: false }
)( component );
import React from "react";
import ReactPaginate from "react-paginate";

export const Paginator = ( { page, countAvailable, limit, handlePageChange } ) => {

    const pageCount = Math.ceil( countAvailable / limit );

    return (
        <ReactPaginate
            previousLabel={"<"}
            previousClassName={"previous"}
            nextClassName={"next"}
            nextLabel={">"}
            breakLabel={"..."}
            breakClassName={"break-me"}
            initialPage={page - 1}
            forcePage={page - 1}
            disableInitialCallback={true}
            pageCount={pageCount}
            marginPagesDisplayed={2}
            pageRangeDisplayed={4}
            onPageChange={handlePageChange}
            containerClassName={"pagination-list"}
            activeClassName={"active"}
        />
    );
};
import React from "react";
import "./SetStatusTypeButtons.scss";
import { ButtonHoverSVG } from "components";

export const SetStatusTypeButtons = props => {
    return (
        <div className="set-infringing-status-type">
            <ButtonHoverSVG onClick={() => props.setOptions( "type" )}>
                {getTypeName( props.data, props.sameTypeId ) || "Set Type"}
            </ButtonHoverSVG>
            <ButtonHoverSVG onClick={() => props.setOptions( "status" )}>
                {getStatusName( props.data, props.sameStatusId ) || "Set Status"}
            </ButtonHoverSVG>
        </div>
    );
};

const getStatusName = ( data, sameStatusId ) => {
    const CurrentStatusName =  data && data.enforcementStatus && data.enforcementStatus.name;
    return sameStatusId && CurrentStatusName;
};

const getTypeName = ( data, sameTypeId ) => {
    const CurrentTypeName =  data && data.infringementType && data.infringementType.name;
    return sameTypeId && CurrentTypeName;
};

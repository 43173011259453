export function ProfilesTemplate() {
    return {
        Brand: { key: "brandID" },
        Platform: { key: "platform" },
        "Profile name": { key: "profileName" },
        "Profile ID": { key: "profileID" },
        "Profile URL": { key: "profileURL" },
        "Profile picture": { key: "profileImage" },
        "Profile description": { key: "profileDescription" },
        "Profile followers": { key: "profileFollowers" },
        "Profile views": { key: "profileViews" },
        "Profile likes": { key: "profileLikes" },
        "Profile posts": { key: "profilePosts" },
        "Evidence URL": { key: "evidenceURL" },
        "Connected to": { key: "connectedTo" },
        Country: { key: "countryCode2" },
        Label: { key: "mark" },
        "Infringement type": { key: "infringementType" },
        "Enforcement status": { key: "enforcementStatus" },
        Comments: { key: "note" }
    };
}

export const columnsProfiles = {
    taskIds: [
        "Status",
        "Platform",
        "Profile",
        "Posts in DB",
        "Views",
        "Date",
        "Description"
    ]
};

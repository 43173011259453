import { call, put, select } from "redux-saga/effects";

import * as PlatformAPI from "./api";
import { loadPlatformListAction, savePlatformListAction } from "./actions";
import { activeBrandSelector } from "services/Brand/selectors";

//WORKERS
function* platformWorker() {
    //Loading Brands
    yield put( loadPlatformListAction( true ) );
    //Get brands list from API
    const activeBrand = yield select( activeBrandSelector );
    const response = yield call( PlatformAPI.getPlatformList, activeBrand );
    if ( response ) yield put( savePlatformListAction( response ) );
    yield put( loadPlatformListAction( false ) );
}

// WATCHERS
export function* watchingPlatform() {
    yield call( platformWorker );
}

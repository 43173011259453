import { takeLatest } from "redux-saga/effects";

import { activeFilterSelector } from "pages/MarketProtect/services/Options/selectors";
import { populateCardsAction } from "pages/MarketProtect/services/Card/actions";
import { activeServiceValidationWrapper } from "services/UserView/sagas";
import { serviceDefinition } from "pages/MarketProtect/services/constants";

import { generateApplyFilterFromUrlWorker } from "services/Navigation/sagas";
import { navigationCompletedAction } from "services/Navigation/actions";
const applyFilterFromUrl = generateApplyFilterFromUrlWorker( activeFilterSelector, populateCardsAction );

export function* applyFilterFromUrlMPWatcher() {
    yield takeLatest(
        navigationCompletedAction,
        activeServiceValidationWrapper,
        {
            ...serviceDefinition,
            worker: applyFilterFromUrl
        }
    );
}
import { createSelector } from "reselect";
import { activeModuleStateSelector } from "services/UserView/selectors";

export const pageViewStateSelector = createSelector(
    state => {
        const activeModuleState = activeModuleStateSelector( state );
        return state.socialProtect.userView[activeModuleState].page_list_view;
    },
    state => state
);

export const customizeTableViewSelector = createSelector(
    state => {
        const activeModuleState = activeModuleStateSelector( state );
        if ( activeModuleState === "posts" ) {
            return state.socialProtect.userView.posts.customize_table_view;
        } else if ( activeModuleState === "profiles" ) {
            return state.socialProtect.userView.profiles.customize_table_view;
        }
    },
    state => state
);

export const tableColumnSelectedTasksSelector = createSelector(
    state => {
        const activeModuleState = activeModuleStateSelector( state );
        if ( activeModuleState === "posts" ) {
            return state.socialProtect.userView.posts.customize_table_view
                .list_enabled_item;
        } else if ( activeModuleState === "profiles" ) {
            return state.socialProtect.userView.profiles.customize_table_view
                .list_enabled_item;
        }
    },
    state => state
);

export const tableColumnSelectedColumnsSelector = createSelector(
    state => {
        const activeModuleState = activeModuleStateSelector( state );
        if ( activeModuleState === "posts" ) {
            return state.socialProtect.userView.posts.customize_table_view.columns;
        } else if ( activeModuleState === "profiles" ) {
            return state.socialProtect.userView.profiles.customize_table_view.columns;
        }
    },
    state => state
);

export const pageCardDisplayViewSelector = createSelector(
    state => {
        const activeModuleState = activeModuleStateSelector( state );
        return state.socialProtect.userView[activeModuleState].page_card_display_view;
    },
    state => state
);

export const spViewDetailItemDataSelector = createSelector(
    state => {
        const activeModuleState = activeModuleStateSelector( state );
        const id = state.socialProtect[activeModuleState].item_view_detail;

        if ( !id ) return null;

        const currentItem = state.socialProtect[
            activeModuleState
        ].card_list_data.results.filter( e => e.id === id )[0];

        return {
            data: currentItem,
            index: state.socialProtect[activeModuleState].card_list_data.results.indexOf(
                currentItem
            ),
            lengthList:
        state.socialProtect[activeModuleState].card_list_data.results.length
        };
    },
    state => state
);

export const selectedViewDetailItemSelector = createSelector(
    state => {
        const activeModuleState = activeModuleStateSelector( state );
        return state.socialProtect[activeModuleState].item_view_detail;
    },
    state => state
);

export const socialProtectViewDetailItemDataSelector = createSelector(
    state => {
        const activeModuleState = activeModuleStateSelector( state );
        const id = state.socialProtect[activeModuleState].item_view_detail;
        if ( !id ) return null;
        const currentItem = state.socialProtect[
            activeModuleState
        ].card_list_data.results.filter( e => e.id === id )[0];
        return {
            data: currentItem,
            index: state.socialProtect[activeModuleState].card_list_data.results.indexOf(
                currentItem
            ),
            lengthList:
        state.socialProtect[activeModuleState].card_list_data.results.length
        };
    },
    state => state
);

import { createAction } from "redux-actions";

export const isLoadingBrandsAction = createAction( "isLoadingBrandsAction" );
export const saveBrandsAction = createAction( "saveBrandsAction" );
export const saveBrandsFavoriteListAction = createAction(
    "saveBrandsFavoriteListAction"
);
export const addBrandToFavoritesAction = createAction(
    "addBrandToFavoritesAction"
);
export const saveActiveBrandAction = createAction(
    "saveActiveBrandAction"
);
export const activeBrandSwitchedAction = createAction(
    "activeBrandSwitchedAction"
);
export const activeBrandConflictErrorAction = createAction( "activeBrandConflictErrorAction" );
export const isSwitchingBrandAction = createAction( "isSwitchingBrandAction" );
export const storeActiveBrandAction = createAction( "storeActiveBrandAction" );
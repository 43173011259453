import { call, put, takeLatest, select } from "redux-saga/effects";

import { activeServiceValidationWrapper } from "services/UserView/sagas";
import { activeModuleStateSelector } from "services/UserView/selectors";
import { activeBrandSwitchedAction } from "services/Brand/actions";
import { serviceDefinition } from "pages/SocialProtect/services/constants";

import { generateLabelsWorker } from "services/Label/sagas";
import { labelsSelector } from "./selectors";
import * as PostMarkAPI from "./api";
import {
    storeIsLoadingLabelsAction,
    storeLabelsAction
} from "./actions";

function* fetchLabelWorker( { activeBrand, activeModuleState } ) {
    const response = yield call( PostMarkAPI.getMarkList, { activeBrand: activeBrand, markType: activeModuleState.slice( 0, -1 ) } );
    if ( response ) {
        response.unshift( { id: "0", code: "UNHANDLED", name: "Unhandled" } );
        yield put( storeLabelsAction( { labels: response, activeModuleState } ) );
    }
}

export const labelsWorker = generateLabelsWorker( labelsSelector, storeIsLoadingLabelsAction, fetchLabelWorker );

export function* activeBrandSwitchedLabelSPWatcher() {
    yield takeLatest(
        activeBrandSwitchedAction,
        activeServiceValidationWrapper,
        {
            ...serviceDefinition,
            worker: function* ( { payload } ){
                const activeModuleState = yield select( activeModuleStateSelector );
                yield call( labelsWorker, activeModuleState );
            }
        }
    );
}
let isRedirecting = false;

const setUrl = ( url ) => {
    isRedirecting = false;

    window.location.replace( url );
};

export const Redirecter = ( { message, url, timeout } ) => {
    if ( !isRedirecting ) {
        isRedirecting = true;

        if ( !timeout ) setUrl( url );
        else setTimeout( () => setUrl( url ), timeout );
    }

    return message || "";
};
import { takeLatest, put, call, select, delay } from "redux-saga/effects";
import { urlUtil } from "utils";

//API
import * as API from "pages/MarketProtect/services/Entries/api";

// Actions
import {
    saveFilterAndSearchAction,
    storeFilterAction,
    saveFilterAction,
    searchByFilterAction
} from "./actions";
import {
    isLoadingEntriesAction,
    saveEntriesAction
} from "pages/MarketProtect/services/Card/actions";
// Selectors
import { activeModuleStateSelector } from  "services/UserView/selectors";
import { activeBrandSelector } from  "services/Brand/selectors";
import {
    allFiltersSelector,
    activeFilterSelector
}from "pages/MarketProtect/services/Options/selectors";

//Utils
const { modifyURLByFiltersWorker }  = urlUtil;

// Workers
function* searchByFilterWorker() {
    // select data
    const activeBrand = yield select( activeBrandSelector );
    const activeModuleState = yield select( activeModuleStateSelector );
    const activeFilters = yield select( activeFilterSelector );
    //Start loading
    yield put( isLoadingEntriesAction( true ) );

    const newFilter = {
        ...activeFilters,
        brand: activeBrand,
        module: activeModuleState
    };

    yield delay( 1000 );
    yield call( modifyURLByFiltersWorker, newFilter );
    const response = yield call( API.getEntries, newFilter );
    yield put( saveEntriesAction( { data: response, category: activeModuleState } ) );
    yield put( isLoadingEntriesAction( false ) );
}

export function* saveFilterAndSearchWorker( { payload } ) {
    if ( !payload ) {
        console.warn( "Called saveFilterAndSearchWorker without payload" );
        return;
    }

    // Init save value and later API call by filters values
    yield call( saveFilterWorker, { payload } );
    yield call( searchByFilterWorker );
}

export function* saveFilterWorker( { payload } ) {
    if ( !payload ) {
        console.warn( "Called saveFilterWorker without payload" );
        return;
    }

    const { moduleState } = payload;
    //Add filter value in store without api call
    const activeModuleState = yield select( activeModuleStateSelector );
    const activeFilters = moduleState
        ? yield select( allFiltersSelector )
        : yield select( activeFilterSelector );
    let newFilterData = { ...activeFilters };
    if ( moduleState ) newFilterData = { ...activeFilters[moduleState] };
    for ( let key in payload ) {
        if ( Object.prototype.hasOwnProperty.call( payload, key ) ) {
            if ( Object.prototype.hasOwnProperty.call( newFilterData, key ) ) newFilterData[key] = payload[key];
        }
    }
    yield put(
        storeFilterAction( { payload: newFilterData, activeModuleState: moduleState || activeModuleState } ),
    );
}

// Watchers
export function* saveFilterAndSearchMPWatcher() {
    yield takeLatest( saveFilterAndSearchAction, saveFilterAndSearchWorker );
}

export function* saveFilterMPWatcher() {
    yield takeLatest( saveFilterAction, saveFilterWorker );
}

export function* searchByFilterMPWatcher() {
    yield takeLatest( searchByFilterAction, searchByFilterWorker );
}
import React from "react";
import { ModuleCard } from "pages/Landing/components";
import "./DefaultGridView.scss";

export const DefaultGridView = ( { modules } ) => (
    <div className="landingContainer-defaultGridView">
        {modules.map(
            ( x, i ) => {
                return (
                    <ModuleCard
                        {...x}
                        key={x.identifier}
                        description={x.description}
                        preferLocalPath={true}
                        className=""
                    />
                );
            }
        )}
    </div>
);
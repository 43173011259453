import React, { PureComponent } from "react";
import { ViewDetailsWrapper } from "components";
import { ViewDetailsPanel } from "./components";
import "./ViewDetails.scss";

const options = { capture: true };

export class ViewDetails extends PureComponent {
    constructor( props ) {
        super( props );
        this.myRef = React.createRef();
        this.state = {
            activeTab: null,
            module: null,
            optionValue: null
        };
    }

    static getDerivedStateFromProps( nextProps, prevState ) {
        if (
            nextProps.activeModuleState &&
            nextProps.activeModuleState !== prevState.module
        ) {
            if ( nextProps.activeModuleState === "posts" ) {
                return {
                    activeTab: "posts",
                    module: nextProps.activeModuleState
                };
            }
            return {
                activeTab: "profile",
                module: nextProps.activeModuleState
            };
        }
        return null;
    }

    componentDidMount() {
        document.addEventListener( "click", this.documentClick, options );
    }

    componentWillUnmount() {
        document.removeEventListener( "click", this.documentClick, options );
    }

    documentClick = e => {
        const { showViewDetailsPanelMPAction } = this.props;
        const node = this.myRef.current;
        if ( e.target.closest( ".view-details-panel" ) !== node ) {
            showViewDetailsPanelMPAction( null );
            // this.closeTab();
        }
    };

    closePanel = () => {
        const { showViewDetailsPanelMPAction } = this.props;
        //close tab
        this.setOptions( null );
        //clear props
        showViewDetailsPanelMPAction(  null );
    };

    setCurrentInfringementType = e => {
        //Save ID InfringementType in store
        const {
            selectInfringementTypeInTopBarAction,
            selectEnforcementStatusInTopBarAction
        } = this.props;
        const { optionValue } = this.state;
        if ( optionValue === "type" ) {
            selectInfringementTypeInTopBarAction( e );
        } else if ( optionValue === "status" ) {
            selectEnforcementStatusInTopBarAction( e );
        }
    };

    setOptions = optionValue => {
        this.setState( {
            optionValue: optionValue
        } );
    };

    closeTab = () => {
        const { selectedPostMarkIdAction } = this.props;
        //Clear props
        selectedPostMarkIdAction( null );
        this.setCurrentInfringementType( null );
        //Clear state
        this.setState( { optionValue: null } );
    };

    closeList = () => {
        this.setCurrentInfringementType( null );
        this.setState( {
            optionValue: null
        } );
    };

    selectedPostMark = e => {
        const {
            selectedPostMarkIdAction,
            selectedNewPostMarkAction,
            viewDetailItemDataState
        } = this.props;
        //set value to store
        selectedPostMarkIdAction( e );
        if ( e !== 1 ) {
            //make a call to API with change values
            selectedNewPostMarkAction( {
                ids: [ viewDetailItemDataState.data.id ],
                mark_id: e
            } );
        }
    };

    clickApplyInfringement = () => {
        const { optionValue } = this.state;
        const {
            selectedPostMarkIdAction,
            startEnforcementAction,
            viewDetailItemDataState
        } = this.props;
        selectedPostMarkIdAction( 1 );
        return new Promise( ( resolve, reject ) => {
            startEnforcementAction( {
                optionValue: optionValue,
                id: [ viewDetailItemDataState.data.id ],
                resolve,
                reject
            } );
        } ).then( () => {
            this.closePanel();
        } );
    };

    render() {
        const {
            viewDetailItemDataState,
            goToNextViewDetailsMPAction,
            infringementTypeIdState,
            selectedEnforcementStatusIdState,
            statusListState,
            infringementTypeListState
        } = this.props;
        const { optionValue } = this.state;
        if (
            !viewDetailItemDataState ||
            ( viewDetailItemDataState && !viewDetailItemDataState.data )
        )
            return null;
        const { data } = viewDetailItemDataState;
        let valueList = null;
        let changeListValues = [];
        if ( optionValue === "type" ) {
            changeListValues = infringementTypeListState;
            valueList = infringementTypeIdState || ( data.infringementType && data.infringementType.id );
        } else if ( optionValue === "status" ) {
            changeListValues = statusListState.items;
            valueList = selectedEnforcementStatusIdState  || ( data.enforcementStatus && data.enforcementStatus.id );
        }
        return (
            <ViewDetailsWrapper myRef={ this.myRef } >
                <ViewDetailsPanel
                    { ...this.props }
                    data={viewDetailItemDataState.data}
                    index={viewDetailItemDataState.index}
                    lengthList={viewDetailItemDataState.lengthList}
                    closePanel={this.closePanel}
                    goToNextViewDetailsAction={goToNextViewDetailsMPAction}
                    selectedPostMark={this.selectedPostMark}
                    setOptions={this.setOptions}
                    closeList={this.closeList}
                    setCurrentInfringementType={this.setCurrentInfringementType}
                    clickApplyInfringement={this.clickApplyInfringement}
                    optionValue={optionValue}
                    valueList={valueList}
                    changeListValues={changeListValues}
                />
            </ViewDetailsWrapper>
        );
    }
}

import React, { PureComponent } from "react";
import {
    Avatar,
    TabBar,
    TextInput,
    Loader,
    ButtonClose,
    arrow,
    starSvg,
    starActiveSvg,
    searchSmall
} from "components";
import { logoImage } from "./LogoImage";
import { genCharArray, sortAlphabetically } from "utils";
import "./BrandSelect.scss";


const BrandList = ( {
    brands,
    favouriteBrands,
    mode,
    availableHeight,
    scrollTop,
    setBrandAsActive,
    addBrandToFavorite
} ) => {

    let listOfBrands = brands || null;
    if ( mode === "starred" && listOfBrands ) {
        listOfBrands = listOfBrands.filter(
            e => favouriteBrands.indexOf( e.id ) !== -1
        );
    }

    if ( !listOfBrands ) return null;

    //render list of Brands
    const marginBot = 30;
    const itemHeight = 185 + marginBot;
    const itemInRow = 6;
    const numLength = listOfBrands && listOfBrands.length;
    const numRows = Math.ceil( numLength / itemInRow );

    const totalHeight = itemHeight * numRows;
    const scrollBottom = scrollTop + availableHeight;

    const startIndex = Math.max(
        0,
        Math.floor( scrollTop / itemHeight ) * itemInRow - 10
    );
    const endIndex = Math.min(
        numRows * itemInRow,
        Math.ceil( scrollBottom / itemHeight ) * itemInRow + 10
    );

    const sortedBrands = sortAlphabetically( listOfBrands, true, "name" )
        .sort( ( a, b ) => {
            const aIsFavourite = favouriteBrands.indexOf( a.id ) === -1;
            const bIsFavourite = favouriteBrands.indexOf( b.id ) === -1;

            if( aIsFavourite && !bIsFavourite ) return 1;
            if( !aIsFavourite && bIsFavourite ) return -1;
            return 0;
        } );

    const items =  sortedBrands.reduce( ( total, elem, index ) => {
        if ( index < endIndex && index >= startIndex ) {
            const isFavourite = favouriteBrands.indexOf( elem.id ) === -1;

            total.push(
                <li
                    className="brands__item"
                    key={index}
                    onClick={el => setBrandAsActive( elem, el )}
                    style={{ marginBottom: marginBot }}
                    role="presentation"
                >
                    <button
                        className="select-brand"
                        type="button"
                        onClick={() => addBrandToFavorite( elem )}
                    >
                        {isFavourite
                            ? starSvg( "#cbdae0" )
                            : starActiveSvg()
                        }
                    </button>
                    <div>
                        <Avatar
                            data={[
                                !elem.logo || elem.logo === "" ? logoImage() : elem.logo
                            ]}
                            size={"65"}
                        />
                        <span>{elem.name}</span>
                    </div>
                </li>
            );
        }

        return total;
    }, [] );

    return (
        <ul
            style={{
                paddingTop: startIndex * ( itemHeight / itemInRow ),
                height: totalHeight
            }}
        >
            {items}
        </ul>
    );
};


export class BrandSelect extends PureComponent {
    constructor( props ) {
        super( props );
        this.myRef = React.createRef();
        this.topDrop = null;
        this.timeout = null;
        this.state = {
            open: false,
            mode: "brands",
            searchValue: "",
            brands: null,
            filter: null,
            scrollTop: 0,
            availableHeight: 661
        };
    }

    static getDerivedStateFromProps( nextProps, prevState ) {
        if ( nextProps.brandsState && !prevState.brands ) {
            return {
                brands: nextProps.brandsState
            };
        }
        return null;
    }

    componentDidMount() {
        if ( localStorage.brandTab ) {
            this.setState( {
                mode: localStorage.brandTab
            } );
        }

        document.addEventListener( "click", this.documentClick, { capture: true } );
    }

    componentDidUpdate() {
        const { favouriteBrands } = this.props;
        const { brands, mode } = this.state;
        if ( mode !== "starred" || !brands ) return;
        const newArr = brands.filter(
            e => favouriteBrands.indexOf( e.id ) !== -1
        );
        if ( !newArr.length ) {
            this.setState( { mode: "brands" } );
        }
    }

    componentWillUnmount() {
        document.removeEventListener( "click", this.documentClick, {
            capture: true
        } );
    }

    documentClick = e => {
        const node = this.myRef.current;
        if ( e.target.closest( ".brand-select" ) !== node ) this.closeDropDown();
    };

    changeStateCardActions = () => {
        if ( !this.topDrop ) {
            const rect = document
                .getElementById( "brand-select" )
                .getBoundingClientRect();
            this.topDrop = 20 + rect.height + rect.top;
        }
        this.setState(
            prevState => ( { open: !prevState.open } ),
            () => {
                this.changeScrollBody( this.state.open );
            }
        );
    };

    closeDropDown = () => {
        this.setState( { open: false } );
        this.changeScrollBody( false );
    };

    changeScrollBody = index => {
        if ( index ) {
            document.documentElement.style.overflow = "hidden"; // firefox, chrome
            document.body.scroll = "no";
        } else {
            document.documentElement.style.overflow = "auto"; // firefox, chrome
            document.body.scroll = "yes"; // ie only
        }
    };

    changeMode = mode => {
        localStorage.brandTab = mode;
        this.setState( { mode } );
    };

    addBrandToFavorite = e => {
        const { addBrandToFavoritesAction } = this.props;
        addBrandToFavoritesAction( e.id );
    };

    setBrandAsActive = ( e, el ) => {
        if ( el.target.tagName === "BUTTON" || el.target.closest( "svg" ) ) return;
        const { saveActiveBrandAction } = this.props;

        this.setState(
            prevState => ( { open: !prevState.open } ),
            () => {
                saveActiveBrandAction( { id: e.id, shouldUpdateSession: true } );
                this.changeScrollBody( this.state.open );
            }
        );
    };

    scrollListBoxToTop = () => {
        const elem = document.getElementsByClassName( "list-brands" )[0];
        if ( elem.scrollTop > 0 ) {
            elem.scrollTop = 0;
        }
    };

    filterAlphabet = e => {
        const val = e.target.value;
        const { filter } = this.state;
        const { brandsState } = this.props;
        if ( filter === val ) {
            this.setState( {
                brands: brandsState,
                searchValue: "",
                filter: null
            } );
        } else {
            const newArr = brandsState.filter( e => {
                if ( val.indexOf( "9" ) > -1 && Number.isInteger( Number( e.name.charAt( 0 ) ) ) )
                    return e;
                if ( e.name.charAt( 0 ).toLowerCase() === val.toLowerCase() ) return e;
                return false;
            } );
            this.setState( {
                brands: newArr,
                searchValue: "",
                filter: val
            } );
            this.scrollListBoxToTop();
        }
    };

    searchChange = e => {
        const { value } = e.target;
        const { brandsState } = this.props;
        const that = this;
        if ( this.timeout ) {
            clearTimeout( this.timeout );
        }
        this.setState( {
            searchValue: value
        } );
        this.timeout = setTimeout( () => {
            if ( value === "" ) {
                that.setState( {
                    filter: null,
                    brands: brandsState
                } );
            } else {
                const newArr = brandsState.filter( e => {
                    if ( e.name.toLowerCase().search( value.toLowerCase() ) !== -1 ) return e;
                    return false;
                } );
                that.setState( {
                    filter: null,
                    brands: newArr
                } );
                this.scrollListBoxToTop();
            }
        }, 800 );
    };

    handleScroll = event => {
        this.setState( {
            scrollTop: event.target.scrollTop
        } );
    };

    genCharArray( charA, charZ ) {
        var a = [],
            i = charA.charCodeAt( 0 ),
            j = charZ.charCodeAt( 0 );
        for ( ; i <= j; ++i ) {
            a.push( String.fromCharCode( i ) );
        }
        return a;
    }

    render() {
        const {
            favouriteBrands = [],
            loadingBrandState = false,
            activeBrandState,
            isSwitchingBrand,
            brandsState
        } = this.props;
        const {
            open,
            mode,
            brands,
            filter,
            searchValue
        } = this.state;
        let brandSelectClassName = "brand-select";
        if ( open ) brandSelectClassName += " show";
        if ( !activeBrandState || loadingBrandState || isSwitchingBrand )
            return <Loader style={{ marginLeft: "50px", marginRight: "50px" }} />;
        let activeBrandData = null;
        if ( brandsState && brandsState.length ) {
            activeBrandData = brandsState.filter(
                e => e.id === Number( activeBrandState )
            )[0];
        }

        return (
            <div
                ref={this.myRef}
                className={brandSelectClassName}
                id="brand-select"
            >
                <div
                    className="front-box"
                    onClick={this.changeStateCardActions}
                    role="presentation"
                >
                    <Avatar data={[ activeBrandData && activeBrandData.logo ]} />
                    <div className="brand-select__box">
                        <div className="brand-select__description">
                            <span>Brand</span>
                            <div className="brand-select__name">
                                {activeBrandData ? activeBrandData.name : "Not selected"}
                            </div>
                        </div>
                        <button type="button">{arrow()}</button>
                    </div>
                </div>
                <div
                    className="brand-select__drop-down scroll-m"
                    style={{
                        top: this.topDrop,
                        height: `calc(100vh - ${this.topDrop}px)`
                    }}
                >
                    <ButtonClose
                        className="close-btn"
                        color="#979797"
                        onClick={this.changeStateCardActions}
                    />
                    <div className="search-panel">
                        <TextInput
                            placeholder={"Search…"}
                            wrapperStyle={{ flexDirection: "row-reverse" }}
                            childrenStyle={{
                                marginRight: "10px"
                            }}
                            handleChange={this.searchChange}
                            defaultValue={searchValue}
                        >
                            {searchSmall()}
                        </TextInput>
                    </div>
                    <div className="filter-box">
                        <TabBar>
                            <button
                                type="button"
                                className={mode === "brands" ? "active" : ""}
                                onClick={() => this.changeMode( "brands" )}
                            >
                                All brands
                            </button>
                            <button
                                type="button"
                                className={mode === "starred" ? "active" : ""}
                                onClick={() => this.changeMode( "starred" )}
                                disabled={favouriteBrands.length === 0}
                            >
                                Starred
                            </button>
                        </TabBar>
                        <div className="alphabet-filter">
                            <ul>
                                <li>
                                    <button
                                        type="button"
                                        onClick={this.filterAlphabet}
                                        value="9"
                                        className={filter === "9" ? "active" : ""}
                                    >
                                        0-9
                                    </button>
                                </li>
                                {genCharArray( "A", "Z" ).map( letter => (
                                    <li key={letter}>
                                        <button
                                            type="button"
                                            onClick={this.filterAlphabet}
                                            value={letter}
                                            className={filter === letter ? "active" : ""}
                                        >
                                            {letter}
                                        </button>
                                    </li>
                                ) )}
                            </ul>
                        </div>
                    </div>

                    <div
                        className="list-brands"
                        style={{
                            height: "100%",
                            overflowY: "scroll"
                        }}
                        onScroll={this.handleScroll}
                    >
                        {brands && brands.length && open &&
                        (
                            <BrandList
                                brands={brands}
                                favouriteBrands={favouriteBrands}
                                mode={mode}
                                setBrandAsActive={this.setBrandAsActive}
                                addBrandToFavorite={this.addBrandToFavorite}
                                availableHeight={this.state.availableHeight}
                                scrollTop={this.state.scrollTop}
                            />
                        )}
                    </div>
                </div>
            </div>
        );
    }
}

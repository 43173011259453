import { call, put, select, takeLatest } from "redux-saga/effects";
import * as StatusAPI from "./api";
import { loadStatusListAction, saveStatusListAction } from "./actions";
import { activeBrandSelector } from "services/Brand/selectors";
import { activeBrandSwitchedAction } from "services/Brand/actions";

//WORKERS
function* statusInitialiser() {
    //Loading Brands
    yield put( loadStatusListAction( true ) );
    //Get brands list from API
    const activeBrand = yield select( activeBrandSelector );
    const response = yield call( StatusAPI.getStatusList, activeBrand );
    if ( response ) yield put( saveStatusListAction( response ) );
    yield put( loadStatusListAction( false ) );
}

// WATCHERS
export function* watchingStatus() {
    yield takeLatest( activeBrandSwitchedAction, statusInitialiser );
}

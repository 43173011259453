/*
    If abstracted further and following a similar path as the label service,
    this could become boilerplate for the other modules.

    That is to say, to avoid repetition, each module could generate a service like this
    by requesting such a service from a global service.
    This is similar to how the label service is structured.
*/
import { handleActions } from "redux-actions";

//@TODO: Shouldn't we migrate (many) of these actions into the entries service?
import {
    saveEntriesAction,
    isLoadingEntriesAction,
    saveIDItemSelectedAction,
    selectedCardSaveAction,
    chooseAllCardSaveAction,
    showDetailsMixedViewAction
} from "pages/MarketProtect/services/Card/actions";

import {
    storeIsLoadingMoreAction
} from "./actions";

import { getCategorySelectionKey } from "./selectors";

function generateSelectionCategory() {

    return {
        hasSelectedAll: false,
        arrID: [],
        itemIdViewDetail: null
    };
}

const initialState = {
    isLoading: false,
    isLoadingMore: false,
    itemIdMixedView: null,
    listings: null,
    listingsSelection: generateSelectionCategory(),
    sellers: null,
    sellersSelection: generateSelectionCategory()
};

export const marketProtectEntriesReducer = handleActions(
    {
        [isLoadingEntriesAction]: ( state, { payload } ) => {

            return {
                ...state,
                isLoading: payload
            };
        },
        [storeIsLoadingMoreAction]: ( state, { payload } ) => {

            return {
                ...state,
                isLoadingMore: payload
            };
        },
        [showDetailsMixedViewAction]: ( state, { payload } ) => {

            return {
                ...state,
                itemIdMixedView: payload
            };
        },
        [saveEntriesAction]: ( state, { payload } ) => {
            const { data, category } = payload;

            return {
                ...state,
                [category]: data
            };
        },
        [saveIDItemSelectedAction]: ( state, { payload } ) => {
            const { data, category } = payload;
            const key = getCategorySelectionKey( category );

            return {
                ...state,
                [key]: {
                    ...state[key],
                    itemIdViewDetail: data
                }
            };
        },
        [selectedCardSaveAction]: ( state, { payload } ) => {
            const { data, category } = payload;

            const key = getCategorySelectionKey( category );

            return {
                ...state,
                [key]: {
                    ...state[key],
                    hasSelectedAll: false,
                    arrID: data
                }
            };
        },
        [chooseAllCardSaveAction]: ( state, { payload } ) => {
            const { status, category, arr } = payload;
            const key = getCategorySelectionKey( category );

            return {
                ...state,
                [key]: {
                    ...state[key],
                    hasSelectedAll: status,
                    arrID: arr
                }
            };
        }
    },
    initialState,
);

export const statusFixture = {
    "items": [
        {
            "source": {
                "platform": {
                    "id": "5af1995cc093211f93599322",
                    "name": "Market Protect",
                    "code": "MP"
                },
                "id": "5af19963c093211f9a6ed0c2",
                "name": "Listing online/offline status",
                "code": "ADVERT_STATUS_ONLINE"
            },
            "parent": {
                "source": {
                    "platform": {
                        "id": "5af1995cc093211f93599322",
                        "name": "Market Protect",
                        "code": "MP"
                    },
                    "id": "5af19963c093211f9a6ed0c2",
                    "name": "Listing online/offline status",
                    "code": "ADVERT_STATUS_ONLINE"
                },
                "parent": null,
                "isDisabled": false,
                "id": "5d5a8854970a94470e1f56d2",
                "name": "Unavailable",
                "code": "UNAVAILABLE"
            },
            "isDisabled": false,
            "id": "5d5a89df970a9462492ce2e2",
            "name": "Unavailable to purchase",
            "code": "UNAVAILABLE_TO_PURCHASE"
        },
        {
            "source": {
                "platform": {
                    "id": "5af1995cc093211f93599322",
                    "name": "Market Protect",
                    "code": "MP"
                },
                "id": "5af19963c093211f9a6ed0c2",
                "name": "Listing online/offline status",
                "code": "ADVERT_STATUS_ONLINE"
            },
            "parent": {
                "source": {
                    "platform": {
                        "id": "5af1995cc093211f93599322",
                        "name": "Market Protect",
                        "code": "MP"
                    },
                    "id": "5af19963c093211f9a6ed0c2",
                    "name": "Listing online/offline status",
                    "code": "ADVERT_STATUS_ONLINE"
                },
                "parent": null,
                "isDisabled": false,
                "id": "5d5a8854970a94470e1f56d2",
                "name": "Unavailable",
                "code": "UNAVAILABLE"
            },
            "isDisabled": false,
            "id": "5d5a89d0970a9461957ce002",
            "name": "Sold out",
            "code": "SOLD_OUT"
        },
        {
            "source": {
                "platform": {
                    "id": "5af1995cc093211f93599322",
                    "name": "Market Protect",
                    "code": "MP"
                },
                "id": "5af19963c093211f9a6ed0c2",
                "name": "Listing online/offline status",
                "code": "ADVERT_STATUS_ONLINE"
            },
            "parent": {
                "source": {
                    "platform": {
                        "id": "5af1995cc093211f93599322",
                        "name": "Market Protect",
                        "code": "MP"
                    },
                    "id": "5af19963c093211f9a6ed0c2",
                    "name": "Listing online/offline status",
                    "code": "ADVERT_STATUS_ONLINE"
                },
                "parent": null,
                "isDisabled": false,
                "id": "5d5a8854970a94470e1f56d2",
                "name": "Unavailable",
                "code": "UNAVAILABLE"
            },
            "isDisabled": false,
            "id": "5d5a89c2970a946185579de2",
            "name": "Out of stock",
            "code": "OUT_OF_STOCK"
        },
        {
            "source": {
                "platform": {
                    "id": "5af1995cc093211f93599322",
                    "name": "Market Protect",
                    "code": "MP"
                },
                "id": "5af19963c093211f9a6ed0c2",
                "name": "Listing online/offline status",
                "code": "ADVERT_STATUS_ONLINE"
            },
            "parent": {
                "source": {
                    "platform": {
                        "id": "5af1995cc093211f93599322",
                        "name": "Market Protect",
                        "code": "MP"
                    },
                    "id": "5af19963c093211f9a6ed0c2",
                    "name": "Listing online/offline status",
                    "code": "ADVERT_STATUS_ONLINE"
                },
                "parent": null,
                "isDisabled": false,
                "id": "5d5a8854970a94470e1f56d2",
                "name": "Unavailable",
                "code": "UNAVAILABLE"
            },
            "isDisabled": false,
            "id": "5d5a885d970a9447201c5662",
            "name": "Ended",
            "code": "ENDED"
        },
        {
            "source": {
                "platform": {
                    "id": "5af1995cc093211f93599322",
                    "name": "Market Protect",
                    "code": "MP"
                },
                "id": "5af19963c093211f9a6ed0c2",
                "name": "Listing online/offline status",
                "code": "ADVERT_STATUS_ONLINE"
            },
            "parent": null,
            "isDisabled": false,
            "id": "5d5a8854970a94470e1f56d2",
            "name": "Unavailable",
            "code": "UNAVAILABLE"
        },
        {
            "source": {
                "platform": {
                    "id": "5af1995cc093211f93599322",
                    "name": "Market Protect",
                    "code": "MP"
                },
                "id": "5af19963c093211f9a6ed0c2",
                "name": "Listing online/offline status",
                "code": "ADVERT_STATUS_ONLINE"
            },
            "parent": null,
            "isDisabled": false,
            "id": "5af1996cc093211fa75cb032",
            "name": "Online",
            "code": "ONLINE"
        },
        {
            "source": {
                "platform": {
                    "id": "5af1995cc093211f93599322",
                    "name": "Market Protect",
                    "code": "MP"
                },
                "id": "5af19963c093211f9a6ed0c2",
                "name": "Listing online/offline status",
                "code": "ADVERT_STATUS_ONLINE"
            },
            "parent": null,
            "isDisabled": false,
            "id": "5af19968c093211fa020aca2",
            "name": "Offline",
            "code": "OFFLINE"
        }
    ],
    "page": 1,
    "limit": 50,
    "countResults": 7
};
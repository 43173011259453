import React from "react";
import {
    web
} from "components/Icons/Svg";
import {
    Checkbox
} from "components";
import "./CustomizeBlockWrapper.scss";

export const CustomizeBlockWrapper = ( props ) => {
    const {
        myRef,
        open,
        pageView,
        label,
        changeStateDropDown,
        cardList,
        rowHeightList,
        dragDropContext,
        selectAll,
        changeAll
    } = props;
    return (
        <div ref={ myRef } className="customize-body">
            <div className="flex">
                {label ? <div className="title">{label}</div> : null}
                <button type="button" onClick={()=>changeStateDropDown()}>
                    {web( "#809096" )}
                </button>
            </div>
            <div className={open ? "customize-menu show" : "customize-menu"}>
                {pageView === "block" ? (
                    <>
                        <div className="customize-menu__header">
                            <div className="title">Display options</div>
                        </div>
                        <div className="customize-menu__body">
                            <div className="list-block-views">
                                <div className="title">Card size:</div>
                                {cardList}
                            </div>
                        </div>
                    </>
                ) : pageView === "row" ? (
                    <>
                        <div className="customize-menu__header">
                            <div className="title">Show, Hide Columns</div>
                            <div className="flex">
                                <div>
                                    <Checkbox
                                        label="Select all"
                                        handleChange={() => changeAll( true )}
                                        defaultValue={selectAll}
                                    />
                                </div>
                                <div>
                                    <button type="button" onClick={() => changeAll( false )}>
                                        Reset
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="customize-menu__body">
                            {dragDropContext}
                        </div>
                        <div className="customize-menu__footer">
                            <div>Row height:</div>
                            <div>
                                {rowHeightList}
                            </div>
                        </div>
                    </>
                ) : null}
            </div>
        </div>
    );
};
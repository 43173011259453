import React, { PureComponent } from "react";
import { moreHoriz, deleteSvg, editSvg } from "components/Icons/Svg";
import "./Actions.scss";

export class CardActions extends PureComponent {
    constructor( props ) {
        super( props );
        this.myRef = React.createRef();
        this.state = {
            open: false,
            position: ""
        };
    }

    componentDidMount() {
        document.addEventListener( "click", this.documentClick );
    }

    componentWillUnmount() {
        document.removeEventListener( "click", this.documentClick );
    }

    getLengthData = () => {
        const {
            // horizontally = false,
            editBtn = true,
            deleteBtn = true
        } = this.props;
        if ( editBtn && deleteBtn ) return 2;
        return 1;
    };

    getPositionList = () => {
        const { dropHeight } = this.props;
        const element = this.myRef.current;
        const domRect = element.getBoundingClientRect();
        const bottom = window.innerHeight - domRect.bottom;
        const lengthList = this.getLengthData();
        let height = dropHeight ? dropHeight : lengthList * 54 + 20;
        this.setState( prevState => ( {
            open: !prevState.open,
            position: height > bottom ? "top" : "bottom"
        } ) );
    };

    documentClick = e => {
        const node = this.myRef.current;
        if ( e.target.closest( ".card-header-actions-body" ) !== node )
            this.closeDropDown();
    };

    changeStateCardActions = () => {
        const { disabled } = this.props;
        if ( disabled ) return;
        this.getPositionList();
    };

    closeDropDown = () => {
        this.setState( { open: false } );
    };

    removeItem = () => {
        const { id = null, deleteOneCardsAction = () => {} } = this.props;
        this.setState(
            {
                open: false
            },
            () => deleteOneCardsAction( id )
        );
    };

    editItem = () => {
        const { editAction = () => {} } = this.props;
        this.setState(
            {
                open: false
            },
            () => editAction()
        );
    };

    render() {
        const { open, position = "" } = this.state;
        const {
            horizontally = false,
            color = null,
            editBtn = true,
            deleteBtn = false
        } = this.props;
        let cardActionClassName = `card-header-actions-body ${position}`;
        if ( open ) cardActionClassName += " show";
        return (
            <div ref={this.myRef} className={cardActionClassName}>
                <span
                    role="presentation"
                    onClick={this.changeStateCardActions}
                    style={horizontally ? { transform: "rotate(90deg)" } : null}
                >
                    {moreHoriz( color ? color : "#809096" )}
                </span>
                <div className="card-header-actions-drop-down">
                    <ul>
                        {editBtn ? (
                            <li>
                                <button type="button" onClick={this.editItem}>
                                    {editSvg( "#809096" )} Edit
                                </button>
                            </li>
                        ) : null}
                        {deleteBtn ? (
                            <li>
                                <button type="button" onClick={this.removeItem}>
                                    {deleteSvg( "#809096" )} Delete
                                </button>
                            </li>
                        ) : null}
                    </ul>
                </div>
            </div>
        );
    }
}

import { ApiCreator } from "utils/connected";
import { BASE_URL_API } from "config";
const api = ApiCreator( BASE_URL_API );

export const startEnforcement = ( {
    brand = "1",
    ids,
    mark_id,
    active_module
} = {} ) => {
    const mode =
    active_module === "posts"
        ? "postMark"
        : active_module === "profiles"
            ? "profileMark"
            : null;
    const objKay =
    active_module === "posts"
        ? "post_ids"
        : active_module === "profiles"
            ? "profile_ids"
            : null;
    if ( !mode ) return null;
    let url = `generic/storage/${mode}/set`;
    const body = {
        mark_id: mark_id
    };
    body[objKay] = ids;
    return api.post( url, {
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json"
        },
        body: JSON.stringify( {
            mark_id: mark_id,
            [objKay]: ids
        } )
    } );
};

export const startInfringementType = ( {
    brand = "1",
    ids,
    type_id,
    active_module
} = {} ) => {
    const mode =
    active_module === "posts"
        ? "setTypeForPost"
        : active_module === "profiles"
            ? "setTypeForProfile"
            : null;
    const objKay =
    active_module === "posts"
        ? "post_ids"
        : active_module === "profiles"
            ? "profile_ids"
            : null;
    if ( !mode ) return null;
    let url = `generic/storage/infringementType/${mode}`;
    const body = {
        type_id: type_id
    };
    body[objKay] = ids;
    return api.post( url, {
        body: JSON.stringify( body ),
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json"
        }
    } );
};

export const startSetStatus = ( {
    brand = "1",
    ids,
    status_id,
    active_module
} = {} ) => {
    const mode =
    active_module === "posts"
        ? "setStatusForPost"
        : active_module === "profiles"
            ? "setStatusForProfile"
            : null;
    const objKay =
    active_module === "posts"
        ? "post_ids"
        : active_module === "profiles"
            ? "profile_ids"
            : null;
    if ( !mode ) return null;
    let url = `generic/storage/enforcementStatus/${mode}`;
    const body = {
        status_id: status_id
    };
    body[objKay] = ids;
    return api.post( url, {
        body: JSON.stringify( body ),
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json"
        }
    } );
};

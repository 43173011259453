import { connect } from "react-redux";
import { AddProfileForm as component } from "./AddProfileForm";

import { addCardItemAction } from "pages/SocialProtect/services/Card/actions";
import { countryListSelector } from "services/Country/selectors";
import { platformListSelector } from "services/Platform/selectors";
import { statusListSelector } from "services/Status/selectors";
import { labelsSelector } from "pages/SocialProtect/services/Label/selectors";
import { infringementTypeListSelector } from "services/InfringementType/selectors";

const mapDispatchToProps = {
    addCardItemAction
};

const mapStateToProps = state => {
    const countryListState = countryListSelector( state );
    const platformListState = platformListSelector( state );
    const statusListState = statusListSelector( state );
    const labelsState = labelsSelector( state );
    const infringementTypeListState = infringementTypeListSelector( state );
    const formFieldsValues =
    ( state.form.AddProfileForm && state.form.AddProfileForm.values ) || {};

    return {
        countryListState,
        platformListState,
        statusListState,
        labelsState,
        infringementTypeListState,
        formFieldsValues
    };
};

export const AddProfileForm = connect(
    mapStateToProps,
    mapDispatchToProps
)( component );

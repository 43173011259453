import React, { PureComponent } from "react";
import { arrow } from "components/Icons/Svg";
import {
    StatusDropdown,
    Button,
    ChangeLabel,
    EnforcementActionButtons,
    SelectButtons,
    SetStatusTypeButtons,
    OptionsTopBarWrapper,
    GridTableViewTopBar
} from "components";

export class OptionTopBarDisplay extends PureComponent {
    state = {
        open: false,
        sameLabelId: false,
        optionValue: null,
        dataCards: [],
        selectInfringementType: false,
        startEnforcement: false
    };

    static getDerivedStateFromProps( nextProps, prevState ) {
        if (
            nextProps.selectedCardsListState &&
            nextProps.selectedCardsListState.length &&
            nextProps.cardListOnPageState &&
            nextProps.length
        ) {
            const newData = [];
            nextProps.cardListOnPageState.forEach( el => {
                nextProps.selectedCardsListState.forEach( e => {
                    if ( e === el.id ) newData.push( el );
                } );
            } );
            let sameLabelId = false;
            if ( newData && newData.length ) {
                sameLabelId = !!newData.reduce( ( a, b ) => {
                    return ( ( a.mark && a.mark.id ) || NaN ) ===
                    ( ( b.mark && b.mark.id ) || NaN )
                        ? a
                        : NaN;
                } );
            }
            let sameTypeId = false;
            if ( newData && newData.length ) {
                sameTypeId = !!newData.reduce( ( a, b ) => {
                    return ( ( a.infringementType && a.infringementType.id ) || NaN ) ===
                    ( ( b.infringementType && b.infringementType.id ) || NaN )
                        ? a
                        : NaN;
                } );
            }
            let sameStatusId = false;
            if ( newData && newData.length ) {
                sameStatusId = !!newData.reduce( ( a, b ) => {
                    return ( ( a.infringementType && a.infringementType.id ) || NaN ) ===
                    ( ( b.infringementType && b.infringementType.id ) || NaN )
                        ? a
                        : NaN;
                } );
            }
            return {
                sameLabelId: sameLabelId
                    ? ( newData[0].mark && newData[0].mark.id ) || true
                    : false,
                sameTypeId: sameTypeId,
                sameStatusId: sameStatusId,
                dataCards: newData,
                openChange: nextProps.selectedPostMarkIdState
            };
        }
        return {
            open: false,
            openChange: false,
            dataCards: [],
            selectInfringementType: false
        };
    }

    handleOpenCardList = () => {
        this.setState( prevState => ( {
            open: !prevState.open
        } ) );
    };

    setCurrentFunction = e => {
        const { selectedPostMarkIdAction } = this.props;
        if ( e !== 1 ) this.setState( { startEnforcement: true } );
        this.setState( { optionValue: null }, () => selectedPostMarkIdAction( e ) );
    };

    setCurrentInfringementType = e => {
        //Save ID InfringementType in store
        const {
            selectInfringementTypeInTopBarAction,
            selectEnforcementStatusInTopBarAction
        } = this.props;
        const { optionValue } = this.state;
        if ( optionValue === "type" ) {
            selectInfringementTypeInTopBarAction( e );
        } else if ( optionValue === "status" ) {
            selectEnforcementStatusInTopBarAction( e );
        }
    };

    clearOptionValueProps = () => {
        const {
            selectInfringementTypeInTopBarAction,
            selectEnforcementStatusInTopBarAction
        } = this.props;
        selectInfringementTypeInTopBarAction( null );
        selectEnforcementStatusInTopBarAction( null );
    };

    setOptions = optionValue => {
        this.setState( {
            optionValue: optionValue,
            selectInfringementType: true
        } );
    };

    closeAndClear = () => {
        this.setCurrentFunction( null );
        this.setCurrentInfringementType( null );
        this.setState( {
            selectInfringementType: false,
            startEnforcement: false
        } );
    };

    closeTab = () => {
        this.setCurrentInfringementType( null );
        this.setState( {
            selectInfringementType: false
        } );
    };

    clickApplyInfringement = () => {
        const { selectedPostMarkIdAction } = this.props;
        this.setState( { startEnforcement: true } );
        selectedPostMarkIdAction( 1 );
    };

    startEnforcement = () => {
        const { startEnforcementAction } = this.props;
        const { optionValue } = this.state;
        return new Promise( ( resolve, reject ) => {
            startEnforcementAction( {
                optionValue: optionValue,
                resolve,
                reject
            } );
        } ).then( () => {
            this.setState( { startEnforcement: false }, () => this.clearOptionValueProps() );
        } );
    };

    render() {
        const {
            open,
            dataCards,
            selectInfringementType,
            startEnforcement,
            sameLabelId,
            optionValue,
            sameTypeId,
            sameStatusId
        } = this.state;
        const {
            selectedCardsListState = [],
            chooseAllCardAction = () => {},
            // deleteAllSelectedCardsAction = () => {},
            labelListState,
            selectedPostMarkIdState,
            startEnforcementLoaderState,
            infringementTypeListState,
            infringementTypeIdState,
            selectedEnforcementStatusIdState,
            tableProps,
            statusListState,
            activeModuleState
        } = this.props;
        const labelList = ( labelListState && labelListState.filter( ( e )=> e.name !== "Unhandled" ) ) || [];
        let changeListValues = [];
        let defaultLabel = null;
        if ( optionValue === "type" ) {
            changeListValues = infringementTypeListState;
            defaultLabel = infringementTypeIdState
                || getTypeID( dataCards[0], sameTypeId );
        } else if ( optionValue === "status" ) {
            changeListValues = statusListState;
            defaultLabel = selectedEnforcementStatusIdState
                || getStatusID( dataCards[0], sameStatusId );
        }

        const statusDefaultValue = ( ( sameLabelId && typeof sameLabelId === "number" ) ? sameLabelId : selectedPostMarkIdState ) || "";
        let wrapperClassName = "options-top-bar noselect";
        if ( selectedCardsListState.length ) wrapperClassName += " show";
        return (
            <OptionsTopBarWrapper
                wrapperClassName={ wrapperClassName }
                background={ open && (
                    <div
                        role="button"
                        tabIndex={0}
                        className="bg-list-top-bar"
                        onClick={this.handleOpenCardList}
                        onKeyPress={e => e.keyCode === 13 ? this.handleOpenCardList() : false}
                    />
                ) }
                body={
                    <>
                        { open && (
                            <div className="options-top-bar__list scroll-m">
                                <div>
                                    <GridTableViewTopBar
                                        {...tableProps}
                                        data={ dataCards }
                                        activeModuleState={ activeModuleState }
                                    />
                                </div>
                            </div>
                        )}
                        <div className="options-top-bar__options">
                            <div className="list-actions">
                                <button
                                    type="button"
                                    className={open ? "btn-bar__list show" : "btn-bar__list"}
                                    onClick={this.handleOpenCardList}
                                >
                                    {arrow()}
                                </button>
                                {
                                    startEnforcement ? (
                                        <EnforcementActionButtons
                                            isEnforcementLoading={ startEnforcementLoaderState }
                                            startEnforcement={ this.startEnforcement }
                                            closeEnforcement={ this.closeAndClear }
                                            textMessage={"Are you sure you want to enforce the following this posts?"}
                                        />
                                    ) : (
                                        <>
                                            <div className="select_block">
                                                {/*@TODO: ADD loader*/}
                                                <StatusDropdown
                                                    wrapperClassName="status-dropdown"
                                                    data={labelList}
                                                    defaultValue={statusDefaultValue}
                                                    handleChange={this.setCurrentFunction}
                                                />

                                                {
                                                    ( ( sameLabelId && sameLabelId === 1 ) ||
                                                        ( selectedPostMarkIdState && selectedPostMarkIdState === 1 ) ) &&
                                                    <SetStatusTypeButtons
                                                        setOptions={ this.setOptions }
                                                        sameTypeId={ sameTypeId }
                                                        sameStatusId={ sameStatusId }
                                                        data={ dataCards[0] }
                                                    />
                                                }

                                                {selectInfringementType ? (
                                                    <ChangeLabel
                                                        cssInner="top-bar"
                                                        open={selectInfringementType}
                                                        title={optionValue}
                                                        defaultValue={defaultLabel}
                                                        data={changeListValues}
                                                        handleChange={this.setCurrentInfringementType}
                                                    >
                                                        <Button
                                                            text="Apply"
                                                            handleClick={this.clickApplyInfringement}
                                                        />
                                                        <Button
                                                            text="Cancel"
                                                            color="secondary"
                                                            handleClick={this.closeTab}
                                                        />
                                                    </ChangeLabel>
                                                ) : null}
                                                <SelectButtons
                                                    handleClick={this.handleOpenCardList}
                                                    length={selectedCardsListState.length}
                                                    chooseAll={chooseAllCardAction}
                                                />
                                            </div>
                                        </>
                                    )
                                }
                            </div>
                        </div>
                    </>
                }
            />
        );
    }
}

const getTypeID = ( data, sameTypeId ) => {
    const CurrentTypeID = data && data.infringementType && data.infringementType.name;
    return sameTypeId && CurrentTypeID;
};

const getStatusID = ( data, sameStatusId ) => {
    const CurrentStatusID = data && data.enforcementStatus && data.enforcementStatus.id;
    return sameStatusId && CurrentStatusID;
};


import { createAction } from "redux-actions";

//Save filter values
export const saveFilterAction = createAction(
    "saveFilterAction"
);
export const searchByFilterAction = createAction(
    "searchByFilterAction"
);
export const saveFilterAppliedAction = createAction(
    "saveFilterAppliedAction"
);
export const saveFilterAndSearchAction = createAction(
    "saveFilterAndSearchAction"
);
export const storeFilterAction = createAction( "storeFilterAction" );

export const saveTitleFilterAction = createAction(
    "saveTitleFilterAction"
);

export const saveSearchWordTitlePostAction = createAction(
    "saveSearchWordTitlePostAction"
);
export const saveSearchWordTitleProfileAction = createAction(
    "saveSearchWordTitleProfileAction"
);

export const saveProfileFilterAction = createAction(
    "saveProfileFilterAction"
);
export const saveSearchWordProfileNameInPostAction = createAction(
    "saveSearchWordProfileNameInPostAction"
);
export const saveSearchWordProfileNameInProfileAction = createAction(
    "saveSearchWordProfileNameInProfileAction"
);

export const selectInfringementTypeInTopBarAction = createAction(
    "selectInfringementTypeInTopBarAction"
);
export const selectEnforcementStatusInTopBarAction = createAction(
    "selectEnforcementStatusInTopBarAction"
);

import React, { PureComponent } from "react";
import {
    SocialProtectAddPost,
    SocialProtectAddProfiles
} from "pages";
import {
    SideBar,
    Header,
    PageRoute,
    ViewDetails,
    OptionsTopBar
} from "pages/SocialProtect/components";
import { TabBar, NoMatch } from "components";
import { Route, Switch, Redirect, NavLink } from "react-router-dom";

export class Dashboard extends PureComponent {
    constructor( props ) {
        super( props );
        const { match } = this.props;
        this.baseUrl =
        match.url[match.url.length - 1] === "/" ? match.url : match.url + "/";
    }

    render() {
        const { sideBarIsOpen, activeModuleState } = this.props;
        const addItemPage =  this.props.location.pathname.split( "/" ).includes( "add" );

        let pageClassName = "page bg-primary-transparent";
        if( addItemPage ) pageClassName = "bg-primary-transparent";

        if ( sideBarIsOpen ) pageClassName += " with-open-side-bar";

        if ( !activeModuleState ) return <Redirect to={`${this.baseUrl}posts`} />;

        return (
            <div>
                {/*SideBar start*/}
                { !addItemPage && <SideBar /> }
                {/*SideBar end*/}

                {/*SpOptionsTopBar start*/}
                <OptionsTopBar />
                {/*SpOptionsTopBar end*/}

                <div className={pageClassName}>
                    <div className="page-container scroll-m">
                        {/*Header start*/}
                        <Header title="Social Protect">
                            <TabBar>
                                <NavLink to="/social-protect/posts" activeClassName="active">
                                    Posts
                                </NavLink>
                                <NavLink to="/social-protect/profiles" activeClassName="active">
                                    Profiles
                                </NavLink>
                            </TabBar>
                        </Header>
                        {/*Header end*/}

                        {/*View Details start*/}
                        <ViewDetails />
                        {/*View Details end*/}

                        <Switch>
                            <Route
                                path={`${this.baseUrl}`}
                                exact
                                component={()=>(
                                    <Redirect to={`${this.baseUrl}posts`} />
                                )}
                            />
                            <Route path={`${this.baseUrl}posts`} exact component={PageRoute} />
                            <Route path={`${this.baseUrl}profiles`} exact component={PageRoute} />
                            <Route
                                exact path={`${this.baseUrl}posts/add`}
                                component={SocialProtectAddPost}
                            />
                            <Route
                                exact path={`${this.baseUrl}profiles/add`}
                                component={SocialProtectAddProfiles}
                            />
                            <Route component={NoMatch} />
                        </Switch>
                    </div>
                </div>
            </div>
        );
    }
}

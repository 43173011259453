import React from "react";
import { urlUtil } from "utils";
import { RowLinkView, TitleDetails, RowStatistics, ListDetails } from "components";
const { extractHostname } = urlUtil;

export const PostInfo = ( { data } ) => {
    return (
        <div className="info-wrapper">
            <TitleDetails text={"Post information"} />
            <RowStatistics
                data={[
                    { label: "Views:", value: data.listingViews },
                    { label: "Likes:", value: data.listingLikes },
                    { label: "Shares:", value: data.listingShares },
                    { label: "Followers:", value: data.listingFollowers }
                ]}
            />
            <ListDetails
                data={[
                    { label: "Platform:", value: data.platform.name },
                    { label: "Country:", value: data.countryName },
                    { label: "Post URL:", value: data.listingURL && (
                        <RowLinkView
                            label={data.platform.name}
                            iconLink={data.listingURL}
                            targetBlank={true}
                            handleClickIcon={() => {
                                window.open( data.listingURL, "_blank" );
                            }}
                        />
                    ) },
                    { label: "Post ID:", value: data.id },
                    { label: "Evidence URL", value: data.evidenceURL && (
                        <RowLinkView
                            label={data.evidenceURL}
                            iconLink={data.evidenceURL}
                            targetBlank={true}
                            handleClickIcon={() => {
                                window.open( data.evidenceURL, "_blank" );
                            }}
                        />
                    ) },
                    { label: "Submitted by", value: data.submittedBy },
                    { label: "Connected to", value: data.connectedTo && (
                        <RowLinkView
                            label={extractHostname( data.connectedTo )}
                            iconLink={data.connectedTo}
                            targetBlank={true}
                            handleClickIcon={() => {
                                window.open( data.connectedTo, "_blank" );
                            }}
                        />
                    ) },
                    { label: "Batch upload ID", value: data.batchUploadID }
                ]}
            />
        </div>
    );
};
